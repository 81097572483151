import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Think about a significant event that took place in your sample system. Use
      the iceberg to help you surface the mental models that influenced the event. How
      did the event impact, modify or sustain existing mental models?
    </p>
    <p>Respond to the questions below the water level.</p>
    <div className='iceberg'>
      <div className='q-container event'>
        <p className='iceberg-question'>Describe the event</p>
        <Field
          name='iceberg-event'
          component='textarea'
          className='iceberg-input'
          placeholder='Enter your response here.'
        />
      </div>
      <div className='q-container'>
        <p className='iceberg-question'>What are some mental models that influenced this event?</p>
        <Field
          name='iceberg-one'
          component='textarea'
          className='iceberg-input'
          placeholder='Enter your response here.'
        />
      </div>
      <div className='q-container two'>
        <p className='iceberg-question'>How did this event influence the development of mental models?</p>
        <Field
          name='iceberg-two'
          component='textarea'
          className='iceberg-input'
          placeholder='Enter your response here.'
        />
      </div>
    </div>
  </div>
