import * as React from 'react'
import { Field } from 'redux-form'
import { ToggleText } from 'components/shared/toggle-text'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>

    <p>
      Let’s revisit one of the causal pairs we reviewed from the previous lesson.
    </p>

    <div className='causal-link'>
      <div className='causal-column'>
        <h4>
          Level of cooking confidence
        </h4>
      </div>

      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />

        <p className='link-label blue'>S</p>
      </div>

      <div className='causal-column'>
        <h4>
          Courage to serve new recipes
        </h4>
      </div>

    </div>

    <p>
      As a practice, tell the virtuous and vicious stories of this causal pair.
    </p>

    <h4>
      Virtuous Story
    </h4>

    <Field
      name='virtuous'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <ToggleText
      showText='Show Hint'
      hideText='Hide Hint'
      text={[{ id: 1, text: 'When level of cooking confidence increases, the courage to serve new recipes increases.' }]}
    />

    <h4>
      Vicious Story
    </h4>

    <Field
      name='vicious'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <ToggleText
      showText='Show Hint'
      hideText='Hide Hint'
      text={[{ id: 1, text: 'When the level of cooking confidence decreases, the courage to serve new recipes decreases.' }]}
    />

    <p>
      Use the list of variables below and identify two more causal pairs.  Fill in the blanks in the diagram and tell the story of your causal connections.
    </p>

    <h3>
      Variables:
    </h3>

    <ul>
      <li>
        Courage to serve a new recipe
      </li>

      <li>
        Level of satisfaction in serving a new recipe
      </li>

      <li>
        Willingness to try new recipes
      </li>

      <li>
        Fear of serving a new recipe
      </li>

      <li>
        # of times new recipes are served
      </li>

      <li>
        Level of cooking confidence
      </li>

      <li>
        Quality of the meal
      </li>

      <li>
        Opportunities for guest appreciation
      </li>

      <li>
        # of guest compliments
      </li>

      <li>
        Anticipation of guest reaction
      </li>

      <li>
        Level of guest satisfaction
      </li>

      <li>
        Amount of food consumed on each plate
      </li>
    </ul>

    <h3>#1</h3>

    <div className='causal-link'>
      <div className='causal-column'>
        <Field
          name='variable1'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your first variable.'
        />
      </div>

      <div className='causal-column field-container'>
        <Field
          name='link-label-1'
          component='input'
          className='variable-field'
        />

        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>

      <div className='causal-column'>
        <Field
          name='variable2'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your second variable.'
        />
      </div>
    </div>

    <h4>
      Tell the story of your link:
    </h4>

    <Field
      name='story1'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h3>#2</h3>

    <div className='causal-link'>
      <div className='causal-column'>
        <Field
          name='variable3'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your first variable.'
        />
      </div>

      <div className='causal-column field-container'>
        <Field
          name='link-label-2'
          component='input'
          className='variable-field'
        />

        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>

      <div className='causal-column'>
        <Field
          name='variable4'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your second variable.'
        />
      </div>
    </div>

    <h4>
      Tell the story of your link:
    </h4>

    <Field
      name='story2'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
