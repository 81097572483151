import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='article-container'>

      <p><em>James “Jim” L. Waters</em></p>
      <p><em>October 7, 1925 – May 17, 2021</em></p>
      <p><em>Founder of Waters Center for Systems Thinking</em></p>

      <img src='images/resources/jim-waters-tribute/jim-students.jpg' className='med-img' />
      <p>Systems thinkers make the world a better place.</p>
      <p>This was the belief of our founder, Jim Waters, who passed away peacefully on May 17, 2021 at the age of 95. It is this belief that fueled the work of the Waters Foundation, which evolved into the Waters Center for Systems Thinking non-profit organization in 2019.</p>
      <p>Jim’s approach to learning and life make up the fabric of the Waters Center — and we were privileged to have Jim as an active part of our work, providing guidance, counsel, and friendship up until his passing.</p>
      <p>Jim’s mantra, one that he was known for and that we were reminded of daily, was to “deliver benefit.” Essentially, it’s not enough to deliver a service — you must provide something of unique value to others by having an acute understanding of their needs, desires, and goals. Jim believed building the capacity of people’s systems thinking skills not only delivered benefit to them, but it also delivered benefit to the people they interact with – whether that be in a business, personal, or community setting. A delivering benefit feedback loop!</p>
      <p>Jim credited his commitment to delivering benefit for his success as a businessman. In 1958, Jim founded Waters Associates (now known as The Waters Corporation) which manufactures analytical laboratory instruments. He started with 5 employees, operating out of the basement of the Framingham Police Department in Massachusetts. Today, the Waters Corporation operates in 35 countries, with more than 7,400 employees and more than $2 billion in revenue.</p>
      <p>Jim would often remark that successive approximation, or his ability to identify a challenge, implement changes, and monitor outcomes, was a huge driver of his success. It’s also what made him a brilliant scientist; Jim was a pioneer in liquid chromatography, a technique for separating and isolating the chemical components in a liquid mixture so they can be accurately measured.</p>
      <p>Without knowing the term, Jim was using systems thinking since childhood. He found great joy in applying systems thinking to everyday events. In fact, recently, Jim kept a log of daily anecdotes of how he used systems thinking – everywhere from the dinner table to his consulting role involving new patents and the development of improved scientific instruments. Jim was formally introduced to systems thinking in the early 1980s by Dr. Jay Forrester of the Massachusetts Institute of Technology (MIT). A few years later, Jim learned that Dr. Gordon Brown, Dean Emeritus of the College of Engineering at MIT, was sharing systems thinking with educators in Tucson, Arizona, to enhance the way PreK-12 students approached learning.</p>
      <p>Jim jumped at the opportunity to get involved. He and his beloved wife, Faith, a former teacher, visited Tucson to see the work firsthand. They joined teachers and administrators in meetings and engaged with students in classrooms.</p>
      <p>The Waters Foundation launched its Systems Thinking and Dynamic Modeling Partnerships in 1989 at the Catalina Foothills School District in Tucson, Arizona. In the years that followed, the work expanded to schools across the United States. Today, the Waters Center for Systems Thinking delivers benefits to classrooms, schools, organizations, and communities around the globe — none of which would be possible without Jim and Faith’s vision.</p>

      <img src='images/resources/jim-waters-tribute/jim-faith-smaller' className='lesson-img med-img' />

      <p>Jim believed systems thinkers made the world a better place — and he, without a doubt, did just that. His legacy will live on in the hearts and minds of learners around the world for generations to come. It has been the privilege of a lifetime to know Jim and to carry out his mission of delivering benefits — something we will continue to do in his honor.</p>
      <p>To read more about Jim Waters, <a href='https://www.bostonglobe.com/2021/05/19/business/waters-corp-founder-namesake-jim-waters-dies-age-95/' target='_blank' rel='noopener noreferrer'>click this link</a> for a feature in the Boston Globe.</p>
      <p>To learn more about the history of the Waters Center for Systems Thinking, <a href='https://waterscenterst.org/who-we-are?tab=history' target='_blank' rel='noopener noreferrer'>click here</a>.</p>
    </div>
  </div>
