import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-double'
        src='/images/resources/double-ladders/double-ladder.svg'
        alt='Double Ladder of Inference'
      />

      <img
        className='template-double'
        src='/images/resources/double-ladders/double-ladder-data.svg'
        alt='Double Ladder of Inference with Data Questions'
      />
    </div>
  </div>
