import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <div className='quote'>
      <p className='quote-text'>“It’s not easy, but it’s simple.”</p>
      <p className='quote-author'> — Eric Thomas, author and motivational speaker</p>
    </div>
    <img
      className='lesson-img'
      src='/images/courses/tools/01-behavior-over-time-graphs/lesson-one-img.png'
      alt='Quote'
    />
    <p>The behavior-over-time graph (BOTG) is a systems thinking tool that illustrates patterns and trends. A BOTG shows how something changes over time. It is elegant in its simplicity, and yet is a valuable tool for understanding complexity. A BOTG is a line graph for which the X-axis always shows time. The Y-axis provides a scale for what is changing. The line of the graph tells the story of the variable or element being graphed.</p>
    <p>Behavior-over-time graphs (BOTGs) make thinking visible. They communicate differently than words alone, and as such, allow users to explain their thinking — to pause and think about information in new ways. They help people focus on patterns of change over time, rather than on isolated events.  A BOTG can lead to rich discussions and promote deeper thinking about how and why something is changing.</p>
    <p>After completing this course you will be able to:</p>
    <ul>
      <li>Identify elements of a system that are changing over time.</li>
      <li>Use BOTGs to represent and explain dynamic systems.</li>
      <li>Observe a system in motion.</li>
      <li>Label a graph’s axes in a variety of ways.</li>
    </ul>
    <img
      className='lesson-img'
      src='/images/courses/tools/01-behavior-over-time-graphs/lesson-one-img2.jpg'
      alt='BOTG Graph'
    />
    <h3>Behavior-Over-Time Graph Basics</h3>
    <p>The BOTG is a tool that can help people focus on patterns of change over time, rather than on isolated events. They measure change over time and show what happened in the system as represented by the graphed component.</p>
    <p>Here are some important components of a BOTG to keep in mind:</p>
    <ul>
      <li>A BOTG is a line graph showing the trend or pattern of change of a variable over time.</li>
      <li>A BOTG includes an X and a Y-axis.</li>
      <li>The X-axis is always labeled in units of time.</li>
      <li>The Y-axis identifies the variable being graphed and must be labeled with that variable’s name and a scale by which to measure the variable's change.</li>
    </ul>
    <img
      className='lesson-img'
      src='/images/courses/tools/01-behavior-over-time-graphs/lesson-one-img3.png'
      alt='Bald Eagle BOTG'
    />
    <p>Graphs are often used to show a specific set of data.  For example, the daily high temperature for the last 30 days, a student’s weekly spelling scores throughout the grading period, or the bald eagle population over the last 40 years. These are all examples of commonly created BOTGs. A systems thinker observes the graph and asks questions about patterns and trends. Has the temperature been fairly stable over the last 30 days or has it shown more of an oscillating pattern? What direction is the student’s spelling score trending? Will the eagle population continue to grow at the same rate; why or why not?</p>
    <p>In addition to representing data that is easily displayed in terms of hard numbers, BOTGs can also be used to display data that is less concrete. The line of the graph can tell the story of an individual’s perception of a system. For example, morale in a company during the first six months of a corporate takeover, a child’s excitement in the weeks before her/his birthday, or anxiety felt before, during and after a visit to the dentist.</p>
  </div>
