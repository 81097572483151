import * as React from 'react'
import Helmet from 'react-helmet'
import { NoAuthComponent } from 'components/auth'
import { ImageSlider } from 'components/shared/image-slider'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

const AuthHome = () => (
  <div>
    <Header />
    <div className='flow-container'>
      <Helmet>
        <title>Thinking Tools Studio</title>
        <meta name='description' content='Welcome to the Thinking Tools Studio.' />
      </Helmet>

      <section className='photo-header home'>
        <h1>
          Become a systems thinker in your own time and in your own way – free of charge.
        </h1>

        <a href='https://waterscenterst.org/join'><button className='orange big'>Get Started</button></a>
      </section>

      <section className='split white space-between'>
        <div className='w60'>
          <div className='videowrap'>
            <iframe width='800' height='450' src='https://www.youtube.com/embed/SvlS0VQ8YnY?rel=0' frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
          </div>
        </div>

        <div className='w40'>
          <h4 className='blue'>
            Welcome to the Thinking Tools Studio
          </h4>

          <p>
            The Thinking Tools Studio is brought to you by the Waters Center for Systems Thinking. We are committed to delivering benefits to users through engaging, innovative and applicable content free of charge and full of learning.
          </p>

          <p>
            With over 30 years of experience in the field, we’ve curated the Studio with content suitable for all types of learners and applicable to any system of interest.
          </p>
        </div>
      </section>

      <section className='grey'>

        <ImageSlider
          slides={[
            { id: 1, text: 'Interactive courses on the Habits, tools, and archetypes of systems thinking with opportunities for practice and reflection for both individuals and groups', link: '/features', image: '/images/site/courses-icon.svg' },
            { id: 2, text: 'Resources like lesson plans, facilitation guides, templates and assessments', link: '/features', image: '/images/site/features-icon.svg' },
            { id: 3, text: 'Regularly added and updated courses, resources, and features', link: '/features', image: '/images/site/benefits-icon.svg' }]}
        />

      </section>

      <section className='split white'>
        <div className='benefits-img' />

        <div className='stacked'>
          <h4 className='blue centered'>
            Benefits of Systems Thinking
          </h4>

          <p className='centered'>
            What makes systems thinking so powerful? It provides Habits, strategies, and tools for overcoming complex challenges. From the classroom to the boardroom, it puts desired results within reach.<br /><a href='/benefits'>Learn More</a>
          </p>

          <p className='centered'>
            The Thinking Tools Studio is a free service. To maintain this status and support our delivery of updated resources and features, we rely on donations from individuals and organizations.
          </p>

          <a href='/donate'><button className='orange block'>Donate</button></a>
        </div>

        <div />

      </section>
    </div>
    <Footer />
  </div>
)
export const Home = NoAuthComponent(AuthHome)
