import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/04-perspectives/lesson-three-img.png'
      alt='Dialogue'
    />
    <p>One strategy to help you intentionally practice being open to other
      perspectives is <b>Dialogue</b>. There are several excellent resources to help you deepen your
      understanding and practice of dialogue. The classic work, <a href='http://sprott.physics.wisc.edu/Chaos-Complexity/dialogue.pdf' target='_blank' rel='noopener noreferrer'>"On Dialogue,"</a> is a collection of essays written in the 1970s and 80s by legendary physicist David Bohm.
    </p>
    <p>Bohm provides this definition; “'Dialogue' comes from the Greek word
      dialogos. Logos means 'the word' or in our case we would think of the 'meaning
      of the word'. And dia means 'through' - it doesn't mean two. A dialogue can be
      among any number of people, not just two. Even one person can have a sense of
      dialogue within himself, if the spirit of the dialogue is present. The picture
      of image that this derivation suggests is of a stream of meaning flowing among
      and through us and between us. This will make possible a flow of meaning in the
      whole group, out of which will emerge some new understanding. It's something
      new, which may not have been in the starting point at all. It's something
      creative. And this shared meaning is the 'glue' or 'cement' that holds people
      and societies together.”
    </p>
    <p>Dialogue is a discipline, and as such, differs greatly from informal
      discourse, discussion, debate or idle chatter. Dialogue asks us to step away
      from our deeply held judgements and resist the tendency to place value on what
      people offer or the perspectives they hold. It’s the difference between hearing
      what people say and listening with the intent to understand the message of the words spoken.
    </p>
    <p>There are very different intentions between the discipline of dialogue and
      another mode of group communication referred to as skillful discussion.
    </p>
    <p>One way to see the value of both is to consider an important decision a group
      needs to make. Before weighing possibilities and advocating for one course of
      action versus another, a group could allocate time for dialogue to open up the
      conversation and to explore, discover and gain insight by surfacing multiple
      possibilities before making judgement. After some time, an intentional shift to
      skillful discussion is useful to begin to converge the ideas surfaced during
      dialogue. Skillful discussion helps us reach a decision or agreement, and also
      establish priorities.
    </p>
    <img
      className='diagram'
      src='/images/courses/habits/04-perspectives/lesson-3-diagram.png'
      alt='Dialogue'
    />
    <div className='resources'>
      <p id='reflection-question'>Other resources to help you learn more about dialogue include:</p>
      <ul>
        <li>
          <a
            href='https://thesystemsthinker.com/dialogue-the-power-of-collective-thinking/'
            target='_blank' rel='noopener noreferrer'
          >Dialogue: The Power of Collective Thinking
          </a>, by William N. Isaacs
        </li>
        <li>
          <a
            href='https://thesystemsthinker.com/the-process-of-dialogue-creating-effective-communication/'
            target='_blank' rel='noopener noreferrer'
          >The Process of Dialogue: Creating Effective Communication
          </a>, by Edgar H. Schein
        </li>
        <li>
          <a
            href='https://thesystemsthinker.com/flexing-a-different-conversational-muscle-the-practice-of-dialogue/'
            target='_blank' rel='noopener noreferrer'
          >Flexing a Different Conversational “Muscle”: The Practice of Dialogue
          </a>, by Glenna Gerard and Linda Ellinor
        </li>
        <li>
          <a
            href='https://www.amazon.com/Dialogue-Thinking-Together-William-Isaacs/dp/0385479999/ref=pd_sim_14_2?_encoding=UTF8&pd_rd_i=0385479999&pd_rd_r=E90D1KSAKDSF1RXGN1TZ&pd_rd_w=1khwD&pd_rd_wg=stRbt&psc=1&refRID=E90D1KSAKDSF1RXGN1TZ'
            target='_blank' rel='noopener noreferrer'
          >Dialogue: The Art of Thinking Together
          </a>, by William N. Isaacs
        </li>
      </ul>
    </div>
  </div>
