import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/15-successive-approximation/lesson-three-img.jpg'
      alt='Kids with a map'
    />
    <p>It would be difficult to get through a day without the continuous adjustment we make to our thinking and actions based on our experiences.  You may be driving to a new destination and realize you are in unfamiliar surroundings and likely lost and off course.  You have several ways to get more information that include GPS systems, stopping at a gas station to ask for directions, or accessing a map.  Once you make adjustments and get back on course, you still check for indicators that you are on track and continue to make adjustments to ensure you reach your destination. This Habit is about learning; and learning can be about progress, but also about setbacks.  Systems thinkers look for opportunities to learn from the setbacks, mistakes or failures in order to adjust, improve and get back on course. The value of disappointing results that lead to modification is seen in science, medicine, education, engineering and many other areas of our world and lives.</p>
  </div>
