import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='images/courses/tools/04-iceberg/iceberg-photo.jpg'
      alt='iceberg'
    />

    <p>
      In your systems thinking learning journey you are developing an understanding of a system as a group of interacting, interrelated, interdependent parts that form a complex, unified whole. We know that all systems have a purpose and are comprised of structures incorporating feedback that inform the system’s behavior. As a reminder,  a system’s “structure” is defined as the way in which the parts of a system are interconnected or organized. You may remember this quote by W. Edward Deming from previous courses: “Every system is perfectly designed (structured) to get the results that it does.”
    </p>

    <p>
      The visual of an iceberg is an excellent metaphor for the process used to examine problems and solutions. Above the water line of the iceberg is what is visable; in other words, the events we experience day-to-day. A deeper understanding of the system that produces day-to-day events lies below the water line of the iceberg. Systems thinkers develop this understanding below the water surface by utlizing both Habits and tools of systems thinking.
    </p>

    <h1>
      Let’s take a look at the different levels of the iceberg.
    </h1>

    <div className='split'>
      <img
        className='lesson-img wide-berg'
        src='images/courses/tools/04-iceberg/iceberg-levels.svg'
        alt='iceberg'
      />

      <div className='exerpt'>
        <p>
          The iceberg encourages us to observe how elements within systems change over time and how a system’s structure generates its behavior.  The iceberg gives us an opportunity to go beyond day-to-day events so that we can utilize a deeper understanding to identify possible leverage actions.
        </p>
      </div>
    </div>

    <p>
      The iceberg can be used to identify and implement high-leverage, lasting change to a recurring issue. An example of a common challenge encountered in the workplace is the need for effective communication. This example will be useful as we start to explore the different levels of the iceberg.
    </p>

    <div className='berg-level'>
      <h3>
        Start at the tip of the iceberg and begin by asking, “What is happening?”
      </h3>

      <img
        className='lesson-img small-berg'
        src='images/courses/tools/04-iceberg/iceberg-tip.svg'
        alt='iceberg tip'
      />

      <p>
        To use workplace communication as an example, you might notice a lack of communication among staff and hear complaints about team member interactions.  To respond to the recurring complaints, you could send an email to everyone, stressing the need for timely and consistent communication, reiterating the expectations and policies that are in place. This strategy may possibly improve communication, but without a deep understanding of the underlying causes, it is doubtful that an email will change the system. The iceberg helps people investigate system challenges like poor communication with key questions and visual tools used at levels below the surface.
      </p>
    </div>

    <div className='berg-level'>
      <h3>
        Evaluate Patterns & Trends
      </h3>

      <img
        className='lesson-img small-berg'
        src='images/courses/tools/04-iceberg/iceberg-patterns.svg'
        alt='iceberg patterns'
      />

      <p>
        At this level, systems thinkers go beyond what they initially observe and begin to look for patterns and trends.<br />Questions to ask:
      </p>

      <ul>
        <li>
          <em>What has been happening?</em>
        </li>

        <li>
          <em>Have there been issues with communication?</em>
        </li>

        <li>
          <em>How have the issues been addressed?</em>
        </li>

        <li>
          <em>What changes occurred?</em>
        </li>

        <li>
          <em>What can change over time, and what would that change look like?</em>
        </li>
      </ul>

      <p>
        Sample responses include:
      </p>

      <ul>
        <li>
          “We have been experiencing an increase in number of complaints.”
        </li>

        <li>
          “We see a dip in the quality of written communications as people need more clarity.”
        </li>

        <li>
          “Too many emails are needed for basic directives or expectations.”
        </li>
      </ul>
    </div>

    <div className='berg-level'>
      <h3>Examine Structures</h3>

      <img
        className='lesson-img small-berg'
        src='images/courses/tools/04-iceberg/iceberg-structures.svg'
        alt='iceberg structures'
      />

      <p>
        Examining the issue further, systems thinkers look for the structures that are generating the trending behaviors the system is experiencing.<br />Questions to ask:
      </p>

      <ul>
        <li>
          <em>What is causing this issue?</em>
        </li>

        <li>
          <em>Why are these patterns and trends happening?</em>
        </li>

        <li>
          <em>What structures (practices, policies, professional relationships) are in place that are causing these patterns?</em>
        </li>
      </ul>

      <p>Sample responses may include:</p>

      <ul>
        <li>
          Perhaps there are not enough opportunities for open or regular communication (team meetings, etc.) or the policies mentioned above have not been effectively communicated to all team members.
        </li>

        <li>
          Maybe the quality of professional relationships that impact trust and collegiality are affecting our ability to communicate effectively.
        </li>
      </ul>
    </div>

    <div className='berg-level'>
      <h3>
        Uncover Mental Models
      </h3>

      <img
        className='lesson-img small-berg'
        src='images/courses/tools/04-iceberg/iceberg-models.svg'
        alt='iceberg models'
      />

      <p>
        At the deepest level of the iceberg, systems thinkers consider the mental models that are both contributing to trending behaviors and impacting their abilities to solve the communication issue. Mental models are an important component of systems structure. <br />
        Questions to ask:
      </p>

      <ul>
        <li>
          <em>What perceptions do people hold of the expectations and policies in place regarding communication?  </em>
        </li>

        <li>
          <em>Are there different values placed on communication? </em>
        </li>

        <li>
          <em>How can a team develop an awareness of how mental models present a barrier to effective communication?</em>
        </li>
      </ul>

      <p>
        Sample responses include:
      </p>

      <ul>
        <li>
          Some people need more information than others.
        </li>

        <li>
          People have different levels of trust in one another.
        </li>

        <li>
          Sometimes, people are not fully transparent when making decisions which challenges the value of being honest.
        </li>
      </ul>
    </div>

    <p>
      The above example dives into each level of the iceberg and does so by starting at the top and working down toward the bottom—or, the last level of the iceberg where mental models are uncovered. However, the iceberg does not necessarily need to be worked through in this order. When identifying an issue to be addressed, it’s up to the user to decide where to start; in other words, which level of the iceberg best meets the circumstances at hand. A person or group can start at any level of the iceberg they feel is most appropriate and work their way up or down the framework from there.
    </p>

    <h3>
      The Systems Thinking Iceberg: How Does It Work?
    </h3>

    <p>
      One of the Habits of a Systems Thinker asks us to <strong><em>consider an issue fully and resist the urge to come to a quick conclusion.</em></strong> This Habit reminds us to take the time necessary to understand the dynamics of a system before taking action. Using the iceberg to take a deep dive when examining a work-related challenge or goal allows us to shift from a “reactive” decision making or problem solving approach to to a “proactive” approach.
      {/* The framework below provides a series of questions to get you started. */}
    </p>

    <h3>
      An Iceberg Example: Implementation of a New Reading Program
    </h3>

    <img
      className='lesson-img wide-berg left'
      src='images/courses/tools/04-iceberg/iceberg-example.png'
      alt='iceberg example'
    />

    <p>
      Notice the two arrows to the left of the iceberg. The deep learning arrow indicates that time spent learning about system structures and mental models is necessary to the development of a sound understanding of the system. The arrow to the far left reinforces that a deep understanding, especially at the mental model level, is critical to the identification of leverage actions. Learn more about leverage in the Habits course, <a href='/courses/14-leverage'>Uses understanding of systems structure to identify possible leverage actions</a>. The iceberg alone will not identify specific solutions to system challenges, but its use will effectively inform decision-making and positively influence your capacity to identify areas of leverage in a system.
    </p>
  </div>
