import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>If you keep a journal, identify important trends in your life that you are trying to impact. In addition to words, draw a BOTG in your journal to track your progress and make the change visible.</p>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-well-being.svg'
      alt='Well-being Example'
    />
    <p>For example, “I am trying to not be so negative when I am in team meetings.” You can draw a BOTG as a part of your journal entry and use it to show how your level of negativity changes over a week’s time.</p>
    <p>This is a very quick way to enter a reflection in your journal even if you do not have the time to write a meaningful entry.</p>
    <h4>Think of a trend in your own life. On a piece of paper, create a BOTG to show the shape of change as influenced by causal factors.</h4>
    <h4>Be sure you remember to:</h4>
    <ul>
      <li>Decide on your x-axis time boundaries. For example, hours, days, weeks, years.</li>
      <li>Clearly label your one changing variable on the y-axis. To get you started, sometimes it is helpful to begin with phrases like “level of,” “number of,” “quality of,” or “amount of.”</li>
      <li>Draw your trend line and tell the story as you draw.  Include causal factors for changes and plateaus.</li>
    </ul>
    <Field
      name='botg'
      component={FileUpload} image
      instructions='Take a photo of your BOTG and upload it below.'
    />
    <h4>Tell the story of your graph.</h4>
    <Field
      name='graph-story'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <div className='quote'>
      <p className='quote-text'>“Progress is impossible without change, and those who cannot change their minds cannot change anything.”</p>
      <p className='quote-author'> — George Bernard Shaw, author</p>
    </div>
  </div>
