import * as React from 'react'

export const Section02 = () =>
  <div className='content'>

    <h3>
      Prerequisite Skills
    </h3>

    <ol>
      <li>
        Knowledge of block-based coding
      </li>

      <li>
        Knowledge of the engineering design process
      </li>
    </ol>

    <h3>
      Introduction
    </h3>

    <h4>
      First 60-Minute Block
    </h4>

    <p>
      Ask students what they know about mazes.  What makes them fun?  What makes them challenging?  What components are required in a maze? How could you construct a maze that would allow two different people/groups to race each other through the maze?  Are the requirements different for a two person/group maze?  How would the requirements differ? Be sure to guide the students’ discussion toward needing two starting points if they do not come to this conclusion on their own.
    </p>

    <p>
      Place students into teams. Teams of 2-4 work best but your numbers may be determined by your technology availability. Tell students they will use markers and butcher paper to create ½ of a maze.  Let them know that once they have completed their ½ maze, it will be added to another team’s ½ maze to create a complete maze.  They will then code a Sphero Bolt to navigate the complete maze.
    </p>

    <img src='images/resources/maze-craze/app-screenshot.png' className='lesson-img med-img' />

    <p>
      Rules to determine a winner should be decided.  The score taking method will need to take into account speed (time to navigate the maze) and accuracy (coding the robot so that it does not touch any of the lines of the maze).  Taking the finish time of a team and adding a time penalty of 5 seconds for each time a robot touches the lines of the maze works well.  Also, requiring a specific number of blocks in their code will prevent teams from coding their robot to roll straight to the finish along with the penalty for having their robot touch a line.  (Yes, some will realize they can do this…and win!)  Discuss how many turns they think should be included on their ½ of the maze.  Remind them that each move they draw (turn, stop, acceleration, deceleration, and/or movement in any direction) will require a separate block of code to make the robot execute that specific move.
    </p>

    <p>
      Ask how many moves they feel should be included to make the maze challenging but not frustrating. The photo to the right shows the blocks of code needed to make the robot roll forward, turn to the right, come to a stop to stabilize, turn left, come to a stop to stabilize, and turn right.  Stops may be needed in areas with tight turns or narrow passages to keep the robot from wobbling.
    </p>

    <p>
      Give each student a Maze Craze handout.  They will have 15 minutes to draw their ½ maze. <br /><em>Note: Early elementary students should start with two or three moves for each ½ maze, knowing that will equal four to six moves for the complete maze.</em>
    </p>

    <p>
      Plot the <em>first data point, designing ½ maze,</em> on the behavior-over-time graph designating how successful they feel after designing their ½ maze.
    </p>

    <p>
      Pair each team with one other team.  The separate teams will combine their ½ mazes together to create a complete maze.  Teams will compete against each other to navigate the combined maze more quickly (time to navigate the maze), and accurately (coding the robot so that it does not touch any of the lines of the maze), than the other team.
    </p>

    <p>
      Ask what they need to include in the combined maze and how to make the design fair from either starting point.  What would make the maze fair?  Are there at least two routes that would require close to the same number of code blocks to complete the maze?  How can they keep the robots from running into each other?  Did they include a start/finish point for each team at opposite sides of the maze?
    </p>

    <p>
      A team’s failure to take action to prevent the robots from trying to traverse a narrow passage at the same time could cause an escalation of frustration between teams, so teams will need to collaborate to find the best way to combine their mazes and incorporate sections that will allow their robots to pass without the robots interfering with each other.  Teams will need to anticipate the path of their opponent to prevent interference.  Remind them that they need to balance the “narrow” view of navigating their half of the maze with the “big picture” of completing the whole maze quickly, but accurately.  Students will draw the combined maze on their Maze Craze handout.
    </p>

    <p>
      Plot the <em>second data point, combining maze halves,</em> on the behavior-over-time graph designating how successful they feel after combining their maze halves.
    </p>

    <p>
      Before passing out the 3’ butcher paper, ask how to ensure the scale of the map fits their robot.  Have the robots available for students to use as a measurement tool while they are drawing their combined maze.  Have students draw their large-scale maze on the butcher paper with pencil first and then go over the pencil with marker.
    </p>

    <p>
      Plot the <em>third data point, drawing maze,</em> on the behavior-over-time graph designating how successful they feel after drawing their combined maze.
    </p>

    <h4>
      Second 60-Minute Block
    </h4>

    <img src='images/resources/maze-craze/maze2.png' className='lesson-img med-img' />

    <p>
      Pass out an iPad and Sphero Bolt to each team.  Teams will use the first 20 minutes to code their robot for the first time.  They will have to carefully consider their path and determine the best code to get their robot to the end quickly (time to navigate the maze), and accurately (i.e. coding the robot so that it does not touch any of the lines of the maze). Teams will need to resist the urge to code their robot to move too quickly, which could result in their robot not being accurate, which will add a penalty to their finish time. Teams will need to incorporate code that stops their robot for an appropriate amount of time before reorienting its direction when navigating tight corners to prevent the robot from wobbling into the lines of the maze.
    </p>

    <p>
      Students will draw the path they have chosen to follow and write their code in the Design box on the back of their Maze Craze handout.
    </p>

    <p>
      Plot the <em>fourth data point, begin coding,</em> on the behavior-over-time graph designating how successful they feel after they begin coding.
    </p>

    <p>
      Teams will test their code and each member will write or draw the results of this first test in the Results box on their Maze Craze handout.
    </p>

    <p>
      Plot the <em>fifth data point, testing code,</em> on the behavior-over-time graph designating how successful they feel after testing their code for the first time.
    </p>

    <p>
      Teams will write about what worked and what did not work during the first test in the Assess box on their Maze Craze handout.
    </p>

    <p>
      Plot the <em>sixth data point, assessing code,</em> on the behavior-over-time graph designating how successful they feel after assessing their code from the first test.
    </p>

    <p>
      Teams will improve on their code/path and draw their improved code/path in the Redesign box on their Maze Craze handout.
    </p>

    <p>
      Plot the <em>seventh data point, improving code,</em> on the behavior-over-time graph designating how successful they feel after improving their code.
    </p>

    <h4>
      Third 60-Minute Block
    </h4>

    <img
      src='images/shared/habit-cards/shadow/surfaces-tests.png'
      className='lesson-img small-img'
    />

    <p>
      Teams will surface and test their assumptions through the design process as they design, test, assess, redesign, and then retest their code to navigate the maze until they are satisfied with their results or time runs out.
    </p>

    <p>
      Plot the <em>eighth data point, design process,</em> on the behavior-over-time graph designating how successful they feel after working through the design process to design a successful code.
    </p>

    <p>
      Teams will compete in the Maze Craze Challenge.
    </p>

    <p>
      Plot the <em>ninth data point, completing challenge,</em> on the behavior-over-time graph designating how successful they feel after completing the Maze Craze Challenge.
    </p>

    <h4 className='clear'>
      Student Work Examples:
    </h4>

    <div className='habits-row'>
      <img src='images/resources/maze-craze/ex2-1.png' className='lesson-img med-img' />

      <img src='images/resources/maze-craze/ex2-2.png' className='lesson-img med-img' />

      <img src='images/resources/maze-craze/ex3-1.png' className='lesson-img med-img' />

      <img src='images/resources/maze-craze/ex3-2.png' className='lesson-img med-img' />
    </div>

    <h3>
      Debriefing
    </h3>

    <p>
      After plotting their last data point, have students review their behavior-over-time graph.  Ask them to look at the points they plotted and what they can infer from the data.  Have them swap graphs with their partner or a neighbor and see what they can infer about that student’s experience with the Maze Craze Challenge.  Give partners or neighbors 5 minutes to discuss what they felt most successful with and what was their biggest struggle.
    </p>

    <p>
      Ask students which data point was the lowest and highest for them and why they marked it that way.  Have a few students from different groups share their completed graph with the class and talk through their design process.  Ask them if there were any moments where something clicked and they got it, or if there were any points during the challenge where they just felt stuck and what they did to move past that point.
    </p>

    <p>
      The questions included below are good to ask while reviewing a few of the Habits of a Systems Thinker this lesson covers.
    </p>

    <ul>
      <li>
        Ask students if they were able to see the “big picture” when planning and navigating the maze.  Ask what they think “big picture” means.
      </li>

      <li>
        Ask them to tell you one time they checked their results and changed their actions if needed.
      </li>

      <li>
        Ask if changing perspectives helped increase their understanding of the maze.  Discuss maze strategy and what helped them to be successful.  Did they strategize with their opponent or did they work separately?  If they did strategize with their opponent, how did that help?
      </li>

      <li>
        Ask if they considered the challenge fully and designed their path accordingly, or did they just try to make it through the maze as fast as possible.  Did they go for speed and sacrifice accuracy or did they focus on accuracy and sacrifice speed?  Considering the strategy they used and the results they got, would they make any changes?
      </li>

      <li>
        Do they see any connections between the Maze Challenge and other activities in their learning/life?
      </li>
    </ul>

    <div className='habits-row'>
      <img src='images/shared/habit-cards/shadow/big-pic.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/successive-approx.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/perspectives.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/considers-issue.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/connections.png' className='lesson-img small-img' />
    </div>

    <p>
      Lesson’s Author: Beth Bass
    </p>
  </div>
