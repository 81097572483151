import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>

    <h3>Practice the Archetype</h3>

    <p>
      Now it's time to take a look at this archetype using your own personal experience. When have you encountered a Success to the Successful archetype? Reflect on that experience below.
    </p>

    <p>
      Feel free to <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/success-to-the-successful-blank-so.pdf' target='_blank' rel='noopener noreferrer'>download a blank “success to the successful” template</a> and use it to tell your story as you respond to the questions.
    </p>

    <h3>
      1. Identify the first reinforcing loop in the Success to the Successful archetype.
    </h3>

    <div className='textbox-with-img'>
      <img
        src='images/courses/archetypes/02-success-to-the-successful/stts-loop1.svg'
        alt='Problem Symptom'
      />

      <div>
        <h4>
          What resource was allocated?
        </h4>

        <Field
          name='resource1'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />

        <h4>
          To whom was the resource allocated?  What success was created as a result of resource allocation?
        </h4>

        <Field
          name='resource1-success'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />
      </div>
    </div>

    <h3>
      2. Identify the second reinforcing loop.
    </h3>

    <div className='textbox-with-img'>
      <img
        src='images/courses/archetypes/02-success-to-the-successful/stts-loop2.svg'
        alt='Problem Symptom'
      />

      <div>
        <h4>
          What resource was allocated?
        </h4>

        <Field
          name='resource2'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />

        <h4>
          To whom was the resource allocated?  What success was created or impeded as a result of resource allocation?
        </h4>

        <Field
          name='resource2-success'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />
      </div>
    </div>

    <div className='textbox-with-img'>
      <img
        src='images/courses/archetypes/02-success-to-the-successful/stts-full.svg'
        alt='Unintended Consequences'
      />

      <div>
        <h4>
          Tell the Story:
        </h4>

        <Field
          name='story'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />
      </div>
    </div>

    <h3>
      Reflecting on Your Archetypical Story
    </h3>

    <h4>
      What options exist when allocating resources?
    </h4>

    <Field
      name='options'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      What result can you anticipate when resources are allocated?
    </h4>

    <Field
      name='anticipated-results'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      How can you foresee when gain for one will result in loss of or limited gain for another?
    </h4>

    <Field
      name='gain-and-loss'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      Having applied this archetype, how might you avoid a success to the successful situation in the future?
    </h4>

    <Field
      name='avoid-future'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

  </div>
