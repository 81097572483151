import * as React from 'react'

export const Section01 = () =>
  <div className='content'>

    <h3>
      Introduction:
    </h3>

    <p>
      We all have leaders — whether it be someone we know personally, or famous figures from the past or present, that we admire. But what qualities, or Habits, do these leaders have? This engaging, dialogue-inducing exercise will get groups to explore this concept.
    </p>

    <h3>
      Outcomes:
    </h3>

    <ul>
      <li>
        Individuals will identify a leader they admire, and select at least one Habit this leader practices consistently.
      </li>
      <li>
        Individuals will realize the degree to which strong and effective leaders actively practice the Habits of a Systems Thinker.
      </li>
    </ul>

    <h3>
      Preparation:
    </h3>

    <p>
      Each participant has access to a set of Habits cards, the Habits app or the one-page Habits of a Systems Thinker handout, available for download <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/habits-single-page-2020.pdf' target='_blank' rel='noopener noreferrer'>here</a>. To begin, place one  Habit card on each of 14 pieces of blank chart or large paper and hang them around the perimeter of the room.
    </p>

    <h3>
      Directions:
    </h3>

    <ol>
      <li>
        <strong>Provide these directions:</strong><br />
        “Think of a leader you highly respect and who has influenced you. Choose one key Habit this person practices/practiced consistently. Be ready to explain your choices.” (See example below, which may be helpful to share before asking people to begin the exercise).
      </li>

      <li>
        <strong>Once everyone has identified their leader and chosen a Habit, give these instructions:</strong><br />
        <ul>
          <li>
            “Go to the chart paper of the Habit you identified for your leader and write down your leader’s name on the paper.”
          </li>

          <li>
            “Join with others who chose that Habit, or others nearby, and share your explanation for choosing your leader and their Habit strength.”
          </li>
        </ul>
      </li>
    </ol>

    <h3>Debrief</h3>

    <p>
      After sharing, note similarities and differences when sharing Habits as key traits of admired leaders.
      Debrief questions:
    </p>

    <ul>
      <li>
        As you identified an admired leader, was it difficult to choose only one Habit? If so, why?
      </li>

      <li>
        Which Habits seem to relate to the one you chose for your identified leader?
      </li>

      <li>
        As you think of yourself as a leader, which Habits do you think others would choose for you as strengths?
      </li>

      <li>
        Which Habits would <strong>you</strong> like to be known for practicing regularly?
      </li>
    </ul>

    <h3>
      Facilitation Example:
    </h3>

    <img
      className='lesson-img small-img'
      src='/images/resources/leaders-we-admire/MLKJR.png'
      alt='Martin Luther King Jr.'
    />

    <p>
      Share an example of a well-known leader, like Martin Luther King Jr., and a Habit that would be considered a strength for that person.
    </p>

    <p>
      The example could be shown as a slide or visual.
    </p>

    <div className='quote'>
      <p className='quote-text'>
        “It really boils down to this: that all life is interrelated.  We are caught in an inescapable network of mutuality, tied in a single garment of destiny. Whatever affects one directly affects all indirectly.”
      </p>

      <p className='quote-author'>
        - Martin Luther King Jr.
      </p>
    </div>

    <p>
      Ask, <em>”Which Habits of a Systems Thinker would you consider strengths of Dr. King?”</em>
    </p>

    <p>
      This will help to facilitate dialogue and the group should quickly recognize how the Habits of a Systems Thinker connect with one another.
    </p>
  </div>
