import * as React from 'react'
import { Field } from 'redux-form'
import { FileUpload } from 'components/shared/file-upload'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>

    <img
      className='lesson-img med-img'
      src='/images/courses/tools/07-cld-reinforcing/new-recipes.svg'
      alt='recipe loop'
    />

    <p>
      You can go through the process of identifying causal pairs, or sometimes the loop just comes together as you look for causal relationships. Here is one example of a causal loop that includes variables from the list to form a causal story. The finishing touch is to label your loop as a reinforcing loop. Use the letter R in the center of your loop as a label.  Sometimes you will see a snowball icon as a label for a reinforcing loop.
    </p>

    <p>
      After drawing your loop, it is important to talk it through to ensure that each causal link makes sense and represents your system of interest.
    </p>

    <h3>
      The story of the loop (virtuous):
    </h3>

    <p>
      As the number of times new recipes are served increases, the cook’s confidence will build. As confidence builds, the cook’s courage to try new recipes increases, which feeds back to his/her willingness to continue trying new recipes.
    </p>

    <h3>
      The story of the loop (vicious):
    </h3>

    <p>
      As the number of times new recipes are served decreases, the cook’s confidence may wane. As confidence diminishes, the cook’s courage to try new recipes also diminishes, which feeds back to his/her lack of willingness to continue trying new recipes.
    </p>

    <p>
      Now try drawing one of your own.  Draw your loop on paper, take a photo and upload it below.
    </p>

    <p>
      You can begin to redraw the example loop about trying a new recipe and change a variable or two. Or you can redraw and reword some of the existing variables in the given example.  Another option is to start fresh and draw a loop from scratch.
    </p>

    <p>
      As a suggestion, you can include two or more variables in your loop. The number of variables is up to you.
    </p>

    <Field
      name='reinforcingLoop'
      component={FileUpload} image
      instructions=''
    />

    <p>
      Do not forget to tell the story of your loop.  Be sure you can tell the story as both virtuous and vicious scenarios.
    </p>

    <h4>
      Virtuous story
    </h4>

    <Field
      name='story1'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      Vicious story
    </h4>

    <Field
      name='story2'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
