import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise05 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Take some time to reflect on the following questions as you begin this practice exercise.</p>
    <h4>What is an accumulation you desire to grow or decrease?</h4>
    <h4>How can careful consideration of that element and the factors affecting the inflow and the outflow help you achieve the desired change in your stock?</h4>
    <p>The best way to practice this habit is by creating your own stock-flow map. Go back to your earlier reflections in this course. You can use an accumulation from other exercises (e.g. trust as an accumulation) or another one that you want to pay attention to.</p>
    <p>Respond to the following questions to get you started.</p>
    <h4>What elements of your chosen system can you see, feel, count or measure as amounts that change over time? In other words, what are the key accumulations or stocks of your system?</h4>
    <Field
      name='chosen-system'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Choose one stock from your list above that you think is most important. What causes the accumulation to increase or decrease? How quickly (or slowly) does this accumulation increase or decrease? What is influencing the rates of change?</h4>
    <Field
      name='one-stock'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <p>The next two questions are designed to help you find feedback in your system. You might want to refer to the <a href='/courses/09-circular-nature' target='blank' rel='noopener'>Circular Causality course</a> for a quick refresher about feedback.  Accumulations are dynamic; they can change. Looking for feedback helps you anticipate these causal changes.</p>
    <h4>How might this accumulation impact other elements in the system?</h4>
    <Field
      name='other-elements'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>If the accumulation changes, what is the effect on the inflow or outflow?</h4>
    <Field
      name='inflow-outflow'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <img
      className='lesson-img left'
      src='/images/courses/habits/13-accumulations/stock-flow-template.png'
      alt='Stock-flow Template'
    />
    <p><a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/stock-flow-template.pdf' target='blank' rel='noopener'>Click here</a> to download this template.</p>
    <p>Now, it’s time to draw a stock-flow map. If needed, refer to the <a href='/courses/03-stock-flow' target='blank' rel='noopener'>Stock-flow course</a> in the Tools section of TTS. We encourage you to either download the template and label your map.</p>
    <Field
      name='stockFlowMap'
      component={FileUpload} image
      instructions='Or better yet, draw your map on paper and upload it below.'
    />
    <p>As a next step, we highly recommend the <a href='/courses/03-stock-flow' target='blank' rel='noopener'>Stock-flow course</a> in the Tools section of TTS to help you review accumulations and provide an opportunity for additional practice with stock-flow mapping.</p>
  </div>
