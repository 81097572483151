import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { resetAction } from 'components/forgot-password/password.actions'
import { push } from 'connected-react-router'
import { NoAuthComponent } from 'components/auth'
import PropTypes from 'prop-types'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

// Validation items
const required = value => value ? undefined : 'Required'

export const matchPasswords = (value, allValues) => {
  if (value !== allValues.password) {
    return "Passwords don't match"
  } else {
    return null
  }
}

export const checkPassword = (value) => {
  if (value.length < 8) {
    return 'Password must be at least 8 characters'
  }
  if (value.search(/[a-z]/i) < 0) {
    return 'Your password must contain at least one letter.'
  }
  if (value.search(/[0-9]/) < 0) {
    return 'Your password must contain at least one digit.'
  } else {
    return undefined
  }
}

// Field for input
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type} />
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
)
renderField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object
}

function ResetPasswordView ({ match, handleSubmit, error, submitting }) {
  return (
    <div>
      <Header />
      <main className='reset-password-page'>
        <h1 className='centered'>Reset Password</h1>
        <section className='white'>
          <p className='centered'>
            Create a new password using the form below. Your password must be at least 8 characters, one letter and one number.
          </p>
        </section>
        <section className='grey'>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={submitting}>
              <div className='form-field'>
                <div className='form-field'>
                  <label htmlFor='password'>Password*</label>
                  <Field
                    name='password'
                    component={renderField}
                    type='password'
                    validate={[required, matchPasswords, checkPassword]}
                  />
                </div>
                <div className='form-field'>
                  <label htmlFor='password'>Confirm Password*</label>
                  <Field
                    name='confirmPassword'
                    component={renderField}
                    type='password'
                    validate={[required, matchPasswords, checkPassword]}
                  />
                </div>
              </div>
              {error && <strong>{error}</strong>}
              <button className='blue' type='submit'>Submit</button>
            </fieldset>
          </form>
        </section>
        <section className='white' />
      </main>
      <Footer />
    </div>
  )
}
ResetPasswordView.propTypes = {
  handleSubmit: PropTypes.any,
  error: PropTypes.any,
  submitting: PropTypes.any,
  match: PropTypes.any
}

const ResetPasswordForm = reduxForm({
  form: 'ForgotPassword',
  onSubmit: resetAction
})(ResetPasswordView)

export const ResetPasswordPage = connect(
  (state, props) => props,
  (dispatch, { match }) => ({
    onSubmit: async (d) => {
      const result = await resetAction({ ...d, forgotToken: match.params.forgotToken })
      dispatch(result)
      window.alert('Your password has been reset successfully!')
      dispatch(push('/login'))
    }
  })
)(NoAuthComponent(ResetPasswordForm))
