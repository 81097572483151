import * as React from 'react'
import { Field } from 'redux-form'
import { FileUpload } from 'components/shared/file-upload'

const Systems = [
  'well-being',
  'workplace',
  'school',
  'community',
  'family'
]

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>

    <h4>
      Choose a system of interest:
    </h4>

    <div className='system-select'>
      {Systems.map(s =>
        <div key={s} className={`system-select-item ${s}`}>
          <Field name='systemSelect' id={`ss-${s}`} component='input' type='radio' value={s} />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      )}
    </div>

    <p>
      Identify one key variable that you would like to see change over time.  Indicate how you would like to see this variable change: increasing, decreasing or maybe even striving for stability!
    </p>

    <p>
      Here are some examples to get you started:
    </p>

    <ul>
      <li>
        Increase teenage initiative to help out at home (i.e. helping out without being asked to do so)
      </li>

      <li>
        Increase commitment to recycle in your community
      </li>

      <li>
        Decrease # of plastic bags used in your community
      </li>

      <li>
        Increase time spent reading professional journals
      </li>

      <li>
        Decrease body weight based on # of pounds or kilograms
      </li>

      <li>
        Increase efforts to follow-up with potential clients
      </li>
    </ul>

    <h4>
      Specify the variable you will use to begin drawing your causal map:
    </h4>

    <Field
      name='variable'
      component='textarea'
      className='reflection-input'
      placeholder='Enter your response here.'
    />

    <p>
      Now it is time to begin creating your causal map.  There are a number of ways to do this.
    </p>

    <p>
      The easiest way to complete this practice exercise is to draw your map on a piece of paper. Feel free to use color, pictures and even behavior-over-time graphs to show how you see variables changing over time. Here is an example of a map that was drawn by a group of high school students. The variable they focused on was student success.
    </p>

    <img
      className='med-img lesson-img block'
      src='/images/courses/tools/09-bringing-loops-together/loop-drawing.png'
      alt='Causal map on paper'
    />

    <p>
      And, if you want to challenge yourself, there are a variety of web-based platforms and software programs that support causal loop mapping.  Here are some to check out, in no particular order:
    </p>

    <ul>
      <li>
        <a href='https://ncase.me/loopy/' target='_blank' rel='noopener noreferrer'>Loopy</a> is one that is simple to use and enables you to color-code and animate your map.
      </li>

      <li>
        <a href='https://www.kumu.io/' target='_blank' rel='noopener noreferrer'>Kumu</a> is free for student and public use, but the free version will make your map available to all users.  If you want to maintain privacy, you need to pay.
      </li>

      <li>
        <a href='https://vensim.com/free-download/' target='_blank' rel='noopener noreferrer'>VensimPLE</a> is a free download of system dynamics modeling software that enables you to create causal loop and stock-flow maps and simulations.
      </li>

      <li>
        <a href='https://www.plectica.com/' target='_blank' rel='noopener noreferrer'>Pletica</a> is a mapping software that has a free version, yet limits the number of “cards” you can build and storage space is limited to 25 MB.
      </li>
    </ul>

    <h3>
      Steps for creating a causal connection map
    </h3>

    <ol>
      <li>
        Begin by writing or typing your variable name in the center of the paper or computer screen.  This will be your focus variable. In the student example, <strong>Student Success</strong> was their focus variable.

        <p>
          Here are some questions to help you start mapping.
        </p>

        <ul>
          <li>
            <em>What is a key variable that causes the focus variable to <strong>increase?</strong></em>
          </li>

          <li>
            <em>What is a key variable that causes the focus variable <strong>decrease?</strong></em>
          </li>
        </ul>

        <p>
          Include the responses to these questions on your map and use arrows to show causal connections.  Don’t forget to label your arrowheads with <strong>+</strong> for changing in the same direction and <strong>–</strong> for an inverse causal relationship.  You can refresh your memory about making and labeling causal links by visiting <a href='/courses/05-causal-links'>Causal Links course</a> in the Studio.
        </p>

        <p>
          It also might be helpful to refer to the students’ causal map photograph.  Notice how they identified <strong>grades</strong> as a variable that affects student success with a <strong>+</strong>. Another causal connection (that is a bit difficult to see in the drawing) is <strong>teacher motivation</strong> with a <strong>+</strong>.
        </p>
      </li>

      <li>
        Next ask this question:

        <p>
          <em>When your focus area increases or decreases, what other key variables are affected in your system?</em>
        </p>

        <img
          className='lesson-img'
          src='/images/courses/tools/09-bringing-loops-together/student-success.png'
          alt='Causal map on paper'
        />

        <p>
          Using the student example, you can see that they identified <strong>student success</strong> affecting <strong>sports</strong>, <strong>career</strong> and <strong>teacher motivation</strong>.
        </p>

        <p>
          Next, add some effects that your focus variable causes.
        </p>
      </li>

      <li>
        Now that you are started, continue to ask those questions of variable that are key to your map. Be sure to look for opportunities to see feedback loop relationships in your map.  For example, the student saw a reinforcing feedback loop between <strong>student success</strong> and <strong>teacher motivation</strong>.  It would be interesting to hear this story as a feedback loop mental model! Another loop you might notice in the student map is <strong>student success</strong> and <strong>sports</strong>. Notice that <strong>grades</strong> are also a factor impacting <strong>sports</strong>.
      </li>

      <li>
        In addition to labeling arrowheads, be sure to also label loops as they develop in your map.  <strong>R</strong> is for reinforcing and <strong>B</strong> for balancing.  If you have more than one reinforcing or balancing loop, use a numbering system next to the loop labels, e.g. <strong>R1, R2</strong> or <strong>B1, B2</strong>.
      </li>
    </ol>

    <Field
      name='fixesThatBackfire-template'
      component={FileUpload} image
      instructions='Upload a photograph or PDF of the map you created below.'
    />

  </div>
