import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      Let’s begin by reviewing the meaning of the word <em>feedback</em> when identifying causal relationships. Think about a time when you received feedback from another person.
    </p>

    <img
      className='lesson-img'
      src='/images/courses/tools/07-cld-reinforcing/pasta.jpg'
      alt='dinner recipe'
    />

    <p>
      Here is an example: you are having a dinner party and decide to try your friend’s new recipe. You’ve never made this
      dish before, but it tasted great when your friend made it for a potluck a few weeks ago. You decide to serve the new
      dish and your guests begin making comments about how delicious it is and how much they are enjoying the experience.
    </p>

    <p>
      <em>This new recipe is delicious! How did you get the sauce so perfectly blended? I love the flavors—can I have a copyof the recipe?</em>
    </p>

    <p>
      This type of feedback gives you information about the quality of the meal you served. Your guests’ affirming compliments
      also impact your level of confidence and motivation to continue making and serving new recipes. Feedback in systems
      thinking involves the causal connections that feed back to the original cause.
    </p>

    <p>
      Reinforcing feedback is present when cause and effect relationships generate growth and self-perpetuate as variables
      continually reinforce one another.  The new recipe and positive response to the meal could be a growth feedback story.
      Willingness to serve new recipes, increased confidence in trying new recipes and motivation to branch out and try new
      cooking experiences all suggest growing, reinforcing behaviors.  When reinforcing feedback is moving in a desirable
      direction it is often called “virtuous.”
    </p>

    <h3>
      Other examples of virtuous reinforcing scenarios include:
    </h3>

    <ul>
      <li>
        Compounding interest-earning accounts
      </li>

      <li>
        Blockbuster movie attendance
      </li>

      <li>
        Random acts of kindness
      </li>

      <li>
        Mindfulness practices
      </li>

      <li>
        Support for a political candidate
      </li>
    </ul>

    <p>
      Reinforcing feedback can result in decline, shrinkage or collapse as well. Let’s suppose the meal hadn’t gone well and people were politely pushing food aside and trying to hide their disappointment.  The effect of this reaction could take the experience in the opposite direction.
    </p>

    <p>
      Effects could include, <em>I’ll never make this recipe again! That is the last time I will try a new, untested recipe with guests! I am a miserable cook—we will eat out next time.</em>
    </p>

    <p>
      The impact is still reinforcing, but this undesirable decline in confidence and motivation can spin in what is sometimes referred to as a “vicious” manner.
    </p>

    <p>
      Thus, reinforcing feedback where variables build upon themselves in either growing or declining manners can be considered virtuous or vicious.
    </p>

    <p>
      One important distinction is that growth is not always virtuous. For example, consider when rising anxiety leads to growing stress which generates unhealthy symptoms. These growing, escalating causal connections can paint a vicious or undesirable scenario that can escalate and manifest itself over time.
    </p>

    <h3>
      Examples of vicious reinforcing scenarios include:
    </h3>

    <ul>
      <li>
        Adverse reactions to a political candidate
      </li>

      <li>
        Sibling rivalry
      </li>

      <li>
        Spread of a cold or flu
      </li>

      <li>
        Apathy, procrastination and laziness
      </li>
    </ul>
  </div>
