import * as React from 'react'
import Helmet from 'react-helmet'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

export const Benefits = () =>
  <div>
    <Header />
    <div className='flow-container'>
      <Helmet>
        <title>Benefits - Thinking Tools Studio</title>
        <meta name='description' content='The benefits of systems thinking are wide-ranged and far-reaching.' />
        <meta property='og:title' content='Benefits - Thinking Tools Studio' />
        <meta property='og:description' content='The benefits of systems thinking are wide-ranged and far-reaching.' />
        <meta property='og:image' content='https://thinkingtoolsstudio.org/images/site/benefits-bg.jpg' />
        <meta property='og:url' content='https://thinkingtoolsstudio.org/benefits' />
        <meta name='twitter:image' content='https://thinkingtoolsstudio.org/images/site/benefits-bg.jpg' />
        <meta name='twitter:image:alt' content='Benefits: Expand your thinking to find essential solutions' />
      </Helmet>

      <section className='photo-header benefits'>
        <h1>
          Benefits
        </h1>

        <img src='images/site/benefits-icon.svg' />

        <h2>
          Expand your thinking to find essential solutions
        </h2>

      </section>

      <section className='white'>
        <p className='centered'>
          The Thinking Tools Studio has been created and is curated by the Waters Center for Systems Thinking:
        </p>

        <ul className='colordrops'>
          <li>
            Leaders in systems thinking capacity building worldwide since 1989
          </li>

          <li>
            Over 30,000 educators and hundreds of thousands of students in over 800 schools have benefited from Waters Center systems thinking work
          </li>

          <li>
            Waters Center materials used in hundreds of schools, districts and organizations around the globe
          </li>
        </ul>
      </section>

      <section className='grey split space-between'>
        <div className='benefits-img-2' />
        <div>
          <h4 className='orange'>
            Why Systems Thinking?
          </h4>
          <p>
            Systems thinking provides learners the Habits and tools to develop a deeper understanding of systems around them
            and move closer to achieving their highest performance. Systems thinkers have a sharpened and
            clarified understanding of how systems actually work. Previously confusing, disconnected snapshots of life
            start to make more sense when understood as interdependent patterns of change over time.
          </p>

          <p>
            When a person develops the capacity to understand how elements interact within and between systems,
            and can then communicate these observations effectively, tremendous change is possible.
          </p>

          <p>
            A huge benefit of teams utilizing systems thinking is the development of a shared language and
            understanding of concepts – that's because an important catalyst for change is a solid
            foundation among colleagues and a direction of where to go.
          </p>

          <p>
            The Thinking Tools Studio provides this opportunity to teams and individuals, free of charge.
          </p>
        </div>
      </section>

      <section className='white'>
        <h4 className='blue centered'>
          Systems Thinking...
        </h4>

        <div className='benefits-icons'>
          <div>
            <img src='images/site/thinking-visual.svg' /><br />Makes Thinking Visual
          </div>

          <div>
            <img src='images/site/organizes-thoughts.svg' /><br />Organizes Thoughts
          </div>

          <div>
            <img src='images/site/changes-perspectives.svg' className='rotate' /><br />Changes Perspectives
          </div>

          <div>
            <img src='images/site/improves-communication.svg' /><br />Improves Communication
          </div>
        </div>

        <p>
          These are just some of the many benefits of systems thinking that you will find in the courses and content throughout
          the Thinking Tools Studio. Everyone who is a part of a system (and we all are!) can use systems thinking Habits,
          concepts, and tools to look for leverage points to find essential solutions. The Studio features content that can be
          applied to every type of role within every type of system. For example, educators often integrate systems thinking into
          instruction because they recognize that demonstrated understanding of dynamic systems is an integral part of educational
          standards.
        </p>

        <p>
          As students better understand the complex, dynamic systems they are studying in a classroom, they can transfer
          that knowledge and skill to any situation or challenge. This also applies to individuals or groups within businesses, community
          organizations and more. The benefits of systems thinking are multifaceted and far-reaching:
        </p>

        <div className='benefits-icons'>
          <div>
            <img src='images/site/teachers.png' /><span>Teachers</span><br />have the tools and strategies needed
            to create successful learning environments
          </div>

          <div>
            <img src='images/site/students.png' /><span>Students</span><br />become more empowered & engaged with
            their learning
          </div>

          <div>
            <img src='images/site/employers.png' /><span>Businesses & Employers</span><br />gain a pipeline of exceptional
            talent ready to tackle complex challenges
          </div>

          <div>
            <img src='images/site/communities.png' /><span>Communities</span><br />thrive with citizens committed
            to finding equitable solutions to civic & environmental issues
          </div>
        </div>

        <div className='testimonials'>
          <img src='images/site/quote.png' />

          <p>
            Systems thinking and the work of the Waters Center has promoted deeper thinking and learning at my
            school. I've found that teachers can better facilitate critical and visual thinking in
            students, and work with multiple perspectives in an organized fashion. I've seen that students
            can better justify and explain content. Systems thinking not only stretches thinking, but it also
            simplifies it in the most beneficial way.
          </p>

          <span>Bonnie Short, Principal, West Smiths Station Elementary School, Lee County Schools, Alabama</span>
        </div>

        <div className='stacked'>
          <p className='centered'>
            Are you ready to begin your systems thinking learning journey?
          </p>

          <a href='/signup'><button className='orange big'>Start Now</button></a>
        </div>
      </section>
    </div>
    <Footer />
  </div>
