import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      You might be in an "repression and revolution” scenario when….
    </p>

    <div className='quote'>
      <p className='quote-text'>
        Official policies are perceived as repressive and members of an oppressed group join together to act&nbsp;defiantly.
      </p>
    </div>

    <img
      className='lesson-img large-img block'
      src='/images/courses/archetypes/09-repression-revolution/repression-revolution.svg'
      alt='Repression and Revolution'
    />

    <h3>
      Story of this Archetype
    </h3>

    <p>
      As you read each part of the story, press the “Next Part of Story” button below the graphic to see the story of the archetype unfold.
    </p>

    <div className='arch-components'>
      <div className='arch-p-container'>

        <p>
          <strong>Part 1:</strong> The story begins when an established governing body feels threatened in some way. In response to the threat, the governing body represses members of the opposition through an action, which could include creating a new, or enforcing an established, policy. This repressive action by the governing body deters some opposition members, which initially decreases the threat to the “establishment.” This creates <a href='/courses/08-cld-balancing'>balancing feedback</a>, as signified by the <strong>B</strong> in the loop.  A simple example of this story would be a babysitter, i.e. a governing figure, feeling her authority is threatened when two of the five children in her care don’t follow a household expectation such as removing their shoes upon entering the house. For defying the household rule, the babysitter withholds “screen time” for the offending children, which motivates one of the two to remove his shoes.
        </p>

        <p>
          <strong>Part 2:</strong> While the initial repressive act has the effect of decreasing some opposition, it also has the effect of adding legitimacy to the opposition. In the story of the babysitter and the five children, when two of the three children who had followed the rule and removed their shoes see that their siblings are being punished, they are so angry at what they perceive as the babysitter’s strictness, they join their siblings in solidarity by putting their shoes back on.  Now the babysitter has three children with their shoes on; her repressive action has generated more opposition members, which increases the threat to her authority.  A <a href='/courses/07-cld-reinforcing'>reinforcing loop</a> has developed around the outside of the diagram at this point in the story.
        </p>

        <p>
          <strong>Part 3:</strong> As the number of opposition members grow, that adds to the legitimacy of the opposition. In the case of the repressive babysitter whose authority is threatened by the shoe-wearing children, once three of the children put their shoes on in defiance, the last two join them. The revolution is complete!  In the diagram, the <strong>R</strong> between Opposition Members and Legitimacy of Opposition designates the <a href='/courses/07-cld-reinforcing'>reinforcing dynamic</a>.
        </p>
      </div>

      <div className='arch-build-container'>
        <ToggleImages
          images={[{ id: 1, image: '/images/courses/archetypes/09-repression-revolution/rr-part1.svg' }, { id: 2, image: '/images/courses/archetypes/09-repression-revolution/rr-part2.svg' }, { id: 3, image: '/images/courses/archetypes/09-repression-revolution/rr-part3.svg' }]}
        />
      </div>
    </div>

    <div className='exerpt'>
      <p>
        <strong>Reflection:</strong>  Consider a time when you, or a group you were a part of, felt that policies or actions that were being taken were oppressing you. Conversely, consider a time when you, or a governing group you were a part of, felt threatened by opposition and took some action to repress the opposition.
      </p>
    </div>

    <p>
      Continue to Lesson 2 for examples of stories that illustrate the Repression and Revolution archetype.
    </p>
  </div>
