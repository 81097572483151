import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/09-circular-nature/course-img.png'
      alt='Circular Nature'
    />
    <p>When observing the natural world, cause and effect relationships are all around us. The essential relationship between bees and flowers, as demonstrated in the image to the right, shows how one needs the other in order to survive. Bees cause flowers to reproduce and grow through pollination of the flower, and bees depend on the nectar of flowers as a food source. This interdependency is just one example of the vast number of causal relationships that help define our world.</p>
    <p>The term “feedback” is important to understand when practicing this Habit. The meaning differs between product feedback and performance or task feedback (defined as information provided as a basis for improvement).</p>
    <p>For example, a supervisor provides feedback in an annual review; the employee receives the feedback and may agree, disagree or dismiss the information.  The purpose of this kind of performance feedback should inspire improvement. The same goes for feedback about a product, a workshop, a conference, a movie or theatrical performance, and so on. However, there is no guarantee that change will occur.</p>
    <p>The enhanced meaning from a systems thinking perspective is that the information provided to inspire improvement actually feeds back to the original input and causes reinforcement or change.</p>
    <img
      className='lesson-img clear'
      src='/images/courses/habits/09-circular-nature/lesson-one-img-two.png'
      alt='Circular Nature'
    />
    <p>Consider a young person learning how to drive. The adult instructor provides corrective feedback expecting the new driver to adjust and incorporate suggestions into her/his driving behaviors.  Examples of corrective feedback include hand position on the wheel, distance maintained between cars, ways to check for oncoming traffic before proceeding, and so on. Feedback is truly circular when the new driver makes the necessary adjustments and incorporates the suggestions as he/she builds confidence and skill as a competent driver. You can imagine the circular nature of the interchange.</p>
    <p>If the driver is going too fast or too slow, the instructor asks the driver to adjust the speed of the car. The driver then makes the adjustment and maintains the proper speed limit. When the proper speed limit is maintained, there is no need for warnings about going too fast or too slow.</p>
    <p>Yet, when the speed exceeds the limit or is too slow to be safe, the driver is reminded to adjust.</p>
  </div>
