import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <h3>
      Introduction
    </h3>

    <p>
      <strong>Note:</strong> The use of “dual” ladders of inference was developed as a writing strategy by teachers at Allen-Field Elementary School, Milwaukee Public Schools.
    </p>

    <p>
      <strong>Materials:</strong> Dual ladders worksheet. <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/dual-ladders-worksheet.pdf' target='_blank' rel='noopener noreferrer'>Click here to download</a>.
    </p>

    <p>
      Mental models are personal beliefs, values, and perceptions that control what we see, how we interpret what we see, and
      the beliefs we develop based on what we see.  Mental Models are often deeply buried and hidden from awareness; they begin
      with us and stay with us.
    </p>

    <p>
      To prepare for facilitation of this activity, look at the images below. What do you see? What do you think? Duck or rabbit?
      Face or rocks? Face or word?
    </p>

    <div className='wdys-imgs'>
      <img
        src='/images/resources/what-do-you-see/rabbit.png'
      />

      <img
        src='/images/resources/what-do-you-see/face.png'
      />

      <img
        src='/images/resources/what-do-you-see/word.png'
      />

    </div>

    <img
      className='lesson-img med-img'
      src='/images/resources/what-do-you-see/ladder-1.png'
    />

    <p>
      What you see depends on your mental models. Mental models influence the ways we interpret the world and the systems we live
      in. The ladder of inference is a tool that allows us to slow our thinking in order to examine our mental models. To learn
      more, visit the <a href='/courses/02-ladder-of-inference'>Ladder of Inference course</a>.
    </p>

    <p>
      Mental Models are often accepted without thought and can be difficult to change. Using a reflective process to examine our
      mental models facilitates an awareness of our perceptions, how we interpret those perceptions and develop beliefs, and the
      impact of our beliefs on our actions.
    </p>

    <p>
      This activity is a way to introduce learners to mental models and how they impact our perception of the world and systems
      surrounding us. It is a precursor to using the ladder of inference in a number of different ways when addressing a system’s
      structure and behavior.
    </p>

    <div className='practitioner-tip'>
      <p>
        Practitioner Tip:  When introducing a new tool or strategy, try using content the learner is familiar with or that is
        non-threatening. This way the learner can practice the use of the tool or strategy and not worry about retaining content.
      </p>
    </div>

    <p>
      The Dual Ladder allows the learner to examine their mental models through a reflective process.  It begins with an observation:
      a picture is presented without any information, and you simply ask people to observe. The dual ladder shown has been modified
      from the ladder of inference to enable the examination of how our beliefs can impact our observations.
    </p>

    <p>
      The steps are limited to what is noticed, beliefs developed based on what we see, and what is wondered (or questions that are formed).
      The learner will answer questions, going up the ladder as they explore their mental model of a picture.  The last step of the ladder,
      “do something based on beliefs,” is structured as a query for reflection.
    </p>

    <h4>
      Procedure:
    </h4>

    <ol>
      <li>
        Choose a picture to use for the activity. A great source for a variety of pictures is National Geographic Picture of the Day: <a href='https://www.nationalgeographic.com/photography/photo-of-the-day/2018/09/bear-salmon-alaska/' target='_blank' rel='noopener noreferrer'>https://www.nationalgeographic.com/photography/photo-of-the-day/2018/09/bear-salmon-alaska/</a> Read the captions to determine if the picture is open to interpretation. We’ve also included some photos with captions as examples.
      </li>

      <li>
        Begin by discussing the purpose of the activity, define mental models and how they influence our approach to our world or systems.

        <div className='wdys-imgs'>
          <div className='img-with-caption'>
            <img
              src='/images/resources/what-do-you-see/eels.png'
            />

            <p>
              The lights from the boats attract the Shirasu eel at night in Tokushima, Japan. Matt Adams published this photo in the Daily Dozen saying, "This image felt very out of this world and sci-fi and I just love this scene. What a cool thing to witness and then to capture with your camera."
            </p>
          </div>

          <div className='img-with-caption'>
            <img
              src='/images/resources/what-do-you-see/train.png'
            />

            <p>
              In Bangladesh, thousands of people ride on the roof of an overcrowded train. They’re on their way to celebrate Eid-al-Firt with their families, a Muslim holiday that marks the end of Ramadan. Photograph by Noor Ahmed Gelaf.
            </p>
          </div>

        </div>
      </li>

      <li>
        Next, introduce the audience to the activity. Each learner should have a copy of the dual ladder to take notes/reflection.
        Have learners fold the paper in half and begin with the left side of the ladder.

        <div className='wdys-imgs'>

          <div className='practitioner-tip'>
            <h4>
              First Review
            </h4>

            <p>
              Use the left side of the ladder first.
            </p>

            <ul>
              <li>
                Introduce the activity
              </li>

              <li>
                Ask: “What do you see?”
              </li>

              <li>
                Ask: “What beliefs do you have based on what you see?”
              </li>

              <li>
                Ask “What are you wondering about the picture?”
              </li>
            </ul>
          </div>

          <img
            className='med-img clear'
            src='images/resources/what-do-you-see/dual-ladders.svg'
          />

          <div className='practitioner-tip'>
            <h4>
              Second Review
            </h4>

            <p>
              Present the learners with a caption of the picture, or information regarding the picture.
            </p>

            <p>
              After completing the left side, complete the right side of ladder.
            </p>

            <ul>
              <li>
                Ask: “What do you notice now?”
              </li>

              <li>
                Ask: “What beliefs do you have based on the caption? Have you beliefs changed?"
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li>
        Present the picture selected to the audience <strong>without the caption</strong>. Have participants work on their own to individually record their
        thoughts on the ladder handout. Give 2-3 minutes. Then, open up to a whole group discussion, asking questions for each step of ladder. Process:
        <ul>
          <li>Ask question</li>
          <li>Learner quietly and individually records thoughts on ladder step</li>
          <li>Discuss as a whole group</li>
          <li>Repeat process with each question-step of ladder</li>
        </ul>
      </li>

      <li>
        Begin with the left side, bottom of the ladder. First Step: tell learners we are only looking for factual information on this step.  Ask participants:
        What do you notice? What do you see?  <strong>Caution:</strong> this is the most difficult step for learners. Many jump right to “beliefs.” Don’t accept an interpretation
        of the picture.  Instead, ask, “Is that a fact?  What do you see that makes you say that?”

        <div className='practitioner-tip'>
          <h4>
            Example using train photo
          </h4>

          <ul>
            <li>
              Ask: What do you see? What do you notice?
            </li>

            <li>
              <i>Learner:  I see people on top of a train.</i>
            </li>

            <li>
              Ask:  What do you see that leads you to believe they are top of a train?
            </li>

            <li>
              <i>Learner:  The tree tops and the picture is blurry so it’s going fast.</i>
            </li>

            <li>
              State: So you see that the picture is blurry in places and that the people are sitting on something that is as high as the tree tops.
            </li>

            <li>
              <i>Learner:  Yes.</i>
            </li>

            <p>
              You may have to do this two or three times.
            </p>
          </ul>
        </div>
      </li>

      <li>
        Second Step:  After discussing the first step, learners detail what they believe about what they see/notice (follow process). Interpretation of what is seen is what this step is all about.

        <div className='practitioner-tip'>
          <h4>
            Example using train photo
          </h4>

          <ul>
            <li>
              Ask: What beliefs have you developed based on what you see?
            </li>

            <li>
              <i>Learner: I see people sit by the tops of trees and the trees are blurry in the picture so I believe they are on the top of a moving train. I only see men in the picture; I think they are traveling to work.</i>
            </li>
          </ul>
        </div>
      </li>

      <li>
        Third Step:  After discussing the second step, learners detail what they wonder about the picture (follow process).  The “wonders” assist in digging deeper into what is seen by individuals.

        <div className='practitioner-tip'>
          <h4>
            Example using train photo
          </h4>

          <ul>
            <li>
              <i>Learner:  I wonder where they are going? I wonder where they are? I wonder who is inside the train?</i>
            </li>
          </ul>
        </div>
      </li>

      <li>
        After going up the left side of the ladder, flip the dual ladder worksheet and complete the right side.  Present the picture with the caption and have the learners go up the right side of the ladder.  Repeat the process.  Focus on the any changes in perceptions in what learners now see and believe. The last step focuses on new learning and what we want to know based on new perceptions.

        <p>
          The right side of the ladder is an opportunity to explore how we often “jump up the ladder” and state our beliefs and take action without examining how we got there.
        </p>
      </li>
    </ol>
  </div>
