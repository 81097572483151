import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson02 = () =>
  <div className='content'>

    <img className='lesson-img med-berg' src='images/courses/tools/04-iceberg/iceberg-tools.svg' alt='iceberg example' />

    <p>
      The tools of systems thinking can be used at each level of the iceberg below the water line. The iceberg allows us
      to understand the big picture, rather than details and occurances as isolated events.
    </p>

    <p>
      Below is a summary of how levels of the iceberg connect to other systems thinking tools. It may be helpful to use
      the links to revisit the courses for each of the tools mentioned below.
    </p>

    <h4>
      Patterns and Trends: <a href='/courses/01-behavior-over-time-graphs'>Behavior-over-time graphs (BOTGs)</a> provide
      data to analyze and determine what is changing over time.
    </h4>

    <p>
      Change can be measured and documented. Drawing out and talking through BOTGs helps communicate the how and why of
      change. Analyzing patterns and trends helps to understand reality at a deeper level.
    </p>

    <h4>
      Structure: <a href='/courses/06-connection-circles'>Connection circles</a>, causal loops and <a href='/courses/03-stock-flow'>stock-flow maps</a> help to examine a system and identify the leverage action needed
      to generate desired behavior.
    </h4>

    <p>
      Thinking at the structure level means thinking in terms of causal connections. Tools used at the structure level
      help to determine the relationship among the parts of the system and leads to high-level, lasting change.
    </p>

    <h4>
      Mental Models: The <a href='/courses/02-ladder-of-inference'>ladder of inference</a> helps to identify the deeply
      held beliefs people hold about the system.
    </h4>
    <p>
      Mental models are people’s current reality and greatly influence their view of the future. Mental models can be
      difficult to change, which is why surfacing and examining them through the ladder of inference is so important.
    </p>

    <h3>The Iceberg as a Tool: How Does It Work?</h3>
    <p>
      Below is an example of an iceberg using systems thinking tools to determine the impact of ineffective communication
      at a workplace.<br />
      <em>Remember to ask the questions presented in Lesson #1 to help you as you consider each level of the iceberg.
      </em>
    </p>

    <div id='interactive-berg'>

      <ToggleImages
        images={[{ id: 1, image: '/images/courses/tools/04-iceberg/iceberg-botg.svg' }, { id: 2, image: '/images/courses/tools/04-iceberg/iceberg-connectioncircle.svg' }, { id: 3, image: '/images/courses/tools/04-iceberg/iceberg-ladder.svg' }]}
      />

    </div>
    <div className='exerpt'>
      <p>
        By using the iceberg to consider the issue fully, you move from seeing a series of unrelated events to an
        interdependent, big picture view of the system.
      </p>
    </div>

  </div>
