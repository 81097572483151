import { handleActions } from 'redux-actions'
import { ActionTypes } from './answers.actions'

export const answersReducer = handleActions({
  [ActionTypes.Load.Start]: (state) => ({
    ...state,
    isFresh: true,
    isLoading: true
  }),
  [ActionTypes.Load.Success]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    data: (payload || []).reduce((r, item) => { r[item.id] = item; return r }, {})
  }),
  [ActionTypes.Load.Fail]: (state) => ({
    ...state,
    isLoading: false,
    data: {}
  }),
  [ActionTypes.Save.Start]: (state) => ({
    ...state,
    isSaving: true
  }),
  [ActionTypes.Save.Success]: (state, { payload, meta }) => ({
    ...state,
    isSaving: false,
    data: {
      ...state.data,
      [meta.id]: payload
    }
  }),
  [ActionTypes.Save.Fail]: (state) => ({
    ...state,
    isSaving: false
  })
}, {
  isFresh: false,
  data: {}
})
