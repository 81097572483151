import { handleActions, combineActions } from 'redux-actions'

import { ActionTypes } from './auth.actions'

export const authReducer = handleActions(combineActions(ActionTypes.LoginSuccess, ActionTypes.SignupSuccess), {
  next: (state, { payload }) => ({
    ...state,
    ...payload
  })
}, {
  auth: false
})
