import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      If you completed the “<a href='courses/07-cld-reinforcing'>Causal Loop Diagrams Part 1: Reinforcing Feedback</a>” course, you will have discovered that there are causal relationships that generate growth or decline. Reinforcing relationships are always accompanied by some balancing dynamic or limit because virtually nothing can grow or decline forever.  Balancing feedback then is the regulator that helps a system maintain a goal or keep a changing system steady and stable. Sometimes that steadiness is desirable; for example, a work and life balance or a bank account balance of deposits and withdrawals. But other times, balancing factors can hold a system back from improving, as in a physical limitation when trying to excel at a sport or factors that are barriers to students’ abilities to learn.
    </p>

    <p>
      Sometimes balancing feedback is called “goal-seeking,” as the causal relationships help move a system toward a goal and help maintain and sustain that state.  Other words that describe balancing feedback scenarios include homeostasis, sustainability and equilibrium. Think of your own body temperature having a general goal of maintaining a 98.6˚F temperature.
    </p>

    <img
      className='lesson-img'
      src='/images/courses/tools/08-cld-balancing/thermometer.jpg'
      alt='thermometer'
    />

    <p>
      When the body is exposed to cold conditions, shivering or rapid rubbing to increase friction and blood flow will strive to warm your inner core temperature. When it is hot, the body sweats to try to cool down. We also do things to try to regulate our body temperature and subsequent comfort such as wearing sweaters and jackets in cooler temperatures, and lightweight clothes with breathable fabrics during warmer months.
    </p>

    <p>
      As with the body temperature example, day-to-day conditions can cause oscillating or wavy increases and decreases in how the body temperature responds to external conditions. Think about a time when you may have been on an airplane.  When you first come on board, the plane might be hot, especially during warm months, because the window shades may have been up allowing the sun’s radiation to contribute to a warm interior.  Then, the airline crew adjusts the thermostat setting and the air conditioner kicks in, triggering a robust influx of cold air causing the interior to become much colder. After the passengers settle in and the plane takes off, the ambient air temperature may begin to adjust to a more comfortable level.  Adjustments to the air conditioning system and external conditions like time of year and outside temperatures, hot sun and number of passengers can all contribute to the comfort of passengers on a plane. Imagine how the comfort level can be a subtle oscillating <strong>behavior-over-time graph</strong> based on the conditions described above.
    </p>

    <p>
      Besides body temperature, other common balancing feedback scenarios include:
    </p>

    <ul>
      <li>
        Predator-prey relationships
      </li>

      <li>
        Supply and demand
      </li>

      <li>
        A car’s cruise control
      </li>

      <li>
        A balanced diet based on nutrition that the body needs to thrive
      </li>

      <li>
        The time and attention that each child in a family is given
      </li>

      <li>
        The management of stress when working through a risky situation or challenge
      </li>
    </ul>
  </div>
