import * as React from 'react'
import PropTypes from 'prop-types'

export function DownloadBar ({ course }) {
  return (
    <div>
      {!course.downloads ? null
        : (
          <div className='download-bar'>
            <span>Course Downloads:</span>
            {course.downloads.map(o =>
              <a
                key={o.id}
                className='blue-button'
                target='_blank'
                rel='noopener noreferrer'
                href={`${o.url}`}
              >{o.label}
              </a>)}
          </div>)}
    </div>
  )
}
DownloadBar.propTypes = {
  course: PropTypes.object
}
