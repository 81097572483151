import * as React from 'react'

export const Lesson04 = () =>
  <div className='content'>
    <h3>Surfacing Assumptions with Colleagues</h3>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-workplace.svg'
      alt='Workplace Example'
    />
    <p>Maria’s team has regularly scheduled meetings at 1:00 p.m. every Wednesday.
      Maria works very hard to be on time and considers it a sign of disrespect when
      someone is late. Susan, one of her colleagues, is often late. Maria begins to
      notice other things Susan does that she finds impolite, such as checking her
      phone during meetings. On this particular Wednesday, Susan arrives at 1:10 p.m.
      and asks Maria to recap a critical piece of information that she missed due to
      her tardiness. After hearing the recap, Susan then offers a modification to the
      current course of action. Her suggestion causes Maria to reach her breaking
      point. She publicly discredits Susan’s input, explaining that she is not a
      contributing member of the team and that she finds her behavior rude. At that
      point, another member of the team explains that Susan has actually been part of
      a weekly call to help out the district manager. She has not been willfully late
      and disrespectful, but rather has discreetly been handling a difficult client.
      In that moment, Maria realizes that she has jumped up the ladder of inference
      and allowed the high-value she places on promptness to cause her to misjudge a
      colleague. She went from observation (Susan is late), to belief (Susan is
      disrespectful), to action (publicly discrediting her contributions). Maria
      apologizes for her action and is eventually able to restore a trusting,
      collegial relationship with Susan.
    </p>
    <p>One way we “jump up the ladder” and fail to adequately test our assumption(s)
      is by making judgments about people; hence the popular phrase, “You only have
      one chance to make a good first impression.” No matter how open-minded or
      forward-thinking we believe ourselves to be, the reality is we are jumping to
      conclusions all the time.
    </p>
    <h3>Reflection, Inquiry and Advocacy</h3>
    <p>Three practices that help you use the ladder of inference are reflection,
      inquiry and advocacy. Reflection allows you to suspend judgment and become more
      aware of your own thinking. Reflection is a time to make sure that you are
      paying attention to all the relevant data related to your system of interest.
    </p>
    <p>Inquiry encourages you to ask questions that help you understand someone
      else’s thinking. When inquiring about someone’s thinking, ask open-ended
      questions in order to seek clarification and help you better understand what
      others are noticing that leads them to their beliefs and actions they take.
      Advocacy is a skill in which you make your own thinking and reasoning more
      visible to others by describing what influences your beliefs and actions.
    </p>
  </div>
