import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const ToggleCard = (props) => {
  const { front, back, flipCardText } = props
  const [side, setSide] = useState(front)

  const handleSideChange = (side) => {
    return side === front ? setSide(back) : setSide(front)
  }

  useEffect(() => {
    setSide(front)
  }, [front])

  return (
    <div className='toggle-card' style={{ backgroundImage: `url(${side})` }}>
      <button type='button' className='toggle-button-cards' onClick={() => handleSideChange(side)}>
        {flipCardText}
      </button>
    </div>)
}

ToggleCard.propTypes = {
  front: PropTypes.string,
  back: PropTypes.string,
  flipCardText: PropTypes.string
}
