import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <div className='quote'>
      <p className='quote-text'>“You can discover more about a person in an hour of play than in a
        year of conversation.”
      </p>
      <p className='quote-author'> – Plato, philosopher</p>
    </div>
    <p>Providing people a kinesthetic, playful experience can create a safety net
      for encouraging flexibility and the surfacing and examining of diverse mental
      models. Place small tubs of modeling clay or PlayDoh® on tables during a meeting
      or group conversation, so that people can feel the pliable nature of the clay as
      a reminder to remain flexible in their thoughts and opinions. To help debrief
      this experience, note how difficult the clay is to mold once it is dried up. The
      importance of constant molding and manipulation of the clay is the same process
      that encourages mental model shifts and the development of new insights. Suggest
      that people share one another’s clay and combine colors and forms. This too can
      serve as a powerful metaphor for collaborative thinking that is malleable and
      flexible.
    </p>
  </div>
