import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/15-successive-approximation/course-img.png'
      alt='Successive Approximation'
    />
    <p>Much like an cook's efforts to make continuous improvements and refinements to a recipe, systems thinkers embrace change as an ongoing process that pays attention to continual improvement. An understanding of the system and the goal of delivering a beneficial product motivate each adjustment. Systems thinkers learn from experience and use that experience to improve their actions. This process is called “successive approximation.”</p>
    <div className='quote'>
      <p className='quote-text'>“One reason I was successful in business is that I was open to making mistakes and learning from them.”</p>
      <p className='quote-author'> — James L. Waters, founder of Waters Corporation and Waters Foundation</p>
    </div>
    <p>When asked years ago about successive approximation, Dr. Gordon Brown, Dean Emeritus of the College of Engineering at MIT responded, “Successive approximation: Is there any other way?”</p>
    <p>As a fundamental Habit of a Systems Thinker, successive approximation involves a willingness to take risks and overcome failure. The process applies to a wide range of change initiatives including:</p>
    <ul>
      <li>The design and implementation of an innovation.</li>
      <li>Plans for making improvements.</li>
      <li>The learning of a new skill.</li>
      <li>Efforts to solve problems and make change.</li>
    </ul>
    <h3>Successive Approximation Cycle</h3>
    <p>Adapted from W. Edwards Deming’s, “Plan, Do, Study, Act” (PDSA) cycle , the model to the right is representative of the successive approximation process. Let’s take a closer look at each step of the cycle:</p>
    <img
      id='improve-implement'
      src='/images/courses/habits/15-successive-approximation/improve-implement.svg'
      alt='Successive Approximation Cycle'
    />
    <h4>Design a Plan:</h4>
    <p>This is the initial phase of the process. When designing a plan, be sure to have clear steps in place that will lead you to your goal and deliver the desired benefits.</p>
    <h4>Implement:</h4>
    <p>Once you are satisfied with your plan, it’s time to put it into action.</p>
    <h4>Assess:</h4>
    <p>After following the steps of your plan for a period of time, you will have an understanding of the positives and negatives of your plan. This is an opportunity to review your results. Remember — the “negative” parts of your plan are really not negative at all. Rather, they are opportunities for learning, growth and improvement.</p>
    <h4>Improve:</h4>
    <p>Once you have assessed your plan, you will implement changes for improvement. A good plan is malleable, and can be altered and refined until the desired benefits (or goal) are achieved. This process will be ongoing, as there will always be room for improvements and improved results. However, if your plan delivers substantial benefits the next cycle of improvement can be delayed.</p>
    <p>Key elements of each step of the cycle are learning from experience, reflection and initiative focused on growth and development. Failure should be seen as an opportunity for further improvement — it is a necessary step of learning, innovating and achieving goals.</p>
    <div className='quote'>
      <p className='quote-text'>“Success is not final, failure is not fatal: it is the courage to continue that counts.”</p>
      <p className='quote-author'> — Winston Churchill, British Prime Minister</p>
    </div>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-workplace.svg'
      alt='Workplace Example'
    />
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-community.svg'
      alt='Community Example'
    />
    <p>Ultimately, a desire to continually imagine a better product, condition or state of a system is the mindset that drives successive approximation. At the heart of successive approximation is the drive to deliver benefits. These benefits can be for others (customers, students, family members, community members, etc.) or, equally as important, for yourself. The following examples illustrate the importance of successive approximation applied to design, improvement, learning and problem-solving initiatives.</p>
    <p>By the mid-1950s, the number of U.S. drivers on the road had increased significantly when compared to the start of the decade. As a result, the number of traffic-related injuries and deaths had also increased. Engineers used already existing technology (in particular, safety belts, which were originally developed in the mid-19th century) as a solution to this problem. The seat belts used during the 1950s were fastened around driver and passenger laps; fittingly, they were referred to as “lap belts.”</p>
    <p>Initially, the lap belt met the need of reducing traffic-related injuries. However, as the years passed, engineers became aware of flaws in these seat belts, particularly the tendency of lap belts to cause separation of the lumbar vertebrae during a collision.</p>
    <img
      className='lesson-img'
      src='/images/courses/habits/15-successive-approximation/lesson-one-img3.png'
      alt='Vehicle Safety'
    />
    <p>It was clear that improvement was needed. Lap belts were good, but not the best. Engineers in the U.S. looked to the three-point harness safety belt commonly used in Europe. The shoulder harness used in this system resulted in more effective safety measures (i.e. fewer injuries and deaths when compared to the lap belt). This type of seat belt was continually refined and improved until it eventually became standard equipment in most cars. In fact, in 2007, the shoulder harness seat belt became a requirement in all seats in passenger vehicles.</p>
    <p>Vehicle safety technology did not — and has not — stopped there. In 1981, Mercedes-Benz introduced the front airbag in Germany. This innovative technology became standard in most vehicles by the mid 1990s. At roughly the same time, BMW set its sites on improving this safety technology even more, and began using side or curtain airbags in its vehicles. Based on the trend of continuous improvement of automobile safety, it seems reasonable to anticipate that through a process of measuring results and changing actions, drivers and passengers will continue to benefit from improved safety features.</p>
  </div>
