import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <p>Mental models can be difficult to change but, are one of the most important places to intervene in a system. Chris Argyris, organizational psychologist, devised the ladder of inference to help people identify the deep-seated beliefs, values, and viewpoints that influence our decisions and actions. This tool allows us to visualize thinking processes and can be used to help <a href='/courses/07-assumptions/'>surface and test assumptions</a>, understand the structure of a system to <a href='/courses/14-leverage'>identify possible leverage actions</a>, and to <a href='/courses/02-connections/'>make meaningful connections within and between systems</a>. If you haven’t already, be sure to visit these courses in the Habits section.</p>
    <p>The ladder of inference is a tool that helps us pay attention to our mental models and their impact on our current reality and the future. Using the ladder allows those in a system to slow down their thinking and reflect. The tool makes our thinking visible and provides us the opportunity to “talk it through.”  This reflective communication on mental models is a gateway to consider an issue, an opportunity to challenge thinking and, if necessary, resist the urge to come to a quick conclusion.</p>
    <h3>How does it work?</h3>
    <img
      className='lesson-img'
      src='/images/courses/habits/07-assumptions/ladder-of-inference.png'
      alt='Ladder of Inference'
    />
    <p>The ladder is an analogy we can use to understand how perceptions and mental models develop unconsciously.</p>
    <p>Starting at the bottom of the ladder is the information and experiences around us that we could observe.</p>
    <p>The first step up the ladder are the details noticed regarding our chosen information and experience.</p>
    <p>Next, meaning is added to the details we notice. Our personal and cultural background influence the meaning we attach.</p>
    <p>The third step is where beliefs are developed based on the meaning added. This is often where conclusions are made on what we observe.</p>
    <p>Finally, we take action based on our beliefs.</p>
    <p>This all happens in a blink of an eye, beneath the surface of our awareness. The beliefs that emerge over time influence the details we pay attention to as we “go up the ladder.” The arrow to the right of the ladder shows how our beliefs influence what we choose to notice. The R next to the arrow means that our beliefs can reinforce what we notice.  This causal connection is a recursive relationship.  The stronger our beliefs, the more influence they have on what we notice.  As we go up the ladder, the more we notice the same things, and the stronger our belief becomes. This creates a reinforcing, or feedback, loop.</p>
    <h3>Consider this example:</h3>
    <p>Developing an awareness of our thinking is important and allows us time to reflect before taking action.  The ladder of inference gives us a process to determine how we come to the beliefs we develop; in other words, “climbing up the ladder.” A similar process can also allow us to analyze our reasoning to see if our conclusion is sound —“climbing down the ladder."</p>
    <h4>Using a typical situation, let’s see how this works.</h4>
    <table className='lesson-table'>
      <tbody>
        <tr>
          <th>Climbing Up the Ladder</th>
        </tr>
        <tr>
          <td>Bottom:</td>
        </tr>
        <tr>
          <td>We step up to the ladder with our background and experience. <em>It’s morning, racing to the car to get to work, I climb in and press the ignition.</em></td>
        </tr>
        <tr>
          <td>1st Step:</td>
        </tr>
        <tr>
          <td>We notice certain information and details. <em>I don’t hear anything at all. Pressing the ignition again – I hear nothing!</em></td>
        </tr>
        <tr>
          <td>2nd Step:</td>
        </tr>
        <tr>
          <td>We add our own meaning. <em>The car is not going to start.</em></td>
        </tr>
        <tr>
          <td>3rd Step:</td>
        </tr>
        <tr>
          <td>We develop beliefs. <em>The battery must be dead.</em></td>
        </tr>
        <tr>
          <td>4th Step:</td>
        </tr>
        <tr>
          <td>We do something – take action. <em>I will call a service station to come help me start my car.</em></td>
        </tr>
      </tbody>
    </table>
    <div className='quote'>
      <p className='quote-text'>In this example, in under a minute we come to a conclusion based on the interpretation of data we noticed. We made an assumption and are on the phone requesting assistance.</p>
    </div>
    <h4>But, what if we challenge our assumptions and take a look at the action we are taking.</h4>
    <table className='lesson-table'>
      <tbody>
        <tr>
          <th>Climbing Down the Ladder</th>
        </tr>
        <tr>
          <td>4th Step:</td>
        </tr>
        <tr>
          <td>Why am I doing this? <em>I need to get my car started.</em></td>
        </tr>
        <tr>
          <td>3rd Step:</td>
        </tr>
        <tr>
          <td>What makes me believe the battery is dead? <em>The car didn’t start; it didn’t make any sounds whatsoever. That has happened to me before and the battery was dead.</em></td>
        </tr>
        <tr>
          <td>2nd Step:</td>
        </tr>
        <tr>
          <td>Is the assumption attached to the event valid? <em>Well, maybe, but it could be something else. This is a different car, much newer than I have ever had before. I’ve never had a car without a key. This fob thing is new to me.</em></td>
        </tr>
        <tr>
          <td>1st Step:</td>
        </tr>
        <tr>
          <td>What data am I paying attention to? Why? <em>The car didn’t start when I pressed the ignition button. It was silent. That’s what I listen for when a car is starting and I need to get to work.</em></td>
        </tr>
      </tbody>
    </table>
    <div className='quote'>
      <p className='quote-text'>The conclusion seems obvious based on previous experience. But, using the ladder allows us to test out our assumptions.  Climbing down the ladder slows our thinking down and provides a process to examine our thinking before making a decision. <em>Is there something else that could be causing the car not to start? Is my assumption valid? What else should I be paying attention to?</em></p>
    </div>
    <h4>In this scenario, our beliefs from previous experiences with keys and cars are leading us to action.  But, because of the ladder’s reinforcing loop, there is a failure to notice other details.</h4>
    <div className='quote'>
      <p className='quote-text'>“To raise new questions, new possibilities, to regard old problems from a new angle, requires creative imagination and marks real advance in science.”</p>
      <p className='quote-author'> — Albert Einstein</p>
    </div>
    <h3>Reflection Questions</h3>
    <p>Questions are a key factor in the ladder of inference.  We can use questions to examine our assumptions, beliefs and actions.  Questions help challenge our conclusions or the conclusions of others. <a href='https://www.mindtools.com/pages/article/newTMC_91.htm' target='blank' rel='noopener'>The Ladder of Inference, How to Avoid Jumping to Conclusions</a> from MindTools gives us a series of questions to ask when examining our mental models using the ladder.</p>
    <h4>Questions indicating a need to use the ladder of inference:</h4>
    <ul>
      <li>Is this the right conclusion?</li>
      <li>Why am I making these assumptions?</li>
      <li>Why do I think this is the right thing to do?</li>
      <li>Is this really based on facts?</li>
      <li>Why does (he/she) believe that?</li>
    </ul>
    <h4>Questions to ask when examining our thinking while going down the ladder:</h4>
    <ul>
      <li>Why have I chosen this action? Are there other actions I should have considered?</li>
      <li>What belief led to that action?</li>
      <li>Why did I draw that conclusion? Is that conclusion sound?</li>
      <li>What am I assuming and why? Are my assumptions valid?</li>
      <li>What data have I chosen to use and why? Have I selected data rigorously?</li>
    </ul>
  </div>
