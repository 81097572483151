import { handleActions } from 'redux-actions'
import { ActionTypes } from './edit.actions'

export const editReducer = handleActions({
  [ActionTypes.EditSuccess]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [ActionTypes.LoadSuccess]: (state, { payload }) => ({
    ...state,
    ...payload
  })
}, {
  auth: false,
  formValues: {
    firstname: ' ',
    lastname: ' ',
    city: ' ',
    state: ' ',
    zip: ' ',
    country: ' ',
    organization: ' ',
    username: ' ',
    email: ' '
  }
})
