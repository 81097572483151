import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <p>
      Like the previous exercise, use the text boxes below to make your own causal connections that change in the opposite direction. And then, tell the story of your link.
    </p>
    <h3>#1</h3>
    <div className='causal-link'>
      <div className='causal-column'>
        <Field
          name='variable1'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your first variable.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
        <p className='link-label red'>O</p>
      </div>
      <div className='causal-column'>
        <Field
          name='variable2'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your second variable.'
        />
      </div>
    </div>
    <h4>
      Tell the story of your link:
    </h4>
    <Field
      name='story1'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h3>#2</h3>
    <div className='causal-link'>
      <div className='causal-column'>
        <Field
          name='variable3'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your first variable.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
        <p className='link-label red'>O</p>
      </div>
      <div className='causal-column'>
        <Field
          name='variable4'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your second variable.'
        />
      </div>
    </div>
    <h4>
      Tell the story of your link:
    </h4>
    <Field
      name='story4'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h3>#3</h3>
    <div className='causal-link'>
      <div className='causal-column'>
        <Field
          name='variable5'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your first variable.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
        <p className='link-label red'>O</p>
      </div>
      <div className='causal-column'>
        <Field
          name='variable6'
          component='textarea'
          className='reflection-input'
          placeholder='Enter your second variable.'
        />
      </div>
    </div>
    <h4>
      Tell the story of your link:
    </h4>
    <Field
      name='story3'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
