import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>How do I balance the value of seeking the big
      picture while honoring the importance of details?
    </h4>
    <Field
      name='details-importance'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>How does this broader view provide me with a
      perspective that modified my view or enabled me to see something differently?
    </h4>
    <Field
      name='my-view'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>To what extent is my broader view focused on areas
      I influence, rather than on areas I'm concerned about that I cannot influence?
    </h4>
    <Field
      name='broader-view'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
