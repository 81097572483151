import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <p>Systems thinkers flourish when making connections within and between systems. It was a conscious decision to start with this Habit, in hopes that you will make your own meaningful connections as you work your way through the Habits of a Systems Thinker.
    </p>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/02-connections/course-img.png'
      alt='Making Connections'
    />
    <h3>Making Meaningful Connections</h3>
    <p>Connections exist in nature, in learning and in relationships with others.
      The ability to make meaningful connections is a vital part of thinking and
      learning. Given the many contexts in which this Habit applies, it would be easy
      to trivialize the notion that everything is connected. A systems thinker
      continually makes meaningful connections and consciously weaves them together to
      produce clearer thinking and new ideas.
    </p>
    <div className='quote'>
      <p className='quote-text'>“In nature we never see anything isolated, but everything in
        connection with something else which is before it, beside it, under it and over
        it.”
      </p>
      <p className='quote-author'> – Johann Wolfgang von Goethe, author</p>
    </div>
    <h3>Natural World Examples</h3>
    <p>Examples of connections exist throughout the natural world. A plant grows
      from a seed then flowers and produces fruit that eventually produces seeds. The
      plant is also connected to the birds and insects that pollinate its flowers. It
      needs sun, wind and water to grow. Predators or unfavorable weather can
      adversely affect it. The plant’s survival is dependent on an intricate series of
      connections. On one hand, these connections happen naturally and without much
      thought or consideration, and yet the farmer, whose livelihood is dependent on
      producing a crop of fruit and having seeds for the future, has to give careful
      consideration to all of these factors.
    </p>
  </div>
