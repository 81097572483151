import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <h3>Introduction</h3>
    <p>This activity offers a kinesthetic experience to promote a deeper understanding of rates and flows. It requires some materials, but will certainly be worth the effort, particularly if you want to help others have a deeper understanding of stock-flow mapping or have a desire to further develop your stock-flow skills.</p>
    <h4>To get started, you will need:</h4>
    <ul>
      <li>A container to represent the stock. A graduated cylinder works well for this, but a gallon jug works too.</li>
      <li>Two containers to represent the inflow and the outflow. Plastic cereal containers are a good option here because the pouring spout helps hold the tubing in place.</li>
      <li>100 inches of plastic tubing cut into four, 25-inch sections. Diameter of the tubing must calibrate to the size of valves on the pump.  This is true of both the bulb pumps and the crank pumps.  Crank pumps used in this illustration require tubing with a diameter of 5/16 of an inch.</li>
      <li>Two manual fuel pumps. This is the item you are most likely not to have on hand. A crank fuel pump works best, but a bulb pump works, also.  Either type of pump will work in either the basic setup or the drilled setup, shown below.</li>
    </ul>
    <p>
      The basic setup is the same whether you use a bulb pump or a crank pump.  The setup requires one piece of tubing from the inflow container to the pump and a second piece of tubing from the pump to the stock, or cylinder.  Duplicate this setup on the outflow side.  One piece of tubing goes from the stock/cylinder to the pump and a second piece of tubing from the pump to the outflow container.
    </p>

    <p>
      Illustrated below are two versions of the Water Activity setup.  The first can be assembled with the set of listed materials and requires only that the tubing be cut into 25-inch sections.   The second setup requires the drilling of holes in the inflow container and the stock.
    </p>

    <div className='setup'>
      <div className='setup-column'>
        <img
          className='setup-examples'
          src='/images/resources/water-activity/bulb-pump-setup.png'
          alt='Bulb Pump Setup'
        />
        <h3>
          Basic Apparatus Setup
        </h3>
        <p>
          For the basic pump setup you will need two containers of a similar size. Pictured are two plastic cereal containers, available at a dollar store.  The lid has a circle, typically used for pouring the cereal.  However, in this case it serves to help secure the tubing in place.  If you experience difficulty keeping the tubing deep enough in the stock container (pictured here as a graduated cylinder), consider adding a metal key or washer to the end of the tubing to hold it in place.  Be careful not to tie or crimp the tubing in order to attach the weight; instead, find a key or washer that has a hole an appropriate size to slide onto the tubing.
        </p>

      </div>
      <div className='setup-column'>
        <img
          className='setup-examples'
          src='/images/resources/water-activity/crank-pump-setup.png'
          alt='Crank Pump Setup'
        />
        <h3>
          Drilled Apparatus Setup
        </h3>
        <p>
          The drilled pump setup requires drilling a hole at the bottom of the inflow container.  This hole holds a piece of piping connected to the inflow pump.  A hole drilled at the bottom of the stock holds a piece of tubing that is then connected to the outflow pump.
        </p>

        <p>
          See additional directions with the diagram below.
        </p>

      </div>
    </div>
    <div className='activity'>
      <img
        className='water-activity'
        src='/images/resources/water-activity/water-activity.png'
        alt='Water Activity Picture'
      />
    </div>
    <h3>Drilled Water Apparatus Assembly</h3>
    <ol>
      <li>Arrange the bins (#1 and #7), the cylinder, and the pumps (#4 and #6) as shown in the photo. Note that bin #1 has a small metal nozzle at the bottom and that bin #7 has a lid attached.</li>
      <li>Attach one tube section to the nozzle at point #3.</li>
      <li>Attach the other end of the tube to pump #4. Note: Depending on how you hook the tubes to the pumps, the direction of flow will change. If the tubes are “crossed” then you will turn the pump clockwise.</li>
      <li>Find the tube section with the weight/washer attached.  Attach the side without the washer to pump #4. Place the other end with the weight into the cylinder.</li>
      <li>Push a tube section into the bottom hole (#5) of the cylinder. Avoid removing this once it’s placed.</li>
      <li>Put the other tube end coming from point #5 into pump #6.</li>
      <li>Attach the last tube section to the other port on pump #6 and have it go out to bin #7.  If a lid is available, place the tube in the top hole to stabilize the tube.</li>
      <li>Remember that once you start pumping water, you’ll need to determine the direction of flow based on how you hooked up the tubing.</li>
    </ol>
    <p>Over time, if the tubing gets old, you may need to replace it. The tubing recommended is standard ¼” OD  X .17” ID for use in an aquarium (from a pet store) or plumbing tubing (from a hardware store). Also, remove the weights from the water after completing the activity. Otherwise, they will corrode.</p>
  </div>
