import { handleActions } from 'redux-actions'
import { ActionTypes } from './events.actions'

export const eventsReducer = handleActions({
  [ActionTypes.RegisterSuccess]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [ActionTypes.LoadEventsSuccess]: (state, { payload }) => ({
    ...state,
    ...payload
  })
}, {
  eventData: [{
    id: ' ',
    title: ' ',
    dateTime: ' '
  }]
})
