import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson01 = () =>
  <div className='content'>

    <p>
      You might be in an escalation scenario when…
    </p>

    <div className='quote'>
      <p className='quote-text'>
        You recognize that there are two competing parties of seemingly equal power who each, because of perceived or actual threat, take actions to generate a more powerful position for themselves.
      </p>
    </div>

    <p>
      The components of the archetype template include:
    </p>

    <ul>
      <li>
        Two parties, organizations, companies, groups or individuals represented by the letters <strong>A</strong> and <strong>B</strong>.
      </li>

      <li>
        Two balancing loops that include variables of threat, activity caused by feeling threatened, and results of the activity or actions to alleviate the threat. One loop represents the emotions and actions of <strong>A</strong> while the other represents <strong>B</strong>.
      </li>
    </ul>

    <img
      className='lesson-img large-img block'
      src='/images/courses/archetypes/04-escalation/escalation.svg'
      alt='Escalation'
    />

    <h3>
      Story of this Archetype
    </h3>

    <p>
      As you read each part of the story, press the “Next Part of Story” button below the graphic to see the story of the archetype unfold.
    </p>

    <div className='arch-components'>
      <div className='arch-p-container'>

        <p>
          <strong>Part 1:</strong> It is helpful to begin in the center with a focus on one party or individual who is perceived to have the upper hand or more powerful position. The template shows the parties or individuals as <strong>A</strong> and <strong>B</strong>.
        </p>

        <p>
          <strong>Part 2:</strong> If <strong>A</strong> is perceived to be most powerful at first, that positioning causes <strong>B</strong> to feel threatened. This emotion causes <strong>B</strong> to engage in an activity to alleviate the threat and heighten their own position of superiority.
        </p>

        <p>
          <strong>Part 3:</strong> This activity produces results that place <strong>B</strong> in a dominant position relative to <strong>A</strong>.
        </p>

        <p>
          <strong>Part 4:</strong> With <strong>B</strong> now having the upper hand, <strong>A</strong> feels threatened and engages in activity to attempt to reposition themselves firmly over <strong>B</strong>.
        </p>

        <p>
          <strong>Part 5:</strong> This activity generates results that now place <strong>A</strong> once again achieving dominance over <strong>B</strong>.
        </p>

        <p>
          This pattern of shifting dominance moves in a figure-eight pattern and shows how escalating threats and reactionary activities shift positions of power from <strong>A</strong> to <strong>B</strong> and <strong>B</strong> to <strong>A</strong> and so on, over time.
        </p>
      </div>

      <div className='arch-build-container'>
        <ToggleImages
          images={[{ id: 1, image: '/images/courses/archetypes/04-escalation/escalation-step1.svg' }, { id: 2, image: '/images/courses/archetypes/04-escalation/escalation-step2.svg' }, { id: 3, image: '/images/courses/archetypes/04-escalation/escalation-step3.svg' }, { id: 4, image: '/images/courses/archetypes/04-escalation/escalation-step4.svg' }, { id: 5, image: '/images/courses/archetypes/04-escalation/escalation-step5.svg' }]}
        />
      </div>
    </div>

    <div className='exerpt'>
      <p>
        <strong>Reflection:</strong> Consider a time when as part of a group or as an individual you were competing or striving for the upper hand with another group or individual. You may have felt like you were competing for attention, resources or authority. What did the other party do to threaten your position? What actions did you take to alleviate the threat? What results helped you regain the upper hand or give you the advantage?
      </p>
    </div>

    <p>
      Continue to Lesson 2 for examples of stories that illustrate the Escalation archetype.
    </p>

  </div>
