import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

const Systems = [
  'well-being',
  'workplace',
  'school',
  'community',
  'family'
]

export const Exercise04 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>The first step in identifying circular causality is paying attention to causal links between important changing elements. For this practice, choose a system you are a part of by clicking on one of the icons below. It could be your well-being, a family system, a workplace system, a school system, or a community system.</p>
    <h4>My practice system is:</h4>
    <div className='system-select'>
      {Systems.map(s =>
        <div key={s} className={`system-select-item ${s}`}>
          <Field name='systemSelect' id={`ss-${s}`} component='input' type='radio' value={s} />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      )}
    </div>
    <p>Ask yourself, “How does a change in one small part of this system influence or cause a change in another part of the system?” For example, in a family system when one parent must leave the home for an extended period of time for a business trip, think about the impact that absence has on the day-to-day routines of the children and the increased expectations on the other parent or caretaker.</p>
    <p>Ask yourself these questions as you consider circular causality in your system. Your responses should help you begin the process of identifying causal loops and leverage in your chosen system.</p>
    <h4>What aspect of your system would you like to see increase (e.g. employee commitment, trust, effective communication) or decrease (e.g. apathy, argument, stress)?</h4>
    <Field
      name='system-aspect'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What is a key factor that would directly cause the variable(s) to increase or decrease?</h4>
    <Field
      name='key-factor'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>How does a change in one of the variables lead to a change in another?</h4>
    <Field
      name='variable-change'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <p>Using a piece of paper, draw a causal map that shows your responses to the questions. Try not to limit yourself to simple causal pairs. Ask what essential elements impact one another in your system. Tell the story and draw the causal links. Be prepared to erase if using pencil or cross out initial attempts. Drawing causal feedback loops can be a messy process, as each iteration brings increased clarity and accuracy.</p>
    <Field
      name='causalMap'
      component={FileUpload} image
      instructions='Draw the loop(s) on paper and upload a photo below.'
    />
    <h4>Tell the story of your loop(s) here.</h4>
    <Field
      name='loops'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
