import * as React from 'react'
import { getSessionAuth } from 'components/shared/session'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

export const MemberHome = () => {
  const { user } = getSessionAuth()
  return (
    <div>
      <Header />
      <div className='content'>
        <h1>Welcome!</h1>
        {user ? <p>Hello, {user.firstname} {user.lastname}</p> : <p><a href='https://waterscenterst.org/join'>Sign up</a> or <a href='https://waterscenterst.org/login'>login</a> to access the Studio.</p>}
        <p>We are glad you’re here. Thank you for being a part of the Thinking Tools Studio, powered by the Waters Center for Systems Thinking.</p>
        <p>We hope you find the Studio to be a place of ongoing learning, full of content that will help you build your systems thinking knowledge and ideas of how to apply what you’ve learned to your systems of interest.<br />Below you will find quick links to courses and resources.</p>
        <p>The Studio will be regularly updated with new content and resources to maximize learning. We will notify you of updates via email.</p>
        <p>Please take a moment to read through the <a href='/terms'>Terms and Conditions</a>.</p>

        <h1 className='centered'>
          Systems Thinking Courses
        </h1>

        <section className='white courses-overview-container'>
          <div className='habitscourses'>
            <h1>Habits Courses</h1>

            <img src='images/site/habitcards-all.png' />

            {/* <p>
              The Habits of a Systems Thinker help learners understand how systems work and how actions taken can impact results seen over time. Thinking Tools Studio features a course for each of the 14 Habits plus a “Getting Started” course.
            </p> */}

            <div className='button-container'>
              <a href='/courses/habits'>
                <button>
                  View Habits Courses
                </button>
              </a>
            </div>
          </div>

          <div className='toolscourses'>
            <h1>Tools Courses</h1>

            <img src='images/site/tools.png' />

            {/* <p>
              You will find courses on a variety of tools in the Thinking Tools Studio. Some tools have been split up into multiple courses to ensure learners are given the time needed to understand these multi-faceted, often complex tools and their underlying concepts.
            </p> */}

            <div className='button-container'>
              <a href='/courses/tools'>
                <button>
                  View Tools Courses
                </button>
              </a>
            </div>
          </div>

          <div className='archetypescourses'>
            <h1>Archetypes Courses</h1>

            <img src='images/site/archetype-feature.svg' />

            {/* <p>
              Systems archetypes and their accompanying visual templates provide us lenses with which to view systems more clearly. Thinking Tools Studio features 9 Archetypes courses.
            </p> */}

            <div className='button-container'>
              <a href='/courses/archetypes'>
                <button>
                  View Archetypes Courses
                </button>
              </a>
            </div>
          </div>
        </section>

        <h1 className='centered'>
          Systems Thinking Resources
        </h1>

        <section className='white resources-overview-container'>
          <div className='templates'>
            <h1>Templates</h1>

            <img src='images/resources/shared/icons-notext/templates-icon.svg' />

            {/* <p>
              Here you will find downloadable templates and worksheets to help you, or others in a group you may be facilitating, apply systems thinking concepts to areas of interest. Templates and worksheets can be downloaded, printed and shared under the Creative Commons terms of use.
            </p> */}

            <div className='button-container'>
              <a href='/resources/templates'>
                <button>
                  View Templates
                </button>
              </a>
            </div>
          </div>

          <div className='guides'>
            <h1>Facilitation Guides</h1>

            <img src='images/resources/shared/icons-notext/facilitation-icon.svg' />

            {/* <p>
              Facilitation guides will aid those looking to facilitate systems thinking workshops, learning sessions, and activities with groups of varying sizes and from various types of systems. Each facilitation guide has an intended audience (children through adults) — be sure to explore all the guides to see which will suit your needs best. Keep in mind that with a little creativity and planning, many can be adapted to best address the needs of your participants.
            </p> */}

            <div className='button-container'>
              <a href='/resources/guides'>
                <button>
                  View Facilitation Guides
                </button>
              </a>
            </div>
          </div>

          <div className='lessonplans'>
            <h1>Lesson Plans</h1>

            <img src='images/resources/shared/icons-notext/primary-icon.svg' />

            {/* <p>
              Lesson plans offered have been produced and successfully used by educators with students in PreK-Secondary settings. When using the lesson plans, you may want to implement them as is, or modify them to better meet the needs of your students, the content your students will be learning, and the outcomes you desire.
            </p> */}

            <div className='button-container'>
              <a href='/resources/lessons'>
                <button>
                  View Lesson Plans
                </button>
              </a>
            </div>
          </div>

          <div className='assessments'>
            <h1>Assessments</h1>

            <img src='images/resources/shared/icons-notext/assessments-icon.svg' />

            {/* <p>
              Assessments include rubrics and other tools to help facilitators, educators, and practitioners assess others or self-assess systems thinking capacity and skills.
            </p> */}

            <div className='button-container'>
              <a href='/resources/assessments'>
                <button>
                  View Assessments
                </button>
              </a>
            </div>
          </div>

          <div className='webinars'>
            <h1>Webinars & Videos</h1>

            <img src='images/resources/shared/icons-notext/webinar-icon.svg' />

            {/* <p>
              Here you will find previously recorded webinars and videos. Webinars were facilitated by Waters Center for Systems Thinking staff and associates, and videos feature students and adults demonstrating and applying systems thinking concepts to real-life scenarios.
            </p> */}

            <div className='button-container'>
              <a href='/resources/webinars'>
                <button>
                  View Webinars & Videos
                </button>
              </a>
            </div>
          </div>

          <div className='articles'>
            <h1>Articles</h1>

            <img src='images/resources/shared/icons-notext/articles-icon.svg' />

            {/* <p>
              Explore a variety of published articles relevant to your systems thinking learning and application. Featured articles have been written by both Waters Center for Systems Thinking leaders and outside authors.
            </p> */}

            <div className='button-container'>
              <a href='/resources/articles'>
                <button>
                  View Articles
                </button>
              </a>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  )
}
