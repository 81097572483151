import * as React from 'react'
import { Field } from 'redux-form'
import { FileUpload } from 'components/shared/file-upload'

export const Exercise03 = () =>
  <div className='content'>
    <h3>
      Practice the Tool
    </h3>

    <p>
      Using the map you created in the previous exercise, complete the steps below.
    </p>

    <ol>
      <li>
        If you notice a BOTG variable that seems to have no or very few arrow connections:
        <p>
          <b>a.</b> Feel free to eliminate this variable because it may not be essential to a deep understanding of your system, or
        </p>

        <p>
          <b>b.</b>  Consider, “What additional BOTG variables might you add to your map that would cause a change in this variable?” and “When this variable increases or decreases, what other variable(s) would be affected that you may want to add to the map?” Oftentimes, after creating your map, you will add and eliminate variables because of an increased understanding of what is really important.
        </p>

        <Field
          name='consider'
          component='textarea'
          className='reflection-input'
          placeholder='What variables did you eliminate or add to your map? What caused you to make those deletions or additions?'
        />
      </li>

      <li>Identifying BOTG variables that could be most influential to the system:
        <img
          className='lesson-img habit-img'
          src='/images/shared/habit-cards/shadow/leverage.png'
          alt='Leverage'
        />

        <p>
          <b>a.</b> Count the number of arrow tails or ends (not the arrowheads) originating from each variable.  Write the number next to the variable.
        </p>

        <p>
          <b>b.</b> Look for the variable with the greatest number of arrow tails or ends, and ask yourself this question, “How would a small change in this variable impact other system elements, resulting in a ripple effect on the whole system?” Oftentimes, the variable that serves as the most popular cause (most # of arrow tails or ends) on multiple effects is a good place to begin when considering leverage.  Too often people try to make plans for simultaneous change in multiple areas of a system without considering the short and long-term effects on other parts of the system. Systems thinkers have the ability to find places in the system that have the potential to stimulate positive ripple effects that have a desirable impact on the whole. Those efforts relate to the Habit, <a href='/courses/14-leverage' target='blank' rel='noopener'>uses understanding of system structure to identify possible leverage actions</a>.
        </p>

        <Field
          name='consider-2'
          component='textarea'
          className='reflection-input'
          placeholder='What are some possible leverage areas of your map? What strategies did you use to identify leverage?'
        />
      </li>

      <li>Finding loops in your Casual Connection Circle Map:
        <img
          className='lesson-img habit-img'
          src='/images/shared/habit-cards/shadow/circular-nature.png'
          alt='Circular Nature'
        />

        <p>
          <b>a.</b> Identify one variable in your map that is connected to at least one arrow tail and one arrowhead.  Begin with that variable and talk through how it is connected to other variables. Then, continue telling your story by following the arrow connections within your map to see if a causal loop story emerges. A loop will be evident if your story actually ends where it began.  This relates to the Habit, <a href='/courses/09-circular-nature' target='blank' rel='noopener'>identifies the circular nature of complex cause and effect relationships</a>.
        </p>

        <p>
          Sometimes you will find two variables that have arrows connecting them to one another to form a loop.  Other times there will be more than two variables connected with arrows to form a loop.  If you can find one or more loops in your casual connection circle map, pull them out by redrawing them on paper.  {/* You will be able to use these in the <a href="">Feedback Loop course</a> which is the recommended next course. */}
        </p>

        <Field
          name='circleMap'
          component={FileUpload} image
          instructions='If you found loops in your causal connection circle map, take a photo of the redrawn loops and upload them below.'
        />
      </li>

      <li>Have multiple groups map the same system or similar systems:
        <img
          className='lesson-img habit-img clear'
          src='/images/shared/habit-cards/shadow/surfaces-tests.png'
          alt='Surfaces and Tests Assumptions'
        />

        <p>
          <b>a.</b> When multiple small groups go through a mapping process of the same or a similar system, it is a worthwhile experience to share the maps using a gallery walk or a “pair and share.”
        </p>

        <p>
          <b>b.</b> While sharing, people will have an opportunity to see the similarities and differences between maps, and provide groups a chance to revisit their own map and make adjustments that often include adding additional elements and causal arrows.
        </p>

        <p>
          <b>c.</b> Debrief the experience using the <a href='/courses/02-ladder-of-inference'>Ladder of Inference</a>. Causal Connection Circle maps help <a href='/courses/07-assumptions/'>surface and test assumptions</a> we hold about a system. The questions on the back of the surfaces and tests sssumptions <a href='/cards'>Habit card</a> will help inform your debrief. Click on the link to the cards and select “flip card” to access the questions.
        </p>

        <Field
          name='consider-3'
          component='textarea'
          className='reflection-input'
          placeholder='If you shared your map with others who mapped the same system, what were some similarities and differences between the maps? Describe how mental models influence the mapping process.'
        />
      </li>
    </ol>
  </div>
