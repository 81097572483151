import * as React from 'react'
import { Field } from 'redux-form'
import { FileUpload } from 'components/shared/file-upload'
import { ToggleImage } from 'components/shared/toggle-image'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Archetype</h3>

    <p>
      To begin, <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-blank-so.pdf' target='_blank' rel='noopener noreferrer'>download and print a blank Shifting the Burden archetype template.</a>
    </p>

    <p>
      Next, select one or more of the three examples presented in Lesson 2: Case in Point.
    </p>

    <p>
      Now it is time to practice the Shifting the Burden archetype. Fill in each of the boxes on your blank archetype template and then use it to retell the story.
    </p>

    <Field
      name='shiftingtheburden-template'
      component={FileUpload} image
      instructions='Once you have filled in the template, take a photo and upload it below.'
    />

    <p>
      There may be different ways to tell a story using the archetype framework, but in order to provide you some feedback, one version
      of each of the scenarios is available to you. After creating your version of the archetype diagram, click on the appropriate
      link below to see one version of the story.
    </p>

    <div className='archetype-examples'>
      <div>
        <ToggleImage
          src='/images/courses/archetypes/05-shifting-the-burden/caffeine.svg'
          className='med-img'
          alt='Caffeine'
          showText='Caffeine'
          hideText='Hide Example'
        />
      </div>

      <div>
        <ToggleImage
          src='/images/courses/archetypes/05-shifting-the-burden/tech-support.svg'
          className='med-img'
          alt='Tech Support'
          showText='Tech Support'
          hideText='Hide Example'
        />
      </div>

      <div>
        <ToggleImage
          src='/images/courses/archetypes/05-shifting-the-burden/heroics.svg'
          className='med-img'
          alt='Heroics'
          showText='Heroics'
          hideText='Hide Example'
        />
      </div>
    </div>
  </div>
