import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      You might be in a “shifting the burden” scenario when….
    </p>

    <div className='quote'>
      <p className='quote-text'>
        You choose a short-term solution and its side effect undermines your ability to implement a long-term, more fundamental solution.
      </p>
    </div>

    <img
      className='lesson-img large-img block'
      src='/images/courses/archetypes/05-shifting-the-burden/shifting-the-burden.svg'
      alt='Escalation'
    />

    <h3>
      Story of this Archetype
    </h3>

    <p>
      As you read each part of the story, press the “Next Part of Story” button below the graphic to see the story of the archetype unfold.
    </p>

    <div className='arch-components'>
      <div className='arch-p-container'>

        <p>
          <strong>Part 1:</strong> The story begins with a problem symptom. You can think of a problem symptom in a variety of ways. It can be a need, a goal to be met, an opportunity for change or a difficult, complex, messy problem.  Typically, it would be something that is causing enough distress or discomfort that there is a perceived need to take action or to respond in some way.
        </p>

        <p>
          <strong>Part 2:</strong> One response to a problem symptom is to take an action that addresses a symptom of that problem. If a headache is the problem symptom, the response may be to take an aspirin, which addresses the headache symptom. In the Shifting the Burden archetype this is called the symptomatic solution. It can also be thought of as a quick-fix.
        </p>

        <p>
          <strong>Part 3:</strong> At this point in the story, “the pain” has been minimized. The problem has been addressed in the short-term. The problem symptom improves.  Symptomatic solutions can be very effective in addressing problems in the short-term.
        </p>

        <p>
          The symptomatic solution creates a balancing feedback loop by lessening the problem symptom. This causal relationship is noted by the B in the center of the loop. You can read more about balancing feedback in <a href='/courses/08-cld-balancing'>Tools Course #8: Causal Diagrams Part 2: Balancing Feedback</a>.
        </p>

        <p>
          <strong>Part 4:</strong> In a “shifting the burden” story, a side effect emerges, which takes away from a more fundamental solution to the problem. Side effects can be similar to dependency. The system becomes more and more dependent on the symptomatic solution.
        </p>

        <p>
          <strong>Part 5:</strong> Fundamental solutions address the root cause of a problem. Unlike the symptomatic solution, the fundamental solution not only lessens the problem symptom, it actually decreases the problem by creating some type of long-term improvement in the situation.
        </p>

        <p>
          <strong>Part 6:</strong> Putting all the loops together, the archetype story sounds something like this: The “system” gravitates toward the symptomatic solution because it is typically quick and easy. In the short term it helps the problem symptom and becomes the dominant pattern of behavior.  Over time, the symptomatic solution creates a side effect (S). This side effect is often described as a dependency.  It “shifts the burden” away from the fundamental solution or decreases the implementation of that solution (O). Moving away from the fundamental solution makes the problem symptom worse (O). This increase in the problem symptom increases the need for applying the symptomatic solution (S). Over time (note the time delay mark (//) in the archetype) this loop story continues around and around, making it increasingly difficult to arrive at the fundamental solution.
        </p>
      </div>

      <div className='arch-build-container'>
        <ToggleImages
          images={[{ id: 1, image: '/images/courses/archetypes/05-shifting-the-burden/stb-part1.svg' }, { id: 2, image: '/images/courses/archetypes/05-shifting-the-burden/stb-part2.svg' }, { id: 3, image: '/images/courses/archetypes/05-shifting-the-burden/stb-part3.svg' }, { id: 4, image: '/images/courses/archetypes/05-shifting-the-burden/stb-part4.svg' }, { id: 5, image: '/images/courses/archetypes/05-shifting-the-burden/stb-part5.svg' }, { id: 6, image: '/images/courses/archetypes/05-shifting-the-burden/stb-part6.svg' }]}
        />
      </div>
    </div>

    <div className='exerpt'>
      <p>
        <strong>Reflection:</strong> Consider a time when taking action made it difficult to implement a more fundamental, sustainable solution.
      </p>
    </div>

    <p>
      Continue to Lesson 2 for examples of stories that fit the Shifting the Burden archetype.
    </p>
  </div>
