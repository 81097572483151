import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Archetype</h3>

    <p>
      When have you encountered a “tragedy of the commons” story?  Use this archetype to reflect on that experience.
    </p>

    <p>
      Feel free to <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-blank-so.pdf' target='_blank' rel='noopener noreferrer'>download a blank “tragedy of the commons” template</a> and use it to tell your story as you respond to the questions.
    </p>

    <h4>
      1. What is the commons being used?
    </h4>

    <Field
      name='resource-response-one'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      2. Who is using the commons? Remember this can be individuals, groups, companies or even countries and it can be two or more entities drawing on the resource. That all depends on the boundaries you set for your system.
    </h4>

    <Field
      name='resource-response-two'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      3.What is the resource limit?
    </h4>

    <Field
      name='resource-response-three'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      4.How can you create structures to “divide” a resource in a way that people receive feedback about how they directly impact a particular section of the resource?
    </h4>

    <Field
      name='resource-response-four'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      5. What regulations might be established to assure that the resource doesn’t run out over time?
    </h4>

    <Field
      name='resource-response-five'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h3>
      Reflecting on Your Archetypical Story
    </h3>

    <h4>
      What is changing over time in your story?
    </h4>

    <Field
      name='resource-response-six'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      Can you identify elements of balancing and reinforcing feedback as you tell your story?
    </h4>

    <Field
      name='resource-response-seven'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      Having applied this archetype to your particular situation, how might you preserve the resource for the future?
    </h4>

    <Field
      name='resource-response-eight'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      What new insights about your situation were gained by applying this archetype?
    </h4>

    <Field
      name='resource-response-nine'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
