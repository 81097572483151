import * as React from 'react'

export const Lesson06 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/13-accumulations/lesson-six-img.png'
      alt='Stock-flow Map'
    />
    <p>A tool that helps systems thinkers pay attention to accumulations and their rates of change is the stock-flow map.</p>
    <p>Systems thinking is derived from the field of system dynamics, in which practitioners and system dynamicists use advanced stock-flow maps made into mathematical computer models to represent a system. These models are used by a variety of organizations, including government agencies and for-profit corporations. For example, the U.S. Department of Homeland Security uses these models to assess level of volatility in various regions of the world.  Public health agencies use stock-flow models to study the spread of a disease and determine the best methods of prevention.</p>
    <p>As you seek to be more mindful of accumulations and more conscious about the factors that contribute to the increase or decrease of important accumulations, an understanding of stock-flow maps could be useful. You can learn more about <a href='/courses/03-stock-flow'>stock-flow maps</a> in the Tools section of TTS.</p>
    <p>Returning one more time to the bathtub example, the stock-flow map would look like the illustration above: The main accumulation of water in the bath is represented by the rectangle in the center. The blue arrows and circles show the inflow (faucet) and outflow (drain). In this case, they are labeled as filling and draining. A flow represents the rate at which something is changing. Finally, in this map you see one additional element. The need for a bath is shown as a converter. It is affecting the rate at which the tub is filling. The more urgent your need for a bath, the more open you make the faucet enabling the tub to fill more quickly. This is a basic stock-flow map. As the system you seek to represent becomes more complex, the stock-flow map can grow to include additional stocks, converters and eventually feedback loops to more accurately represent the dynamics of the system.</p>
    <h3>Classroom Usage</h3>
    <img
      className='lesson-img'
      src='/images/courses/habits/13-accumulations/lesson-six-img2.jpg'
      alt='Stock-flow Chart'
    />
    <p>What is an accumulation you desire to increase or decrease? How can careful consideration of that element and the factors affecting the inflow and the outflow help you achieve the desired change in your stock? The next exercise will help you practice this systems thinking Habit. In systems thinking classrooms, elementary age students use stock-flow maps to understand changing elements in systems of interest. For example, students use a stock-flow map to reflect on the health of their classroom garden and quickly conclude that the rabbits, living in the nearby desert, were decreasing their harvest. As a result, they added some fencing to prevent the future outflow from their crop. Other factors that influence changes to each flow are noted in the diagram.</p>
    <p>Systems can be complex, but being able to map out or draw a system of interest helps a systems thinker see things more clearly. A single mental model likely fails to tell the whole story. Working together with others to visually represent a system, such as using a tool like a stock-flow map, will make it even more likely to help a systems thinker gain new insight into a system of interest.</p>
  </div>
