import * as React from 'react'
import PropTypes from 'prop-types'

export const LessonHeader = ({ course, lesson }) =>
  <div className='lesson-header'>
    <div className='header-content'>
      <h1>{(course && course.name) || 'No course title!'}</h1>
      <h2>{(lesson && lesson.name) || 'No Lesson Title!'}</h2>
    </div>
    <img
      id='header-img'
      src={(course && course.image) || 'No image!'}
      alt='Habit Cards'
    />
  </div>
LessonHeader.propTypes = {
  course: PropTypes.object,
  lesson: PropTypes.object
}
