import * as React from 'react'
import { Field } from 'redux-form'
import { FileUpload } from 'components/shared/file-upload'
import { ToggleImage } from 'components/shared/toggle-image'

export const Exercise01 = () =>
  <div className='content'>

    <h3>Practice the Archetype</h3>

    <p>
      <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/escalation-blank-so.pdf' target='_blank' rel='noopener noreferrer'>Download a blank Escalation archetype template.</a>
    </p>

    <p>
      Select one or more of the examples presented in Lesson 2: Case in Point.
    </p>

    <p>
      Practice the Escalation archetype by filling in each of the boxes in the archetype and then use the archetype template to retell the story.
    </p>

    <Field
      name='escalation-template'
      component={FileUpload} image
      instructions='Once you have filled in the template, take a photo and upload it below.'
    />

    <p>
      There is always more than one way to draw an archetype. However, in order to provide you some feedback, one version of each of the scenarios is available to you. After creating your version of the archetype diagram, click on the appropriate link below to see one potential version of the story.
    </p>

    <div className='archetype-examples'>
      <div>
        <ToggleImage
          src='/images/courses/archetypes/04-escalation/sibling-rivalry.svg'
          className='med-img'
          alt='Sibling Rivalry'
          showText='Sibling Rivalry'
          hideText='Hide Example'
        />
      </div>

      <div>
        <ToggleImage
          src='/images/courses/archetypes/04-escalation/auction.svg'
          className='med-img'
          alt='Auction'
          showText='The Auction'
          hideText='Hide Example'
        />
      </div>

      <div>
        <ToggleImage
          src='/images/courses/archetypes/04-escalation/arms-race.svg'
          className='med-img'
          alt='Arms Race'
          showText='Arms Race'
          hideText='Hide Example'
        />
      </div>
    </div>

  </div>
