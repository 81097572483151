import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <h3>
      Tell the Story:
    </h3>

    <p>
      When talking through a Repression and Revolution archetype, start off by thinking about the first word in the title of the archetype. If anyone or any group in your system is feeling repressed, or is going to feel repressed by an action that will be taken, that should be a red flag that you need to re-examine your system.  Perhaps you’ll find there are <a href='/courses/08-structure'>structures or policies in your system that are generating outcomes</a> that staff find oppressive. Perhaps it’s not a structure or policy but the personal perspective of someone in a position of authority that could provide <a href='/courses/14-leverage'>leverage</a> for change in the system. If that person can <a href='/courses/04-perspectives'>change their perspective</a> in such a way that they no longer feel threatened in the first place, that would keep them from implementing what could be perceived as a repressive policy.
    </p>

    <div className='habits-row'>

      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/structure.png'
        alt='System Structure Generates Behavior'
      />

      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/leverage.png'
        alt='Identifies leverage options'
      />

      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/perspectives.png'
        alt='Changes Perspectivves to Increase Understanding'
      />
    </div>

    <h3>
      Questions to Help You Reflect on Your Archetypical Story:
    </h3>

    <ul className='colorpoints left'>
      <li>
        <em>Are policies being implemented that are seen as unjust or oppressive by one group or another?</em>
      </li>

      <li>
        <em>Is that group taking action against those policies?</em>
      </li>

      <li>
        <em>As the group takes action, are additional oppressive policies being implemented, leading to legitimacy of the oppressed group?</em>
      </li>
    </ul>

    <p className='clear'>
      Of course, looking for leverage to avoid repressive action assumes that you want to rewrite this story in order to avoid revolution. Revolution brings about drastic change, which can be desirable in a system at times. But even if you’re looking for drastic change, it would be wise to consider what some of the <a href='/courses/11-consequences'>short-term, long-term and unintended consequences</a> would be before you choose to implement any strategies to bring about change in your system.
    </p>

    <img
      className='lesson-img small-img'
      src='/images/shared/habit-cards/shadow/consequences.png'
      alt='Considers Short-Term, Long-Term, and Unintended Consequences of actions'
    />
  </div>
