import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/07-assumptions/lesson-two-img.png'
      alt='Testing Assumptions'
    />
    <p>The importance of listening also contributes to the power of this Habit of a Systems Thinker.</p>
    <p>When we listen only to our own inner voice, we become quickly wed to our own ideas and may not pause to consider additional information. Surfacing and testing assumptions goes two ways.</p>
    <p>This Habit is practiced not only by speaking and sharing our ideas with others, but also by actively pursuing the thoughts, ideas and perspectives of others.</p>
    <div className='quote'>
      <p className='quote-text'>“It is the province of knowledge to speak. And it is the privilege of wisdom to listen.”</p>
      <p className='quote-author'> — Oliver Wendell Holmes, Supreme Court Justice</p>
    </div>
  </div>
