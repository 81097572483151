import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='article-container'>

      <img src='images/resources/honoring-mary-scheetz/mary-scheetz.png' className='lesson-img med-img' />

      <p>When Mary Scheetz was a middle school principal in the 1980s, she sought to do what any good principal does: discover ways to make her school the best it could possibly be for students, staff and families. She knew this was her goal. What she didn’t know was the magnitude of what was to come and the incredible impact systems thinking would make throughout her journey as a leader.</p>
      <p>One of the wonderful things about systems thinking is that anyone can become a systems thinker with intentional practice. And some people, like Mary, have a natural tendency to think systemically.</p>
      <p>“My father was an engineer and for as long as I can remember I’ve been interested in patterns and trends, and understanding why and how things happen the way they do,” Mary says. Ask any former colleague of Mary’s and they will also tell you she is an acute and perceptive problem-solver.</p>
      <p>It’s no surprise, then, that as principal of Orange Grove Middle School in Tucson, she viewed her school as a system — a collaborative community where departments, areas of study, grade-levels, teachers, and classrooms worked together as interconnected parts of a larger whole.</p>
      <p>So, when Gordon Brown, retired Dean of Engineering at MIT, showed up at Orange Grove to talk to her about systems thinking, she was immediately intrigued.</p>
      <p>“I was so pleased that Dr. Brown, with his background and incredible credentials, had an interest in piloting systems thinking training for our teachers. It was very serendipitous because it spoke to my desire, along with the desire of my teachers, to bring our curriculum alive and build student engagement. Because that’s what it’s really all about.”</p>
      <p>A true systems thinker, Mary knew that the option to learn systems thinking should not be limited to teachers from only a select subject area, which at the time, was science.</p>
      <p>“I said, ‘If we are going to do this, it has to be open to anyone that wishes to participate.’”</p>
      <p>Mary helped open the door to systems thinking at her school for teachers from every discipline, including social studies and language arts. Today, over 30 years later, systems thinking continues to play a role in these subject areas at Orange Grove and in classrooms district-wide.</p>
      <p>Once Mary and others saw that students were significantly more engaged when learning through a systems thinking lens and relationships were built between departments that were once siloed, she knew something special was happening.</p>
      <p>“I went from saying we need to learn systems thinking to we need to be systems thinkers. It was a huge culture shift. We had to walk the talk.”</p>
      <p>And walk the talk they did. So much so that Mary was offered an opportunity to focus on systems thinking full time. She teamed up with Jim and Faith Waters; Jim, a successful entrepreneur, associate of Gordon Brown’s, scientist and founder of the Waters Corporation; and Faith, a retired school teacher and Jim’s wife, to take systems thinking in schools to the next level. The Waters Foundation, Systems Thinking in Schools project was born in 1989 with Mary as the leader.</p>
      <p>“The work really took on a life of its own. We didn’t claim to be systems thinking experts. Instead, we took what valuable information we had learned from our systems thinking work and combined it with what we had learned from others in the field.”</p>
      <p>Mary, Jim, and Faith knew that what makes systems thinking so remarkable is that, in Mary’s words, it “applies to all and helps all.”</p>
      <p>With that knowledge, the Waters Foundation, Systems Thinking in Schools project took systems thinking to rural schools, urban schools, large schools, small schools, private schools, public schools … schools in a variety of settings that were willing to put trust in this concept. These adopters of systems thinking saw the potential for improving student engagement and performance while making their school the best it could be for students and their families.</p>
      <p>“The work was ramping up and we were documenting everything we did and learned. From there, we would determine best practices and then customize our approach depending on the desired outcomes of the group we were working with.” Mary, Jim and Faith were using successive approximation, a cornerstone of systems thinking, in their approach at the Waters Foundation.</p>
      <p>Mary continued as Director of the Waters Foundation, Systems Thinking in Schools for 24 years. During this time, she gained priceless experience and knowledge while delivering benefits to so many worldwide.</p>
      <p>“When we were first introduced to systems thinking, the notion was that only older students would be able to benefit from it. But now we know that preschoolers can find immense value in applying systems thinking Habits and tools to their learning. To see the work expand to all grade-levels, higher-ed, and organizations of all sizes has been remarkable.”</p>
      <p>As a pioneer in the systems thinking world, Mary’s journey was not without some bumps along the way.</p>
      <p>“There is a tendency for people to want simple, linear solutions to dynamic, complex challenges. There were times it was difficult to get people to want to go deeper – to really examine and analyze their system and to get out of their comfort zones to find new solutions. When they did it was so rewarding to see the positive changes that were made.”</p>
      <p>Throughout the 31 years that Mary has been a part of the Waters Foundation (now the Waters Center), she has been a champion for all who want to build their systems thinking capacity to make their system, and the world, a better place.</p>
      <p>When asked what some of her fondest memories from working with the Waters Center are, it’s clear that Mary has a passion for empowering young people. And it is this passion that she will be remembered for as she steps back from working with the Waters Center to enjoy her retirement, her new home in Silverton, Oregon, her family (including grandchildren), and friends.</p>
      <p>“One of my fondest memories is from when I was working in Milwaukee Public Schools. I spent a lot of time in one particular fourth grade classroom. A student from the class was asked to give a presentation on using systems thinking in her learning to the Board of Education. I was so impressed with her, and asked, ‘What is it that you love about systems thinking?’ She looked at me with such confidence and said, ‘Well … it helps you take really hard things and make them easier to figure out.’”</p>
      <p>“I was so proud – she summed up the benefits of systems thinking so perfectly. I then thanked her for her presentation to the board and she said, ‘No problem. If you need me for anything else, just let me know.’ It made me chuckle and warmed my heart all at once.”</p>
      <p>While this memory describes the experience of one student, the notion of making the “hard things easier to figure out” has impacted thousands of students over Mary’s career.</p>
      <p>“While I was in St. Louis, one of our colleagues from the Netherlands asked to come over to do some systems thinking workshops with students. We spent a day at a school and that afternoon a very talented teacher came over to me and said, ‘Today I saw my students do things I did not know they were capable of doing.’”</p>
      <p>As Mary recounts these memories, her voice cracks and she begins to well up. She is a lifelong educator whose commitment to the success of young people is central to her core. She believes in them and their ability to achieve and succeed in school and in life. Her legacy and foundational impact will continue to inspire, influence and deliver benefits to the work of the Waters Center for Systems Thinking. Thank you, Mary, for pioneering this work and for having a vision for what we could dream is possible!</p>

    </div>
  </div>
