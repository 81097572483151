import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>

    <p>
      When you recognize that your system may be experiencing a limits to success dynamic, there are ways out of the archetype. When you notice a shift in dominance from a reinforcing loop to a balancing loop, remember that nothing grows forever! There is a naturally occurring resistance. Constraints cause the limiting actions and can be caused by limits within the immediate system or by other elements that are interdependent with the system.
    </p>

    <p>
      Ways out of this dynamic include anticipating constraints and increasing understanding of the impact of limiting actions that come about.
    </p>

    <h3>
      Common questions to ask:
    </h3>

    <p>
      Are successful programs plateauing or diminishing? Are there constraints creating limits to growth or success? How is the system responding to the decrease in growth or success?
    </p>

    <p>
      The Limits to Success archetype is a valuable tool when trying to determine future problems.  Think back to the introduction of archetypes; they can help us analyze a situation and identify potential storylines.  Knowing the story of the limits to success will help to identify a balancing process before it begins to decrease success.
    </p>

    <h3>
      Prevention:
    </h3>

    <p>
      This archetype is particularly useful when it is used prior to applying an “effort to increase success.” When planning a change for improvement, achievement or growth, one helpful Habit is: <a href='/courses/15-successive-approximation'>Checks results and changes actions if needed – successive approximation</a>.
    </p>

    <img
      className='lesson-img small-img right'
      src='/images/shared/habit-cards/shadow/successive-approx.png'
      alt='Successive Approximation'
    />

    <p>
      When setting a goal, establish benchmarks to assess improvement. Scheduling times to pause and assess the plan will allow a detailed look at your reinforcing loop and determine if there are any potential limits to success. Ask – “What kinds of pressure do we expect to build up as a result of growth or ongoing success?”
    </p>

  </div>
