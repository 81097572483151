import * as React from 'react'
import Helmet from 'react-helmet'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

export const LiveOnlineEvents = () =>
  <div>
    <Header />
    <div className='flow-container open-studio'>
      <Helmet>
        <title>Live Online Events</title>
        <meta name='description' content='Online systems thinking webinars, workshops, and forums focused on an area of interest.' />
        <meta property='og:title' content='Live Online Events' />
        <meta property='og:description' content='' />
        <meta property='og:image' content='' />
        <meta property='og:url' content='' />
        <meta name='twitter:image' content='' />
        <meta name='twitter:image:alt' content='' />
      </Helmet>
      <section className='photo-header live-events'>
        <h1>Live Online Events</h1>

        {/* <img src='images/site/forum-icon-white.svg' /> */}

        <h2>
          Connecting Systems Thinkers Around the Globe
        </h2>

      </section>

      <section className='white stacked event-overview-container'>
        <h3>
          Please note: Event registration has moved to the Waters Center website. The links below will take you to the new event pages.
        </h3>
        <div className='webinars'>
          <h1>Webinars</h1>

          <img src='images/site/open-studio-icon.svg' />

          <p>
            Free, hour-long, interactive Zoom webinars led by Waters Center staff and special guests. Two webinar options are offered: Open Studio webinar, which focuses on topics of interest suitable for learners from all types of systems, and Teacher Studio webinar, which features topics of interest relevant to educators.
          </p>

          <div className='button-container'>
            <a href='https://waterscenterst.org/webinars'>
              <button>
                View Webinars
              </button>
            </a>
          </div>
        </div>

        <div className='online-workshops'>
          <h1>Online Workshops</h1>

          <img src='images/site/ow-icon.svg' />

          <p>
            Online Workshops include multiple hours of collaborative learning facilitated by Waters Center staff. Each participant will be mailed a set of Habits of a Systems Thinker cards. Workshop options include Introduction to Systems Thinking and Leading for Impactful Change. Additional workshop options will be added based on demand.
          </p>

          <div className='button-container'>
            <a href='https://waterscenterst.org/workshops'>
              <button>
                View Online Workshops
              </button>
            </a>
          </div>
        </div>

        <div className='virtual-forums'>
          <h1>Virtual Forums</h1>

          <img src='images/site/forum-icon-white.svg' />

          <p>
            Virtual Forums are one-day, online events – think of them as an in-person conference, reimagined in a digital format! Forums focus on a specific area of interest and bring together guest speakers, experts in the field, and stakeholders to ensure a whole system approach is maintained.
          </p>

          <div className='button-container'>
            <a href='https://waterscenterst.org/forums'>
              <button>
                View Virtual Forums
              </button>
            </a>
          </div>
        </div>

        <div className='wcfst-cert'>
          <h1>Systems Thinking Advanced Facilitator&nbsp;Credential</h1>

          <img src='images/site/credential-icon.svg' />

          <p>
            Systems thinkers have the ability and responsibility to effectively communicate and facilitate systems thinking learning with others in order to leverage opportunities for effective systemic change. This facilitator credential is designed for experienced systems thinkers who are interested in advancing their skills and capacities to apply and integrate systems thinking into teaching, facilitating and coaching practices.  Each participant will be a member of a learning team, engaged in synchronous and asynchronous learning sessions and assigned a Waters Center coach to support and guide performance-based skill development. The Systems Thinking Advanced Facilitator credential denotes a highly-skilled systems thinking practitioner prepared and committed to sharing the benefits and uses of systems thinking with others in a professional environment.
          </p>

          <div className='button-container'>
            <a href='https://wcstcredential.org' target='_blank' rel='noopener noreferrer'>
              <button>
                Learn More & Apply
              </button>
            </a>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
