import * as React from 'react'
import { Field } from 'redux-form'
import { ToggleText } from 'components/shared/toggle-text'

export const Exercise04 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <p>
      Make improvements to each variable listed below by writing in the box. If you need help or would like suggested improvement, click on the hint button.
    </p>
    <h4>
      Improved performance
    </h4>
    <Field
      name='performance'
      component='textarea'
      className='reflection-input'
      placeholder='Enter your improved variable here.'
    />
    <ToggleText
      showText='Show Hint'
      hideText='Hide Hint'
      text={[{ id: 1, text: 'Level of performance' }]}
    />
    <h4>
      Growing debt
    </h4>
    <Field
      name='debt'
      component='textarea'
      className='reflection-input'
      placeholder='Enter your improved variable here.'
    />
    <ToggleText
      showText='Show Hint'
      hideText='Hide Hint'
      text={[{ id: 1, text: 'Amount of debt' }]}
    />
    <h4>
      Preferred service
    </h4>
    <Field
      name='service'
      component='textarea'
      className='reflection-input'
      placeholder='Enter your improved variable here.'
    />
    <ToggleText
      showText='Show Hint'
      hideText='Hide Hint'
      text={[{ id: 1, text: 'Quality of service' }]}
    />
    <h4>
      Heightened awareness
    </h4>
    <Field
      name='awareness'
      component='textarea'
      className='reflection-input'
      placeholder='Enter your improved variable here.'
    />
    <ToggleText
      showText='Show Hint'
      hideText='Hide Hint'
      text={[{ id: 1, text: 'Level of awareness' }]}
    />
  </div>
