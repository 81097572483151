import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-double'
        src='/images/resources/shifting-the-burden/shifting-the-burden-summary-so.svg'
        alt='Sifting the Burden Summary'
      />

      <img
        className='template-double'
        src='/images/resources/shifting-the-burden/shifting-the-burden-template-so.svg'
        alt='Shifting the Burden Template'
      />
    </div>
  </div>
