import * as React from 'react'
import { ToggleText } from 'components/shared/toggle-text'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <p>The ladder of inference provides a reflective opportunity to slow down our thinking and develop an awareness of how mental models influence actions.</p>
    <p>This is a still photo of an important meeting where the presenter is sharing the specifics of new changes that will impact all staff members.  Take a few moments to examine the picture.  Imagine you have just walked into the meeting.  Respond to the ladder questions below.</p>
    <div className='review-img'>
      <img
        className='lesson-img left'
        src='/images/courses/tools/02-ladder-of-inference/exercise-one-img.jpg'
        alt='Business Meeting'
      />
    </div>
    <h4>Walk yourself up the ladder and beginning at #1. Remember to explain your thinking.</h4>
    <img
      className='lesson-img'
      src='/images/courses/tools/02-ladder-of-inference/ladder-with-numbers.png'
      alt='Ladder of Inference'
    />
    <div className='ladder'>
      <div className='ladder-questions'>
        <h4>1. What details do you notice?</h4>
        <Field
          name='details'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />
        <ToggleText
          showText='Show Example Response'
          hideText='Hide Example Response'
          text={[{ id: 1, text: '“I notice a woman looking at her phone.”' }, { id: 2, text: '“I notice a man standing at a white board.”' }, { id: 3, text: '“Two people are looking at a piece of paper.”' }, { id: 4, text: '“There is a woman with black hair looking toward the man at the white board.”' }, { id: 5, text: '“I see four people sitting at a table and one person standing at the front of the table.”' }]}
        />
        <h4>2. What meaning could you add to what you are noticing?</h4>
        <Field
          name='meaning'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />
        <ToggleText
          showText='Show Example Response'
          hideText='Hide Example Response'
          text={[{ id: 1, text: '“The woman might be texting or emailing during the meeting.”' }, { id: 2, text: '“This might be a meeting.”' }, { id: 3, text: '“The man might be in charge of the meeting.”' }, { id: 4, text: '“The two people looking at the paper are sharing something.”' }, { id: 5, text: '“The woman with black hair is paying attention.”' }]}
        />
        <h4>3. What beliefs or assumptions might you develop based on the meaning you added to what you see?</h4>
        <Field
          name='develop'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />
        <ToggleText
          showText='Show Example Response'
          hideText='Hide Example Response'
          text={[{ id: 1, text: '“She doesn’t find what the presenter is sharing to be important information.”' }, { id: 2, text: '“The man standing is explaining something or answering a question.”' }, { id: 3, text: '“The man standing is ignoring the woman on the phone.”' }, { id: 4, text: '“The woman on the phone doesn’t find what the man is sharing to be important information.”' }, { id: 5, text: '“The meeting is not that important if three people are not engaged.”' }]}
        />
        <h4>4. What possible actions might occur based on what you notice in the photo?</h4>
        <Field
          name='photo'
          component='textarea'
          className='reflection-input-large'
          placeholder='Enter your response here.'
        />
        <ToggleText
          showText='Show Example Response'
          hideText='Hide Example Response'
          text={[{ id: 1, text: '“Politely ask the woman with the phone to put it away because it is distracting.”' }, { id: 2, text: '“Ask the two people sharing information to please wait until after the meeting for a side conversation.”' }, { id: 3, text: '“Focus on the man presenting as best as possible given the distractions.”' }, { id: 4, text: '“Ask a question that is important to everyone at the meeting to get all participants engaged.”' }]}
        />
      </div>
    </div>
  </div>
