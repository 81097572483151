import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <img
      className='lesson-img small-img'
      src='/images/resources/ladybug-and-aphid/ladybug-loop.svg'
      alt='Ladybug Loop'
    />

    <h3>
      Materials:
    </h3>

    <ul>
      <li>
        20 dice per team
      </li>

      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/ladybugs-aphids-game-board.pdf' target='_blank' rel='noopener noreferrer'>Ladybugs and Aphids Mat (duplicate on 11x17 paper)</a>
      </li>

      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/ladybugs-aphids-data-chart.pdf' target='_blank' rel='noopener noreferrer'>Data Chart</a>
      </li>

      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/ladybugs-aphids-botg.pdf' target='_blank' rel='noopener noreferrer'>BOTG Sheet</a>
      </li>

      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/ladybugs-aphids-student-instructions.pdf' target='_blank' rel='noopener noreferrer'>Student Instructions</a>
      </li>
    </ul>

    <h3>
      Lesson Structure
    </h3>

    <h4>
      Instruction:
    </h4>

    <ol>
      <li>
        Most children will be familiar with ladybugs, but may not be familiar with aphids. Ask if anyone’s family grows roses at home and has noticed these small,
        green insects on the buds. Show a photo of aphids. Explain that aphids can destroy rosebuds and that some gardeners use ladybugs to control them because
        ladybugs eat aphids. Ask the children to identify which of these two insects is the predator and which is the prey.
      </li>

      <li>
        Describe and model the game that children will play to model what happens in a predator-prey relationship. Use the student instruction sheet when modeling so
        students can match the procedures to the instructions. After modeling, ask the students to predict what might happen in the population of ladybugs and aphids
        from one round to the next.
      </li>
    </ol>

    <h4>
      Play:
    </h4>

    <ol>
      <li>
        Depending on how many dice you have available, divide the students into teams to play and record the numbers of ladybugs and aphids at the end of each round.
      </li>

      <li>
        Each group should play for 10 rounds, or until there are no more surviving ladybugs or aphids.
      </li>
    </ol>

    <h4>
      The BOTG:
    </h4>

    <ol>
      <li>
        Using the data recorded during the game, students should draw a line to represent the ladybug population after each round in one color, and the aphid population after
        each round in a different color. Both lines should be drawn on the same BOTG.
      </li>

      <li>
        Ask the students to share what they notice about each line and about how the lines relate. In other words, when the ladybug population increases, what happens to the population
        of aphids? When the population of aphids declines, what happens to the ladybugs? At this point, if the students keep notebooks, they might be asked to write about their observations.
      </li>
    </ol>

    <h4>
      The Causal Loop:
    </h4>

    <ol>
      <li>
        Work together with the students to create a causal loop showing how increases and declines in one insect population lead to increases and declines in the other. You might use this
        language and ask students to show with their thumbs what is happening as they talk their way around the circle:
        <em>If there are more ladybugs, will there be more or fewer aphids? Fewer) If there are fewer aphids, will there be more or fewer ladybugs? (Fewer)</em>
        As you move around the loop, mark each link as S (same) or O (opposite) to show the direction of the change. Your loop will look like this:
      </li>
    </ol>

    <p>
      Explain to the children that this represents a balancing loop, if they do not already recognize it as such. Ask them to look again at their BOTGs and see if they can identify a range
      of ladybug and aphid populations when they did not see wild swings in the numbers of insects. This range could be numbers at which the populations would stay in balance.  Share with
      the students that any predator-prey relationship works this way and invite them to consider other similar relationships.
    </p>

    <p>
      <strong>Lesson’s Author:</strong> Caryl Crowell
    </p>
  </div>
