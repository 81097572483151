import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-double'
        src='/images/resources/repression-and-revolution/repression-and-revolution-summary-so.svg'
        alt='Repression and Revolution Summary'
      />

      <img
        className='template-double'
        src='/images/resources/repression-and-revolution/repression-and-revolution-template-so.svg'
        alt='Repression and Revolution Template'
      />
    </div>
  </div>
