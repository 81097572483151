import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/resources/water-hole/water-hole.png'
      alt='Water Hole'
    />
    <ol>
      <li>First, engage students in a shared reading of the text, taking ample time to enjoy the illustrations. Before and during the reading, ask students to think about “what is changing?” Students readily see that the number of animals is increasing and the water in the water hole is decreasing.</li>
      <li>Next, create two separate BOTGs. Create the first graph to show the number of animals. Use the sequence of animals in the story to represent time on the X-axis with number of animals on the Y-axis. Depending on the age of the students and the lesson or unit objective, numbers can count one to ten, as seen in the book, or some students may enjoy the challenge of calculating the cumulative number of animals (1 + 2 + 3 + 4 and so on). The second you create will represent the water in the water hole; it may look something like the photo shown.
        <img
          id='water-graph'
          src='/images/resources/water-hole/water-graph.png'
          alt='Water Graph'
        />
      </li>
      <li>Allow the students to retell the story using the graphs and the text.  Use the language and develop the concept of change over time.</li>
      <li>The water in "The Water Hole" works as a metaphor for the stock in a stock-flow map. Consider creating a physical apparatus that allows students to add water and drain it out as they retell the story of The Water Hole.  The apparatus can be simple, like an iced tea jar with a spout. You can also use a complete water apparatus set up.  For directions, see the <a href='/resources/water-activity/water-apparatus-assembly' target='blank' rel='noopener'>Water Activity</a> resource. Pictured here is an apparatus made out of a one-liter soda bottle with a hose nozzle attached to the neck of the bottle. The goal is to give students a concrete experience that allows them to re-enact what is happening in the book.</li>
      <img
        className='lesson-img'
        src='/images/resources/water-hole/kids.png'
        alt='Kids'
      />
      <li>Encourage students to retell the story as they add to and take away from the water in the container.</li>
      <li>Once students are comfortable with the language of stock and flow, introduce the stock-flow map visual tool.  For more information about stock-flow maps, see the <a href='/courses/03-stock-flow'>Stock-Flow course</a>. You can recreate the stock-flow map visual tool by drawing the image shown.</li>
      <img
        className='flow-template'
        src='/images/courses/tools/03-stock-flow/flow-template.svg'
        alt='Flow Template'
      />
      <li>For clarity, it is easiest to start with water in "The Water Hole" as the stock. Label the inflow and outflow. The converter, or cause of increase, is rain, and the cause of decrease is drinking.</li>
      <li>A final beauty about the book <em>The Water Hole</em> is that it clearly illustrates the interdependence between animals drinking and the amount of water in the water hole.  This interdependence can be represented by a stock-flow map with two stocks.
        <img
          className='lesson-img left'
          src='/images/resources/water-hole/water-hole-example.jpg'
          alt='Water Hole Example'
        />
      </li>
    </ol>
    <div className='practitioner-tip'>
      <p>Practitioner Tip: This lesson can be as simple or as complex as is appropriate for the learners with which you are working. You might just read the book and talk with students about what is changing. You might choose to read the story and use it as an initial introduction to behavior-over-time graphs. In some instances, students might be ready to model the story using a water apparatus, or you may have students draw a stock-flow map.</p>
    </div>
  </div>
