import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-double'
        src='/images/resources/limits-to-success/limits-to-growth-summary-so.svg'
        alt='Limits to Success Summary'
      />

      <img
        className='template-double'
        src='/images/resources/limits-to-success/limits-to-growth-template-so.svg'
        alt='Limits to Success Template'
      />
    </div>
  </div>
