import * as React from 'react'
import { Field } from 'redux-form'

const Systems = [
  'well-being',
  'workplace',
  'school',
  'community',
  'family'
]

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>My practice system is:</h4>
    <div className='system-select'>
      {Systems.map(s =>
        <div key={s} className={`system-select-item ${s}`}>
          <Field name='systemSelect' id={`ss-${s}`} component='input' type='radio' value={s} />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      )}
    </div>
    <p>Consider the boundaries of your current position in this sample system.</p>
    <h4>What are some boundaries that are fixed and resistant to your influence?</h4>
    <Field
      name='consider-boundaries'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Where can you influence the boundaries of your
      system and grow your influence to create a bigger picture of what is possible?
    </h4>
    <Field
      name='bigger-picture' component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
