import * as React from 'react'
import PropTypes from 'prop-types'

export const ContentList = ({ course }) =>
  <div className='course-content'>
    <h2>Course Outline</h2>
    <ul>
      {course.lessons.map(l =>
        <li key={l.path}><a href={'courses/' + course.path + '/' + l.path}>{l.name}</a></li>)}
    </ul>
  </div>
ContentList.propTypes = {
  course: PropTypes.object
}
