import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/07-assumptions/ladder-of-inference.png'
      alt='Ladder of Inference'
    />
    <h3>Ladder of Inference</h3>
    <p>The Ladder of Inference, developed by Chris Argyris, Harvard professor of Education and Organizational Behavior, is a tool that can be helpful in practicing the Habit of surfaces and tests assumptions. The theory behind the ladder is that we all have experiences that lead us to pay attention to certain things.</p>
    <p>In turn, we add meaning to what we notice based on our personal and cultural background. It is from this filtered input that we develop our beliefs.</p>
    <p>The real power of this tool is in recognizing that our beliefs lead to our actions and what we choose to notice in the future. Unexamined, highly-filtered beliefs can lead to ill-informed actions.</p>
    <p>To learn more about the <a href='/courses/02-ladder-of-inference'>Ladder of Inference</a>, consider this course in the Tools section of TTS.</p>
  </div>
