import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <a href='/courses/08-structure'>
      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/structure.png'
        alt='System Structure determines behavior'
      />
    </a>

    <p>
      How do you change the plot when you recognize a Success to the Successful archetype dynamic is occurring?  The most obvious way to rewrite the plot is to use a system’s structure to level the playing field.  The success, or the lack of success, of those in the archetype may depend on the structure in which they are placed.
    </p>

    <p>
      To determine the underlying structure of the archetypical situation, ask, <em>What is the overarching goal?</em> When revisiting your goals, ask, <em>Does it include success for all? Has the goal been compromised?</em>
    </p>

    <p>
      Examine the structure in place that promotes the success of one over the success of another.  Ask, <em>How do the parts of the structure affect one another? Has the interaction of the parts created the behavior shown in the archetype?</em>
    </p>

    <p>
      If you believe that you may have a reinforcing loop rewarding a person, at the expense of another, encourage collaboration and make efforts to seek equity. Eliminate the tendency to use the terms “winners” or “losers.”  Bring all parties into one reinforcing loop that tells a story of fair allocation of resources and success to all.
    </p>

    <h3>
      Rewriting the Story in Advance: Preventing Success to the Successful
    </h3>

    <a href='courses/05-mental-models'>
      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/mental-models.png'
        alt='Considers How Mental Models Affect Current Reality and the Future'
      />
    </a>

    <p>
      When resources are limited, groups who do similar work tend to divide themselves into competing groups. Consider using the Success to the Successful archetype to minimize competition and ensure that resource allocations are fair and mutually beneficial.  Creating a “win-win” situation that encourages cooperation, rather than competition, is important to an environment where all individuals can succeed.
    </p>

  </div>
