import * as React from 'react'
import Helmet from 'react-helmet'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

export const Contact = () =>
  <div>
    <Header />
    <div className='flow-container'>
      <Helmet>
        <title>Contact - Thinking Tools Studio</title>
        <meta name='description' content='Contact the Thinking Tools Studio' />
      </Helmet>
      <section className='photo-header contact'>
        <h1>
          Contact Us
        </h1>

        <img src='images/site/contact-icon.svg' />

        <h2>
          How can we help?
        </h2>
      </section>
      <section className='white stacked'>
        <h4 className='blue'>
          General Inquiries
        </h4>

        <p>
          Do you have a question about the Thinking Tools Studio or the work of the Waters Center?
        </p>

        <p>
          For general inquires, email <a href='mailto:info@waterscenterst.org'>info@waterscenterst.org</a>
        </p>

        <p>
          For more on the Waters Center and our services, <a href='https://waterscenterst.org/why-systems-thinking?tab=impact' target='blank' rel='noopener'>click here</a>.
        </p>
      </section>

      <section className='grey stacked'>
        <h4 className='orange'>
          Additional Resources
        </h4>

        <p>
          Our Habits of a Systems Thinker™ cards and <em>The Habit-forming Guide to Becoming a Systems Thinker</em> book are great
          resources to supplement and continue your Studio learning – both as an individual or team.
        </p>

        <p>
          Both items are available via <a href='https://waters-center-for-systems-thinking.myshopify.com' target='blank' rel='noopener'>our Online Shop</a>.
        </p>
      </section>

      <section className='white centered'>
        <h4 className='blue'>
          Donations
        </h4>

        <p>
          The Thinking Tools Studio is made possible because of donors like you. For more information, <a href='/donate'>click here</a>.
        </p>

      </section>
    </div>
    <Footer />
  </div>
