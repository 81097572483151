import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <div className='wide-img'>
      <img
        src='/images/courses/habits/03-big-picture/lesson-two-img.jpg'
        alt='School Example'
      />
    </div>
    <div className='quote'>
      <p className='quote-text'>"Begin challenging your own assumptions. Your assumptions are your
        windows on the world. Scrub them off every once in a while, or the light won’t
        come in."
      </p>
      <p className='quote-author'> – Alan Alda, actor</p>
    </div>
    <p>In addition to Alda’s recommendation, how can you increase the size of the
      window from which you view the world? Check in with others for opinions, views
      and insights. The check-ins will deepen your understanding of a bigger whole.
    </p>
    <h4>Given that an understanding of the bigger whole is
      important to systems thinkers, good questions to ponder are:
    </h4>
    <div id='extra-questions'>
      <p>What do we mean by “big picture” or “whole picture"? How does our definition
        of “big” determine our view of a system?
      </p>
      <p>How big of a picture is too big?</p>
      <p>How do I know if my view is too narrow?</p>
    </div>
    <p>These questions point to the importance of boundaries when practicing this
      Habit. It is important to pay attention to the boundaries that define your
      system of interest. Sometimes boundaries are drawn for you, as in a map, job
      description or organizational chart; other times, you can determine them
      based on your viewpoint, disposition and perspective.
    </p>
    <img
      id='circle-concern'
      src='/images/courses/habits/03-big-picture/circle-of-concern.svg'
      alt='Cirle of Concern'
    />
    <p>Your role, position or mindset determine the way you define your internal
      locus of control or your boundaries of influence.
    </p>
    <p>In the best-selling book, <em>7 Habits of Highly Effective People</em>, Stephen Covey presents a diagram
      that distinguishes between an internal locus of control or circle of influence,
      versus an external locus of control or circle of concern. This is another way to
      examine your own boundaries of influence. Covey posits that by maintaining your
      time and energy to aspects of your work or life where you have influence, your
      internal circle will expand, creating a large, more expansive system of
      influence.
    </p>
    <img
      className='system-icon'
      src='/images/courses/shared/icon-workplace.svg'
      alt='Workplace Example'
    />
    <p>For example, consider the workplace boundaries of a software salesperson.
      Inside the circle of influence are efforts to develop positive client
      relationships, knowledge of software products and sales skills. Outside this
      circle are assigned sales territories, quality and number of products expected
      to sell, and company incentive policies. However, as sales performance
      increases, and trust and belief between the supervisor and sales person
      develops, the circle of influence grows, resulting in expanded assigned
      territories, increased number of products to sell, and opportunities for
      managerial roles that could impact company policy.
    </p>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-community.svg'
      alt='Workplace Example'
    />
    <p>A volunteer signs up to spend time participating in community-based
      opportunities and events. As a volunteer, you may or may not have influence on
      the organization’s strategic plan, fundraising structures or evaluation of
      community impact. Taking advantage of leadership opportunities can expand a
      volunteer’s boundary of influence and increase the ability to contribute to
      decisions that impact the system. These boundaries of influence can grow as
      leadership opportunities present themselves. When volunteers chair a committee,
      assume a role on governing boards, run for office or organize a community event,
      volunteers expand their circles of influence and their sense of the bigger picture.
    </p>
    <img
      className='system-icon'
      src='/images/courses/shared/icon-well-being.svg'
      alt='Workplace Example'
    />
    <p>Taking charge of and maintaining balance among the many demands of family,
      work and health can be challenging. The big picture Habit also draws attention to
      understanding the boundaries of multiple systems simultaneously and their
      overlapping interactions. A focus on personal need priorities like regular
      exercise, a healthy diet, plentiful sleep and regular medical check-ups will help
      balance the demands of family and work systems. Maintaining balance is a
      strategy that supports this Habit that seeks and maintains the big picture of
      work and life priorities.
    </p>
  </div>
