import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-single'
        src='/images/resources/ladder-with-graphics/ladder-with-graphics.svg'
        alt='Ladder: Template with Graphics and Questions'
      />
    </div>
  </div>
