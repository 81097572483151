import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>After reading the examples below, think about other topics that have varying claims based on evidence and add them to this list.</h4>
    <ul>
      <li>Low carbohydrate diets: Can a protein-rich, high fat and low carbohydrate “ketogenic diet” help you lose weight?  Or, does a keto diet cause you to lose muscle, resulting in extreme fatigue, making it harder to lose weight?</li>
      <li>The value of homework on student learning: Is it beneficial to have a no homework policy, or is homework important practice and vital to student learning?</li>
      <li>The value or danger of childhood vaccines: Do vaccines protect children from debilitating diseases? Or can vaccines be harmful to children and cause a number of disabling conditions?</li>
      <li>The effects of prolonged video-game play in young children: Is video game play harmful to children’s eyesight, brain function, social-emotional development? Or, can video game play stimulate critical thinking, reasoning and fine motor coordination?</li>
      <li>Others?</li>
    </ul>
    <Field
      name='claims'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h3>What do you pay attention to as you weigh each side of a controversial claim? How do your experiences influence the development of your theories and assumptions? Consider these questions to help you reflect.</h3>
    <h4>Typically, we have experience with one side of the story, issue or occurrence, so we lean toward that belief. For example,</h4>
    <ul>
      <li>I love red wine, so it must not be bad for me. I am a healthy person.</li>
      <li>When I eliminate carbs from my diet, I lose a few pounds.</li>
      <li>I hate it when my children have homework because it gets in the way of family time. My children don’t need this extra practice.</li>
      <li>I have a friend whose child had a bad reaction from a vaccine. Vaccines must not be a good idea.</li>
      <li>Video-game play keeps my child engaged when I am busy doing other things.  They love the challenge of moving from level to level.</li>
    </ul>
    <h4>How do we seek information to help us test our assumptions? Consider these examples;</h4>
    <ul>
      <li>I depend on people I trust and consider experts to influence my thinking.</li>
      <li>I read as much as I can from a variety of sources to gain knowledge about all positions and claims.</li>
    </ul>
    <h4>What do you pay attention to as you weigh each side of a controversial claim?</h4>
    <Field
      name='weigh-side'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
