import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>

    <h3>
      Chapped lips
    </h3>

    <p>
      One particularly cold December, a child’s lips become chapped.  When her lips feel dry, she licks them to make them feel better.  In the short-term, licking her lips provides some immediate relief from her dry, itchy lips.  When they feel dry again, she licks them again, which once again brings short-term, symptomatic relief.  Over time her chapped lips get worse.  They grow red and begin to crack. Licking lips was a short-term solution to relieve chapped lips that had an unintended consequence of creating not only chapped lips but cracked lips, making the initial problem much worse.
    </p>

    <h3>
      Procrastination in the workplace
    </h3>

    <p>
      You are having a difficult time completing a project at work.  To avoid the project in the short-term, you busy yourself with other less critical tasks.  You remain busy.  You are making progress on things that need to be done.  It seems that you have fixed your problem.  After a few more weeks, the original project deadline is only hours away. Now you no longer have the time, the needed information or the human capital resources to meet the deadline.  The initial problem of having some difficulty completing certain aspects of the project has now become an even bigger problem, resulting in a missed deadline.
    </p>

    <h3>
      Absenteeism
    </h3>

    <p>
      Administrators within a school system were distressed by the number of teachers who were out of the classroom on any given day.  Their response was to dramatically restrict the number of personal days granted to teachers.  Initially, teacher absenteeism decreased.  Over time the new policy created resentment, so instead of requesting personal days when they needed time away from school, teachers would simply call in sick.  Sick leave skyrocketed and the initial problem of teachers out of the classroom increased significantly.
    </p>

    <a href='/courses/11-consequences'>
      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/consequences.png'
        alt='Consequences'
      />
    </a>

    <p>
      For additional examples of the Fixes that Backfire archetype, visit the Habits Course, <a href='/courses/11-consequences'>Long-term, Short-term and Unintended Consequences</a>.
    </p>

  </div>
