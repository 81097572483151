import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Choose a topic and identify a belief that you hold that has multiple, contrasting claims. You can choose one from the list in Lesson 1 or identify one of your own.</p>
    <h4>Describe the topic and the belief you hold about the topic.</h4>
    <Field
      name='topic-belief'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What has influenced your belief and how would you respond to others with different claims?</h4>
    <Field
      name='your-belief'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
