import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>

    <p>
      Think of something you are working on that you would like to improve.  Ideas to get you started include:
    </p>

    <ul>
      <li>
        Improve your fitness level by increasing the average number of steps you take each day.
      </li>

      <li>
        Increase the amount of time you read professional literature each week.
      </li>

      <li>
        Decrease the time you spend on social media each day.
      </li>

      <li>
        Decrease the amount of time you spend looking at a monitor each day (e.g. computer, phone, television).
      </li>

      <li>
        Increase the amount of money you deposit into your savings each month.
      </li>

      <li>
        Decrease the number of unanswered emails in your inbox.
      </li>
    </ul>

    <p>
      The balancing goal and gap loop does not always have to involve numbers, but a scenario that you can easily quantify is a good first practice.
    </p>

    <p>
      Use one of the example scenarios above or choose your own and fill in the Balancing Loop below.
    </p>

    <div className='balancing-loop-template'>
      <h4 className='mobile-only'>
        Current State
      </h4>

      <Field
        name='current'
        component='textarea'
        className='reflection-input b-loop current'
        placeholder='Enter your response here.'
      />

      <h4 className='mobile-only'>
        Desired State
      </h4>

      <Field
        name='desired'
        component='textarea'
        className='reflection-input b-loop desired'
        placeholder='Enter your response here.'
      />

      <h4 className='mobile-only'>
        Gap
      </h4>

      <Field
        name='gap'
        component='textarea'
        className='reflection-input b-loop gap'
        placeholder='Enter your response here.'
      />

      <h4 className='mobile-only'>
        Strategies to intervene
      </h4>

      <Field
        name='strategies'
        component='textarea'
        className='reflection-input b-loop strategies'
        placeholder='Enter your response here.'
      />
    </div>
  </div>
