import * as React from 'react'
import { Field } from 'redux-form'

export const Lesson05 = () =>
  <div className='content'>
    <p>Lessons 1 - 4 of this course focused on single stock systems; however, most complex systems have multiple accumulations changing at once, and those accumulations are often dependent on one another.</p>
    <p>As we talk through the story of the Advocates and Resisters stock-flow map, recognize that each stock is an accumulation of people.  The number of people in each stock will increase and decrease overtime depending on the impact the converters have on the flows. Even though this map is a 2-dimensional image, imagine the people flowing in and out of each stock. Stock-flow maps are dynamic or moving representations of systems.</p>
    <h3>A Story of Advocates and Resisters</h3>
    <img
      className='awareness-stock-flow1'
      src='/images/courses/tools/03-stock-flow/awareness-stock-flow.svg'
      alt='Awareness Stock Flow'
    />
    <p>Think about a time that you were trying to get support for a new idea. Maybe it was recruiting your neighbors for a weekly game night or maybe it was trying to take your product idea to a national market. Regardless of the scale of your particular story, you likely experienced something similar to this story of “Advocates and Resisters.”  The following multiple stock, stock-flow map is adapted from Chris Soderquist of Pontifex Consulting.</p>
    <p>The story begins with a stock of “unaware individuals.” In other words, people who are unaware of your current idea. You want to create a flow that moves these individuals from being unaware to a state of awareness.</p>
    <p>Once people become aware of the idea, some individuals buy-in right away. These individuals are often referred to as early adopters.  It is likely these people will become advocates for your idea.</p>
    <img
      className='awareness-stock-flow2'
      src='/images/courses/tools/03-stock-flow/awareness-stock-flow2.svg'
      alt='Awareness Stock Flow Map Two'
    />
    <p>At the same time people are buying in, there will likely be another group that is resistant to the new idea. They are the resisters.</p>
    <img
      className='awareness-stock-flow'
      src='/images/courses/tools/03-stock-flow/awareness-stock-flow3.svg'
      alt='Awareness Stock Flow Map Three'
    />
    <p>It seems to be a reasonable assumption that the faster your stock of advocates grows, the quicker your desired change will take place.</p>
    <p>That is why you will see a connector from the advocates stock to the buying inflow. The more advocates there are, the more likely they will influence others to buy in.  On the other hand, if the advocates are very small, the influence of those small numbers could begin to close up the flow. The connector arrow shows the causal impact of amount in the stock to the flow.  The same is true for the resister side.  Resisters can have a contagious effect on others and could open up that flow as well. When small, they may have little recruitment impact, and as a result, the flow rate would be minimal.</p>
    <p>The following diagram suggests that the greater the net value of your idea, the faster people will buy-in. The faster the buy-in, the greater the number of advocates. The more advocates, the greater the net value of your idea, making communication of the net value of your idea an important element of your plan to grow the number of people buying into your idea.</p>
    <p>What might be the effect of perceived value?  The author of this map suggests that perceived value directly affects net value.  Thus, an increase in perceived value will increase net value, which increases the rate of buy-in, and ultimately, the number of advocates.</p>
    <img
      className='awareness-stock-flow'
      src='/images/courses/tools/03-stock-flow/awareness-stock-flow4.svg'
      alt='Awareness Stock Flow Map Four'
    />
    <p>What about the resisters? What would it take to move people from the resister stock to the advocate stock?</p>
    <p>This theory contends that as the advocate stock increases it will open up the conversion flow. As the advocate stock grows, their influence will increase conversion and literally pull resisters over to the advocate stock. Again, the same holds true for resister influence on the conversion flow. When resisters are many, conversion to advocacy diminishes.</p>
    <p>Certainly, there are almost always resisters to new ideas. When in this situation, think about strategies people typically take to try and convert resisters. Examples might include trying to sell them on new ideas, mandating compliance or providing incentives. This theory or model indicates that the best way to manage resisters is to focus on advocates and build the net value that collectively increases a perceived value of the new initiative or idea. That effort will open up flows from both the aware individual stock and the converting flow from the resister stock. The message here for managers is to not spend too much time or effort trying to convert resisters directly, but to leverage the influence of advocates by building their success with the new idea or initiative and build the net and perceived value to the organization.</p>
    <img
      className='awareness-stock-flow'
      src='/images/courses/tools/03-stock-flow/awareness-stock-flow5.svg'
      alt='Awareness Stock Flow Map Five'
    />
    <h3>Reflection</h3>
    <h4>How does this stock-flow map help you better understand a situation you may have experienced or are currently experiencing?</h4>
    <Field
      name='may-help'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What are some converters or variables that could impact the rate at which people moved from resisters to advocates?</h4>
    <Field
      name='converters'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Could the converting flow go the other way?  If so, what could cause individuals to move from being advocates to being resisters?</h4>
    <Field
      name='advocates'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
