import * as React from 'react'
import { Field } from 'redux-form'

const Systems = [
  {
    name: 'well-being',
    title: 'Well-Being',
    text: 'Should you invest in the time it takes to exercise and build fitness for long-term health benefits, or should you invest that time at work, securing a good job with health insurance?'
  },
  {
    name: 'workplace',
    title: 'Workplace',
    text: 'When considering the benefits and trade-offs of the cost and time of pursuing an advanced degree or professional credential, is it worth it in the long-term?'
  },
  {
    name: 'school',
    title: 'School',
    text: 'Would a quick text message resolve a misunderstanding, or is it better to make a phone call even though it takes more time?'
  },
  {
    name: 'community',
    title: 'Community',
    text: 'How much time should you invest in building relationships with your clients? It may seem like a burden in the short-run, but what long-term benefits will those quality relationships bring you in the future?'
  },
  {
    name: 'family',
    title: 'Family',
    text: 'How does the use of plastic bags versus reusable bags to package groceries impact our environment?'
  }
]

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Read over the everyday examples below. Choose one that you have experienced or write one of your own.</p>
    <div className='system-select vertical'>
      {Systems.map(s =>
        <div key={s.name} className={`system-select-item ${s.name} vertical`}>
          <Field name='systemSelect' id={`ss-${s.name}`} component='input' type='radio' value={s.name} />
          <label className='vertical' htmlFor={`ss-${s.name}`}>{s.name}</label>
          <div className='system-content'>
            <h4>{s.title}</h4>
            <p>{s.text}</p>
          </div>
        </div>
      )}
    </div>

    <h4>My own experience:</h4>
    <Field
      name='own-experience'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What were some of the short-term consequences of your actions?</h4>
    <Field
      name='short-term'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Who was impacted by your decision?</h4>
    <Field
      name='your-decision'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What were some of the long-term consequences? Did they outweigh the short-term hardships and deliver benefits, or did they fall short?</h4>
    <Field
      name='long-term'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
