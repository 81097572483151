export const cardList = [
  {
    lang: 'english',
    habitsTitle: 'Habits of a Systems Thinker',
    introText:
      'The Habits of a Systems Thinker help learners understand how systems work and how actions taken can impact results seen over time. They encompass a spectrum of strategies that foster problem-solving and encourage questioning. The Thinking Tools Studio contains a course for each of the Habits. Below you will find a brief explanation of each Habit. We encourage you to refer to this page often — it’s a helpful resource when completing lessons, exercises and more.',
    flipText:
      'Click “Flip Card” to see the reverse side of each Habit card which contains further information and important questions to consider.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/english/big-pic.png',
        back: '/images/shared/habit-cards/english/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Flip Card'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/english/change-over-time.png',
        back: '/images/shared/habit-cards/english/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Flip Card'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/english/perspectives.png',
        back: '/images/shared/habit-cards/english/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Flip Card'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/english/circular-nature.png',
        back: '/images/shared/habit-cards/english/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Flip Card'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/english/mental-models.png',
        back: '/images/shared/habit-cards/english/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Flip Card'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/english/considers-issue.png',
        back: '/images/shared/habit-cards/english/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Flip Card'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/english/leverage.png',
        back: '/images/shared/habit-cards/english/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Flip Card'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/english/structure.png',
        back: '/images/shared/habit-cards/english/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Flip Card'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/english/consequences.png',
        back: '/images/shared/habit-cards/english/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Flip Card'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/english/successive-approx.png',
        back: '/images/shared/habit-cards/english/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Flip Card'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/english/surfaces-tests.png',
        back: '/images/shared/habit-cards/english/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Flip Card'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/english/time-delays.png',
        back: '/images/shared/habit-cards/english/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Flip Card'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/english/accumulations.png',
        back: '/images/shared/habit-cards/english/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Flip Card'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/english/connections.png',
        back: '/images/shared/habit-cards/english/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Flip Card'
      }
    ]
  },
  {
    lang: 'german',
    habitsTitle: 'Gewohnheiten für Systemdenkende',
    translationCredit: 'Übersetzt von Thomas Krynicki, Carlos Silva und Miriam Naomi Spano',
    introText:
      'Die Gewohnheiten für Systemdenkende helfen dem Lernenden zu verstehen, wie Systeme funktionieren und wie sich Handlungen im Laufe der Zeit auf die Ergebnisse auswirken können. Sie umfassen eine große Auswahl an Strategien, die zur Problemlösung beitragen und zum Hinterfragen anregen. Das Thinking Tools Studio bietet einen Kurs für jede Gewohnheit an. Nachfolgend findest du eine kurze Erklärung zu jeder Gewohnheit. Wir empfehlen dir, diese Seite regelmäßig zu besuchen – sie ist eine hilfreiche Ressource beim Abschließen von Lektionen, Übungen und mehr.',
    flipText:
      'Klicke auf „Karte umdrehen“, um die Rückseite der jeweiligen Karte anzuzeigen, die weitere Informationen und anregende Fragen enthält.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/german/big-pic.png',
        back: '/images/shared/habit-cards/german/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/german/change-over-time.png',
        back: '/images/shared/habit-cards/german/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/german/perspectives.png',
        back: '/images/shared/habit-cards/german/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/german/circular-nature.png',
        back: '/images/shared/habit-cards/german/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/german/mental-models.png',
        back: '/images/shared/habit-cards/german/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/german/considers-issue.png',
        back: '/images/shared/habit-cards/german/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/german/leverage.png',
        back: '/images/shared/habit-cards/german/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/german/structure.png',
        back: '/images/shared/habit-cards/german/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/german/consequences.png',
        back: '/images/shared/habit-cards/german/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/german/successive-approx.png',
        back: '/images/shared/habit-cards/german/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/german/surfaces-tests.png',
        back: '/images/shared/habit-cards/german/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/german/time-delays.png',
        back: '/images/shared/habit-cards/german/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/german/accumulations.png',
        back: '/images/shared/habit-cards/german/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Karte umdrehen'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/german/connections.png',
        back: '/images/shared/habit-cards/german/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Karte umdrehen'
      }
    ]
  },
  {
    lang: 'spanish',
    habitsTitle: 'Hábitos de un Pensador de Sistemas',
    translationCredit: '',
    introText:
      'Los Hábitos de un Pensador de Sistemas ayudan a estudiantes a entender cómo funciona el sistema y cómo las medidas que tomamos afectan los resultados que vemos a través del tiempo. Ellos abarcan un espectro de estrategias que promueven resolver problemas e incitan a formular preguntas. El Estudio de Herramientas de Pensar contiene un curso para cada uno de los Hábitos. A continuación encontrará una breve explicación de cada Hábito. Le animamos a que consulte a esta página con frecuencia - es un recurso de ayuda al completar lecciones, ejercicios y más.',
    flipText:
      'Oprima “Carta Invertida” para ver el otro lado de cada tarjeta de Hábito que contiene información adicional y preguntas importantes para considerar.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/spanish/big-pic.png',
        back: '/images/shared/habit-cards/spanish/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/spanish/change-over-time.png',
        back: '/images/shared/habit-cards/spanish/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/spanish/perspectives.png',
        back: '/images/shared/habit-cards/spanish/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/spanish/circular-nature.png',
        back: '/images/shared/habit-cards/spanish/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/spanish/mental-models.png',
        back: '/images/shared/habit-cards/spanish/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/spanish/considers-issue.png',
        back: '/images/shared/habit-cards/spanish/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/spanish/leverage.png',
        back: '/images/shared/habit-cards/spanish/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/spanish/structure.png',
        back: '/images/shared/habit-cards/spanish/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/spanish/consequences.png',
        back: '/images/shared/habit-cards/spanish/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/spanish/successive-approx.png',
        back: '/images/shared/habit-cards/spanish/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/spanish/surfaces-tests.png',
        back: '/images/shared/habit-cards/spanish/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/spanish/time-delays.png',
        back: '/images/shared/habit-cards/spanish/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/spanish/accumulations.png',
        back: '/images/shared/habit-cards/spanish/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Carta Invertida'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/spanish/connections.png',
        back: '/images/shared/habit-cards/spanish/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Carta Invertida'
      }
    ]
  },
  {
    lang: 'italian',
    habitsTitle: 'Abitudini di un Pensatore Sistemico',
    translationCredit: 'Tradotto da Rocco Scolozzi & Stefano Armenia, SYDIC -',
    translationCreditLink: 'https://www.systemdynamics.it/',
    translationCreditLinkText: 'System Dynamics Italian Chapter',
    introText:
      'Le “Abitudini di un Pensatore Sistemico” vi aiuteranno a comprendere come funzionano i sistemi e come certe azioni possono determinare i risultati che osserveremo al trascorrere del tempo. Queste “carte” includono un ampio spettro di strategie a supporto della risoluzione dei problemi (problem-solving) e che stimoleranno la vostra curiosità e voglia di porre e porvi domande. La nostra applicazione “Thinking Tool Studio” contiene un corso per ognuna delle “Abitudini”. Qui sotto troverete una breve spiegazione di ognuna delle Abitudini. Vi incoraggiamo a consultare spesso questa pagina, poiché sarà una utilissima risorsa quando completerete le lezioni, gli esercizi, ecc.',
    flipText:
      'Cliccare su “Gira la Carta” per consultare il retro di ogni carta “Abitudine”, che contiene ulteriori informazioni e importanti domande da considerare.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/italian/big-pic.png',
        back: '/images/shared/habit-cards/italian/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/italian/change-over-time.png',
        back: '/images/shared/habit-cards/italian/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/italian/perspectives.png',
        back: '/images/shared/habit-cards/italian/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/italian/circular-nature.png',
        back: '/images/shared/habit-cards/italian/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/italian/mental-models.png',
        back: '/images/shared/habit-cards/italian/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/italian/considers-issue.png',
        back: '/images/shared/habit-cards/italian/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/italian/leverage.png',
        back: '/images/shared/habit-cards/italian/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/italian/structure.png',
        back: '/images/shared/habit-cards/italian/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/italian/consequences.png',
        back: '/images/shared/habit-cards/italian/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/italian/successive-approx.png',
        back: '/images/shared/habit-cards/italian/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/italian/surfaces-tests.png',
        back: '/images/shared/habit-cards/italian/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/italian/time-delays.png',
        back: '/images/shared/habit-cards/italian/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/italian/accumulations.png',
        back: '/images/shared/habit-cards/italian/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Gira la Carta'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/italian/connections.png',
        back: '/images/shared/habit-cards/italian/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Gira la Carta'
      }
    ]
  },
  {
    lang: 'portuguese',
    habitsTitle: 'Hábitos de um Pensador Sistêmico',
    translationCredit: 'Traduzido por Sandra Costa e Daniel Rocca',
    introText:
      'Os Hábitos de um Pensador Sistêmico ajudam os alunos a entender como os sistemas funcionam e como ações tomadas, podem afetar os resultados vistos ao longo do tempo. Eles abrangem um espectro de estratégias que promovem a resolução de problemas e incentivam o questionamento. O Thinking Tools Studio contém um curso para cada um dos Hábitos. Abaixo você encontrará uma breve explicação de cada Hábito. Incentivamos você a consultar esta página com frequência – é um recurso útil ao concluir lições, exercícios e muito mais.',
    flipText:
      'Clique em “Virar Cartão” para ver o verso de cada cartão de Hábito, que contém mais informações e questões importantes a serem consideradas.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/portuguese/big-pic.png',
        back: '/images/shared/habit-cards/portuguese/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/portuguese/change-over-time.png',
        back: '/images/shared/habit-cards/portuguese/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/portuguese/perspectives.png',
        back: '/images/shared/habit-cards/portuguese/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/portuguese/circular-nature.png',
        back: '/images/shared/habit-cards/portuguese/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/portuguese/mental-models.png',
        back: '/images/shared/habit-cards/portuguese/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/portuguese/considers-issue.png',
        back: '/images/shared/habit-cards/portuguese/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/portuguese/leverage.png',
        back: '/images/shared/habit-cards/portuguese/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/portuguese/structure.png',
        back: '/images/shared/habit-cards/portuguese/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/portuguese/consequences.png',
        back: '/images/shared/habit-cards/portuguese/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/portuguese/successive-approx.png',
        back: '/images/shared/habit-cards/portuguese/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/portuguese/surfaces-tests.png',
        back: '/images/shared/habit-cards/portuguese/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/portuguese/time-delays.png',
        back: '/images/shared/habit-cards/portuguese/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/portuguese/accumulations.png',
        back: '/images/shared/habit-cards/portuguese/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Virar Cartão'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/portuguese/connections.png',
        back: '/images/shared/habit-cards/portuguese/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Virar Cartão'
      }
    ]
  },
  {
    lang: 'japanese',
    habitsTitle: 'システム思考家の習慣',
    translationCredit: '翻訳　有限会社チェンジ・エージェント',
    introText:
      '「システム思考家の習慣」（本シート）は、システムがどのように機能するか、取った行動が時間の経過とともに結果にどのような影響を与えるかの理解に役立ちます。記載された習慣は問題解決力を養い、問いを立てる戦略を網羅します。「思考ツールスタジオ」にはそれぞれの習慣に対応したコースも用意されています。本シートでは、それぞれの習慣についての簡単な説明をご覧いただけます。レッスンや演習を行う際に役立つリソースとして、このシートを頻繁に参照することをお勧めします。',
    flipText:
      '「カードを裏返す」をクリックして、それぞれの習慣カードの裏面を見ると、より詳細な説明と考えを深めるために重要な問いが書かれています。',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/japanese/big-pic.png',
        back: '/images/shared/habit-cards/japanese/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/japanese/change-over-time.png',
        back: '/images/shared/habit-cards/japanese/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/japanese/perspectives.png',
        back: '/images/shared/habit-cards/japanese/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/japanese/circular-nature.png',
        back: '/images/shared/habit-cards/japanese/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/japanese/mental-models.png',
        back: '/images/shared/habit-cards/japanese/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/japanese/considers-issue.png',
        back: '/images/shared/habit-cards/japanese/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/japanese/leverage.png',
        back: '/images/shared/habit-cards/japanese/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/japanese/structure.png',
        back: '/images/shared/habit-cards/japanese/structure-back.png',
        alt: 'Structure',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/japanese/consequences.png',
        back: '/images/shared/habit-cards/japanese/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/japanese/successive-approx.png',
        back: '/images/shared/habit-cards/japanese/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/japanese/surfaces-tests.png',
        back: '/images/shared/habit-cards/japanese/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/japanese/time-delays.png',
        back: '/images/shared/habit-cards/japanese/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/japanese/accumulations.png',
        back: '/images/shared/habit-cards/japanese/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'カードを裏返す'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/japanese/connections.png',
        back: '/images/shared/habit-cards/japanese/connections-back.png',
        alt: 'Connections',
        flipCardText: 'カードを裏返す'
      }
    ]
  },
  {
    lang: 'persian',
    habitsTitle: 'عادات یک متفکر سیستمی',
    translationCredit: 'Farhad Bolouri ترجمه شده توسط',
    introText:
      'عادات یک متفکر سیستمی به فراگیران کمک می کند تا درک کنند سیستم چگونه کار می کند و چگونه اقدامات انجام شده می تواند بر نتایج دیده شده در طول زمان تأثیر بگذارد. آنها طیف وسیعی از استراتژی ها را شامل می شوند که حل مسئله را تقویت می کنند و به سوال کردن تشویق می کنند. Thinking Tools Studio شامل دوره ای برای هر یک از عادت ها است. در زیر توضیح مختصری درباره هر عادت خواهید یافت. ما شما را تشویق می کنیم که اغلب به این صفحه مراجعه کنید - این یک منبع مفید هنگام اتمام دروس ، تمرینات و موارد دیگر است.',
    flipText:
      'برای دیدن قسمت پشتی هر کارت عادت که شامل اطلاعات بیشتر و سوالات مهمی است که باید در نظر بگیرید، بر روی "برگرداندن کارت"  کلیک کنید.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/persian/big-pic.png',
        back: '/images/shared/habit-cards/persian/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/persian/change-over-time.png',
        back: '/images/shared/habit-cards/persian/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/persian/perspectives.png',
        back: '/images/shared/habit-cards/persian/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/persian/circular-nature.png',
        back: '/images/shared/habit-cards/persian/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/persian/mental-models.png',
        back: '/images/shared/habit-cards/persian/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/persian/considers-issue.png',
        back: '/images/shared/habit-cards/persian/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/persian/leverage.png',
        back: '/images/shared/habit-cards/persian/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/persian/structure.png',
        back: '/images/shared/habit-cards/persian/structure-back.png',
        alt: 'Structure',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/persian/consequences.png',
        back: '/images/shared/habit-cards/persian/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/persian/successive-approx.png',
        back: '/images/shared/habit-cards/persian/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/persian/surfaces-tests.png',
        back: '/images/shared/habit-cards/persian/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/persian/time-delays.png',
        back: '/images/shared/habit-cards/persian/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/persian/accumulations.png',
        back: '/images/shared/habit-cards/persian/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'برگرداندن کارت'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/persian/connections.png',
        back: '/images/shared/habit-cards/persian/connections-back.png',
        alt: 'Connections',
        flipCardText: 'برگرداندن کارت'
      }
    ]
  },
  {
    lang: 'turkish',
    habitsTitle: 'Sistem Düşünürünün Alışkanlıkları',
    translationCredit: 'Sistem Düşüncesi Derneği tarafından çevrilmiştir',
    introText:
      'Sistem Düşünürünün Alışkanlıkları, öğrenenlerin sistemlerin nasıl çalıştığını ve eylemlerin zaman boyunca görülen sonuçları nasıl etkileyebileceğini anlamalarına yardımcı olur. Sorun çözmeyi geliştiren ve soru sormayı özendiren çeşitli stratejileri kapsar. Düşünme Araçları Stüdyosu (The Thinking Tools Studio) her Alışkanlık için ayrı bir kurs içerir. Aşağıda her Alışkanlık için kısa bir açıklama bulunuyor. Bu sayfaya sık sık başvurmanızı öneriyoruz – ders, alıştırma vb üzerinde çalışırken yardımcı olacak bir kaynaktır.',
    flipText:
      'Her Akışkanlığın, daha fazla bilgi ve üzerinde düşünülmesi gereken önemli soruları içeren arka yüzünü görmek için “Kartı Çevir” üzerine tıklayın.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/turkish/big-pic.png',
        back: '/images/shared/habit-cards/turkish/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/turkish/change-over-time.png',
        back: '/images/shared/habit-cards/turkish/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/turkish/perspectives.png',
        back: '/images/shared/habit-cards/turkish/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/turkish/circular-nature.png',
        back: '/images/shared/habit-cards/turkish/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/turkish/mental-models.png',
        back: '/images/shared/habit-cards/turkish/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/turkish/considers-issue.png',
        back: '/images/shared/habit-cards/turkish/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/turkish/leverage.png',
        back: '/images/shared/habit-cards/turkish/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/turkish/structure.png',
        back: '/images/shared/habit-cards/turkish/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/turkish/consequences.png',
        back: '/images/shared/habit-cards/turkish/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/turkish/successive-approx.png',
        back: '/images/shared/habit-cards/turkish/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/turkish/surfaces-tests.png',
        back: '/images/shared/habit-cards/turkish/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/turkish/time-delays.png',
        back: '/images/shared/habit-cards/turkish/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/turkish/accumulations.png',
        back: '/images/shared/habit-cards/turkish/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Kartı Çevir'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/turkish/connections.png',
        back: '/images/shared/habit-cards/turkish/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Kartı Çevir'
      }
    ]
  },
  {
    lang: 'french',
    habitsTitle: 'Les habitudes d’un Penseur en Systèmes',
    translationCredit: 'Traduit par Fabrice CLUZEL',
    introText:
      'Les habitudes d’un Penseur en Systèmes aident les apprenants à comprendre comment les systèmes fonctionnent et comment les actions entreprises peuvent avoir un impact sur les résultats observés au fil du temps. Elles englobent un éventail de stratégies qui favorisent la résolution de problèmes et encouragent le questionnement.  Le Studio des Outils de pensée - The Thinking Tools Studio - contient un cours pour chacune des habitudes. Vous trouverez ci-dessous une brève explication de chaque Habitude. Nous vous encourageons à vous référer souvent à cette page - c’est une ressource utile pour compléter les leçons et les exercices, entre autres.',
    flipText:
      'Cliquez sur "retourner la carte" pour voir le verso de chaque carte Habitudes qui contient des informations supplémentaires et des questions importantes à prendre en compte.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/french/big-pic.png',
        back: '/images/shared/habit-cards/french/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/french/change-over-time.png',
        back: '/images/shared/habit-cards/french/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/french/perspectives.png',
        back: '/images/shared/habit-cards/french/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/french/circular-nature.png',
        back: '/images/shared/habit-cards/french/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/french/mental-models.png',
        back: '/images/shared/habit-cards/french/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/french/considers-issue.png',
        back: '/images/shared/habit-cards/french/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/french/leverage.png',
        back: '/images/shared/habit-cards/french/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/french/structure.png',
        back: '/images/shared/habit-cards/french/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/french/consequences.png',
        back: '/images/shared/habit-cards/french/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/french/successive-approx.png',
        back: '/images/shared/habit-cards/french/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/french/surfaces-tests.png',
        back: '/images/shared/habit-cards/french/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/french/time-delays.png',
        back: '/images/shared/habit-cards/french/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/french/accumulations.png',
        back: '/images/shared/habit-cards/french/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Retourner la Carte'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/french/connections.png',
        back: '/images/shared/habit-cards/french/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Retourner la Carte'
      }
    ]
  },
  {
    lang: 'dutch',
    habitsTitle: 'Denkgewoonten van een Systeemdenker',
    translationCredit: 'Vertaald door Ties van Dijk en Luc Leistra',
    introText:
      'De Denkgewoonten van een Systeemdenker helpen te begrijpen hoe systemen werken en hoe bepaalde acties op de lange termijn invloed hebben op de uitkomsten. Ze omvatten een breed spectrum aan strategieën voor het oplossen van problemen en het aanmoedigen van het stellen van vragen. De Thinking Tools Studio bevat een training voor elk van de denkgewoonten. Hieronder vind je een korte uitleg van elke denkgewoonte. We raden je aan om deze pagina regelmatig te raadplegen. Het is een handige bron bij het uitvoeren van lessen, oefeningen en meer.',
    flipText:
      'Klik op ‘Kaart omdraaien’ om de achterkant van elke Denkgewoontekaart te bekijken. Deze bevat extra informatie en belangrijke vragen om over na te denken.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/dutch/big-pic.png',
        back: '/images/shared/habit-cards/dutch/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/dutch/change-over-time.png',
        back: '/images/shared/habit-cards/dutch/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/dutch/perspectives.png',
        back: '/images/shared/habit-cards/dutch/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/dutch/circular-nature.png',
        back: '/images/shared/habit-cards/dutch/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/dutch/mental-models.png',
        back: '/images/shared/habit-cards/dutch/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/dutch/considers-issue.png',
        back: '/images/shared/habit-cards/dutch/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/dutch/leverage.png',
        back: '/images/shared/habit-cards/dutch/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/dutch/structure.png',
        back: '/images/shared/habit-cards/dutch/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/dutch/consequences.png',
        back: '/images/shared/habit-cards/dutch/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/dutch/successive-approx.png',
        back: '/images/shared/habit-cards/dutch/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/dutch/surfaces-tests.png',
        back: '/images/shared/habit-cards/dutch/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/dutch/time-delays.png',
        back: '/images/shared/habit-cards/dutch/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/dutch/accumulations.png',
        back: '/images/shared/habit-cards/dutch/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Kaart omdraaien'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/dutch/connections.png',
        back: '/images/shared/habit-cards/dutch/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Kaart omdraaien'
      }
    ]
  },
  {
    lang: 'finnish',
    habitsTitle: 'Systeemiajattelijan tavat',
    translationCredit: 'Kääntäjät Tom Weckström ja Sami Honkonen',
    introText:
      'Systeemiajattelijan tavat auttavat oppijoita ymmärtämään, miten systeemit toimivat ja miten toteutetut toimenpiteet voivat vaikuttaa ajan myötä nähtäviin tuloksiin. Nämä tavat edistävät ongelmanratkaisua ja rohkaisevat kysymysten esittämistä. Thinking Tools Studio sisältää kurssin kutakin tapaa varten (saatavilla englanniksi). Alta löydät lyhyen kuvauksen kustakin Systeemiajattelijan tavasta. Kannustamme sinua palaamaan tähän tapojen yhteenvetoon usein, koska se auttaa systeemiajattelun oppimisessa kursseja suorittaessasi ja harjoitteita tehdessäsi.',
    flipText:
      'Napsauta "Käännä Kortti" nähdäksesi jokaisen kortin kääntöpuolen, joka sisältää lisätietoja ja tärkeitä kysymyksiä.',
    cards: [
      {
        id: 1,
        front: '/images/shared/habit-cards/finnish/big-pic.png',
        back: '/images/shared/habit-cards/finnish/big-pic-back.png',
        alt: 'Big Picture',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 2,
        front: '/images/shared/habit-cards/finnish/change-over-time.png',
        back: '/images/shared/habit-cards/finnish/change-over-time-back.png',
        alt: 'Change Over Time',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 3,
        front: '/images/shared/habit-cards/finnish/perspectives.png',
        back: '/images/shared/habit-cards/finnish/perspectives-back.png',
        alt: 'Perspectives',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 4,
        front: '/images/shared/habit-cards/finnish/circular-nature.png',
        back: '/images/shared/habit-cards/finnish/circular-nature-back.png',
        alt: 'Circular Nature',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 5,
        front: '/images/shared/habit-cards/finnish/mental-models.png',
        back: '/images/shared/habit-cards/finnish/mental-models-back.png',
        alt: 'Mental Models',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 6,
        front: '/images/shared/habit-cards/finnish/considers-issue.png',
        back: '/images/shared/habit-cards/finnish/considers-issue-back.png',
        alt: 'Considers Issue',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 7,
        front: '/images/shared/habit-cards/finnish/leverage.png',
        back: '/images/shared/habit-cards/finnish/leverage-back.png',
        alt: 'Leverage',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 8,
        front: '/images/shared/habit-cards/finnish/structure.png',
        back: '/images/shared/habit-cards/finnish/structure-back.png',
        alt: 'Structure',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 9,
        front: '/images/shared/habit-cards/finnish/consequences.png',
        back: '/images/shared/habit-cards/finnish/consequences-back.png',
        alt: 'Concequences',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 10,
        front: '/images/shared/habit-cards/finnish/successive-approx.png',
        back: '/images/shared/habit-cards/finnish/successive-approx-back.png',
        alt: 'Successive Approximation',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 11,
        front: '/images/shared/habit-cards/finnish/surfaces-tests.png',
        back: '/images/shared/habit-cards/finnish/surfaces-tests-back.png',
        alt: 'Surfaces and tests assumptions',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 12,
        front: '/images/shared/habit-cards/finnish/time-delays.png',
        back: '/images/shared/habit-cards/finnish/time-delays-back.png',
        alt: 'Time Delays',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 13,
        front: '/images/shared/habit-cards/finnish/accumulations.png',
        back: '/images/shared/habit-cards/finnish/accumulations-back.png',
        alt: 'Accumulations',
        flipCardText: 'Käännä Kortti'
      },
      {
        id: 14,
        front: '/images/shared/habit-cards/finnish/connections.png',
        back: '/images/shared/habit-cards/finnish/connections-back.png',
        alt: 'Connections',
        flipCardText: 'Käännä Kortti'
      }
    ]
  }
]
