module.exports = [
  { id: 1, value: 'Not Applicable' },
  { id: 185, value: 'United States of America' },
  { id: 2, value: 'Afghanistan' },
  { id: 3, value: 'Albania' }, { id: 4, value: 'Algeria' },
  { id: 5, value: 'Andorra' },
  { id: 6, value: 'Angola' },
  { id: 7, value: 'Antigua and Barbuda' },
  { id: 8, value: 'Argentina' },
  { id: 9, value: 'Armenia' },
  { id: 10, value: 'Australia' },
  { id: 11, value: 'Austria' },
  { id: 12, value: 'Azerbaijan' },
  { id: 13, value: 'Bahamas' },
  { id: 14, value: 'Bahrain' },
  { id: 15, value: 'Bangladesh' },
  { id: 16, value: 'Barbados' },
  { id: 17, value: 'Belarus' },
  { id: 18, value: 'Belgium' },
  { id: 19, value: 'Belize' },
  { id: 20, value: 'Benin' },
  { id: 21, value: 'Bhutan' },
  { id: 22, value: 'Bolivia' },
  { id: 23, value: 'Bosnia and Herzegovina' },
  { id: 24, value: 'Botswana' },
  { id: 25, value: 'Brazi' },
  { id: 26, value: 'Brunei' },
  { id: 27, value: 'Bulgaria' },
  { id: 28, value: 'Burkina Faso' },
  { id: 29, value: 'Burundi' },
  { id: 30, value: 'Côte d\'Ivoire' },
  { id: 31, value: 'Cabo Verde' },
  { id: 32, value: 'Cambodia' },
  { id: 33, value: 'Cameroon' },
  { id: 34, value: 'Canada' },
  { id: 35, value: 'Central African Republic' },
  { id: 36, value: 'Chad' },
  { id: 37, value: 'Chile' },
  { id: 38, value: 'China' },
  { id: 39, value: 'Colombia' },
  { id: 40, value: 'Comoros' },
  { id: 41, value: 'Congo (Congo-Brazzaville)' },
  { id: 42, value: 'Costa Rica' },
  { id: 43, value: 'Croatia' },
  { id: 44, value: 'Cuba' },
  { id: 45, value: 'Cyprus' },
  { id: 46, value: 'Czechia' },
  { id: 47, value: 'Democratic Republic of the Congo' },
  { id: 48, value: 'Denmark' },
  { id: 49, value: 'Djibouti' },
  { id: 50, value: 'Dominica' },
  { id: 51, value: 'Dominican Republic' },
  { id: 52, value: 'Ecuador' },
  { id: 900, value: 'Egypt' },
  { id: 53, value: 'El Salvador' },
  { id: 54, value: 'Equatorial Guinea' },
  { id: 55, value: 'Eritrea' },
  { id: 56, value: 'Estonia' },
  { id: 57, value: 'Ethiopia' },
  { id: 58, value: 'Fiji' },
  { id: 59, value: 'Finland' },
  { id: 60, value: 'France' },
  { id: 61, value: 'Gabon' },
  { id: 62, value: 'Gambia' },
  { id: 63, value: 'Georgia' },
  { id: 64, value: 'Germany' },
  { id: 65, value: 'Ghana' },
  { id: 66, value: 'Greece' },
  { id: 67, value: 'Grenada' },
  { id: 68, value: 'Guatemala' },
  { id: 69, value: 'Guinea' },
  { id: 70, value: 'Guinea-Bissau' },
  { id: 71, value: 'Guyana' },
  { id: 72, value: 'Haiti' },
  { id: 73, value: 'Honduras' },
  { id: 74, value: 'Hungary' },
  { id: 75, value: 'Iceland' },
  { id: 76, value: 'India' },
  { id: 77, value: 'Indonesia' },
  { id: 78, value: 'Iran' },
  { id: 79, value: 'Iraq' },
  { id: 80, value: 'Ireland' },
  { id: 81, value: 'Israel' },
  { id: 82, value: 'Italy' },
  { id: 83, value: 'Jamaica' },
  { id: 84, value: 'Japan' },
  { id: 85, value: 'Jordan' },
  { id: 86, value: 'Kazakhstan' },
  { id: 87, value: 'Kenya' },
  { id: 88, value: 'Kiribati' },
  { id: 89, value: 'Kuwait' },
  { id: 90, value: 'Kyrgyzstan' },
  { id: 91, value: 'Laos' },
  { id: 92, value: 'Latvia' },
  { id: 93, value: 'Lebanon' },
  { id: 94, value: 'Lesotho' },
  { id: 95, value: 'Liberia' },
  { id: 96, value: 'Libya' },
  { id: 97, value: 'Liechtenstein' },
  { id: 98, value: 'Lithuania' },
  { id: 99, value: 'Luxembourg' },
  { id: 100, value: 'Madagascar' },
  { id: 101, value: 'Malawi' },
  { id: 102, value: 'Malaysia' },
  { id: 103, value: 'Maldives' },
  { id: 104, value: 'Mali' },
  { id: 105, value: 'Malta' },
  { id: 106, value: 'Marshall Islands' },
  { id: 107, value: 'Mauritania' },
  { id: 108, value: 'Mauritius' },
  { id: 109, value: 'Mexico' },
  { id: 110, value: 'Micronesia' },
  { id: 111, value: 'Moldova' },
  { id: 112, value: 'Monaco' },
  { id: 113, value: 'Mongolia' },
  { id: 114, value: 'Montenegro' },
  { id: 115, value: 'Morocco' },
  { id: 116, value: 'Mozambique' },
  { id: 117, value: 'Myanmar (formerly Burma)' },
  { id: 118, value: 'Namibia' },
  { id: 119, value: 'Nauru' },
  { id: 120, value: 'Nepal' },
  { id: 121, value: 'Netherlands' },
  { id: 122, value: 'New Zealand' },
  { id: 123, value: 'Nicaragua' },
  { id: 124, value: 'Niger' },
  { id: 125, value: 'Nigeria' },
  { id: 126, value: 'North Korea' },
  { id: 127, value: 'North Macedonia' },
  { id: 128, value: 'Norway' },
  { id: 129, value: 'Oman' },
  { id: 130, value: 'Pakistan' },
  { id: 131, value: 'Palau' },
  { id: 132, value: 'Palestine State' },
  { id: 133, value: 'Panama' },
  { id: 134, value: 'Papua New Guinea' },
  { id: 135, value: 'Paraguay' },
  { id: 136, value: 'Peru' },
  { id: 137, value: 'Philippines' },
  { id: 138, value: 'Poland' },
  { id: 139, value: 'Portugal' },
  { id: 140, value: 'Qatar' },
  { id: 141, value: 'Romania' },
  { id: 142, value: 'Russia' },
  { id: 143, value: 'Rwanda' },
  { id: 144, value: 'Saint Kitts and Nevis' },
  { id: 145, value: 'Saint Lucia' },
  { id: 146, value: 'Saint Vincent and the Grenadines' },
  { id: 147, value: 'Samoa' },
  { id: 148, value: 'San Marino' },
  { id: 500, value: 'Sao Tome and Principe' },
  { id: 149, value: 'Saudi Arabiae' },
  { id: 150, value: 'Senegal' },
  { id: 151, value: 'Serbia' },
  { id: 152, value: 'Seychelles' },
  { id: 153, value: 'Sierra Leone' },
  { id: 154, value: 'Singapore' },
  { id: 155, value: 'Slovakia' },
  { id: 156, value: 'Slovenia' },
  { id: 157, value: 'Solomon Islands' },
  { id: 158, value: 'Somalia' },
  { id: 159, value: 'South Africa' },
  { id: 160, value: 'South Korea' },
  { id: 161, value: 'South Sudan' },
  { id: 162, value: 'Spain' },
  { id: 163, value: 'Sri Lanka' },
  { id: 164, value: 'Sudan' },
  { id: 165, value: 'Suriname' },
  { id: 166, value: 'Swaziland' },
  { id: 167, value: 'Sweden' },
  { id: 168, value: 'Switzerland' },
  { id: 169, value: 'Syria' },
  { id: 170, value: 'Tajikistan' },
  { id: 171, value: 'Tanzania' },
  { id: 172, value: 'Thailand' },
  { id: 173, value: 'Timor-Leste' },
  { id: 174, value: 'Togo' },
  { id: 175, value: 'Tonga' },
  { id: 176, value: 'Trinidad and Tobago' },
  { id: 177, value: 'Tunisia' },
  { id: 178, value: 'Turkey' },
  { id: 179, value: 'Turkmenistan' },
  { id: 180, value: 'Tuvalu' },
  { id: 181, value: 'Uganda' },
  { id: 182, value: 'Ukraine' },
  { id: 183, value: 'United Arab Emirates' },
  { id: 184, value: 'United Kingdom' },
  { id: 186, value: 'Uruguay' },
  { id: 187, value: 'Uzbekistan' },
  { id: 188, value: 'Vanuatu' },
  { id: 189, value: 'Venezuela' },
  { id: 200, value: 'Vietnam' },
  { id: 190, value: 'Yemen' },
  { id: 191, value: 'Zambia' },
  { id: 192, value: 'Zimbabwe' }
]
