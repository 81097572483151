import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <section className='white'>
      <p>
        Part 1:  (7 mins)<br />
        <strong>Building Systems Thinking Capacity with Early Childhood Leaders</strong><br />
        From 2017-2020, the Waters Center “Systems Thinking in School Communities” project, funded by the W.K. Kellogg Foundation, worked to develop a model to scale the value of a systems thinking approach to school improvement, community engagement and student empowerment in preschool and elementary grades. In this video, hear from Chris Holiday, Disability, Mental Health & Nutrition Manager, Head Start, Middle Kentucky Community Action, who describes using systems thinking tools during professional development with early childhood teachers.
      </p>

      <iframe
        width='560'
        height='315'
        src='https://www.youtube.com/embed/d4vnovo8ULs?rel=0'
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        className='center'
      />
    </section>

    <section className='white'>
      <p>
        Part 2:  (7 mins)<br />
        <strong>CLASS and Systems Thinking</strong><br />
        From 2017-2020, the Waters Center “Systems Thinking in School Communities” project, funded by the W.K. Kellogg Foundation, worked to develop a model to scale the value of a systems thinking approach to school improvement, community engagement and student empowerment in preschool and elementary grades. In this video, hear from Chris Holiday, Disability, Mental Health & Nutrition Manager, Head Start, Middle Kentucky Community Action, who reviews the connection between systems thinking and the use of the Classroom Assessment Scoring System (CLASS).
      </p>

      <iframe
        width='560'
        height='315'
        src='https://www.youtube.com/embed/8bmLzlZw_nc?rel=0'
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        className='center'
      />
    </section>
  </div>
