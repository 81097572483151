import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Archetype</h3>

    <p>
      Now it’s time to take a look at this archetype using your own personal experience.<br />
      When have you encountered or observed a "repression and revolution" scenario? Use the archetype to reflect on that experience.
    </p>

    <p>
      Feel free to <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-blank-so.pdf' target='_blank' rel='noopener noreferrer'>download a blank Repression and Revolution template</a> and use it to tell your story as you respond to the questions.
    </p>

    <h4>
      1. Who/what was the established governing body/authority? Who was the opposition group?
    </h4>

    <Field
      name='resource-response-one'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      2. What were one or more actions taken by the governing body that were considered oppressive?
    </h4>

    <Field
      name='resource-response-two'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      3. What effects did those actions have on the “opposition”? Did the governing body’s actions create any weakening of the opposition? Increased legitimacy of the opposition?
    </h4>

    <Field
      name='resource-response-three'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
