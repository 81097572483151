import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      You might be in an "accidental adversaries” scenario when….
    </p>

    <div className='quote'>
      <p className='quote-text'>
        Two individuals or groups who are initially cooperating and working toward a common goal, accidentally take actions that undermine one another's success.
      </p>
    </div>

    <p>
      The “accidental” descriptor of this archetype is important as the disruption of a collaborative partner’s success is unintentional. The lack of awareness and unintended effects of actions on others who are working toward a common goal creates the potential for this adversarial dynamic.
    </p>

    <img
      className='lesson-img large-img block'
      src='/images/courses/archetypes/08-accidental-adversaries/accidental-adversaries.svg'
      alt='Tragedy of the Commons'
    />

    <p>
      The components of this archetype template include:
    </p>

    <ul>
      <li>
        Two parties, e.g. organizations, companies, groups or individuals, who are working toward common goals and have the intentions of a win-win collaboration.  They are represented by the letters A and B.
      </li>

      <li>
        A reinforcing dynamic in which one party’s success contributes to actions that impact the other party’s success because both parties are collaborating and mutually supporting one another’s actions.  This is represented in the outside loop of the archetype.
      </li>

      <li>
        Two balancing loops, one for each party, that include variables of success and actions to improve results.
      </li>

      <li>
        Reinforcing feedback that show how one party’s actions to achieve success can obstruct the other party’s chances at achieving success.
      </li>
    </ul>

    <h3>
      Story of this Archetype
    </h3>

    <p>
      As you read each part of the story, press the “Next Part of Story” button below the graphic to see the story of the archetype unfold.
    </p>

    <div className='arch-components'>
      <div className='arch-p-container'>

        <p>
          <strong>Part 1:</strong> <em>R1:</em> Because both parties are collaborating and mutually supporting one another’s actions, there is a reinforcing loop that shows how <strong>A’s</strong> success contributes favorably to the actions and success of <strong>B</strong> and similarly, <strong>B’s</strong> success is favorable to the actions and success of&nbsp;<strong>A</strong>.
        </p>

        <p>
          <strong>Part 2:</strong> <em>B1 and B2:</em> They are each taking individual actions to improve and achieve success. The template shows the parties or individuals as <strong>A</strong> and&nbsp;<strong>B</strong>.
        </p>

        <p>
          <strong>Part 3:</strong> <em>R2:</em> <strong>A’s</strong> actions to improve their own results actually obstruct <strong>B’s</strong> success, which leads to <strong>B’s</strong> actions to improve their results, causing obstruction to <strong>A’s</strong> success. Both parties’ actions generate a series of obstructing effects that impact the success of the other.
        </p>

        <p>
          The unintended, but disruptive feedback dynamic creates a pattern of shifting dominance that moves in a figure-eight pattern and shows how escalating obstructive actions, although unintentional, shift positions of success from <strong>B</strong> to <strong>A</strong> and <strong>A</strong> to <strong>B</strong> and so on, over time.
        </p>
      </div>

      <div className='arch-build-container'>
        <ToggleImages
          images={[{ id: 1, image: '/images/courses/archetypes/08-accidental-adversaries/aa-step1.svg' }, { id: 2, image: '/images/courses/archetypes/08-accidental-adversaries/aa-step2.svg' }, { id: 3, image: '/images/courses/archetypes/08-accidental-adversaries/aa-step3.svg' }]}
        />
      </div>
    </div>

    <div className='exerpt'>
      <p>
        <strong>Reflection:</strong>  Consider a time when you were working with another person or group toward a common goal and soon discovered that you began to compete with one another as the actions they took negatively impacted you and vice versa.<br /> <em>What did the other party do to obstruct your success?<br /> What actions did you take to counteract the impact of their actions?<br /> At what point did you realize that you shifted from collaborators to adversaries?</em>
      </p>
    </div>

    <p>
      Continue to Lesson 2 for examples of stories that illustrate the Accidental Adversaries archetype.
    </p>
  </div>
