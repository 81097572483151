import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <p>Time to create your own BOTG. On a piece of paper, draw at least one graph for both Category A and Category B.  Choose one of the scenarios provided or create your own. Don’t forget to label your X and Y-axes.</p>
    <table className='lesson-table'>
      <tbody>
        <tr>
          <th>Category A—A graph based on actual numbers</th>
          <th>Category B—A graph based on perception</th>
        </tr>
        <tr>
          <td>The temperature in your hometown from 6:00 a.m. to 10:00 p.m</td>
          <td>Your personal level of stress from November 1 to January 31 for any given year</td>
        </tr>
        <tr>
          <td>Any indicator of financial health over the last three months, e.g. NASDAQ, S&P 500, FTSE 100, DAX, Nikkel 225</td>
          <td>The amount of clutter in your garage over the last three years</td>
        </tr>
        <tr>
          <td>A school’s state rating or NAEP scores over the last five years</td>
          <td>The level of fear you felt in the year prior to becoming a parent</td>
        </tr>
        <tr>
          <td>Your weight over the last year</td>
          <td>Your personal satisfaction at your job for the last three years</td>
        </tr>
        <tr>
          <td>The number of teeth in your mouth from birth to death</td>
          <td>Learning something new (e.g. playing tennis, learning a new language, riding a motorcycle, knitting) and graph the level of difficulty in the first year as you learned and practiced the new skill</td>
        </tr>
      </tbody>
    </table>
    <Field
      name='graphs'
      component={FileUpload} image
      instructions='Take a photo of each of the graphs you created and upload them below.'
    />
    <p>Think through each of the questions below. As you drew the behavior-over-time graph for each scenario…</p>
    <h4>What came easily to you?</h4>
    <Field
      name='easily'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What did you have to stop and think about?</h4>
    <Field
      name='think'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What questions did this raise for you?</h4>
    <Field
      name='raise'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What surprised you about the process?  Did you find anything unexpected in the data?  If so, what?</h4>
    <Field
      name='process'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What new insights did you gain about graphing as a tool for making thinking visible?</h4>
    <Field
      name='insights'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
