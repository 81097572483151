import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <h3>
      Family Challenges
    </h3>

    <p>
      Consider the situation of two parents who have separated amicably and have mutually agreed to share custody and co-parent their children despite the break-up of the marriage or relationship. The parents jointly create a custodial schedule, agree to communicate regularly and seem to have visitations, school and extra-curricular activities on the calendar with everyone on the same page.  At some point, work obligations shift for one parent and financial challenges surface for the other which cause some actions to occur that are not clearly communicated but still meet the caring responsibilities of parenting.  Also, as the children mature and become more social, boundaries for what is acceptable and not also may change.  One parent may give permission for a daughter to sleep over at a friend’s house even though the host’s parents won’t be home, making that parent the “good parent” in the daughter’s mind. One parent may disagree with a child’s request for an expensive cell phone, yet the co-parent chooses to purchase the phone and becomes “the hero.” Decisions are made based on what an individual thinks is best, but the result is friction between parents who are no longer on the same page when it comes to parenting.
    </p>

    <h3>
      School Enrollment
    </h3>

    <p>
      A large suburban school district has a network of elementary schools that serve a variety of neighborhoods in their region.  Each school’s principal is a member of an elementary school leadership cohort.  This cohort meets and shares best practices and leadership challenges with the expectation that they support and learn from one another.  The principals appreciate and see the value of this opportunity to work together.  A new policy of school choice has become popular in United States schools.  School choice enables families to choose the school they want for their children, even if the school is outside of the family’s neighborhood. This dynamic begins to trigger actions on the part of principals to entice students outside of their neighborhoods to attend their schools in order to grow their enrollment numbers.  When families make choices, enrollment shifts based on school reputation, word of mouth and even published achievement measures. Even though school principals are all working toward a common goal of providing the very best learning environments for students throughout the entire district, the competition for student enrollment has resulted in a competitive environment with marketing strategies, enrollment fairs and other promotional activities. Adversarial relationships begin to develop among members of the elementary principal cohort as competition for students grows.
    </p>

    <h3>
      Sales and Marketing
    </h3>

    <p>
      The sales and marketing departments of a small and innovative software development company were working hard to promote and sell several new product lines. The directors of both departments had a positive working relationship.  They often went out for Friday Happy Hour after work to debrief the week, were respectful and supportive of one another during meetings and in general, got along well.  Soon after product launch, quarterly sales reports showed disappointing results. Taken by surprise by the gap in performance, the directors of both departments made independent requests to their Chief Financial Officer (CFO) for increases in their budgets in order to improve performance.  The sales team director wanted to establish new performance quotas for each member with the possibility of earning newly established, built-in bonuses, necessitating a significant budget increase. The marketing team director asked for financial support to upgrade their customer relation management (CRM) platform to support more in-depth documentation of customer leads. The belief was that if the marketing department could generate data to show their efforts, then they would be off the hook if sales stagnated.  The two department directors had initially worked collaboratively with a focus on a common goal of company performance. Their subsequent actions to request separate budget increases resulted in a competition for limited additional funds. The drop in quarterly performance measures triggered a change in their relationship and caused actions to compete for funds with isolated plans to address their role in contributing to performance gaps. This resulting, inadvertent adversarial relationship between the directors rippled down to the team members causing a reinforcing dynamic of blame and resentment.
    </p>
  </div>
