import * as React from 'react'

export const Section01 = () =>
  <div className='content'>

    <p>
      The Systems Thinking Rubrics designed by the Catalina Foothills School District (CFSD) provide a common vocabulary
      and illustrate a continuum of performance. By design, the rubrics were not written to align to any specific subject
      area; they are intended to be contextualized within the academic content areas based on the performance area(s) being
      taught and assessed. In practice, this will mean that not every performance area in each of the rubrics will be
      necessary in every lesson, unit, or assessment.
    </p>

    <p>
      The CFSD rubric for Systems Thinking was designed as a cross-disciplinary tool to support educators in teaching
      and assessing the performance areas associated with this proficiency:
    </p>

    <ul>
      <li>Change Over Time</li>

      <li>Interdependencies</li>

      <li>Consequences</li>

      <li>System as Cause</li>

      <li>Leverage Actions</li>

      <li>Big Picture</li>

      <li>Self-Regulation and Reflection</li>
    </ul>

    <p>
      These rubrics are to be used primarily for formative instructional and assessment purposes; they are not intended
      to generate psychometrically valid, high stakes assessment data typically associated with state and national testing.
      CFSD provides a variety of tools and templates to support the integration of Systems Thinking into units, lessons, and
      assessments. When designing units, teachers are encouraged to create authentic assessment opportunities in which
      students can demonstrate mastery of content and the deep learning proficiencies at the same time.
    </p>

    <p>
      Links to rubrics can be found in the blue header at the top of the page.
    </p>
  </div>
