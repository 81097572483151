import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/06-patterns/course-img.png'
      alt='Change Over Time'
    />
    <p>Change over time is an inherent property of systems. A person can change a shirt or
      change her mind in a moment’s notice, but unlike a one-time-event view of
      change, systems thinkers focus on the nature of change over time.
    </p>
    <h3>Measuring Change</h3>
    <p>The ability to observe how elements within systems change over time, and the
      means to represent that change, are important practices of a systems thinker.
      Change can be measured in concrete ways as with the changing height and weight
      of a growing child. But change can also be documented from a particular point of
      view, as in the changing temperament or level of independence of a developing
      child. For example, a parent may view her teenage daughter’s repeated efforts to
      become more independent as growing rebellious behavior, whereas the daughter
      views her independence as a part of growing up. For the daughter, becoming more
      mature means being less dependent on her parents.
    </p>
    <div className='quote'>
      <p className='quote-text'>“To exist is to change, to change is to mature; to mature is to go on creating
        oneself endlessly.”
      </p><br />
      <p className='quote-author'> - Henri Bergson, philosopher</p>
    </div>
    <p>The ability to observe change and make those observations visible helps
      people communicate viewpoints about how and why things change. Like the spread
      of a tweet or Instagram post, or the ups and downs of the stock market, trends
      are a part of daily life and our changing world. We hear about trends, see them
      illustrated in the news, and feel them personally. Observing changing elements
      in a system as patterns and trends can be practiced in all kinds of systems.
      Systems thinkers come to do this naturally.
    </p>
    <h4>Examples of trends:</h4>
    <div className='trends'>
      <div className='trend'>
        <img
          className='trend-img'
          src='/images/courses/shared/icon-well-being.svg'
          alt='Well Being Example'
        />
        <div className='trend-content'>
          <h4>Well-being</h4>
          <p>Time spent reading for enjoyment</p>
        </div>
      </div>
      <div className='trend'>
        <img
          className='trend-img'
          src='/images/courses/shared/icon-workplace.svg'
          alt='Well Being Example'
        />
        <div className='trend-content'>
          <h4>Workplace</h4>
          <p>Morale of a company’s employees</p>
        </div>
      </div>
      <div className='trend'>
        <img
          className='trend-img'
          src='/images/courses/shared/icon-community.svg'
          alt='Well Being Example'
        />
        <div className='trend-content'>
          <h4>Community</h4>
          <p>Number of cars on a freeway throughout the day</p>
        </div>
      </div>
      <div className='trend'>
        <img
          className='trend-img'
          src='/images/courses/shared/icon-family.svg'
          alt='Well Being Example'
        />
        <div className='trend-content'>
          <h4>Family</h4>
          <p>Stress that a family experiences as children grow up</p>
        </div>
      </div>
      <div className='trend'>
        <img
          className='trend-img'
          src='/images/courses/shared/icon-school.svg'
          alt='Well Being Example'
        />
        <div className='trend-content'>
          <h4>School</h4>
          <p>Excitement when starting a new school year</p>
        </div>
      </div>
    </div>
  </div>
