import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Archetype</h3>

    <p>
      When have you encountered a “shifting the burden” story?  Use this archetype to reflect on that experience.
    </p>

    <p>
      Feel free to <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/shifting-the-burden-blank-so.pdf' target='_blank' rel='noopener noreferrer'>download a blank “shifting the burden” template</a> and use it to tell your story as you respond to the questions.
    </p>

    <h4>
      1. Identify the problem symptom.
    </h4>

    <div className='textbox-with-img'>
      <img
        src='/images/courses/archetypes/05-shifting-the-burden/ex2-1.svg'
        alt='A-B'
      />

      <Field
        name='resource-response-one'
        component='textarea'
        className='reflection-input-large'
        placeholder='Enter your response here.'
      />
    </div>

    <h4>
      2. Determine one or more symptomatic solutions that were applied. What was the effect of the fix on the problem symptom?
    </h4>

    <div className='textbox-with-img'>
      <img
        src='images/courses/archetypes/05-shifting-the-burden/ex2-2.svg'
        alt='A-B'
      />

      <Field
        name='resource-response-two'
        component='textarea'
        className='reflection-input-large'
        placeholder='Enter your response here.'
      />
    </div>

    <h4>
      3. What side effects were created by the symptomatic solution?
    </h4>

    <div className='textbox-with-img'>
      <img
        src='/images/courses/archetypes/05-shifting-the-burden/ex2-3.svg'
        alt='A-B'
      />

      <Field
        name='resource-response-three'
        component='textarea'
        className='reflection-input-large'
        placeholder='Enter your response here.'
      />
    </div>

    <h4>
      4. Do you sense there is a fundamental solution that could have a long-term positive impact on the problem symptom?
    </h4>

    <Field
      name='resource-response-four'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      5. Consider possible fundamental solutions.  What steps could you take to achieve those solutions?  What are the obstacles to achieving the fundamental solutions?
    </h4>

    <Field
      name='resource-response-five'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h3>
      Reflecting on Your Archetypical Story:
    </h3>

    <h4>
      What is changing over time?
    </h4>

    <Field
      name='resource-response-six'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      Can you identify elements of balancing and reinforcing feedback as you tell the story?
    </h4>

    <Field
      name='resource-response-seven'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      Having applied this archetype to your particular problem symptom, how might you achieve fundamental solutions in the future?
    </h4>

    <Field
      name='resource-response-eight'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      What new insights about your situation were gained by applying this archetype?
    </h4>

    <Field
      name='resource-response-nine'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
