import * as React from 'react'
import { SectionHeader } from 'components/resources/resource-parts/section-header'
import { getResourcesNav } from 'components/resources/resourceList'
import { NavButtons } from 'components/shared/nav-buttons'
import { Downloads } from 'components/resources/resource-parts/downloads'
import PropTypes from 'prop-types'

// This layout gets used for all sections in a lesson
// It is also used for single section lessons (like exercises), for now.  We may want to change this
export const SectionLayout = (props) => {
  const { resource, section } = props
  const nav = getResourcesNav({ resource, section })
  return (
    <div>
      <SectionHeader resource={resource} section={section} />
      <Downloads resource={resource} />
      {/* display actual page */}
      <props.component {...nav} />
      <NavButtons nav={nav} />
    </div>
  )
}
SectionLayout.propTypes = {
  resource: PropTypes.object,
  section: PropTypes.object
}
