import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <h3>
      The first way to label a causal connection is with an <span className='blue'><strong>S</strong></span>.
    </h3>

    <p>
      The <span className='blue'><strong>S</strong></span> label means that the variables are changing in the same direction—either increasing or decreasing.  In addition to the label of <span className='blue'><strong>S</strong></span>, some people prefer to use a + which means the causal relationship is additive.  When one variable changes, the second variable adds to the original change, thus changing in the same direction.  In this course, we will be using <span className='blue'><strong>S</strong></span> and in Lesson #4, <span className='red'><strong>O</strong></span> labels.
    </p>

    <p>
      Job satisfaction and morale in the following example are changing in the same direction as a result of their causal connection.
    </p>

    <div className='causal-link'>
      <div className='causal-column'>
        <h4>
          Job satisfaction
        </h4>
      </div>

      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />

        <p className='link-label blue'>S</p>
      </div>

      <div className='causal-column'>
        <h4>
          Morale
        </h4>
      </div>
    </div>

    <p>
      The causal link stories that describe the above causal connection:
    </p>

    <div className='causal-link'>
      <div className='causal-column'>
        <h4>
          A decrease in job satisfaction
        </h4>
      </div>

      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />

        <p className='arrow-label'>could cause</p>

        <p className='link-label blue'>S</p>
      </div>

      <div className='causal-column'>
        <h4>
          a decrease in Morale.
        </h4>
      </div>
    </div>

    <p className='mobilecenter'>OR</p>

    <div className='causal-link'>
      <div className='causal-column'>
        <h4>
          An increase in job satisfaction
        </h4>
      </div>

      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />

        <p className='arrow-label'>could cause</p>

        <p className='link-label blue'>S</p>
      </div>

      <div className='causal-column'>
        <h4>
          an increase in Morale.
        </h4>
      </div>
    </div>

    <p>
      What other causal pairs from Practice Exercise #1 change in the same direction and are labeled with an S at the arrowhead?
    </p>

    <p>
      An example to get you started:
    </p>

    <div className='causal-link double'>
      <div className='causal-column'>
        <h4>
          Level of Confidence
        </h4>

        <h4>
          Level of Skills
        </h4>
      </div>

      <div className='causal-column double'>
        <img
          className='arrow tilt15'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />

        <img
          className='arrow tilt-15'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />

        <p className='link-label blue'>S</p>

        <p className='link-label blue'>S</p>
      </div>

      <div className='causal-column'>
        <h4>
          Ability and willingness to take on new tasks
        </h4>
      </div>
    </div>

    <h3 className='topspace'>
      Tell the story of the links:
    </h3>

    <p>
      <em>When my confidence level increases, I am more able to take on new tasks. </em>
    </p>

    <p className='mobilecenter'>
      <strong>Or</strong>
    </p>

    <p>
      <em>When my confidence is low, I am less able to take on new tasks.</em>
    </p>

    <h3>
      And:
    </h3>

    <p>
      <em>When my skill level is high, my ability to take on new tasks increases. </em>
    </p>

    <p className='mobilecenter'>
      <strong>Or</strong>
    </p>

    <p>
      <em>When my skill level is low, my ability to take on new tasks is also lowered.</em>
    </p>
  </div>
