import * as React from 'react'
import Helmet from 'react-helmet'
// import { ToggleText } from 'components/shared/toggle-text'
// import { EventRegisterForm } from 'components/events/EventRegisterForm'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

export const TeacherStudio = () =>
  <div>
    <Header />
    <div className='flow-container open-studio'>
      <Helmet>
        <title>Teacher Studio Events - Thinking Tools Studio</title>
        <meta name='description' content='Free hour-long systems thinking learning sessions.' />
        <meta property='og:title' content='Teacher Studio Events - Thinking Tools Studio' />
        <meta property='og:description' content='Free hour-long systems thinking learning sessions.' />
        <meta property='og:image' content='https://thinkingtoolsstudio.org/images/site/open-studio.png' />
        <meta property='og:url' content='https://thinkingtoolsstudio.org/events/openstudio' />
        <meta name='twitter:image' content='https://thinkingtoolsstudio.org/images/site/open-studio.png' />
        <meta name='twitter:image:alt' content='Open Studio brought to you by the Waters Center for Systems Thinking' />
      </Helmet>

      <section className='photo-header open-studio-photo'>
        <h1>Teacher Studio Webinars</h1>

        <img src='images/site/teacher-studio-icon.svg' />

        <h2>
          brought to you by the Waters Center for Systems Thinking
        </h2>
      </section>

      <section className='white stacked'>
        <h3>Teacher Studio Webinar registration has moved to the Waters Center website. Please visit the <a href='https://waterscenterst.org/webinars'>Webinars page</a> there to see upcoming events and registration.</h3>
        {/* <p>
          We are committed to supporting teachers in any way we can. <strong>Teacher Studio webinars</strong> consist of hour-long Zoom sessions led by Waters Center staff and special guests. Teacher Studio webinars will use systems thinking Habits, tools and strategies to take a deep dive into areas of interest for educators from varying systems. We will also provide ideas for lesson plans that can be adapted to different grade levels.
        </p>

        <p>
          Teacher Studio webinars will occur the first Saturday of every month at 9 a.m. PST unless otherwise stated.
        </p>

        <h3>
          Upcoming Webinars
        </h3>

        <div className='open-studio-container'>
          <h4 className='centered'>
            Saturday, June 5, 2021, 9 a.m. PDT<br />
            <em>Teaching Systems Thinking Concepts Using Cartoons</em>
          </h4>

          <p>
            Following up on Thursday's Open Studio session that featured author Morgan Kauffman, this Teacher Studio will look at some of the systems concepts presented through cartoons in the book <em>Systems 1: An Introduction to Systems Thinking</em> and make connections to classroom-ready resources that give students opportunities to grow in their knoweldge of systems thinking concepts, tools and Habits.
          </p>
        </div>

        <p>
          <em>Check back later for additional events!</em>
        </p> */}
      </section>

      {/* <section className='grey stacked'>

        <h1>
          Teacher Studio Registration
        </h1>

        <EventRegisterForm
          eventType='Teacher Studio'
        />

      </section> */}

      {/* <section className='white stacked'>
        <h3>
          FAQ
        </h3>
        <ToggleText
          showText='Are Teacher Studio Sessions free? +'
          hideText='Are Teacher Studio Sessions free? -'
          text={[{ id: 1, text: 'Yes! We are happy to offer these sessions free of charge. Donations are always appreciated to help us continue our efforts to openly share our resources — no amount is too little.' }]}
        />

        <ToggleText
          showText='How do I sign up? +'
          hideText='How do I sign up? -'
          text={[{ id: 1, text: 'Registration will open for sessions on a rolling basis. As a TTS user, you will receive an email when registration for sessions open. In addition, you can refer to this page and select the registration option in the dropdown menu above.' }]}
        />

        <p>
          Need help with events? <a href='mailto:events@waterscenterst.org'>Email us</a> at events@waterscenterst.org
        </p>

        <h3>
          Past Teacher Studio Topics
        </h3>

        <ul className='pasteventslist'>
          <li>
            Story and STEM for Educators
          </li>
          <li>
            Successive Approximation: Valuable lessons learned when things go awry in the classroom
          </li>
          <li>
            Teaching Thinking Virtually
          </li>
          <li>
            Systems Thinking Celebrates Love of Reading
          </li>
          <li>
            Key Accumulations in the New Year–Stock-Flow Maps
          </li>
          <li>
            These Are a Few of Our Favorite Things… Teacher Edition!
          </li>
          <li>
            Seasons, Salmon and Storms: Integrating Systems Thinking with Science Standards
          </li>
          <li>
            What Can Students Learn From Examining Change?
          </li>
        </ul>
      </section> */}
    </div>
    <Footer />
  </div>
