import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Think about an event or occurrence in your life.</p>
    <h4>Is this a one-time occurrence? If not, how often does it occur?</h4>
    <Field
      name='one-time'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>If you have seen it happen before, can you name the key element that is changing and describe how it is changing?</h4>
    <Field
      name='happen-before'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>How fast or slow are things changing?</h4>
    <Field
      name='or-slow'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>How does the rate of change influence your next steps?</h4>
    <Field
      name='change-rate'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Was the change a result of a new program, initiative or event? What will change look like in the future?</h4>
    <Field
      name='new-program'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
