import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>Think of an issue that is causing you to weigh possible solutions. Describe it here:</h4>
    <Field
      name='causing-you'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Use the Fixes that Backfire template below to think through and identify the following:</h4>
    <ul>
      <li>Problem symptom</li>
      <li>Possible quick fixes that would make the symptom go away (at least temporarily)</li>
      <li>Unintended consequences that would backfire by increasing the problem symptom</li>
      <li>Notice the time delay symbol (//) between the “quick fix” and “unintended consequence”</li>
    </ul>
    <img
      className='template'
      src='/images/courses/habits/11-consequences/consequences-template.png'
      alt='Consequences Template'
    />

    <div className='template-questions'>
      <div className='template-pair'>
        <p className='diagram-identifier'>A.</p>
        <h4>Problem Symptom:</h4>
      </div>
      <Field
        name='symptom'
        component='textarea'
        className='reflection-input-large'
        placeholder='Enter your response here.'
      />
      <div className='template-pair'>
        <p className='diagram-identifier'>B.</p>
        <h4>Quick Fix:</h4>
      </div>
      <Field
        name='quick-fix'
        component='textarea'
        className='reflection-input-large'
        placeholder='Enter your response here.'
      />
      <div className='template-pair'>
        <p className='diagram-identifier'>C.</p>
        <h4>Unintended Consequence:</h4>
      </div>
      <Field
        name='u-i'
        component='textarea'
        className='reflection-input-large'
        placeholder='Enter your response here.'
      />
    </div>
    <h4>Tell the story of your Fixes that Backfire:</h4>
    <Field
      name='fixes-that'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
