import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { signupAction } from 'components/auth/auth.actions'
import PropTypes from 'prop-types'
import { createBrowserHistory } from 'history'
import { RedirectAuthComponent } from './AuthComponent'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

const history = createBrowserHistory()

// Select lists
const countryList = require('../../lib/lists/countries')

// Validation items
const required = value => value ? undefined : 'Required'
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined
const minValue = min => value =>
  value && value.length < min ? `Must be at least ${min} characters` : undefined
const minValue5 = minValue(5)
export const matchPasswords = (value, allValues) => {
  if (value !== allValues.password) {
    return "Passwords don't match"
  } else {
    return null
  }
}
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength80 = maxLength(80)
const maxLength40 = maxLength(40)
const maxLength20 = maxLength(20)

export const checkPassword = (value) => {
  if (value.length < 8) {
    return 'Password must be at least 8 characters'
  }
  if (value.search(/[a-z]/i) < 0) {
    return 'Your password must contain at least one letter.'
  }
  if (value.search(/[0-9]/) < 0) {
    return 'Your password must contain at least one digit.'
  } else {
    return undefined
  }
}

// Field for input
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <input {...input} placeholder={label} type={type} />
    {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
)

renderField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object
}

// Field for select menu
const renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
  <div>
    <label>{label}</label>
    <div>
      <select {...input}>
        {children}
      </select>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
)

renderSelectField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  children: PropTypes.any
}

const SignupPageView = ({ handleSubmit, error, submitting }) => {
  return (
    <div>
      <Header />
      <main className='signup-page'>
        <section className='photo-header signup'>

          <h1>
            Sign up has moved
          </h1>

          <img src='images/site/signup-icon.svg' />
        </section>

        <section className='white'>
          <p className='centered'>
            Please sign up for an account at <a href='https://waterscenterst.org/join'>https://waterscenterst.org/join</a> to access the Studio.
          </p>
        </section>

        <section className='grey' style={{ display: 'none' }}>
          <form onSubmit={handleSubmit}>

            <fieldset disabled={submitting}>

              <div className='form-field'>
                <label htmlFor='firstname'>
                  First Name*
                </label>

                <Field
                  name='firstname'
                  component={renderField}
                  type='text' validate={[required, maxLength40]}
                  placeholder='First Name*'
                />
              </div>

              <div className='form-field'>
                <label htmlFor='lastname'>
                  Last Name*
                </label>

                <Field
                  name='lastname'
                  component={renderField}
                  type='text'
                  validate={[required, maxLength80]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='city'>City*</label>
                <Field
                  name='city'
                  component={renderField}
                  type='text'
                  validate={[required, maxLength40]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='state'>State/Province*</label>
                <Field
                  name='state'
                  component={renderField}
                  type='text'
                  validate={[required, maxLength80]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='zip'>Zip</label>
                <Field
                  name='zip'
                  component={renderField}
                  type='text'
                  validate={[maxLength20]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='country'>Country*</label>
                <Field name='country' component={renderSelectField} validate={[required, maxLength80]}>
                  <option value='' disabled>select</option>
                  {countryList.map(option => <option key={option.id} value={option.value}>{option.value}</option>)}
                </Field>
              </div>

              <div className='form-field'>
                <label htmlFor='organization'>School/Organization*</label>
                <Field
                  name='organization'
                  component={renderField}
                  type='text'
                  validate={[required, maxLength80]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='position'>Title/Position</label>
                <Field
                  name='position'
                  component={renderField}
                  type='text'
                  validate={[maxLength80]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='username'>
                  Username*
                </label>

                <Field
                  name='username'
                  component={renderField}
                  type='text'
                  validate={[required, minValue5, maxLength20]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='email'>
                  Email*
                </label>
                <Field
                  name='email'
                  component={renderField}
                  type='email'
                  validate={[required, email, maxLength80]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='password'>
                  Password*
                </label>

                <Field
                  name='password'
                  component={renderField}
                  type='password'
                  validate={[required, matchPasswords, checkPassword]}
                />
              </div>

              <div className='form-field'>
                <label htmlFor='password'>
                  Confirm Password*
                </label>

                <Field
                  name='confirmpassword'
                  component={renderField}
                  type='password'
                  validate={[required, matchPasswords, checkPassword]}
                />
              </div>

              {error && <strong>{error}</strong>}

              <button className='blue' type='submit'>Sign Up</button>
            </fieldset>
          </form>

          <p className='centered'>
            Already have an account? <a href='https://waterscenterst.org/login'>Click here</a> to log in.
          </p>
        </section>

        <section className='white'>
          <p className='centered'>By signing up, you agree to our <a href='/terms'>Terms and Conditions</a> and <a href='/our-privacy-policy'>Privacy Policy</a>.</p>
        </section>
      </main>
      <Footer />
    </div>
  )
}

const SignupPageForm = reduxForm({
  form: 'SignupPage',
  onSubmit: signupAction
})(SignupPageView)

SignupPageView.propTypes = {
  handleSubmit: PropTypes.any,
  error: PropTypes.any,
  submitting: PropTypes.any
}

export const SignupPage = connect(
  (state, props) => props,
  (dispatch) => ({
    onSubmit: async (d) => {
      const result = await signupAction(d)
      dispatch(result)
      return history.goBack()
    }
  })
)(RedirectAuthComponent(SignupPageForm))
