export function NavLevel (selected, parentList, type) {
  return { selected, parentList, type }
}

export function getCurrentNavSetup (navLevels, endUrl = null, isEnd = false) {
  const result = {}
  // go through each level, starting at the last level (e.g. sections)
  // we start at the last level because we want to give preference to deepest next/prev
  // for example, if they were on a section, you would pick the next and prev section
  // but if they were on a lesson, you would pick the prev and next lesson
  for (let i = navLevels.length - 1; i >= 0; i--) {
    const level = navLevels[i]
    const selected = level.selected

    if (!selected) continue

    const parentList = level.parentList(i && navLevels[i - 1].selected)
    const matchIndex = parentList.indexOf(selected)

    if (!result.next) {
      const children = i === 0 && navLevels[1].parentList(selected)
      // if there is an end url, and no next item has been found up to the top level
      // but also, this is not the first page of a course, then use the end url (e.g. course-feedback)
      if (i === 0 && endUrl && !isEnd && navLevels[1].selected) {
        result.next = {
          url: `${navLevels[0].type}s/${selected.path}/${endUrl}`,
          type: navLevels[0].type,
          end: true
        }
      } else if (children && !isEnd && !navLevels[1].selected) {
        // if this is the top course/resource page, then show first section/lesson in that course as next
        // (rather than the default behavior, which would be to just show the next course/resource)
        result.next = navItem(navLevels, i + 1, children[0])
      } else if (matchIndex < parentList.length - 1) {
        // this is the "normal" logic, to get to next element in the list
        result.next = navItem(navLevels, i, parentList[matchIndex + 1])
      }
    }
    if (!result.prev) {
      const prev = matchIndex >= 0 && parentList[matchIndex - 1]
      const usePrev = isEnd ? selected : prev
      const children = i === 0 && usePrev && navLevels[1].parentList(usePrev)
      if (children) {
        if (!isEnd) navLevels[i].selected = prev
        result.prev = navItem(navLevels, i + 1, children[children.length - 1])
      } else if (prev) {
        result.prev = navItem(navLevels, i, prev)
      }
    }
    if (!result.id && selected.id) {
      result.fullId = selected.id
    }
    // e.g. result.course = selected
    result[level.type] = selected
    // e.g. result.courseIndex = matchIndex
    result[level.type + 'Index'] = matchIndex
  }

  return result
}

function navItem (navLevels, idx, newItem) {
  let url = ''
  for (let i = 0; i < idx; i++) {
    if (navLevels[i].selected) {
      url = `${url}/${navLevels[i].selected.path}`
    }
  }
  url = `${navLevels[0].type}s${url}/${newItem.path}`
  return { url, type: newItem.type || navLevels[idx].type }
}
