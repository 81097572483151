import * as React from 'react'
import { getCoursesNav } from 'components/courses/courseList'
import { NavButtons } from 'components/shared/nav-buttons'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

import { saveFeedback } from 'components/courses/course-parts/course-feedback/feedback.actions'

const required = value => value ? undefined : 'This field is required'

const renderField = ({ input, textarea, label, type, meta: { touched, error, warning } }) => (
  <div className='feedback-field'>
    <textarea className='reflection-input-large' placeholder='Enter your response here.' {...input} type={type} />
    {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
)
renderField.propTypes = {
  input: PropTypes.object,
  textarea: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object
}

const CourseFeedbackView = ({ course, isCompleted, handleSubmit, isLoading, isSaving }) => {
  const nav = getCoursesNav({ course }, true)
  return (
    <div>
      <Header />
      <div className='content'>
        {isLoading &&
          <div className='loading-area'>Loading...</div>}
        {!isCompleted && !isLoading &&
          <form onSubmit={handleSubmit}>
            <fieldset disabled={isSaving}>
              <div className='form-item'>
                <h3>Congratulations on completing this course!</h3>
                <p>Your feedback will help us ensure the Thinking Tools Studio delivers benefits to users.</p>
                <h4>What did you like best about this course?</h4>
                <Field
                  name='whatyoulike'
                  component={renderField}
                  validate={[required]}
                />
                <h4>How could this course be improved?</h4>
                <Field
                  name='improve'
                  component={renderField}
                  validate={[required]}
                />
                <h4>On a scale of 1-5 (1 being not confident at all, 5 being very confident) how would you rate your overall understanding of the topic(s) covered in the course?</h4>
                <Field
                  name='scale'
                  component={renderField}
                  validate={[required]}
                />
                <p>Are you enjoying your systems thinking learning journey? Show your appreciation by making a <a href='/donate'>donation</a>.</p>
                <p>The Thinking Tools Studio is a FREE service made possible because of donations from users like you. Your donation will support our mission of making systems thinking learning accessible to all.</p>
              </div>
              <div className='complete-button'>
                <button type='submit' className='form-submit'>
                  Submit Feedback
                </button>
              </div>
            </fieldset>
          </form>}
        <NavButtons nav={nav} />
        {!nav.next &&
          <div>
            <p>Congratulations — you’ve reached the final course offered at this time! More courses will be released soon.</p>
            <div className='end-nav'>
              <div className='nav-button'>
                <a href='courses/habits'>Back to the Habits courses</a>
              </div>
              <div className='nav-button'>
                <a href='courses/tools'>Back to the Tools courses</a>
              </div>
              <div className='nav-button right'>
                <a href='courses/archetypes'>Back to the Archetypes courses</a>
              </div>
            </div>
          </div>}
      </div>
      <Footer />
    </div>
  )
}
CourseFeedbackView.propTypes = {
  handleSubmit: PropTypes.any,
  course: PropTypes.object,
  isCompleted: PropTypes.any,
  isLoading: PropTypes.any,
  isSaving: PropTypes.any
}

export const CourseFeedback = ({ course }) => {
  const formId = course.id
  let FeedbackForm = reduxForm({
    form: formId,
    enableReinitialize: true
  })(CourseFeedbackView)
  FeedbackForm = connect(
    (state, props) => props,
    (dispatch, ownProps) => ({
      onSubmit: async (d) => {
        await dispatch(saveFeedback({
          formId,
          data: {
            ...d,
            isCompleted: true
          }
        }))
        dispatch(push('/courses/course-end'))
      }
    }))(FeedbackForm)
  return <FeedbackForm course={course} />
}
CourseFeedback.propTypes = {
  course: PropTypes.object
}
