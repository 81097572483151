import * as React from 'react'
import { getSessionAuth, goToDashboard, goToLogin } from 'components/shared/session'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

// Wrap a component to make it redirect to login page if an unauthenticated
// user tries to access teh component
export const AuthComponent = (Component) =>
  (props) => {
  // if the user is not logged in, redirect them to the login
    const { user } = getSessionAuth()
    if (!user) {
      return goToLogin()
    }
    return <Component {...props} />
  }

// obviously, the opposite of the above component
export const NoAuthComponent = (Component) =>
  (props) => {
  // if the user is already logged in, redirect them to the dashboard
    const { user } = getSessionAuth()

    if (user) {
      return goToDashboard()
    }
    return <Component {...props} />
  }

// obviously, the opposite of the above component
export const RedirectAuthComponent = (Component) =>
  (props) => {
  // if the user is already logged in, redirect them to the previous page
    const { user } = getSessionAuth()

    if (user) {
      return history.goBack()
    }
    return <Component {...props} />
  }
