import React, { useState } from 'react'
import PropTypes from 'prop-types'

export function ToggleImages (props) {
  const [image, setImage] = useState(props.images[0])

  const handleImageChange = (imageId) => {
    const nextImage = props.images.find(image => image.id === imageId + 1)
    return nextImage || props.images[0]
  }

  ToggleImages.propTypes = {
    images: PropTypes.array
  }

  return (
    <div className='toggle-container' style={{ backgroundImage: `url(${image.image})` }}>
      <button type='button' className='toggle-button' onClick={() => setImage(handleImageChange(image.id))}>
        Show Next Part of Story
      </button>
    </div>)
}
