import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-single'
        src='/images/resources/ladder-with-questions/ladder-with-questions.svg'
        alt='Ladder Primary 1'
      />
    </div>
  </div>
