import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/05-mental-models/iceberg.png'
      alt='Iceberg'
    />
    <p>The systems thinking iceberg is a framework that applies many of the systems
      thinking Habits to the understanding of complex systems. Each level calls for a
      specific way of thinking about a system of interest.
    </p>
    <p>This framework helps deepen our understanding of complex systems. The tip of
      the iceberg (the only visible piece above the water level) involves the
      day-to-day events and occurrences that you experience. This common but limited
      view produces a surface understanding of what a system is about. For example, a
      store manager can greet customers, observe how employees are serving those
      customers and assess inventory by looking at shelves and storage rooms. From
      these day-to-day events, she can get some sense of whether or not the business
      is thriving.
    </p>
    <p>Good managers are systems thinkers. They intentionally go below the surface
      of the waterline of the iceberg and view their business systems at a variety of
      levels. Each level of the iceberg below the waterline connects with specific
      Habits of a Systems Thinker.
    </p>
    <h3>Trends</h3>
    <p>Trends focus on the dynamics of a system. What are the essential elements
      that are changing over time? How are elements changing and what is causing those
      shifts? This level of the iceberg connects to the Habit <a href='/courses/06-patterns'>Observes How Elements Within Systems Change Over Time, Generating Patterns and Trends.</a>
    </p>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-workplace.svg'
      alt='Workplace Example'
    />
    <p>Obvious trends for a store manager include sales, total revenue, inventory
      and outcomes from price reductions. Broadening the boundary of what could be
      important when focusing on essential elements that change over time might be
      market research, customer needs, local conditions and competitor activity. In
      addition, trends in employee satisfaction, skill development, employee turnover
      and promotion opportunities are also important to a successful business.
    </p>
    <h3>Structure</h3>
    <p>Systems thinkers pay attention to what is causing the shape of trend lines.
      For our store manager, one obvious structure is the relationship between supply
      and demand. It is at this level that systems thinkers fully examine the design
      of their system and how one aspect affects another, which in turn influences
      others. When supply is high and demand is low, it is time to hold a sale and cut
      prices. On the other hand, when demand is high for a popular product, it is
      important to make efforts to order and stock that product to maintain customer
      satisfaction and revenue. The structure level helps show how important system
      elements (like inventory, customer demand, pricing and customer satisfaction)
      are interdependent. And the interdependent structures influence the previously
      identified patterns and trends. The Habit of a Systems Thinker <a href='/courses/08-structure'>Recognizes That A System’s Structure Generates Its Behavior</a> connects to this level.
    </p>
    <p>This structural understanding can help individuals like our store manager
      trace a potential ripple effect when a small change or adjustment generates
      other changes in seemingly unexpected places. For example, our manager may want
      to give careful attention to competitors’ efforts so that she can gain new
      insights and ideas that could positively influence the business. Encouraging
      positive relationships among the competition may seem counterintuitive to a
      business wanting to get ahead and beat out the competition, yet this
      relationship could provide strategies for initiating a positive ripple effect
      resulting in mutual benefits for all.
    </p>
    <h3>Mental Models</h3>
    <p>Mental models are an essential aspect of a system’s structure. Think about
      all of the stakeholders who contribute to and are affected by the system. When
      the various mental models from this diverse stakeholder pool are made explicit,
      a deeper understanding of how human elements contribute to the workings of the
      system become evident. It is difficult to fully understand a system without an
      appreciation of the different mental models of all involved. Without
      consideration of mental models, changes in systems structures (e.g. policies,
      laws, procedures) will only result in surface modifications. In order for the
      small business manager to best address her challenges, she should carefully
      consider the mental models of her employees, her customers and her competition.
      It’s also important that she gives conscious attention to the mental models she
      holds about the business. This human aspect of system structure is often
      considered the highest leverage area. When individuals embrace a mindset that is
      open, flexible and honest, a system will be adaptive and productive.
    </p>
    <img
      className='lesson-img'
      src='/images/courses/habits/05-mental-models/news-img.png'
      alt='Newspaper'
    />
    <p>Shifting gears a bit, consider the tension that exists globally because of
      vastly different mental models around serious issues like gun control,
      immigration, social justice, healthcare and religious freedom. These issues
      inundate our news feeds and social media sources daily. News events draw our
      attention to the day-to-day event level of the iceberg. Policy makers react to
      these events and struggle to create structures to help alleviate the existing
      tension. They look for structures that will help change the course of events and
      adjust the system to more desirable trends.
    </p>
    <p>The iceberg helps us realize that in order to change undesirable patterns of
      system behavior (e.g. violence, hate, inequity and blame), it is essential to
      rise above one’s own mental models and deepen the understanding of the system by
      considering a wide variety of mental models that drive how a system behaves.
      Isolated experiences, individual values, socio-economic position and culture are
      just some of the factors that influence the mental models that are formed across
      sectors. Without intentional efforts to surface and understand the mental models
      of diverse groups and individuals, a reliance on change in physical structure,
      like policies or laws, will only place a temporary bandage on the symptoms of
      ongoing problems. In order to sustain lasting positive change in systems, a more
      fundamental approach centered on the mental model level of the iceberg is
      essential.
    </p>
  </div>
