import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/14-leverage/course-img.png'
      alt='Leverage'
    />
    <p>
      Think of a time when you faced a complex challenge. At first the situation appeared daunting, perhaps with little hope for resolution. The ingenuity needed may not have been immediately apparent. Leverage could be viewed as the mechanical advantage or power gained by using different techniques while fishing, as shown in the illustration. The angle and amount of slack in the fishing rod and the stability and extra strength provided by the woman's foot on the rock are all points of leverage. Leverage could also be the personal ability to choose actions that intentionally influence people, events or decisions to resolve challenges.
    </p>
    <p>Consider this real-life tale of a family vacation.</p>
    <img
      className='system-icon'
      src='/images/courses/shared/icon-family.svg'
      alt='Family Example'
    />
    <p>This story of systems thinking in action describes a family faced with a seemingly impossible situation on a fishing trip in a very remote area of Mexico. The Weston family was traveling on a bumpy, narrow dirt road, pulling a boat on a trailer behind their truck. Excited to get to their chosen fishing spot on a lake near a small Mexican village, Mr. Weston was careful to manage the bumps with slow and steady driving. With little warning, the trailer hit a concealed pothole that jerked the tire and broke the leaf springs that held the rear axle of the trailer. With a thump, the truck and trailer quickly halted. The vehicle and the broken-down boat trailer, now in the middle of the dirt road, blocked passage. There were no services available for miles.</p>
    <p>The family considered their options. <em>Should we unhook the trailer, leave the boat in the middle of the road and drive for help?</em> As they were weighing possibilities, a car of young local men on their way to work approached the family. Seeing that the road was blocked and knowing they needed access to go to work, they spent time assessing the situation and offered to help. Using rope and wooden plank levers, they were able to hoist the boat off the trailer. After a visit to a nearby junkyard to obtain parts and a borrowed generator and welding machine, the resourceful helpers were able to repair the trailer.</p>
    <img
      className='lesson-img'
      src='/images/courses/habits/14-leverage/lesson-one-img2.jpg'
      alt='Truck pulling a boat'
    />
    <p>The local men’s ability to assess the situation and consider possibilities with hope and ingenuity demonstrated their innate systems thinking abilities. They showed how a variety of leverage actions to address what the Weston family considered an impossible situation could alleviate the problem so the family could get on their way with their boat in tow.</p>
    <p>In some cases, a lack of understanding of systems structure and minimal access to options can make it difficult to identify the leverage in a system. In this scenario, the good Samaritans who stopped and offered to help so that they could get to work in a timely manner expanded the pool of options beyond what was available to the Weston family. Systems thinkers do just that — increase their options when selecting leverage actions to address problems.</p>
    <div className='quote'>
      <p className='quote-text'>“Needle in a haystack’s easy – just bring a magnet.”</p>
      <p className='quote-author'> — Keith R.A. DeCandido, author</p>
    </div>
    <p>Author Keith R.A. DeCandido offers an image that shows how small actions can produce very helpful solutions to high stakes challenges.  A magnet is leverage in locating a very small piece of metal in a large pile of straw. The recognition of the power of a magnet in finding such a small object in a massive heap of hay is the way of a systems thinker.</p>
    <p>Systems thinkers have the advantage of tools to help deepen their understanding of systems, especially when things are not going as planned. Making a visual map of the system can help you identify leverage actions. You may want to revisit previous courses that introduce the visual mapping tools of systems thinking, including {/* <a href="">feedback loops</a> and */} <a href='/courses/03-stock-flow'>stock-flow maps</a>. These dynamic mapping tools help create pictures of systems and how they function. The added value of these maps is that they are dynamic. The map icons and arrows show a pathway of change and causality.</p>
    <img
      className='lesson-img'
      src='/images/courses/habits/14-leverage/lesson-one-img3.png'
      alt='Causal Map'
    />
    <p>The map to the right describes the family’s boat trailer challenge. Begin with the broken trailer and follow the arrows to better understand the conditions that helped solve their problem. Collaborative ingenuity from the good Samaritans who stopped to help, their access to spare parts and their mechanical know-how contributed to the trailer’s repair. The visualization of a broken system provides insights that inform next steps, strategies for improvement and access to resources that may not be readily available. When things are broken or produce less than desirable results, the first place to look is the structure of the system. Causality is an important part of system structure, as the causal relationship between the parts of a system will help determine how the system works. The example of the trailer shows causal feedback as the efforts to address the broken trailer actually fed back to alleviate the problem.</p>
    <p>Causal maps are effective tools to help make system structure visible. Feedback loops provide an interdependent picture of a system and can lead to the identification of leverage actions. As noted in a previous course, systems thinkers recognize that a <a href='/courses/08-structure'>system’s structure generates its behavior</a>.</p>
    <p>A deep understanding of system structure is necessary when determining leverage actions.</p>
  </div>
