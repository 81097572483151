import { handleActions, combineActions } from 'redux-actions'
import { ActionTypes } from './password.actions'

export const passwordReducer = handleActions(combineActions(ActionTypes.ResetSuccess, ActionTypes.ForgotSuccess), {
  next: (state, { payload }) => ({
    ...state,
    ...payload
  })
}, {
  auth: false
})
