import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='large-img'
        src='/images/resources/habits-single-page-italian/onepage-habits-italian.png'
        alt='Habits: Single Page Version: Italian Translation'
      />
    </div>
  </div>
