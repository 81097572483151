import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise04 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Reflect on a recent day when you noticed both your bucket filling (when you felt supported with boosts to your personal well-being) and your bucket depleting (when you had moments of disappointment).</p>
    <h4>Using the bucket as a metaphor, describe the changes to your own personal bucket of well-being. You might even decide to draw a behavior-over-time graph on a piece of paper to show the changes. Visit the Habits course, <a href='/courses/06-patterns'>Patterns and Trends</a> and/or the tools course, <a href='/courses/01-behavior-over-time-graphs'>BOTGs</a> if you need a refresher.</h4>
    <Field
      name='bucket'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <Field
      name='bucketBotg'
      component={FileUpload} image
      instructions='If you decided to draw a BOTG, take a photo and upload it below.'
    />
    <h4>What were some of the things that caused filling or increases? Identify as many possibilities as you can. These ideas represent the inflow or the faucet.</h4>
    <Field
      name='things'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What caused draining or decreases? Identify as many possibilities as you can. These ideas represent the outflow or the drain.</h4>
    <Field
      name='draining'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What are some of the ways you intervened to maintain a healthy well-being — opening the faucet, closing the drain, or adjusting some combination of the two?</h4>
    <Field
      name='some-ways'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
