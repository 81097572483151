import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-single'
        src='/images/resources/botg-with-questions/botg-with-questions.svg'
        alt='BOTG with Questions'
      />
    </div>
  </div>
