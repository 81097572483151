import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <p>
      Considering that balancing feedback is goal-seeking and oftentimes oscillating, a standard balancing causal loop structure is a good way to begin to practice drawing balancing loops.
    </p>

    <p>
      Let’s start with a simple example.
    </p>

    <p>
      When you are hungry, it causes you to want to eat. After you finish eating, your hunger decreases, which also decreases your desire to eat. After a while, when you have stopped eating, your hunger will return and gradually increase. Eating is what you do to help maintain balance in your body. Bodies need a fuel source, and feelings of hunger tell you that your body needs food. Here is a simple picture of this balancing story. Notice the <strong>B</strong> in the middle of the loop labels it as <em>balancing</em>.
    </p>

    <img
      className='lesson-img med-img block'
      src='/images/courses/tools/08-cld-balancing/eating-hunger.svg'
      alt='Hunger loop'
    />

    <p>
      In the hunger and eating scenario, balancing feedback strives to close a gap or maintain a goal.  The behavior-over-time graphs for balancing loops show either goal seeking trends or oscillation trends.  See examples below.
    </p>

    <img
      className='med-img med-height'
      src='/images/courses/tools/08-cld-balancing/goal-seeking.svg'
      alt='Goal-Seeking'
    />

    <img
      className='med-img med-height'
      src='/images/courses/tools/08-cld-balancing/oscillation.svg'
      alt='Oscillation'
    />

    <p>
      Let’s revisit body temperature since the temperature of our bodies is a good example to understand this concept. We begin with a goal or desired state of the system. For example, 98.6˚F would be the desired state for the typical person.  Then we identify the current state of the system, meaning how would we describe the way the system is right now.  Imagine you have been outside in very cold weather without the right clothing to keep you warm (and your body core at 98.6˚F). The difference between your desired state and your current state is a gap of about two degrees, leaving you at 96.8˚F.
    </p>

    <img
      className='lesson-img med-img block'
      src='/images/courses/tools/08-cld-balancing/body-temp-1.svg'
      alt='Body Temperature'
    />

    <p>
      In order to minimize the gap and change the current state, strategies to intervene are necessary.  You could put warmer clothes on or seek a warmer environment.  Then, as your body temperature rises, the gap will shrink and, eventually, you will no longer need to pursue intervention strategies.  See the completed causal loop drawing below. Again, notice the <strong>B</strong> in the middle of the loop labels it as <em>balancing</em>.
    </p>

    <div className='flex'>
      <img
        className='large-img'
        src='/images/courses/tools/08-cld-balancing/body-temp-2.svg'
        alt='Body Temperature'
      />

      <div className='exerpt'>
        <h4>
          Loop Labels:
        </h4>

        <p>
          As a reminder, the symbol next to each arrowhead distinguishes the nature of the causal connection.  You can choose 2 different ways to label arrowheads in a causal loop. You can use <strong>S and O</strong> or <strong>+ and -</strong> labels
        </p>

        <p>
          <strong>S or +</strong>: The causal relationship moves in the same direction or the effect adds to the cause (e.g. as cause goes up, the effect goes up or as the cause goes down, the effect goes down.)
        </p>

        <p>
          <strong>O or –</strong>: The causal relationship moves in the opposite direction or the effect moves inversely to the direction of the cause.  (e.g. as the cause goes up, the effect goes down or as the cause goes down, the effect goes up.)
        </p>

        <p>
          In addition, Reinforcing loops have an <strong>R</strong> in the center and Balancing loops are labeled with a <strong>B</strong>.
        </p>
      </div>
    </div>
  </div>
