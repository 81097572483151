import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <h3>
      Abstract:
    </h3>

    <p>
      Schools across the United States and throughout the world are actively pursuing the advantages of integrating systems thinking in classrooms and schools.
      The benefits of such approaches are both immediate to student achievement goals and long-lasting as systems citizenry are developed. While many educators
      view secondary schools as logical entry points for the introduction of systems thinking concepts and tools, it can be argued that children as young as
      five years old benefit from systems thinking classrooms. Building a systems thinking capacity in learners of all ages is a worthy investment as
      educators prepare the young people of today so they are ready to solve the problems of tomorrow.
    </p>

    <p>
      <strong>Author:</strong> Tracy Benson
    </p>
  </div>
