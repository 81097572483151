import * as React from 'react'
import { Field } from 'redux-form'
import { FileUpload } from 'components/shared/file-upload'
import { ToggleImage } from 'components/shared/toggle-image'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Archetype</h3>

    <p>
      To begin, <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/repression-and-revolution-blank-so.pdf' target='_blank' rel='noopener noreferrer'>download and print a blank Repression and Revolution archetype template.</a>
    </p>

    <p>
      Next, select one or more of the three examples presented in Lesson 2: Case in Point.
    </p>

    <p>
      Now it is time to practice the Repression and Revolution archetype. Fill in each of the boxes on your blank archetype template and then use it to retell the story.
    </p>

    <Field
      name='tragedy-template'
      component={FileUpload} image
      instructions='Once you have filled in the template, take a photo and upload it below.'
    />

    <p>
      There is always more than one way to draw an archetype.  However, in order to provide you some feedback, one version of each of the scenarios is available to you. After creating your version of the archetype diagram, click on the appropriate link below to see one potential version of the story.
    </p>

    <div className='archetype-examples'>
      <div>
        <ToggleImage
          src='/images/courses/archetypes/09-repression-revolution/workers-unite.svg'
          className='med-img'
          alt='Workers Unite'
          showText='Workers Unite'
          hideText='Hide Example'
        />
      </div>

      <div>
        <ToggleImage
          src='/images/courses/archetypes/09-repression-revolution/cancelled-dance.svg'
          className='med-img'
          alt='Cancelled Dance'
          showText='Cancelled Dance'
          hideText='Hide Example'
        />
      </div>

      <div>
        <ToggleImage
          src='/images/courses/archetypes/09-repression-revolution/revolutionary-war.svg'
          className='med-img'
          alt='Revolutionary War'
          showText='Revolutionary War'
          hideText='Hide Example'
        />
      </div>
    </div>
  </div>
