import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <h3>
      Workers Unite
    </h3>

    <p>
      The workers at a food processing plant felt that their working conditions were not safe and took their concerns to their supervisors. Knowing that it would cost both time and money to make the improvements to conditions that the workers asked for, management declared that nothing could be done at this time and said that anyone who complained or didn’t show up for their next work shift would lose their job. Some people reported to work the next day, which made the managers feel they may have squelched the uprising, but a number of people stayed away from work. Word circulated in the community that people from the plant were finally “doing something about the problem,” i.e. had complained about the working conditions and were refusing to go to work in the face of being fired. Day by day, the number of people not reporting to work at the plant continued to grow.
    </p>

    <h3>
      Cancelled Dance
    </h3>

    <p>
      In the past two weeks, there has been an increase in locker break-ins and graffiti in the hallways in the building housing the 11th graders at the local high school.  Although the class counselor, the vice-principal and key teachers have implored students to have the guilty parties come forward, no one has taken responsibility. Sensing a threat to orderliness at the school, the administration announces that the 11th graders’ eagerly anticipated year-end dance, i.e. prom, will be cancelled. Two students come forward and admit to committing some acts of vandalism, but they won’t say if they know who broke into lockers. The prom remains cancelled. The majority of the 11th grade class, along with their parents who have helped purchase prom dresses, suits, etc., are outraged about the cancellation and vehemently protest to the administration.  They come to the school, and when the dance isn’t reinstated, they go to the local media to air their complaint.
    </p>

    <h3>
      Revolutionary War
    </h3>

    <p>
      From 1765-1773, in what is now the northeastern region of the United States of America, the British authorities levied a series of taxes on the colonists. The colonists objected to paying  taxes to Britain, based on the principle of “no taxation without representation,” since the colonists weren’t represented in the British Parliament. Feeling increasingly threatened by the colonists’ protests and boycotts, the British authorities eventually passed laws known as the Intolerable Acts and the Boston Port Act. The Boston Port Act closed Boston’s port to trade, choking off the locals’ livelihoods. Although some of the colonists remained loyal to Britain, i.e. loyalists, this repressive move by the British authorities in Boston fueled opposition to the British, not only in Boston but also in neighboring regions that feared they could be the next British target. By closing the Boston harbor, the British fueled opposition in Boston and beyond.  The British were about to face a revolutionary war!
    </p>
  </div>
