import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <h3>Materials:</h3>

    <ul>
      <li>
        <a href='https://www.mindmatters.edu.au/docs/default-source/learning-module-documents/j4383_mm_modulesummary-1-4-relationships-and-belonging-v6.pdf?sfvrsn=0' target='_blank' rel='noopener noreferrer'>“Module 1.4 – Relationships and Belonging"</a> published by Mind Matters
      </li>

      <li>
        There’s also a follow-up to the article that gives simple steps to follow – more concrete examples of how to do what they talk about in the first article: <a href='https://www.mindmatters.edu.au/docs/default-source/learning-module-documents/mm-module1-4_simplesteps_v5.pdf' target='_blank' rel='noopener noreferrer'>https://www.mindmatters.edu.au/docs/default-source/learning-module-documents/mm-module1-4_simplesteps_v5.pdf</a>
      </li>

      <li>
        Large sheets of butcher paper and markers to create stock-flow maps and analogies (one sheet per group)
      </li>
    </ul>

    <h3>
      Visuals:
    </h3>
    <div className='sense-visuals'>
      <img
        src='/images/resources/sense-of-belonging/sense-visual.png'
      />

      <img
        src='/images/resources/sense-of-belonging/sense-of-belonging-stock.svg'
      />

      <img
        src='/images/resources/sense-of-belonging/sense-visual-2.png'
      />
    </div>

    <h3>
      Lesson Structure:
    </h3>

    <p>
      This downloadable <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/sense-of-belonging.pptx' target='_blank' rel='noopener noreferrer'>powerpoint</a> matches the steps below:
    </p>

    <h4>
      Session 1:
    </h4>

    <ol>
      <li>
        Activator: Post bar graphs showing student responses to the survey question: How much do you feel you belong at your school? Groups discuss the question: What do you notice?
        Following small group discussion, move to whole group discussion focused on a couple of interesting trends revealed by the data (overall drop in sense of belonging from 6th to 7th to
        8th grade as well as an increase in the number of students who felt they “completely belong” from 6th to 7th to 8th grade).
      </li>

      <li>
        Discuss – What is the general trend? – create a BOTG based on what they observe. Then ask – What kind of graph do we want? Consensus should be to either have a graph that remains
        steady, or ideally increases over time.
      </li>

      <li>
        Go through “Stages of Knowing” and ask people to self-assess when it comes to stock-flow mapping.
      </li>

      <li>
        Share/discuss the idea that all models are “wrong,” i.e. don’t represent all details and dynamics of the systems they are simulating. Our goal is not to create a model that is “correct.”
        In fact, all the models we create will be wrong, and that’s okay. Our goal is to create models that are useful – that help us understand what’s going on more completely so we can be
        strategic and effective in our approach to the situation. (Note: This seems to be especially important when working with adults so they feel free to make mistakes as they work and
        not get wrapped up in worrying about doing things the “right” way.)
      </li>

      <li>
        Go through the first four steps in the process – stocks, flows, converters, connectors, and feedback. Explain using the examples to illustrate key concepts (starting with one or two
        stocks, keeping the labels of flows simple, thinking carefully about labels for connectors and how they fit into the system, making sure to consider how the stock is impacting the
        system – adding feedback).
      </li>

      <li>
        While groups work, circulate and ask questions as needed, helping people add clarity. We had about 20 minutes for groups to work on their stock-flow maps.
      </li>

      <li>
        If time, have them do a little revising (step 5). We wound up doing more of this during the second session.
      </li>

      <li>
        Make sure groups identify where they feel there’s leverage in the system. We used this as the summarizer for the first session and had groups share out one or two key leverage points.
      </li>
    </ol>

    <h4>
      Session 2:
    </h4>

    <ol>
      <li>
        Begin with a quick review of what was done during the first session. Make sure stock-flow maps are available to groups.
      </li>

      <li>
        Task #1 – review and revise stock-flow maps.
      </li>

      <li>
        Task #2 – on the other side of the butcher paper, come up with an analogy that captures key elements of the overall system and helps pull all the ideas together. Discuss how
        school efforts can often feel disconnected and disjointed and we may not see how they all fit together. This exercise should help people make sense of how things fit together.
      </li>

      <li>
        Have each group share their analogies.
      </li>

      <li>
        Stock-flow maps and analogies should be collected. These are something that can be revisited at various times throughout the year as needed. The stock-flow maps can be used to guide efforts aimed at increasing sense of belonging – for example, by picking a few of the identified leverage actions to focus on throughout the year.  Analogies can be posted in a public place where they’re visible reminders to people of the big picture as they deal with the everyday issues they face.
      </li>
    </ol>

    <p>
      <strong>Author:</strong> Brian Bindschadler, ELA teacher at Orange Grove Middle School, Tucson, Arizona
    </p>
  </div>
