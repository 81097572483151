import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <p>
      When talking through a Drifting Goals archetype, think carefully about the goal and the actual state of the system.
      Identifying the goal and current state of the system will allow you to determine the gap that exists in the system.
      Accurately assessing how large the gap is between the goal and the current state of your system will be crucial in
      determining where the <a href='/courses/14-leverage'>leverage</a> lies as you choose what corrective actions to take. You, or you and your team, will
      also want to consider how long of a time delay you will want to allow for the chosen strategies to act upon your
      system to close the gap. Consciously planning to let the corrective strategies affect the gap, and scheduling when
      you’ll <a href='/courses/15-successive-approximation'>check on results</a> along the way, will help keep you from succumbing to the immediate pressure to lower your goal.
    </p>

    <h4>
      Questions to Help You Reflect on This Archetypical Story:
    </h4>

    <ul>
      <li>
        What checks and balances can we put in place to ensure that our goals don’t erode over time?
      </li>

      <li>
        After implementing corrective actions, how can we individually and organizationally hold the tension and encourage
        patience, knowing that delays may exist before increases in performance occur?
      </li>

      <li>
        What structures can we implement to prompt us to raise, rather than lower, our goals over time?
      </li>
    </ul>

    <p>
      Everyone involved must be willing to apply necessary corrective actions/strategies and wait to have them affect the
      system, before giving in to any pressure to lower the goal. By practicing the Habits
      of <a href='/courses/10-time-delays'>recognizes the impact of time delays</a> when exploring cause and effect relationships,
      as well as <a href='/courses/12-considers-issue'>considering an issue fully and resisting the urge to come to a quick conclusion</a>, you’ll become much more likely to
      focus on corrective actions and their slow effects than on more quickly lowering the original goal.
    </p>

    <div className='habits-row'>
      <img
        className='lesson-img small-img right clear'
        src='/images/shared/habit-cards/shadow/leverage.png'
        alt='Leverage'
      />

      <img
        className='lesson-img small-img right clear'
        src='/images/shared/habit-cards/shadow/successive-approx.png'
        alt='Successive Approximation'
      />

      <img
        className='lesson-img small-img right clear'
        src='/images/shared/habit-cards/shadow/time-delays.png'
        alt='Time Delays'
      />

      <img
        className='lesson-img small-img right clear'
        src='/images/shared/habit-cards/shadow/considers-issue.png'
        alt='Considers Issue Fully'
      />
    </div>
  </div>
