import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <img
      id='exercise'
      src='/images/courses/habits/10-time-delays/exercise.svg'
      alt='Exercise'
    />
    <p>When using causal loops to show how elements in a system interact, you sometimes come across a connection that is not immediate. For example, when you begin a personal exercise program, there is a delay between beginning your efforts and an improved level of fitness. Unfortunately, fitness doesn’t improve overnight!</p>
    <p>In causal loops, the symbol of parallel line segments // placed in the middle of an arrow tail represents a time delay between the cause and effect relationship. The // symbol reminds a systems thinker to consider the impact of time delays. In the case shown, as fitness level increases, so does personal satisfaction, which could motivate a person to exercise more. But, as mentioned before, it takes time for exercise to cause a noticeable impact on the level of fitness.</p>
    <p>Systems archetypes use causal loops to tell stories that reoccur across multiple settings and time periods.</p>
    <img
      className='lesson-img'
      src='/images/courses/habits/10-time-delays/tragedy-commons.png'
      alt='Tragedy Commons'
    />
    <p>One example of a systems archetype is the Tragedy of the Commons, named after the familiar stories it tells. The commons still exist in parts of the United Kingdom. These historic parcels of land, which have remained largely undisturbed through the centuries, are remnants of medieval times when people relied on commons for their survival. The commons are land where the owners of nearby properties all have rights to graze animals or collect wood, for example. Those rights still exist, although they are not exercised as they were in the past, in part because at one time the parcels were so over-grazed that this once fertile land was rendered virtually useless. In the story of the English commons, both Famer A and Farmer B are using this grazing space and making significant profit as a result. Total use of the land is represented by the amount of time and number of animals both farmers spend grazing on the common land.</p>
    <p>Over time (//) their individual gain decreases because use of the land has exceeded the resource limit (i.e. amount of grass on the common land). As a result, the net gain of each farmer begins to decrease until the land is unusable and neither farmer can successfully make a profit using the land.</p>
  </div>
