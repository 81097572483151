import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/tools/03-stock-flow/lesson-three-img.jpg'
      alt='Egg Factory Daily Production'
    />
    <p>The stock is the accumulation and the flow is the rate; however, in a complex system that is not always an easy distinction to make.</p>
    <p>Both stocks and flows represent the same element in a system (e.g. water in the bathtub, money in the bank). They are parts of the same story. However, stocks are more similar to nouns, representing an accumulation. Flows are more similar to verbs. As a rate, flows show how quickly or slowly an action is occurring.  For example, the birth and death rates (flows) affect how quickly or slowly a population increases or decreases.</p>
    <p>So, which came first the chicken or the egg?  That is not actually the question here, but chickens laying eggs is a good way to clarify the difference between stocks and flows.</p>
    <p>Chickens lay a certain number of eggs per day. That is the rate at which they lay the eggs. This rate can be affected by a number of things: age of the chickens, weather, the presence of stressors like predators lurking nearby, and so on.  The rate can be represented by a graph like the one shown.</p>
    <p>If you were an egg farmer, you might want to monitor the rate of egg production, but you would also be interested in total number of eggs produced over a period of time, let’s say a month.  The total number of eggs laid is an accumulation or a stock.  The total eggs laid in a month can also be graphed, as shown.</p>
    <p>In this example the stock and flow graphs are both telling the same story and represent a single element in that story — eggs. The stock-flow map for this system might look like this:</p>
    <img
      className='egg-stock-flow'
      src='/images/courses/tools/03-stock-flow/lesson-three-img2.svg'
      alt='Egg Factory Daily Production Stock Flow Map'
    />
    <p>Now it is time to apply stocks and flows to your own system of interest.</p>
  </div>
