import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>The ability to identify circular cause and effect relationships is an essential Habit of a Systems Thinker. As you’ve learned, causal loops are often called feedback loops because the cause doesn’t merely stop at influencing the effect. The effect feeds back and initiates a change in the initial cause. Consider the examples below. Draw a causal loop on a piece of paper for each pair and identify the loop as either reinforcing or balancing. If it is reinforcing, place an “R” in the center. If it is balancing, place a “B” in the center.</p>
    <p>Talk through your loop two times:</p>
    <div className='uploads'>
      <div className='upload'>
        <h4>Causal Pair #1: Skills and Confidence</h4>
        <Field
          name='causalPair'
          component={FileUpload} image
          instructions='Draw the loop on paper and upload below.'
        />
      </div>
      <div className='upload'>
        <h4>Causal Pair #2: Stress and Coping Strategies</h4>
        <Field
          name='causalLoop'
          component={FileUpload} image
          instructions='Draw the loop on paper and upload below.'
        />
      </div>
    </div>
    <h4>Next, consider the following variables that could be important to your organization:</h4>
    <ul>
      <li>Collaboration</li>
      <li>Morale</li>
      <li>Trust</li>
      <li>Quality of Communication</li>
      <li>Level of Commitment</li>
    </ul>
    <h4>Choose two or more of the above variables and sketch a causal feedback loop on a piece of paper. Use the drawing to think through your mental model of what is important in the workplace.</h4>
    <Field
      name='storyLoop'
      component={FileUpload} image
      instructions='Draw the loop on paper and upload below.'
    />
    <h4>Tell the story of your loop by describing the causal connections.</h4>
    <Field
      name='story-loop'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <p>Through practice, systems thinkers develop the ability to “sense circular causality” and identify the type of dynamic behavior: reinforcing or balancing. The capacity to sense circular causality builds a deep level of understanding of simple and complex systems. It influences the words we choose to use and the actions we take. When people understand the circular nature of cause and effect, they are better able to recognize cause and effect relationships and anticipate the subsequent dynamics these relationships create.</p>
  </div>
