import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

const Systems = [
  'well-being',
  'workplace',
  'school',
  'community',
  'family'
]

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <h4>Choose a system of interest:</h4>
    <div className='system-select'>
      {Systems.map(s =>
        <div key={s} className={`system-select-item ${s}`}>
          <Field name='systemSelect' id={`ss-${s}`} component='input' type='radio' value={s} />
          <label htmlFor={`ss-${s}`}>{s}</label>
        </div>
      )}
    </div>
    <p>Draw a stock-flow map on a piece of paper. Select an important accumulation (stock) that is part of that system of interest. An accumulation can both increase and decrease. Think about the elements of your chosen system that you can see, feel, count or measure as amounts that change over time.</p>
    <ol>
      <li>Draw the stock. Consider possible inflow and/or outflows of the stock.</li>
      <li>Draw and label the flows, and feel free to use simple terms like increasing and decreasing.</li>
      <li>Create converters and connectors. Think about what causes this accumulation to increase or decrease. </li>
      <li>Draw connector arrows to show causal connections between other parts of your map, e.g. a change in one convertor may cause a change in another convertor. Ask, “if the accumulation changes, what is the effect on the inflow and the outflow?”</li>
      <li>Share your map with another person by telling its story. Begin with the stock and talk through the causal connections and their impact on the system. After you finish sharing, discuss areas of possible leverage actions. Ask, “if we were to change only one part of the map, where would we see the biggest impact on our key stock?”</li>
    </ol>
    <Field
      name='completedStockFlowMap'
      component={FileUpload} image
      instructions='Upload a photo of your completed stock-flow map below.'
    />
  </div>
