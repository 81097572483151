import * as React from 'react'

export const Lesson01s01 = () =>
  <div className='content'>
    <p>This introductory course is for anyone who wants to actively learn about and
      engage in the process of becoming a systems thinker. The Habits of a Systems
      Thinker and systems tools that connect to them help develop deep and practical
      understanding of the world. No matter what your experience is with systems
      thinking, whether you are a novice or a skilled practitioner, this online course
      offers a workable approach to your personal development.
    </p>
    <p>Designed as a hands-on experience, each course highlights one
      of 14 Habits of Systems Thinking. The Habits of a Systems Thinker are the
      foundational building blocks that define and describe the practices of a systems
      thinker. Whether you want to develop your leadership capabilities, coaching or
      teaching expertise or your parenting skills, the intention is to provide a wide
      and relevant practice field. The Habits offer practical ways of thinking that
      inform action. They provide a framework to help you reflect on your strengths
      and identify areas of growth. Visit the <a href='/cards'>Habits cards</a> section of the Studio where you will find all of
      the Habits of a Systems Thinker in one place. We suggest referring to this page often, as it will be a helpful
      resource as you make your way through the courses
    </p>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/01-introduction/globe-img.png'
      alt='Globe'
    />
    <h3>The Habits section of TTS includes a course for each of the 14 Habits of a Systems Thinker:</h3>
    <ul>
      <li>Makes meaningful connections within and between systems</li>
      <li>Seeks to understand the big picture</li>
      <li>Changes perspectives to increase understanding</li>
      <li>Considers how mental models affect current reality and the future</li>
      <li>Observes how elements within a system change over time, generating patterns
        and trends
      </li>
      <li>Surfaces and tests assumptions</li>
      <li>Recognizes that a system’s structure generates its behavior</li>
      <li>Identifies the circular nature of complex cause and effect relationships</li>
      <li>Recognizes the impact of time delays when exploring cause and effect
        relationships
      </li>
      <li>Considers short-term, long-term and unintended consequences of actions</li>
      <li>Considers an issue fully and resists the urge to come to a quick conclusion</li>
      <li>Pays attention to accumulations and their rates of change</li>
      <li>Uses understanding of system structure to identify possible leverage actions</li>
      <li>Checks results and changes actions if needed: “successive approximation”</li>
    </ul>
    <h3>Each course contains:</h3>
    <ul>
      <li>Descriptions, stories and examples related to 5 sample systems</li>
      <li>Opportunities to help you practice the Habit include reflection questions
        and strategies to build your understanding and skill
      </li>
      <li>Systems thinking tools: behavior-over-time graphs, stock-flow maps,
        causal loops, ladders of inference, connection circles and icebergs that apply
        to specific Habits, along with practice exercises with the tools
      </li>
      <li>Connections to other Habits of a Systems Thinker because no one Habit exists
        in isolation
      </li>
    </ul>
  </div>
