import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>Think of a time when you were involved with a change effort in order to deliver benefits to others. Describe that time and the process you went through when making the change. You may want to refer to Deming’s PDSA cycle referenced in Lesson 1.</h4>
    <Field
      name='change-effort'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What benefits did the recipient(s) receive?</h4>
    <Field
      name='did-receive'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>When the benefit(s) were received, how did you feel? How did you respond?</h4>
    <Field
      name='benefits-received'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
