import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <h3>Habits of a Systems Thinker Self-Assessment for Individuals</h3>
    <h4>Directions:</h4>
    <p>Review each of the 14 Habits of a Systems Thinker using the cards or <a href='https://thinkingtoolsstudio.waterscenterst.org/cards' target='_blank' rel='noopener noreferrer'>digital cards</a>. Spend time looking at the front and back of each card.  Place or sort the cards into three piles described below (if you are using digital versions of the cards, you can sort by jotting down the Habit names):</p>
    <img
      className='lesson-img small-img'
      src='/images/resources/host-self-assessment/Cards1.png'
      alt='Scattered Cards'
    />
    <ol>
      <li>Strengths
        <p><b>Habits of a Systems Thinker that you practice regularly.</b> These Habits are your strengths, and you can describe through a story or anecdote how you have used each of these selected Habits.</p>
      </li>
      <li>Need Practice
        <p><b>Habits of a Systems Thinker that you see as growth areas.</b> These Habits either need more practice or they do not come naturally and therefore need special attention.</p>
      </li>
      <li>Don’t Understand
        <p><b>Habits of a Systems Thinker that you don’t understand.</b> These Habits have language or concepts that you do not fully understand. This pile is not important for this exercise.</p>
      </li>
    </ol>
    <h3>Reflection Questions:</h3>
    <p>Reflect on the following questions and choose a method to document your responses — a journal is a great option and it will be beneficial to share your reflections orally with a partner or in a small group setting.</p>
    <ul>
      <li>When you considered your strengths, which Habits stood out to you and why?</li>
      <li>Were some Habits strong for work, but not as strong for life outside of work, or vice versa? Why?</li>
      <li>As you think of examples when you have actively practiced a Habit, list some adjectives that describe how you felt as you recalled those experiences. Did you feel capable, innovative, transparent, vulnerable, mindful, etc.?</li>
      <li>As you identified Habits that you consider growth areas, what were some of the contexts that came to mind? Situations with particular people? Conditions that were stressful? Times when you were going through a transition?</li>
      <li>What strategies or practices will help you address your growth areas?</li>
    </ul>
    <img
      className='right small-img clear'
      src='/images/resources/host-self-assessment/Cards2.png'
      alt='Scattered Cards'
    />
    <h3>Habits of a Systems Thinker Self-Assessment in a Group Setting</h3>
    <ol>
      <li>Make the same three piles of cards from the Individual Habits Self-Assessment exercise.</li>
      <li>Be prepared to tell a story/personal example for two of the Habits you selected for your strength pile.</li>
      <li>Everyone will stand in an open area and select a partner to exchange one story with. Once this set of partners has each shared their stories, they will find a second person to partner with. Each person will then share their second story. When participants have finished sharing two stories and listening to stories from two different partners, each person will find a place to sit down and make some notes about things learned from the interactions.</li>
      <li>Reflection questions and small group process: depending on the comfort level of the group, pose some of the reflection questions from the individual exercise and use these questions to process the experience.</li>
    </ol>
  </div>
