import { createFormComponent } from './FormComponent'

export function addFormState (l, childKeys, prefix, endComponent = null) {
  return l.map(x => {
    // return the same object, but with the id set
    const result = {
      ...x,
      // id is the sum of all parent ids, plus your own path
      // if you have no parents, then id is just our path
      id: prefix ? `${prefix}.${x.path}` : x.path
    }
    // if there are any children, set ids on them too
    if (childKeys.length) {
      const nextChildKey = childKeys[0]
      const kids = x[nextChildKey]
      if (Array.isArray(kids)) {
        result[nextChildKey] = addFormState(kids, childKeys.slice(1), result.id)
      }
    }

    if (x.component) {
      result.component = createFormComponent(result.id, x.component, x.form)
    }
    return result
  })
}
