import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <h3>
      Tell the Story:
    </h3>

    <img
      className='lesson-img small-img'
      src='/images/shared/habit-cards/shadow/consequences.png'
      alt='Considers short term, long term, and unintended consequences of actions'
    />

    <img
      className='lesson-img small-img'
      src='/images/shared/habit-cards/shadow/perspectives.png'
      alt='Changes Perspectives to Increase Understanding'
    />

    <p>
      When talking through an “accidental adversaries” story, it is helpful to consider what triggered the shift from collaboration to competition.  It could be an event, a policy that surfaces a gap, or performance report that causes individuals or parties to take actions that unintentionally obstruct the success of others.  Raising awareness of a shift from a collaborative, <em>win-win</em> working relationship to a competitive, <em>win at the possible expense of another</em>, is an important part of telling the story and can be helpful when reflecting on the current situation.  It is helpful to tell the story from the other party’s perspective. By <a href='/courses/04-perspectives'>changing perspectives to increase understanding</a>, both parties can revisit the foundation of their collaborative efforts to work together and see the dynamics play out from the other’s point of view. The “accidental” aspect of this archetype indicates that unintentional actions that obstruct can catch us by surprise. When people <a href='/courses/11-consequences'>increase their awareness of the unintended effects of their actions</a>, resolutions can be more readily available.
    </p>

    <h3>
      Questions to Help You Reflect on Your Archetypical Story:
    </h3>

    <ul className='colorpoints left'>
      <li>
        <em>What could either or both parties have done to maintain a win-win collaborative relationship that focused on the common goal?</em>
      </li>

      <li>
        <em>As you consider taking action, how can you avoid being driven by pain-avoidance or inconvenience to focus on addressing deeper concern?</em>
      </li>

      <li>
        <em>How can we increase our self-awareness about the consequences of actions we choose to take to achieve success?</em>
      </li>

      <li>
        <em>What questions should we be asking to help us consider how our actions impact others?</em>
      </li>

      <li>
        <em>When possible trigger events occur, how can we work together to minimize the potential accidental adversary dynamics?</em>
      </li>

      <li>
        <em>What strategies would help minimize actions that result in obstruction?</em>
      </li>

    </ul>

    <p className='clear'>
      If you find yourself in an “accidental adversaries” scenario, you likely want to find a way out so that you can begin working toward achieving a fundamental solution.  Breaking this adversarial cycle usually requires acknowledging that you accidentally took actions that obstructed the success of a colleague or collaborator.  The ability to engage in dialogue to <a href='/courses/07-assumptions'>surface and test assumptions</a>, and <a href='/courses/03-big-picture'>seek the big picture</a> while suspending judgement is a worthwhile endeavor. Dialogue is an opening up, divergent conversation.  It intends to explore different viewpoints, discover new insights and does not strive to reach consensus or final decision.  On the other hand, when engaging in skillful discussion the intention is to make a decision, reach an agreement or consensus, identify priorities or converge points of view.  When faced with an accidental adversary situation, it is advisable to first engage in dialogue before skillful discussion.  By <a href='/courses/12-considers-issue'>resisting the urge to come to a quick conclusion</a>, the parties use dialogue to gain a full understanding of the situation and the role each plays in contributing to the undesirable dynamic. For more information about the discipline of dialogue, see <a href='https://thesystemsthinker.com/dialogue-the-power-of-collective-thinking/' target='_blank' rel='noopener noreferrer'>this article by William Isaacs</a>.
    </p>

    <div className='habits-row'>
      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/surfaces-tests.png'
        alt='Surfaces & Tests Assumptions'
      />

      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/big-pic.png'
        alt='Seeks to Understand the Big Picture'
      />

      <img
        className='lesson-img small-img'
        src='/images/shared/habit-cards/shadow/considers-issue.png'
        alt='Considers an Issue Fully'
      />
    </div>
  </div>
