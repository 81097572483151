import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='large-img'
        src='/images/resources/habits-sort/habits-sort.png'
        alt='Habits Sort'
      />
    </div>
  </div>
