import * as React from 'react'

export const Section02 = () =>
  <div className='content'>

    <h3>
      Specific Scenarios and Use of the Matrix — Examples
    </h3>

    <p>
      Below you will find concrete, everyday examples of how the HoST matrix can be used.
    </p>

    <div className='clear'>
      <img
        className='lesson-img med-img'
        src='/images/resources/host-matrix/hiring.png'
        alt='Hiring a New Employee'
      />

      <h4>
        Hiring a New Employee
      </h4>

      <p>
        Consider which Habits of a Systems Thinker would be important when hiring a new employee. This example shows how the matrix can be used when a new administrative assistant is needed. The Habits of a Systems Thinker are listed on the far-left column, and the main duties and responsibilities of the new position are listed along the top row.
      </p>

      <p>
        Ask your team to identify which Habits they think will be important for each of the duties and responsibilities. The Habits that are selected most often will be important in guiding your interview questions and reference checks.
      </p>
    </div>

    <div className='clear'>
      <img
        className='lesson-img med-img'
        src='/images/resources/host-matrix/assessment.png'
        alt='Team Assessment'
      />

      <h4>
        Team Assessment
      </h4>

      <p>
        To assess a team’s systems thinking capacity, ask each member to identify individual strengths. In this example, team members’ names are listed along the top row and the Habits of a Systems Thinker are listed on the far-left column.
      </p>

      <p>
        Sample follow-up and questions include:
      </p>

      <ul>
        <li>
          Reflecting on yourself as a team member, use a specific color of dots to identify Habits you see as personal strengths.
        </li>

        <li>
          Reflecting on your team, ask, “Which Habits seem to be our strengths currently?” Use a different color dot to identify team strengths from your perspective.
        </li>
      </ul>

      <p>
        Repeat the same reflection exercise every few months using a new copy of the matrix to capture changes the team is making in their systems thinking development.
      </p>
    </div>

    <div className='clear'>
      <img
        className='lesson-img med-img'
        src='/images/resources/host-matrix/new-initiative.png'
        alt='New Initiative Analysis'
      />

      <h4>
        New Initiative Analysis
      </h4>

      <p>
        Consider a new initiative that is currently being planned and will soon be implemented. List the steps for planning and successful implementation of the new initiative on the top row of the matrix and the Habits of a Systems Thinker on the far-left column.  Ask people to vote for the Habits that will be important for each step of the implementation process.
      </p>

      <p>
        Sample follow-up question:
      </p>

      <ul>
        <li>
          Can we identify all of the Habits that will be important for each step as we plan and implement this new initiative? Place a dot for each Habit you feel will be most important.
        </li>
      </ul>

      <p>
        Repeat the same reflection exercise several times throughout the planning and implementation process.
      </p>

      <p>
        Sample follow-up questions:
      </p>

      <ul>
        <li>
          What do we know now that we weren’t aware of before? What are we learning from this process?
        </li>

        <li>
          How is attention to the Habits of a Systems Thinker helping us to be more effective?
        </li>
      </ul>
    </div>

    <div className='clear'>
      <img
        className='lesson-img med-img'
        src='/images/resources/host-matrix/solve-problem.png'
        alt='Problem Solving'
      />

      <h4>
        Problem Solving
      </h4>

      <p>
        Identify a problem that needs to be solved.  Consider the steps to effectively define and solve the problem. These steps will be placed on the top row of the matrix and the Habits of a Systems Thinker will be placed in the far-left column.
      </p>

      <p>
        Sample follow-up question:
      </p>

      <ul>
        <li>
          Which Habits must we practice before coming up with a solution to this problem?
        </li>
      </ul>

      <p>
        Place a dot for the Habit you feel will be most critical to each problem-solving phase.
      </p>

      <p>
        Repeat the same reflection exercise several times throughout the problem definition and solution-seeking process.
      </p>

      <p>
        Sample follow-up questions:
      </p>

      <ul>
        <li>
          What do we know now that we weren’t aware of before?
        </li>

        <li>
          What are we learning from this process?
        </li>

        <li>
          How is this attention to the Habits of a Systems Thinker helping us to be more effective?
        </li>
      </ul>
    </div>

  </div>
