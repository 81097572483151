import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <p>
      Causal links demonstrate that a change in one element causes a change in another element.
    </p>
    <p>
      An arrow is used to indicate a “link” between two variables.
    </p>
    <p>
      <em>Oftentimes, causal links show a theory of causation, which is helpful for surfacing mental models about what causes what to change.</em>
    </p>
    <p>
      The arrow shows the direction of causality. After drawing links between variables, be sure to tell the story of the link which will help surface your mental model.
    </p>
    <div className='causal-link'>
      <div className='causal-column'>
        <h4>
          CAUSE
        </h4>
        <p className='red label'>Variable</p>
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
        <p className='red label'>Link</p>
      </div>
      <div className='causal-column'>
        <h4>
          EFFECT
        </h4>
        <p className='red label'>Variable</p>
      </div>
    </div>
    <p>
      The arrow shows the direction of causality.  The arrowhead is then labeled with an <span className='blue'><strong>S</strong></span> or <span className='red'><strong>O</strong></span> to show the relationship between the variables. More on this in the lessons to follow.
    </p>
  </div>
