import * as React from 'react'

export const Lesson02s02 = () =>
  <div className='content'>
    <img
      className='stock-flow'
      src='/images/courses/tools/03-stock-flow/lesson-two-img2.svg'
      alt='Stock Flow Diagram'
    />
    <h3>Connectors in a Stock-Flow Map</h3>
    <p>In the stock-flow map, the inflow and outflow are labeled increasing and decreasing. This is a clean, straightforward way to label a stock-flow map.  Some stocks have other understandable verbs for labeling inflows and outflows.  For example, if the stock is money in the bank, the inflow could be depositing and the outflow could be withdrawing.</p>
    <p>Most of the connectors represent relationships that are defined from looking at the picture — the hose is increasing the amount of water in the birdbath, while evaporation, splashing and drinking, are all causing the water in the birdbath to decrease.</p>
    <img
      className='lesson-img small-img'
      src='/images/courses/habits/13-accumulations/course-img.png'
      alt='Accumulations'
    />
    <p>You may have noticed two additional connectors. One goes from the stock to the hose. This connector suggests that the amount of water in the birdbath affects how much water is needed from the hose. In other words, the woman is observing the water level and it is a reasonable inference that when it is full she will turn off the hose and return when the water level has decreased. The second additional connector is the one from the hose to the splash factor. In the illustration, the hose is causing splashing and thus is affecting the decreasing water level. These additional connectors suggest that mapping a system provides more information than just thinking about or even discussing the elements and relationships in a system.</p>
    <div className='quote'>
      <p className='quote-text'>Suggestion for telling stock-flow map stories:  Use the arrows to trace around the map to help you tell the story of the system.  Explain each of the map components to complete the story.</p>
    </div>
    <p>Stock-flow maps are designed to show interdependent relationships. The connector that comes from the stock to the converter, (e.g. “hose”) is significant. When reviewing a stock-flow map, systems thinkers ask the question: How does the stock and its changing accumulation level affect other parts of the system?</p>
    <p>In the birdbath map, the amount of water affects how long the hose will run. It creates a feedback loop between the water in the birdbath and the need for water from the hose. {/* For more information on feedback loops, visit the <a href="">Feedback Loop Course</a>. */}</p>
    <img
      className='birdbath-stock-flow2'
      src='/images/courses/tools/03-stock-flow/birdbath-inflow.svg'
      alt='Birdbath Stock Flow Diagram'
    />
    <h3>Boundaries in a System</h3>
    <p>What’s up with those clouds?  You may have noticed cloud images at either end of the stock-flow map. Those clouds represent boundaries of the system. When drawing out a system, it is important to define the boundaries. It needs to be big enough to cover all essential variables, but small enough that one can reasonably expect to consider relevant factors.</p>
    <p>Consider a system that focuses on a stock of unanswered emails. If I am concerned about the amount of time spent answering emails while at work, variables focused on time spent outside of work hours may not be relevant. On the other hand, including all tasks done at work, not just those related to responding to email, may reveal new insight into the challenge being addressed by the map. Being clear about the boundaries of what is important will help you create a focused and compelling stock-flow map.</p>
    <p>As you continue learning about stock-flow maps, keep the question of boundaries in mind.  Consider what a map would like if you enlarged the boundaries or if you brought the system into tighter focus.</p>
  </div>
