import React, { useState } from 'react'

import { ToggleCard } from './ToggleCard'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'
import { cardList } from './cardList'

export const HabitCards = () => {
  const [optionValue, setOptionValue] = useState('english')

  const handleSelect = (e) => {
    setOptionValue(e.target.value)
  }

  const selectedSet = cardList.filter((list) => list.lang === optionValue)
  return (
    <div>
      <Header />
      <div className='main'>
        <div className='content'>
          <div className='course-header habitcard-header'>
            <div>
              <h1>{selectedSet[0].habitsTitle}</h1>
              <p>{selectedSet[0].translationCredit} <a href={selectedSet[0].translationCreditLink} target='_blank' rel='noopener noreferrer'>{selectedSet[0].translationCreditLinkText}</a></p>
            </div>
            <div className='selectlanguage'>
              <span>Select Language:</span>
              <select name='selectLang' id='selectLang' onChange={handleSelect}>
                <option value='german'>Deutsch</option>
                <option value='english' selected>English</option>
                <option value='spanish'>Español</option>
                <option value='french'>Français</option>
                <option value='italian'>Italiano</option>
                <option value='dutch'>Nederlands</option>
                <option value='portuguese'>Português</option>
                <option value='finnish'>suomi</option>
                <option value='turkish'>Türkçe</option>
                <option value='japanese'>日本語</option>
                <option value='persian'>فارسی</option>
              </select>
            </div>
          </div>
          <p>{selectedSet[0].introText}</p>
          <h4>{selectedSet[0].flipText}</h4>
          <div className='habit-cards'>
            {selectedSet[0].cards.map((card) => (
              <div key={card.id}>
                <ToggleCard front={card.front} back={card.back} alt={card.alt} flipCardText={card.flipCardText} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
