import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Archetype</h3>

    <p>
      Now it’s time to take a look at this archetype using your own personal experience.<br />
      When have you encountered or observed an Accidental Adversaries archetype? Reflect on that experience below.
    </p>

    <p>
      Feel free to <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/accidental-adversaries-template-so-blank.pdf' target='_blank' rel='noopener noreferrer'>download a blank “accidental adversaries” template</a> and use it to tell your story as you respond to the questions.
    </p>

    <h4>
      1. Identify the two parties, organizations, groups or individuals in the story and describe their initial collaborative loop (outside reinforcing loop).
    </h4>

    <Field
      name='resource-response-one'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      2. Identify and describe the two balancing loops.<br />
      Choose one party who first takes action to achieve individual success. What actions does the second party take to counterbalance and achieve some outcome of success?
    </h4>

    <Field
      name='resource-response-two'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      3. Consider how one party’s actions obstruct the success of the other party. When obstruction is caused by each party, a vicious reinforcing loop may result.
    </h4>

    <Field
      name='resource-response-three'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
