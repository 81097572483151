import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise06 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>Think of a time when a group or team came together to discuss an issue, where every person had access to different sources of information and varying experiences. How could BOTGs increase a team’s understanding of a controversial issue?</h4>
    <Field
      name='group-team'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Gather a small group or team and consider using BOTGs as a graphing strategy for an upcoming meeting where you anticipate a potentially challenging conversation to help surface and test assumptions.</h4>
    <h4>Examples to get you started are:</h4>
    <ul>
      <li>Level of commitment people have for a new strategy, policy or practice.</li>
      <li>Amount of trust particular stakeholders have for current management, administration or leadership.</li>
      <li>Quality of customer satisfaction practices — to what degree are customer needs being met?</li>
    </ul>
    <Field
      name='botgGroup'
      component={FileUpload} image
      instructions='Upload photos of a sample BOTGs from your group practice.'
    />
    <h4>How did the graphs help people surface and test their assumptions?</h4>
    <Field
      name='help-people'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
