import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-single'
        src='/images/resources/habits-reflection-sheet/habits-reflection-sheet.svg'
        alt='Habits: Reflection Sheet'
      />
    </div>
  </div>
