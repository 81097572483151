import * as React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

export const ProgressBar = ({ nav: { url, course, lesson, lessonIndex, section, sectionIndex, next, prev } }) => {
  const findPrev = (lessonIndex, sectionIndex) => {
    if (lessonIndex === 0 && sectionIndex === 0) {
      return `/courses/${course.path}`
    }
    if (lessonIndex === 0 && sectionIndex >= 1) {
      return prev.url
    }
    if (lessonIndex === 0) {
      return `/courses/${course.path}`
    } else {
      return prev.url
    }
  }

  const findPrevText = (lessonIndex, sectionIndex) => {
    if (lessonIndex === 0 && sectionIndex === 0) {
      return 'Course Home'
    }
    if (lessonIndex === 0 && sectionIndex >= 1) {
      return 'Previous ' + prev.type
    }
    if (lessonIndex === 0) {
      return 'Course Home'
    } else {
      return 'Previous ' + prev.type
    }
  }

  return (
    <div>
      <div className='progress-bar group'>
        <h2>Course Progress</h2>

        <ul>
          {course.lessons.map((l, lIdx) => {
            const lessonComplete = lIdx < lessonIndex
            const lessonActive = l === lesson
            return (
              <li
                style={{ height: 75 / course.lessons.length + 'vh' }} key={l.path} className={cn({
                  active: lessonActive,
                  complete: lessonComplete
                })}
              >
                {l.sections &&
                  <div className='section-dots'>{l.sections.map((s, sIdx) =>
                    <a
                      href={'/courses/' + course.path + '/' + l.path + '/' + s.path} key={s.path}
                      style={{ height: 100 / l.sections.length + '%' }}
                      className={cn({
                        active: s === section,
                        complete: lessonComplete || (sIdx < sectionIndex && lessonActive)
                      })}
                    />)}
                  </div>}
                <a href={'/courses/' + course.path + '/' + l.path}>{l.navName || l.name}</a>
              </li>
            )
          })}
        </ul>

        <div className='nav-container'>
          <a className='nav-button prev' href={findPrev(lessonIndex, sectionIndex)}>{findPrevText(lessonIndex, sectionIndex)}</a>
          <a className='nav-button next' href={next.url}>{next.end && course ? 'Course Feedback' : `Next ${next.type}`}</a>
        </div>
      </div>

      <div className='nav-container mobile-flex'>
        <a className='nav-button prev' href={findPrev(lessonIndex, sectionIndex)}>{findPrevText(lessonIndex, sectionIndex)}</a>
        <a className='nav-button next' href={next.url}>{next.end && course ? 'Course Feedback' : `Next ${next.type}`}</a>
      </div>
    </div>
  )
}
ProgressBar.propTypes = {
  nav: PropTypes.object
}
