import * as React from 'react'

export const Lesson05 = () =>
  <div className='content'>
    <img
      className='lesson-img small-img'
      src='/images/courses/habits/13-accumulations/circular-nature.png'
      alt='Circular Nature'
    />
    <p>Sometimes an accumulation reinforces its own growth. In this case, you might experience a tipping point. For example, if kindness is increasing in an organization, it is probable that the “rate of people showing kindness” will also increase. In other words, when a quality like kindness (the accumulation) becomes pervasive in a community, it is more likely that people respond kindly (the rate). In essence, the kindness reinforces itself.</p>
    <div className='quote'>
      <p className='quote-text'>“I resolved to stop accumulating and begin the infinitely more serious and difficult task of wise distribution.”</p>
      <p className='quote-author'> — Andrew Carnegie, Industrialist</p>
    </div>
    <p>Who hasn’t resolved to tackle the accumulation of stuff in a garage, closet or junk drawer? These “things” that have accumulated over time are obvious and measurable, but rarely do people pause to reflect on their accumulation of stuff, and recognize that changing the actual quantity of things requires action on both the acquisition and the distribution of items. Our tendency to dump stuff in these spaces like junk drawers can reinforce itself. Just the name “junk drawer” will attract the accumulation of stuff over time, making the name of the container reinforce the increase of the stock (junk) over time.</p>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-workplace.svg'
      alt='Workplace Example'
    />
    <p>Consider car dealerships that are individually owned, but must maintain good standing with the large automotive manufacturers they represent like Ford, Honda, Chrysler, etc. Many factors affect this standing. Some are very concrete, like the number of units sold and profit earned. Customer satisfaction, on the other hand, is quantifiable through returned surveys, which is not as straightforward as the dealership simply making sure it has more happy customers than unhappy ones. When paying attention to customer satisfaction, dealership owners understand the power of word of mouth experience; so, the more satisfied customers, the more new customers will hopefully become satisfied by the service of the dealership.</p>
    <p>For example, Frank is the Customer Service Manager for a dealership. He works to make sure that customers, especially happy ones, respond to the corporate surveys. His job is building a positive reputation for the dealership — from the way a customer is greeted when he/she walks on the lot, through selection of a vehicle, making the deal, delivering the car and providing ongoing service and maintenance. There are many places in the delivery of service for a dealership to experience a decrease in its stock of customer satisfaction, so Frank builds a relationship with customers in order to increase the likelihood of a positive experience. Successful dealerships look at all of those “faucets and drains” and the many ways important stocks feed back to other parts of the system. They then manage the inflows and outflows in such a way that the water in the tub of customer satisfaction keeps growing.</p>
  </div>
