import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Think of a system where trust is an important accumulation.</p>
    <h4>What are some of the factors that build trust or increase the rate of trust building? Examples to get you started may include quality of relationships, honesty and sincerity.</h4>
    <Field
      name='build-trust'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What are factors that work to diminish trust or decrease the stock? Examples to get you started may include competing goals and unethical behavior.</h4>
    <Field
      name='factors-work'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
