import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <img
      className='lesson-img med-img'
      src='/images/resources/water-manager/kids-water.jpg'
      alt='Ladybug Loop'
    />
    <h3>
      Materials:
    </h3>

    <ul>
      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/water-manager-student-instructions.pdf' target='_blank' rel='noopener noreferrer'>Student Instructions</a>
      </li>

      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/water-manager-data-chart.pdf' target='_blank' rel='noopener noreferrer'>Water Manager Data Chart</a>
      </li>

      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/water-manager-botg.pdf' target='_blank' rel='noopener noreferrer'>BOTG Worksheet</a>
      </li>

      <li>
        <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/water-manager-stock-flow.pdf' target='_blank' rel='noopener noreferrer'>Blank Stock-flow Template</a>
      </li>

      <li>
        4 pennies per team
      </li>

      <li>
        Optional within AZ: map of Central Arizona Project (CAP), map of Salt River Project, photos of Roosevelt Lake and Avra Valley recharge ponds
      </li>
    </ul>

    <h3>
      Lesson Structure:
    </h3>

    <h4>
      Introduction:
    </h4>

    <ol>
      <li>
        Remind students of their study of Arizona’s water resources. With the students, brainstorm
        a list of water users. These might include individual consumers, business/industry, and agriculture.
        Share with students that today’s activity will help them understand the role of water managers whose
        job it is to balance the needs of all these water users with the amount of water available in the system.
      </li>
    </ol>

    <h4>
      Playing the game:
    </h4>

    <ol>
      <li>
        Model the game for the students, making decisions as a class, as you go through the Student Instructions sheet.
      </li>

      <li>
        Divide the students into teams of two and give them all the materials they will need for the game: student
        instructions, data charts, BOTGs, and stock-flow maps.
      </li>

      <li>
        Students will play the game for at least 8 rounds with the goal of maintaining between 2 million acre-feet (maf)
        and 10 maf in their reservoir. In other words, they must try to avoid a flood downstream or having their reservoir
        dry up.
      </li>
    </ol>

    <h4>
      After the game (BOTG):
    </h4>

    <ol>
      <li>
        Students will use the data from their charts to create BOTGs showing the changes in water levels in their reservoirs.
        Have the students draw lines across their BOTGs at the 2maf line and the 10 maf line. Anything above the 10maf line
        indicates a flood. Anything below the 2 maf line shows the reservoir going dry and not being able to provide enough
        water for users downstream.
      </li>

      <li>
        Referring to the BOTGs, invite the students to share their results, making sure to address floods and droughts they might
        have encountered.
      </li>

      <li>
        Ask the students to write the story of their reservoir in their science notebooks.
      </li>
    </ol>

    <h4>
      After the game (Stock-flow Map): This part may be completed in another session.
    </h4>

    <ol>
      <li>
        Review the results and the BOTGs from the previous day. Review the sources of Tucson water, the origin of CAP water,
        and the origin of ground water. Review the basic drawing of a stock-flow map. If this is the students’ first use of
        a stock-flow map, then do this part as a whole class activity, rather than in student teams.
      </li>

      <li>
        Using the blank stock-flow map, students can add converters and connectors that affect the inflow and outflow. Their
        inflow converters might include rainfall, snow melt, water flowing in a river that enters the lake behind a dam, etc.
        Outflow converters might include individual water use, business and agricultural use, floods, evaporation, etc. Students
        should be sure to question whether there should be a connector from the stock to any of the converters they put in the
        map.  For example, as the stock of water decreases, would that affect individual, business and/or agricultural water use?
      </li>

      <li>
        Call the class together, and using the students’ diagrams as a resource, collect their ideas to make a composite stock-flow
        map for the class.  Look for feedback loops and draw them, if found.
      </li>

      <li>
        Discuss with the class what we can control in the system (outflow) and what we cannot (inflow). Elicit their ideas for how
        we can increase the stock by decreasing the outflow. How can we change our water use habits to use less water?
      </li>
    </ol>

    <h3>
      Follow up:
    </h3>

    <p>
      To simulate what happens when population increases without an increase in the water supply, change the rules of the game to require that at least 5 maf per year be released from the reservoir. Ask the children to predict what might happen and how their strategies for the game need to change.
    </p>

    <p>
      <strong>Lesson’s Author:</strong> Caryl Crowell, based on materials from Project Wet, titled Discover the Waters of Arizona, 2005.
    </p>
  </div>
