import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='article-container'>
      <img src='images/resources/interview-with-taylor-davidson/taylor-davidson.png' className='lesson-img med-img' />
      <p>Taylor Davidson, Owner and Broker of Neil Benton Arts & Entertainment, is not shy about crediting his success to systems thinking.</p>
      <p>“Applying a systems thinking approach to everything we do allows our company to put more good into the world.”</p>
      <p>Davidson, whose company provides specialized insurance and risk management for movie and event clientele (including feature documentaries like The Ataxian, events such as Live in The Vineyard, and television projects for ESPN), is based in Tucson, AZ.</p>
      <p>Davidson was first introduced to formal systems thinking as a middle schooler at Orange Grove Middle School in Tucson. Interestingly, Orange Grove was the first site for Waters Foundation, Systems Thinking in Education work over 30 years ago and we continue to have a presence in the district to this day.</p>
      <p>“I remember learning about making connections and using computer software for stock-flow modeling … it was so beneficial to slow down my thinking, to identify elements within a system, and to tweak systems to get the results I wanted.”</p>
      <p>Even before middle school, Taylor learned from an early age that “systems are perfectly designed to produce the results they get” — a phrase that we find ourselves using regularly in our work at the Waters Foundation.</p>
      <p>“If you aren’t getting what you need or want,” he says, “you don’t throw out the system … you keep a steady eye on your desired outcomes, adjust your systems, and deploy new systems to achieve those results.”</p>
      <p>“Ask any of my childhood friends and they will tell you how I loved to design games for us to play. But they would complain because I always kept changing the rules! That’s because I was constantly tweaking to ensure we would maintain the end-result I wanted: to have fun!” Taylor was identifying leverage actions to produce his desired outcome — a hallmark of systems thinking.</p>
      <p>This “tweaking” has been essential to Taylor’s ongoing career success. He originally started in the insurance business as a general broker — working on home, health, life, auto policies and many more.</p>
      <p>“I was constantly casting a wide net, trying to catch a fish that no one else in the industry was catching. When you catch that fish, you know that there is a gap in the market — a need that needs to be addressed.”</p>
      <p>Taylor was (and still is!) using one of the Habits of a Systems Thinker, “successive approximation,” or, the process of looking for opportunities for improvement to produce the results you desire.</p>
      <p>This process led him to focus his business solely on production insurance (for film, events, and other artists), where he and his team treat the policies they create like “widgets,” according to Davidson. In other words, they streamline, automate and fine-tune the process as much as possible. They look for what’s going right and what’s causing it (causal links) — and once they think they’ve got it, they continue to look for opportunities for improvement. This systems thinking approach allows them to continually develop deeper expertise, and perform on-time, under-budget and with increasing value for customers.</p>
      <p>Above all else, Taylor is passionate about creating a culture of systems thinkers at his company.</p>
      <p>“I’m not very interested in how much information or content an employee can store in their brain. Content matters less and less with every passing year — anyone can do a quick google search to source facts and information. I am looking for problem-solvers; creators; people who can generate ideas, see the big picture, make connections, be resourceful and come up with innovative solutions …. oh, and by the way, clear critical thinking, seeing all the pieces on the board and how they work together, also makes them effective writers and speakers — invaluable qualities in any business and in any area of life.”</p>
      <p>The nature of Taylor Davidson’s work allows him, his team and his clients to put artful, fun and entertaining projects into the world.</p>
      <p>“The systems culture in our company allows us to be better at our job today than we were yesterday, and better tomorrow than we are today. The better we do our job, the more art and entertainment is in the world. And that’s a beautiful thing.”</p>
      <p><em>To learn more about Neil Benton Arts & Entertainment, <a href='https://neilbenton.com/' target='_blank' rel='noopener noreferrer'>click here</a>.</em></p>
    </div>
  </div>
