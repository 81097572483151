import React, { useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

export function ToggleImage (props) {
  const [isVisible, setVisible] = useState(false)
  const { showText, hideText, ...otherProps } = props

  ToggleImage.propTypes = {
    showText: PropTypes.string.isRequired,
    hideText: PropTypes.string.isRequired
  }

  return (
    <div className={cn('toggle-img', isVisible && 'visible')}>
      <button type='button' className='toggle-button' onClick={() => setVisible(!isVisible)}>
        {isVisible ? hideText : showText}
      </button>
      <img {...otherProps} />
    </div>
  )
}
