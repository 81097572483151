import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>Read over each of the following quotes and think about a situation where this quote could have provided helpful advice to you or others.</h4>
    <div className='quote'>
      <p className='quote-text'>“Many attempts to communicate are nullified by saying too much.”</p>
      <p className='quote-author'> — Robert Greenleaf</p>
    </div>
    <Field
      name='quote-one'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <div className='quote'>
      <p className='quote-text'>“Listening is such a simple act. It requires us to be present, and that takes practice, but we don't have to do anything else. We don't have to advise, or coach, or sound wise. We just have to be willing to sit there and listen.”</p>
      <p className='quote-author'> — Margaret J. Wheatley</p>
    </div>
    <Field
      name='quote-two'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <div className='quote'>
      <p className='quote-text'>“Change happens by listening and then starting a dialogue with the people who are doing something you don't believe is right.”</p>
      <p className='quote-author'> — Jane Goodall</p>
    </div>
    <Field
      name='quote-three'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
