import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/tools/01-behavior-over-time-graphs/exercise-two-img.jpg'
      alt='Balloons'
    />
    <h3>Practice the Tool</h3>
    <p>“Balloon Dynamics” is a powerful way to introduce behavior-over-time graphs. In addition to being fairly straightforward, the activity allows learners to share a common experience and then discover that despite observing the same event, they each see things a bit differently.</p>
    <p>If you want to experience the power of a behavior-over-time graph firsthand, consider assembling a group of three to five people to participate in this exercise. The more people you assemble, the more you will be able to see how differently people can perceive the same experience.</p>
    <p>If you are taking this course with other people, only one person should read the directions all the way through before engaging in the activity.</p>
    <h3>Procedure</h3>
    <ol>
      <li>You will need a balloon.</li>
      <li>Download the <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/balloon-dynamics.pdf' target='blank' rel='noopener'>template</a> and provide a copy for each participant.</li>
      <li>Tell participants that as you blow up the balloon, you will be asking them to record what is happening on their BOTG. The level of direction you provide your participants will depend on their age and prior experience with line graphs.</li>
      <li>Make sure they label the X-axis in a unit of time. Measuring time in seconds works best for this activity. You can use a timing device, a clock with a second hand or allow participants to count the number of seconds in their head.</li>
      <li>Talk with participants about how they are going to label their Y-axis. It will represent the amount of the air in the balloon. Words such as empty, half-way and full are specific enough for the purpose of practicing a BOTG.</li>
      <li>Once participants have their graphs set up, you are ready to begin blowing up the balloon.</li>
      <li>The time starts when you say begin.</li>
      <li>As you blow up the balloon, take at least three slow, deliberate pauses. When the balloon is quite full, but before it pops, release the balloon so that it sails to the ground as the air escapes. At that point, say, “Stop,” provide participants one to two minutes to finish drawing their graphs.</li>
      <li>Have all participants share their graphs, paying careful attention to identifying similarities and differences. There will be some common similarities and differences in the graphs.</li>
    </ol>
    <p>Graphs will have a common basic shape representing air going in and air coming out, but there will be differences that are worth noting.</p>
    <p>Some participants will start their line exactly at (0,0), indicating that there was no air in the balloon at the beginning of the exercise (Figure A). Others will intentionally go up the Y-axis just a bit, suggesting the presence of some air in the deflated balloon (Figure B).</p>
    <p>Typically, the most notable difference in graphs is the contrast between Figure A and Figure B.  Figures A and B both show air in and out, but Figure B shows the time that passed as the person blowing up the balloon paused to take a breath.  While Figure B is technically more accurate with the seconds recorded on the X-axis matching the actual time it took to fill the balloon, both tell the story of what happened and provide opportunities for debrief and discussion.</p>
    <div className='graphs'>
      <img
        className='graph'
        src='/images/courses/tools/01-behavior-over-time-graphs/figure-a.png'
        alt='Figure A'
      />
      <img
        className='graph'
        src='/images/courses/tools/01-behavior-over-time-graphs/figure-b.png'
        alt='Figure B'
      />
      <img
        className='graph'
        src='/images/courses/tools/01-behavior-over-time-graphs/figure-c.png'
        alt='Figure C'
      />
      <img
        className='graph'
        src='/images/courses/tools/01-behavior-over-time-graphs/figure-d.png'
        alt='Figure D'
      />
    </div>
    <p>The slope of the line as air comes out of the balloon is another place that there will likely be some differences.  Sometimes the line comes straight down (Figure B) indicating that the air left the balloon very quickly, almost as if it had been popped.  Others will have a subtler slope suggesting that the air escaped more slowly (Figure C).  It is not completely without precedent for someone to draw loops in their graph that represent the path the balloon took on its descent to the ground (Figure D).  This is actually incorrect because it suggests “going back in time.”</p>
    <h4>In the text box below, describe your experience with balloon dynamics.  What insights did you gain about the tool through leading, participating in, and/or thinking about this experience?</h4>
    <Field
      name='text'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
