import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise04 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>How can the ladder of inference framework make you more aware of the evidence you lean on to reinforce your assumptions and beliefs?</p>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-community.svg'
      alt='Community Example'
    />
    <p>Consider a belief you have about your community. For our purposes here, let’s define community as a place you reside or a place where you belong. It involves relationships formed around a common purpose. Perhaps your assumption is about a recent neighborhood association ruling, a decision by the board of your community group or your city’s plan to decrease fees for using recreational facilities.</p>
    <h4>Use the questions below to walk yourself up the ladder as a way to surface and test the assumptions contained in your belief.</h4>
    <img
      className='lesson-img'
      src='/images/courses/habits/07-assumptions/ladder-with-numbers.png'
      alt='Ladder of Inference'
    />
    <div className='ladder'>
      <div className='ladder-questions'>
        <h4>Questions for you to consider about yourself (walk yourself up the ladder and begin at #1):</h4>
        <h4>5. How are your actions influenced by your beliefs?</h4>
        <Field
          name='number-five'
          component='textarea'
          className='reflection-input-med'
          placeholder='Enter your response here.'
        />
        <h4>4. How do your current beliefs influence what you notice?</h4>
        <Field
          name='number-four'
          component='textarea'
          className='reflection-input-med'
          placeholder='Enter your response here.'
        />
        <h4>3. What in your personal background or experience influences your current viewpoint?</h4>
        <Field
          name='number-three'
          component='textarea'
          className='reflection-input-med'
          placeholder='Enter your response here.'
        />
        <h4>2. What else could you choose to pay attention to that might enhance your understanding of your community?</h4>
        <Field
          name='number-two'
          component='textarea'
          className='reflection-input-med'
          placeholder='Enter your response here.'
        />
        <h4>1. What are you noticing about the current state of your community?</h4>
        <Field
          name='number-one'
          component='textarea'
          className='reflection-input-med'
          placeholder='Enter your response here.'
        />
      </div>
    </div>
    <h4>Based on this walk up the ladder, what evidence do you have that your assumptions are accurate? Inaccurate?</h4>
    <Field
      name='walk-up'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Consider your possible actions by asking yourself some “what if” questions. For example, “What if I stood up for my neighbor in opposition to the neighborhood association?” “What if I wrote a letter expressing support for the lower fees?”</h4>
    <Field
      name='possible-actions'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
