import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <h3>
      Caffeine
    </h3>

    <p>
      After a late night finishing a paper, a college student, feeling very tired, grabs a large coffee and takes it with her
      to class. It is fair to say this scenario likely happens millions of times a day around the globe. But instead, look at
      this story in light of a reoccurring pattern. Whether the fatigue stems from schoolwork, job responsibilities, a busy
      social schedule or the demands of parenting, the symptomatic solution to fatigue is often a quick burst of caffeine.
      No doubt this symptomatic solution (caffeine) relieves the problem symptom (fatigue) for many of those millions of people
      each day. However, this symptomatic solution can also take the user away from what may be more fundamental solutions, such
      as more sleep, better nutrition or increased exercise, all of which could impact the effects of chronic fatigue in the
      long-term.
    </p>

    <h3>
      Tech Support from an Expert
    </h3>

    <p>
      You are having a difficult time getting a new software application to perform as desired, so you call tech support.
      Your company’s tech support person comes to your office, hits a few keys and the system is operating just like you need it
      to.  That is a great solution, until a few weeks later when you move to a new aspect of the system and discover that it
      won’t give you the results you want. Again, you call your tech support person, who happily comes, strikes a few keys and
      has you back to work in the software application. In the short-term, the tech person is taking care of the problems quickly
      and efficiently. Perhaps, you are not the only one in your office relying on her expertise. Over time, her response time
      grows longer, and you find yourself without the capacity to keep moving forward on your project using the new software
      application. Because tech support worked so well in the beginning, you never took the time to learn the ins and outs of the
      program, which was the fundamental solution to your problem.  Now it is more difficult to learn because the tech support
      resources are strained. Perhaps you have developed some “bad habits” using the new software program and now you recognize
      that it will require some real discipline on your part and that of technical support for you to learn the software program.
    </p>

    <h3>
      Heroic Behavior
    </h3>

    <p>
      Too much work! Now that is a problem symptom most people can relate to. Overwhelmed with deadlines, you do your best to
      prioritize, but sometimes delays occur and the quick-fix to meeting the deadline is to jump into crisis mode. Crisis
      modes can vary. It might be pulling an “all-nighter,” rallying the team by paying some overtime or calling in favors.
      Whatever it takes, the deadline is met, and you are free to move on to the next impending deadlines. Oftentimes this
      heroic behavior is met with great appreciation, recognition and praise from colleagues, coworkers and supervisors.
      Your ability to get it done amidst seemingly impossible circumstances is a valuable commodity. The next time an unreasonable
      deadline approaches, you rest confident that you will have the skills and abilities to do the job. Again, great accolades
      come your way and you may actually find yourself with even more tasks with unreasonable deadlines.  This sounds like it
      could be the story of a very successful employee, positioned for promotion and a promising career. However, it could also
      be symptomatic of a dysfunctional workplace that rewards heroic behavior rather than setting realistic goals, improving
      team collaboration and realistically allotting time and resources.
    </p>
  </div>
