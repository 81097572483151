import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <h3>Introduction</h3>
    <img
      className='lesson-img'
      src='/images/resources/botg-retelling/section-one-img.png'
      alt='Kids watching tv'
    />
    <p>You know the feeling … clenched jaw, goosebumps on your arms, holding your breath — it’s the sensation you get when approaching the climax in a very dramatic story. It can come while reading a suspenseful novel, listening to a family member recount a personal experience, or watching a scary movie. All of these examples are opportunities to experience the compelling, intense, dramatic effect of story.</p>
    <p>In homes where children have the benefit of hearing a story as a bedtime ritual and other opportunities to hear stories read aloud, young people are developing intra-textual awareness; in other words, a deep sense of how stories are written and the predictable patterns found in the structure of the text. When these children enter school, they sense that the purpose of reading is to make meaning; they believe that when they read a piece of unfamiliar text, it should make sense; and they can anticipate what is coming, confirming their predictions as they read. These skills are important and can be enhanced and supported by using behavior-over-time graphs (BOTGs) as an instructional strategy in reading — specifically to increase students’ ability to retell a story.</p>
    <p><a href='/courses/01-behavior-over-time-graphs'>Click here</a> for a complete introduction to BOTGs.</p>
    <h3>Setting Up a BOTG for Use with a Children’s Story</h3>
    <p>The <b>x-axis</b> shows the time and the <b>y-axis</b> represents what is changing.</p>
    <p>Story events are sequenced across the x-axis. Events can be represented by small images reproduced from the text or children can draw representations for key events.  Older students can write out event descriptions using words, short phrases or complete sentences. Sometimes teachers opt for a simple 3-point graph indicating beginning, middle and end.</p>
    <p>In this example, students have drawn events from the book, "The Tortoise and the Jackrabbit".</p>
    <img
      className='lesson-img left'
      src='/images/resources/botg-retelling/section-one-img2.png'
      alt='Example From The Tortoise and the Jackrabbit'
    />

    <p>The <b>y-axis</b> should focus on a single dynamic element. One way to ensure that each graphed line focuses on only one element is to talk about the level of “XYZ” (e.g. happiness, confidence, fear) in the story and then use generic descriptors, such as those shown below.</p>
    <div className='practitioner-tip'>
      <p>Practitioner Tip: Teaching subjects and verbs — Require students to use the main character’s name as the first word in each event description. Then point out that the character’s name is the subject of the sentence.</p>
    </div>
    <p>The <b>y-axis</b> should focus on a single dynamic element. One way to ensure that each graphed line focuses on only one element is to talk about the level of “XYZ” (e.g. happiness, confidence, fear) in the story and then use generic descriptors, such as those shown below.</p>
    <div className='botg-examples'>
      <img
        className='botg-example'
        src='/images/resources/botg-retelling/botg-retelling-graph-1.png'
        alt='BOTG Example 1'
      />
      <img
        className='botg-example'
        src='/images/resources/botg-retelling/botg-retelling-graph-2.png'
        alt='BOTG Example 2'
      />
    </div>
    <p>Another way to build a y-axis is to develop a range of vocabulary words that describe a single element. This is a great way to set up a BOTG and to develop vocabulary at the same time. To do this, brainstorm a list of synonyms for your element, for example, “fear.” Students might brainstorm the following list of words for fear: terror, panic, anxiety, dread, distress, and alarm. Then they could spend some time determining how to place their selected words on the y-axis from the least amount of fear to the greatest amount of fear. This kind of thoughtful conversation produces a deeper understanding of the meaning and nuances of the words being discussed.</p>
    <div className='student-example'>
      <img
        className='student-img'
        src='/images/resources/botg-retelling/student-example-one.png'
        alt='Student Example'
      />
      <p>To the left is a student BOTG example. The student is writing in response to the poem, "Life Doesn’t Frighten Me at All", by Maya Angelou. She is telling a personal story about being afraid of the dark. She chose “paralyzed,” “terrified,” “scared,” “afraid” and “sort of over it” to express her personal levels of fear.</p>
    </div>
    <div className='student-example'>
      <img
        className='student-img'
        src='/images/resources/botg-retelling/student-example-two.png'
        alt='Student Example'
      />
      <p>In this example, the teacher has copied the notes from the book, <em>Click, Clack, Moo, Cows that Type</em>, for the x-axis and describes the range of the farmer’s frustration as “content,” “irritated,” “annoyed” and “exasperated.” A unique feature of this text is that the farmer’s emotion is made apparent through the use of silhouette images that clearly show his growing level of frustration.</p>
    </div>
    <div className='student-example'>
      <img
        className='student-img'
        src='/images/resources/botg-retelling/student-example-three.png'
        alt='Student Example'
      />
      <p>For preschool children, consider using only two elements on the y-axis, such as “yes” and “no.” This BOTG asks the question, “Were the villagers afraid of Abiyoyo, the giant?” The pink sad face represents the emotion “afraid,” the yellow smiley face represents “not afraid.” Students responded with a simple “yes” or “no.” Many children’s stories will produce a similar pattern: yes, yes, yes, no or the reverse, no, no, no, yes. Seeing this familiar pattern is very beneficial for students who need to develop inter-textual awareness.</p>
    </div>
    <div className='student-example'>
      <img
        className='student-img'
        src='/images/resources/botg-retelling/student-example-four.png'
        alt='Student Example'
      />
      <p>Based on the book, <em>The Chick and the Duckling</em>, by Mirra Ginsburg, students answered the question, “Can the chick copy the duckling?” The BOTG shows that he does quite well until it comes to swimming!</p>
    </div>
    <p>In the above examples, the BOTG process has been scaffolded for students by allowing them to place an actual dot above the event. When using the BOTG, it is important from early introduction of the tool to emphasize that a BOTG represents a pattern or direction of the story. A character’s feeling or the tension of the story is in constant motion, so it is important to note that things are happening between those dots that inform the direction of the line and its rate of change.</p>
    <div className='practitioner-tip'>
      <p>Practitioner Tip: Create a center activity by using cookie sheets and putting magnets on the back of story elements so students can choose to engage in retelling familiar stories multiple times and employ multiple interpretations.</p>
      <img
        className='left'
        src='/images/resources/botg-retelling/tip-image.png'
        alt='Practitioner Example'
      />
    </div>
    <h3>Telling the Story of the Line: Implications for Using BOTGs for Retelling</h3>
    <p>Once the work of creating the graphs is complete, students can begin using the graphs to retell the story. Encourage children to “tell the story of the line” versus reading the events across the bottom and reading off the level of the variable. Telling the story of the line supports children in not only relating events, but also in making connections and identifying the reasons for change within the text. Change, or dynamics, are central to a good story, and the BOTG is a tool that helps students focus on dynamic story elements.</p>
    <p>Sequencing story elements across the x-axis and focusing on a single dynamic element for the y-axis creates a visual picture of the story that allows children to easily retell the story in their own words with an emphasis on key details, characters, and main events.  It also leads the reader to ask and answer key “why” questions, particularly as they analyze important behaviors in the story.</p>
    <p>A fifth-grade teacher once shared, “I love using BOTGs with literature. I want to give students choice about the books they read, but with small children of my own, I don’t have time to read everything that is current and popular. With traditional book reports, no matter what kind I tried, I always had the feeling that students were pulling the wool over my eyes. They could produce a great report without ever having read the book.  Now, I still encourage them to read what they want and have a large selection of book reports to choose from, but I also require a BOTG for every book they read. When I place my finger on a point in the line of their graph and say, “Tell me what is happening that is causing that line to move,” that is a question they cannot possibly answer without having read the book with deep comprehension.”</p>
  </div>
