import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <h3>
      Overfishing
    </h3>

    <p>
      Not unlike common pastureland, almost any non-renewable resource can have a similar story about
      what happens when a limited resource becomes depleted. Overfishing, for example, occurs when more
      fish are caught than can be replaced through natural reproduction.  This is a problem in many parts
      of the world today. Billions of people rely on fish as their primary source of protein and their
      economic livelihood. Increased fishing coupled with unsustainable practices is pushing many fish
      populations to the point of collapse.
    </p>

    <h3>
      Teacher Time
    </h3>

    <p>
      It is not only tangible accumulations such as pasturelands and fish that have a resource limit.
      Time is another resource that often feels like it’s in short supply. In this scenario, the activity
      (the first variable in the reinforcing loop) is not a person or an entity, but rather an initiative,
      and the resource limit is total amount of available teacher time.
    </p>

    <p>
      A popular, effective elementary school gets a new principal. She is eager to continue moving this
      performing school forward. She carefully researches some initiatives that she believes will benefit
      students. These include a new reporting form that will give parents more anecdotal information on
      their children’s progress, a weekly genius hour that provides students undisturbed time for exploration
      on the topic of their choice, and a hands-on science program that covers the current science standards
      but requires more setup from the teachers. Her highly competent staff is enthused by the principal’s
      many new ideas and they seek to embrace each initiative and to do it well. Each of these three programs
      generates activity and produces observable benefits to students but subsequently increases the amount
      of time teachers are spending on each. The total activity is growing very rapidly.  Over time, the
      gains made by each of these new programs begins to diminish. As the resource of teacher time gets
      even more stretched, teachers are scrambling to find time for their original work, not to mention
      these new initiatives. Resolved to meet the new commitments, they invest even more time, but are still
      faced with diminishing results.
    </p>

    <h3>
      Cool in the Pool
    </h3>

    <p>
      Tom and Shelly have just moved into a new subdivision. Never having had regular access to a swimming
      pool, one of the things they are looking forward to is cooling off and relaxing by their community
      pool. On the first spring day, they head to the pool. They meet another couple from the neighborhood
      and have a relaxing swim. Tom and Shelly go for a quick dip in the pool each day after work. As the
      weather heats up, more and more families are coming to take advantage of the community pool.
      The medium-sized pool was comfortable and relaxing when the number of swimmers was ten or fewer.
      When ten or more families started coming to the pool each evening, the conditions were very different.
      The water was warm and the space around the pool deck crowded and noisy. Tom and Shelly could still
      go to the pool and get wet, but the level of enjoyment they had experienced early in the spring
      rapidly diminished by midsummer.
    </p>
  </div>
