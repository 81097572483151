import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/13-accumulations/lesson-two-img.jpg'
      alt='Rubber Duck'
    />
    <p>Oftentimes, the simple example of water in a bathtub can help us better understand accumulations and rates of change.</p>
    <p>Imagine that one evening you are filling a bathtub with water. You get the temperature just right. You secure a stopper in the drain and then you go off to gather a towel, some essential oil, maybe even your rubber ducky. But in the process, you get distracted. Suddenly, you remember that the water has been running for a very extended period of time and the perfect temperature and soothing suds have been ruined. Any semblance of a relaxing indulgence is gone as you turn off the faucet, release the drain and opt for a quick shower instead.</p>
    <p>This lighthearted scenario provides a vivid picture of someone who is not paying attention to the accumulation in the bathtub nor its rate of change. The faucet represents the inflow into this system: turning it on more slowly would have allowed the bathtub to run for a longer period of time. More fully securing the drain would have prevented loss of those great new bath salts. Adjusting the rate of the inflow or the outflow allows for management of the accumulation (in this case, the level of water in the bathtub).</p>
    <p>The water in a bathtub metaphor helpfully symbolizes any accumulation that is changing over time and how the actions of the faucet and the drain will influence its rate of change.  When young children are first learning about accumulations and rates of change, they use the bathtub metaphor and often refer to the quest for understanding as “bathtub thinking.”</p>
    <p>Systems thinkers refer to accumulations, like water in a bathtub, as stocks. Money in the bank, books in a library, customers in a store, dirty dishes in the sink, wins and losses of your favorite sports team and level of commitment to your work are just a few examples of stocks. There are accumulations that you want to see increase, like friends on Facebook, points on your credit score and feelings of happiness in your personal life. There are also stocks you actively want to decrease, like weeds in a garden, business emails in your inbox or conflicts with coworkers.</p>
    <p>As a systems thinker, you achieve these desired increases and decreases by paying attention to the accumulation and focusing on the rates of change affecting the accumulation. The rate of change that influences the stock is referred to in systems language as the flows.  The inflow or bathtub faucet increases the stock.  The outflow or bathtub drain decreases the stock.</p>
  </div>
