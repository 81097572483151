import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <p>
      This resource provides a listing of children’s literature with connections to systems thinking concepts.
      The books on this list are applicable for grades prek-elementary.  The list provides the name of the book along
      with a brief summary and its connection to some specific Habits, tools, and strategies of systems thinking.
    </p>

  </div>
