import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { SectionLayout } from './SectionLayout'
import { AuthComponent } from 'components/auth'
import PropTypes from 'prop-types'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

export const AuthResourceLayout = ({ resource, match }) =>
  <div>
    <Header />
    <Switch>
      {resource.sections.map(s =>
      // create route for every section in the resource
      // - the path for each section is /resources/{resource-path}/{section-path}
      // - the root path for the resource, /resources/{resource-path}, will take you to the intro page
        <Route
          key={s.path} path={`${match.url}/${s.path}`}
          render={props =>
            <SectionLayout {...props} section={s} resource={resource} component={s.component} />}
        />)}
      {/* for invalid paths, redirect to the intro of the resource which is just {resource-path} */}
      <Redirect to={match.url} />
    </Switch>
    <Footer />
  </div>
AuthResourceLayout.propTypes = {
  resource: PropTypes.object,
  match: PropTypes.any
}

export const ResourceLayout = AuthComponent(AuthResourceLayout)
