import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      You might be in a “drifting goals” scenario if…
    </p>

    <div className='quote'>
      <p className='quote-text'>
        You give in to pressure to lower an established goal rather than wait to see if actions that have been taken would have eventually gotten you to the goal.
      </p>
    </div>

    <img
      className='lesson-img large-img block'
      src='/images/courses/archetypes/06-drifting-goals/drifting-goals.svg'
      alt='Escalation'
    />

    <h3>
      Story of this Archetype
    </h3>

    <p>
      As you read each part of the story, press the “Next Part of Story” button below the graphic to see the story of the archetype unfold.
    </p>

    <div className='arch-components'>
      <div className='arch-p-container'>

        <p>
          <strong>Part 1:</strong> The story begins with a goal that hasn’t been met yet, creating a gap in the system between the goal and the actual
          state of the system.  For example, a couple might have the goal to have enough money to buy a house with their “dream features” (e.g. state-of-the-art
          appliances, swimming pool, large acreage), but currently they have far less than the necessary amount saved, creating a gap in their financial status.
          The accompanying simple diagram begins to illustrate the gap created by the difference between the goal and the actual state of the system.
        </p>

        <p>
          <strong>Part 2:</strong> One way to deal with the gap between the goal and the actual state of the system is to take some corrective
          action, or apply strategies, that will eventually improve the actual state of the system. In order to deal with the gap in their
          finances, our couple may decide to earn more money by working extra shifts when they can, and by investing more money.
          They may also decide to save money by eating out less often and cutting back on their cable tv service. In the accompanying diagram,
          as the couple’s goal increased, their financial gap increased, so they applied strategies, but some of those strategies,
          like investing, may take time to affect their financial state and close the gap, which will prompt them to keep applying their
          strategies/corrective actions. (The II between Corrective Action and Actual designates a time delay.) Note the B in the loop,
          signifying <a href='/courses/08-cld-balancing/meaning-balancing-feedback'>balancing feedback</a>, as the system tries to reach its goal.
        </p>

        <p>
          <strong>Part 3:</strong> Now is when the pressure kicks in for our couple! They’ve been saving money, so their actual financial state
          has improved, but the gap remains between their actual finances and their goal. That on-going gap is creating pressure to lower their
          financial goal. If they take the money they’ve saved so far, they can still buy a house, but it won’t have all their dream features.
          As the Drifting Goals archetype shows, the gap increases the pressure to lower the goal, which lowers the goal, which lowers the gap.
          (Note the <a href='/courses/08-cld-balancing'>balancing dynamic</a> in the top loop, signified by the B.)
        </p>

        <p>
          <strong>Part 4:</strong> Once our couple lowers their goal, the financial gap they faced is decreased, so they may feel they can apply
          fewer savings strategies; perhaps they’ll resume eating out and subscribe to their cable service again. Eventually, their financial state
          will start to decline again, increasing the gap. This leads them to more pressure to lower their goal, and the couple will be faced with
          removing more “dream features” from their future home purchase. This pattern could go on indefinitely, cycling through the archetype like
          a figure-8. The dynamics of the Drifting Goals archetype is such that the top loop with its pressure component dominates the bottom loop.
          The delay in the corrective actions’ affecting the actual state of the system to close the gap “loses out” to the more immediate effect of
          lowering the goal in order to close the gap.
        </p>
      </div>

      <div className='arch-build-container'>
        <ToggleImages
          images={[{ id: 1, image: '/images/courses/archetypes/06-drifting-goals/dg-part1.svg' }, { id: 2, image: '/images/courses/archetypes/06-drifting-goals/dg-part2.svg' }, { id: 3, image: '/images/courses/archetypes/06-drifting-goals/dg-part3-4.svg' }, { id: 4, image: '/images/courses/archetypes/06-drifting-goals/dg-part3-4.svg' }]}
        />
      </div>
    </div>

    <div className='exerpt'>
      <p>
        <strong>Reflection:</strong> Consider a time when you, or a group you were a part of, felt pressure to lower a goal while waiting to see the effect of corrective actions that had been implemented.
      </p>
    </div>

    <p>
      Continue to Lesson 2 for examples of stories that fit the Drifting Goals archetype.
    </p>
  </div>
