import * as React from 'react'

export const Section01 = () =>
  <div className='content'>

    <h1>
      Lesson Details
    </h1>

    <img src='images/resources/caring-classroom/stockflow-1.png' className='lesson-img med-img' />

    <h3>
      Length
    </h3>

    <ol>
      <li>
        <em>Behavior-over-time graphs</em> – plan for four 20-minute lessons (be flexible with time) over the course of four days
      </li>

      <li>
        <em>Stock-flow map</em> – plan for a 20-minute lesson to initially create the stock-flow map; also plan additional 10-minute blocks to host conversations that will lead to adding to the existing stock-flow map
      </li>
    </ol>

    <h3>
      Goal/Purpose
    </h3>

    <p>
      This lesson was designed as a way to rebuild classroom community midway through the year. Students will engage in critical thinking as they assist in creating behavior-over-time graphs and as they participate in conversation that will enable them to identify negative behaviors that “take away” from a caring classroom and positive behaviors that “add to” a caring classroom.
    </p>

    <h3>
      ST Concepts, Habits, and Tools
    </h3>

    <h4>
      Concepts
    </h4>

    <ol>
      <li>
        <strong>Building Vocabulary:</strong>  Students will learn and use vocabulary words that represent different shades of meaning (ex: explosive anger and miffed).  <em>Makes meaningful connections</em>
      </li>

      <li>
        <strong>Speaking and Listening:</strong>  Students will express opinions and will listen to the opinions of others with respect and confidence.  <em>Changes perspectives</em>
      </li>

      <li>
        <strong>Transfer:</strong>  Students will transfer knowledge and thinking exhibited on the behavior-over-time graphs to the stock-flow map.  <em>Makes meaningful connections</em>
      </li>
    </ol>

    <h4>
      Tools
    </h4>

    <ol>
      <li>
        <strong>Behavior-over-time graphs (BOTGs):</strong>  Students will express thinking to help complete several behavior-over-time graphs that depict desirable and undesirable classroom behaviors.
      </li>

      <li>
        <strong>Stock-flow map:</strong>  Students will transfer knowledge and thinking from the completed behavior-over-time graphs to the Caring Classroom stock-flow map.
      </li>
    </ol>

    <h4>
      Related Habits
    </h4>

    <ol>
      <li>
        <strong>Structure generates behavior</strong>
        <ul>
          <li>
            Students will analyze how agreed upon expectations and rules affect behavior
          </li>
        </ul>
      </li>

      <li>
        <strong>Makes meaningful connections within and between systems</strong>
        <ul>
          <li>
            Students will be able to make connections between behaviors, feelings, and reactions
          </li>

          <li>
            Students will make self-connections to feelings they have experienced and reasons behind the feelings
          </li>
        </ul>
      </li>

      <li>
        <strong>Seeks to understand the big picture</strong>
        <ul>
          <li>
            Students will consider how their behavior(s) affect the class as a whole
          </li>
        </ul>
      </li>

      <li>
        <strong>Considers an issue fully and resists the urge to come to a quick conclusion</strong>
        <ul>
          <li>
            Students will learn to think before they act in order to consider how their actions will affect classmates and the classroom environment
          </li>
        </ul>
      </li>

      <li>
        <strong>Identifies the circular nature of complex cause and effect relationships</strong>
        <ul>
          <li>
            Students will learn that a situation may cause certain feelings to arise. They will also learn that when the feelings arise that will affect how they handle the situation.  How they handle the situation will affect how the situation changes.
          </li>
        </ul>
      </li>

      <li>
        <strong>Changes perspectives to increase understanding</strong>
        <ul>
          <li>
            Students learn to “listen with their hearts” to try to understand their classmates’ points of views and feelings
          </li>
        </ul>
      </li>

      <li>
        <strong>Considers short-term, long-term and unintended consequences of actions</strong>
        <ul>
          <li>
            Students learn that behavioral choices have consequences
          </li>
        </ul>
      </li>

      <li>
        <strong>Checks results and changes actions if needed</strong>
        <ul>
          <li>
            Students learn to think about the results of their actions (behavioral choices) and consider how to improve results
          </li>
        </ul>
      </li>
    </ol>

    <div className='habits-row'>
      <img src='images/shared/habit-cards/shadow/structure.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/connections.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/big-pic.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/considers-issue.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/circular-nature.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/perspectives.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/consequences.png' className='lesson-img small-img' />

      <img src='images/shared/habit-cards/shadow/successive-approx.png' className='lesson-img small-img' />
    </div>

    <h3>
      Materials
    </h3>

    <ul>
      <li>
        Chart paper – 1 large piece for each behavior-over-time graph, stock-flow map, friendship mix anchor chart
      </li>

      <li>
        Markers – different colors for the charts
      </li>

      <li>
        Read Alouds of choice. Books used in this lesson:  <em>The Invisible Boy</em> by Trudy Ludwig,  <em>The Most Magnificent Thing</em> by Ashley Spires, and <em>The Golden Rule</em> by Ilene Cooper (modify and adjust choice of read alouds according to what behavior you want to focus on).
      </li>

      <li>
        Copies of “What is a Good Friend?” – 1 cover page for each student, multiple pages available for however many each student wants to add (at least 4 per student)
      </li>

      <li>
        Ingredients for Friendship Mix (We used a spoonful for each student of pretzels, marshmallows, M&Ms, Teddy Grahams, Goldfish, white chocolate chips, milk chocolate chips, Chex mix)
      </li>

      <li>
        Styrofoam bowls to hold friendship mix ingredients (7 bowls – one for each ingredient)
      </li>

      <li>
        Plastic spoons (1 to place in each ingredient bowl)
      </li>

      <li>
        Sealable containers/baggies (1 per student)
      </li>

      <li>
        Typed-up copies of Friendship Mix to send home with students (1 per student)
      </li>
    </ul>
  </div>
