import * as React from 'react'

export const Lesson05 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/habits/07-assumptions/botg.png'
      alt='BOTG'
    />
    <p>Another tool that is useful when surfacing and testing assumption is a behavior-over-time graph (BOTG). BOTGs help us see changes in a system and raise awareness of how people see change in different ways. When groups of people are asked to graph the change they see in quality of communication or level of commitment for a particular project, BOTGs can help surface the different ways people perceive that change. For example, a work team is asked, “How have you seen our level of effectiveness change over the past six months as we worked together on this project?” After spending time individually reflecting and drawing graphs, individuals surface their own interpretation of the team’s level of effectiveness.  Graphs may look very different based on people’s position, the role they play or the value they place on various aspects of effectiveness.</p>
    <p>The graphs can show a wide range of diverse views, but the richness of the tool is that it gives people a visual way to surface their assumptions and test them by telling the story of their graphs.  Let’s “listen” in as this team shares the stories associated with their individual graphs.</p>
    <div className='botgs'>
      <div className='botg-content'>
        <img
          src='/images/courses/habits/07-assumptions/botg-a.svg'
          alt='BOTG'
        />
        <div className='botg-quote'>
          <p className='botg-quote-text'>“Six months ago, I remember we really struggled to work together because we all had different ideas about how we should approach this project. Once we had some time together and had a chance to hear one another out, it seemed that our effectiveness just shot up very quickly. I love working with all of you!”</p>
        </div>
      </div>
      <div className='botg-content'>
        <img
          src='/images/courses/habits/07-assumptions/botg-b.svg'
          alt='BOTG'
        />
        <div className='botg-quote'>
          <p className='botg-quote-text'>“When we first got together, we were all so excited about the project that I felt our ability to work together grew fairly rapidly. Then, after some time, the project became frustrating and a little boring. I think we plateaued because we were ready to move on.”</p>
        </div>
      </div>
      <div className='botg-content'>
        <img
          src='/images/courses/habits/07-assumptions/botg-c.svg'
          alt='BOTG'
        />
        <div className='botg-quote'>
          <p className='botg-quote-text'>“Like Denise, I felt our initial enthusiasm actually had us working together really well right off the bat. After some time that effectiveness seemed steady. I think about three months ago we hit a point where we had trouble agreeing about next steps, and we took a dip when we couldn’t agree about our roll out. I think we currently need to work on our collaboration — we are doing too many things independently and not working together, yet I also enjoy working with this team even when times are tough!”</p>
        </div>
      </div>
      <div className='botg-content'>
        <img
          src='/images/courses/habits/07-assumptions/botg-d.svg'
          alt='BOTG'
        />
        <div className='botg-quote'>
          <p className='botg-quote-text'>“In the beginning of our work together, I saw it kind of like Michael and Denise where we were excited about the possibilities and really were good at sharing ideas. At one point I thought I was part of a dream team the way we communicated and strategized about a workable plan. Then, when it came to deciding on one approach for implementation, our effectiveness broke down as we each tried hard to sell our specific idea versus efforts to compromise or reach a consensus. I think we are stuck.”</p>
        </div>
      </div>
    </div>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-well-being.svg'
      alt='Well-being Example'
    />
    <p>Having groups draw BOTGs prior to discussing their perspectives can be very useful in helping people surface and test their personal assumptions and expose pervasive mental models (assumptions) within the group.  The graphing exercise is very beneficial for individuals as well.</p>
    <p>Perhaps you want to test your assumptions about your current fitness practices. Graphing multiple variables, such as weight, cardio fitness, calorie intake and stamina will give you much more information than your “top-of-the-head” sense of your own well-being and healthy living practices.</p>
    <p>To learn more about BOTGs, visit this <a href='/courses/01-behavior-over-time-graphs'>Tools course</a> in TTS.</p>
  </div>
