import * as React from 'react'
import { courseList } from 'components/courses'
import { getSessionAuth } from 'components/shared/session'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'
import Helmet from 'react-helmet'

export const HabitsCatalog = () => {
  const { user } = getSessionAuth()

  const getAuth = user => ({
    divClass: !user ? 'is-disabled' : '',
    buttonClass: !user ? 'block' : 'none'
  })

  const selected = 'habits'
  return (
    <div>
      <Header />
      <div className='main'>
        <Helmet>
          <title>Habits of a Systems Thinker Courses - Thinking Tools Studio</title>
          <meta name='description' content='Learn about and apply the Habits of a Systems Thinker.' />
          <meta property='og:title' content='Habits of a Systems Thinker Courses - Thinking Tools Studio' />
          <meta property='og:description' content='Learn about and apply the Habits of a Systems Thinker.' />
          <meta property='og:image' content='https://thinkingtoolsstudio.org/images/site/habitcards-all.png' />
          <meta property='og:url' content='https://thinkingtoolsstudio.org/courses/habits' />
          <meta name='twitter:image' content='https://thinkingtoolsstudio.org/images/site/habitcards-all.png' />
          <meta name='twitter:image:alt' content='Habits of a Systems Thinker' />
        </Helmet>

        <div className='content'>
          <h1 className='course-home-title'>Habits of a Systems Thinker Courses</h1>
          <img className='course-home-img' src='/images/site/habitcards-all.png' />
          <p>The Waters Center’s Habits of a Systems Thinker help learners understand how systems work and how actions taken can impact results seen over time.</p>
          <p>The Habits encompass a spectrum of strategies that foster problem-solving and thoughtful reflection about systems of interest.</p>
          <p>Though “habit” is defined as a usual way of doing things, the Habits of a Systems Thinker do not limit ways of thinking. Instead, they encourage flexible thinking and appreciation of new, emerging insights and multiple perspectives.</p>
          <p>The Habits of a Systems Thinker courses are designed to help you learn the Habits by way of explanation, examples, reflection and practice.</p>
          <p>There are 14 Habits of a Systems Thinker. You will find a course for each of these Habits, in addition to a “Getting Started” course (15 total Habits courses). Each course is made up of multiple lessons and practice exercises. While you can take the Habits courses in any order that you like, we recommend taking them in the order listed below. Click on the course name to get started.</p>
          <p>Be sure to take your time reading through each lesson and completing the practice exercises. As you will learn, systems thinking reminds us to slow down our thinking and resist the urge to come to quick conclusions — the work you do in the Thinking Tools Studio is no exception.</p>
          <p>We hope you enjoy learning the Habits of a Systems Thinker!</p>
          <div className='course-cards'>
            {/* For selected group, display list of courses */}
            {courseList
              .filter(c => c.group === selected)
              .map(c =>
                <div key={c.path} className='card-content'>
                  <img
                    className='card-img'
                    src={c.image}
                    alt='Course Image'
                  />
                  <div className='course-info'>
                    <h2><a className={getAuth(user).divClass} href={`/courses/${c.path}`}>{c.name}</a></h2>
                    <p className='card-summary'>{c.summary}</p>
                    <p className='card-time'><b>Estimated Time:</b> {c.time}</p>
                    <a href='https://waterscenterst.org/login'>
                      <button style={{ display: getAuth(user).buttonClass }} className='auth-button'>
                        Log in or Sign Up to View This Content
                      </button>
                    </a>
                  </div>
                </div>)}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
