import * as React from 'react'

export const Lesson04 = () =>
  <div className='content'>
    <p>
      The second way to label a causal connection is with an <span className='red'><strong>O</strong></span>.
    </p>
    <p>
      An <span className='red'><strong>O</strong></span> by the arrowhead means that the variables are changing in the opposite direction—as one variable increases, it causes the other to decrease; or as one variable decreases, it causes the other to increase. Another way to label a causal relationship which changes in the opposite direction is by using a minus sign  -  to show that the causal relationship is inverse. The original cause moves the effect variable in an inverse direction.  In this course we will be using <span className='red'><strong>O</strong></span>’s to show opposite or inverse relationships.
    </p>
    <p>
      What other causal pairs from Practice Exercise #1 change in the opposite direction and are labeled with an <span className='red'><strong>O</strong></span> at the arrowhead?
    </p>
    <h3>
      Example #1 to get you started:
    </h3>
    <div className='causal-link'>
      <div className='causal-column'>
        <h4>
          Amount of support at work
        </h4>
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
        <p className='link-label red'>O</p>
      </div>
      <div className='causal-column'>
        <h4>
          Level of stress at work
        </h4>
      </div>
    </div>

    <h3>
      Tell the story of the link:
    </h3>
    <p>
      <em>When the amount of support I receive at work increases, my stress level decreases.  </em>
    </p>
    <p className='mobilecenter'>
      <strong>Or</strong>
    </p>
    <p>
      <em>When my support decreases, my stress level may rise.</em>
    </p>
    <h3>
      Example #2 to get you started:
    </h3>
    <div className='causal-link'>
      <div className='causal-column'>
        <h4>
          Extracurricular activity obligations
        </h4>
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
        <p className='link-label red'>O</p>
      </div>
      <div className='causal-column'>
        <h4>
          Homework completion
        </h4>
      </div>
    </div>
    <h3>
      Tell the story of the link:
    </h3>
    <p>
      <em>As extracurricular activity obligations increase, students may not have the time to complete homework, so homework completion decreases.  </em>
    </p>
    <p className='mobilecenter'>
      <strong>Or</strong>
    </p>
    <p>
      <em>As extracurricular activity obligations are limited, students have more time to complete homework, so homework completion increases.</em>
    </p>
  </div>
