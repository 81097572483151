import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <p>
      Review the list of trends you could be experiencing on the right-hand side of the arrows below. Consider what causal trends could be linked to what you could be experiencing.
    </p>
    <p>
      Review examples of causes on the left-hand side. Using the text box, add your own possible causes.
    </p>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <h3>Causal trend: What are possible causes?</h3>
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h3>
          Trend you could be experiencing
        </h3>
      </div>
    </div>
    <h3>#1</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Number of tasks you are responsible for
        </p>
        <p>
          Amount of support at work
        </p>
        <p>
          High expectations
        </p>
        <Field
          name='cause1'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Level of stress at&nbsp;work
        </h4>
      </div>
    </div>
    <h3>#2</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Need for validation
        </p>
        <p>
          Joy of reconnecting with friends
        </p>
        <p>
          Perceived benefits of social media
        </p>
        <Field
          name='cause2'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Dependence on social&nbsp;media
        </h4>
      </div>
    </div>
    <h3>#3</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Level of commitment
        </p>
        <p>
          Access to fitness equipment/activities
        </p>
        <p>
          Time required for work
        </p>
        <Field
          name='cause3'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Time allocated to&nbsp;fitness
        </h4>
      </div>
    </div>
    <h3>#4</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Amount of high calorie foods in diet
        </p>
        <p>
          Amount of exercise
        </p>
        <p>
          Level of stress
        </p>
        <Field
          name='cause4'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Weight gain
        </h4>
      </div>
    </div>
    <h3>#5</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Level of confidence
        </p>
        <p>
          Fear of failure
        </p>
        <p>
          Skill level
        </p>
        <Field
          name='cause5'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Ability to take on new tasks
        </h4>
      </div>
    </div>
    <h3>#6</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Number of times you give out your email address
        </p>
        <p>
          Use of spam protectors
        </p>
        <p>
          Presence on social media
        </p>
        <Field
          name='cause6'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Amount of spam mail in your email inbox
        </h4>
      </div>
    </div>
    <h3>#7</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Frequency of illness
        </p>
        <p>
          Parent involvement
        </p>
        <p>
          Student engagement
        </p>
        <Field
          name='cause7'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          School absenteeism
        </h4>
      </div>
    </div>
    <h3>#8</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Opportunity for student interactions
        </p>
        <p>
          Student interest in learning
        </p>
        <p>
          Level of safety (emotional and physical at school)
        </p>
        <Field
          name='cause8'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Level of student engagement
        </h4>
      </div>
    </div>
    <h3>#9</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Job satisfaction
        </p>
        <p>
          Quality of relationships
        </p>
        <p>
          Opportunities to connect with one another
        </p>
        <Field
          name='cause9'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Faculty/staff morale
        </h4>
      </div>
    </div>
    <h3>#10</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Opportunities for advancement
        </p>
        <p>
          Salary scale
        </p>
        <p>
          Faculty/staff morale
        </p>
        <Field
          name='cause10'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Staff turnover
        </h4>
      </div>
    </div>
    <h3>#11</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Clarity of assignments
        </p>
        <p>
          Extracurricular obligations
        </p>
        <p>
          Student motivation to succeed
        </p>
        <Field
          name='cause11'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Homework completion
        </h4>
      </div>
    </div>
    <h3>#12</h3>
    <div className='causal-link tall-link'>
      <div className='causal-column'>
        <p>
          Clarity of behavioral expectations
        </p>
        <p>
          Student need for attention
        </p>
        <p>
          Classroom management skills
        </p>
        <Field
          name='cause12'
          component='textarea'
          className='reflection-input cause'
          placeholder='Enter your causes here.'
        />
      </div>
      <div className='causal-column'>
        <img
          className='arrow'
          src='/images/courses/tools/05-causal-links/arrow.svg'
          alt='arrow'
        />
      </div>
      <div className='causal-column'>
        <h4>
          Disciplinary infractions
        </h4>
      </div>
    </div>
    <p>
      Identifying a <strong>cause and effect</strong> relationship helps you focus on two important elements of understanding: <strong><em>What happened</em></strong> and <strong><em>Why it happened</em></strong>.
    </p>
  </div>
