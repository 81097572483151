import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <iframe
        width='560'
        height='315'
        src='https://www.youtube.com/embed/bfVcqF6Q8nI?rel=0'
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  </div>
