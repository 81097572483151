import * as React from 'react'
import { resourceList } from 'components/resources'
import { getSessionAuth } from 'components/shared/session'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'
import Helmet from 'react-helmet'

export const GuideCatalog = () => {
  const { user } = getSessionAuth()

  const getAuth = user => ({
    divClass: !user ? 'is-disabled' : '',
    buttonClass: !user ? 'block' : 'none'
  })

  return (
    <div>
      <Header />
      <div className='content'>
        <Helmet>
          <title>Facilitation Guides – Systems Thinking Resources</title>
          <meta name='description' content='Guides to apply systems thinking concepts in a group setting.' />
          <meta property='og:title' content='Facilitation Guides – Systems Thinking Resources' />
          <meta property='og:description' content='Guides to apply systems thinking concepts in a group setting.' />
          <meta property='og:image' content='https://thinkingtoolsstudio.org/images/resources/shared/facilitation-icon-blue.svg' />
          <meta property='og:url' content='https://thinkingtoolsstudio.org/resources/guides' />
          <meta name='twitter:image:alt' content='Systems Thinking Facilitation Guides' />
        </Helmet>

        <h1 className='resource-home-title mobilecenter'>
          Facilitation Guides
        </h1>

        <img
          className='catalog-img'
          src='/images/resources/shared/facilitation-icon-blue.svg'
          alt='Facilitation Guides Icon'
        />

        <h2 className='mobilecenter'>
          Systems Thinking Resources
        </h2>

        <p>
          Facilitation guides will aid those looking to facilitate systems thinking workshops, learning sessions, and activities
          with groups of varying sizes and from various types of systems. Each facilitation guide has an intended audience (children through adults)
          — be sure to explore all the guides to see which will suit your needs best. Keep in mind that with a little creativity and planning, many can
          be adapted to best address the needs of your participants.
        </p>
        <div className='resource-cards'>
          {resourceList
            .filter(r => r.groups.includes('guide'))
            .sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
            .map(r =>
              <div key={r.path} className='r-content'>
                <div className='r-info'>
                  <h2>
                    <a className={getAuth(user).divClass} href={`/resources/${r.path}/${r.sections[0].path}`}>{r.name}</a>
                  </h2>

                  <p className='r-summary'>
                    {r.content}
                  </p>

                  <p className='r-concepts'>
                    <b>ST Habits & Tools:</b> {r.concepts}
                  </p>

                  <p className='r-audience'>
                    <b>Audience:</b> {r.audience}
                  </p>

                  <a href='https://waterscenterst.org/login'>
                    <button style={{ display: getAuth(user).buttonClass }} className='auth-button'>
                      Log in or Sign Up to View This Content
                    </button>
                  </a>
                </div>

                <div className='r-icons'>
                  {r.icons.map(i =>
                    <img
                      key={i.id}
                      className='resource-icon'
                      src={i.source}
                      alt={i.name}
                    />)}
                </div>
              </div>)}
        </div>
      </div>
      <Footer />
    </div>
  )
}
