import * as React from 'react'
import { Field } from 'redux-form'
import { FileUpload } from 'components/shared/file-upload'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <p>Think of a recuring issue that you have tried to address, but the issue persists.</p>
    <p><a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/iceberg-template-tts.pdf'>Click on this link to download and print an iceberg template</a>.  Or, draw an iceberg and use the tools at each level to examine how the system’s structure is generating the behavior you recognize as the issue.</p>
    <p>Share your iceberg with a colleague or work group.  Talk through the iceberg and explain each tool used and your reflections at each level.</p>
    <Field
      name='stockFlowTemplate'
      component={FileUpload} image
      instructions='Take a picture of your iceberg and upload it here.'
    />
    <h4>Describe the experience of using the iceberg.</h4>
    <Field
      name='experience' component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What went well?</h4>
    <Field
      name='well' component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What would you do differently?</h4>
    <Field
      name='different' component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
