import * as React from 'react'
import { Field } from 'redux-form'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      Mental models influence the ways we interpret the world and the systems we live in. People can look at, listen to and experience the very same situation and leave with diverse impressions about what occurs.  In <em>Thinking in Systems</em>, by Donella Meadows, it is suggested that we should actively work to understand the mental models that exist in a system because it is one of the most impactful places to intervene.
    </p>

    <div className='quote'>
      <p className='quote-text'>
        “To break a mental model is harder than splitting an atom.”
      </p>

      <p className='quote-author'>
        — Albert Einstein
      </p>
    </div>

    <p>
      Mental models are personal beliefs, values, and perceptions that control what we see, how we interpret what we see, and the beliefs we develop based on what we see. Mental Models are deeply buried and hidden from our awareness. They are seldom examined and often accepted without thought. This, in turn, creates a barrier to a deep understanding of systems.
    </p>

    <img
      className='lesson-img'
      src='/images/courses/tools/02-ladder-of-inference/lesson-one-img.png'
      alt='Backpacks'
    />

    <p>
      Everyone steps into their daily systems with a “backpack” of mental models. Our mental models are the result of our culture and background; in other words, the life experiences we carry with us. These beliefs and assumptions influence our view of the world and how we interpret and respond to life’s challenges and opportunities, both personally and professionally.  Because mental models are developed from individual life experiences, everyone has something different in their backpack.  We all see our systems from a different perspective.
    </p>

    <p>
      People can experience the very same situation (see the same thing, participate in the same conversation, etc.) but walk away with very different impressions about what occurred. Think about a family holiday dinner. When you are reminiscing about an event you, your brothers/sisters, and parents experienced, what often happens? Do you find that different people have different perceptions of what occurred?
    </p>

    <p>
      Consider this example about a woman’s experience with her family on a road trip. The woman’s mother was taking the family to the beach, when suddenly, the car began to smoke. Her mother pulled over and noticed flames — she quickly instructed everyone to jump out of the vehicle. A police officer pulled up and handled the situation. When recounting this situation years later over dinner, the woman noticed that even the basic facts of the event were perceived, remembered, and interpreted differently based on personal characteristics of her family members. This included age, gender, and position of responsibility in the family. Her little brothers remembered the type of motorcycle the officer drove, but the woman did not even recall a motorcycle at all! She did, however, recall that the police officer was very good looking and kind. Her mother remembered how anxious she was, worrying about her children. Again, this story demonstrates different interpretations of the same event.
    </p>

    <p>
      Why do we experience the same event and recall it in different ways?  Because we pay attention to different details; and the details we pay attention to are shaped by the images and stories we all carry of our experiences in the world (i.e. our mental models).  In turn, our mental models influence the details we see and reinforce our existing beliefs.
    </p>

    <p>
      It is no wonder Einstein formulated his perception of mental models!
    </p>

    <h3>
      Reflection Questions:
    </h3>

    <h4>
      Can you think of a family event that was viewed differently by everyone? Describe the experience.
    </h4>

    <Field
      name='experience'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      Describe a time in your classroom or workplace when your mental model of a lesson/meeting was different to that of your students or colleagues.
    </h4>

    <Field
      name='time'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h3>
      Connections to Habits of a System Thinker
    </h3>

    <img
      className='lesson-img small-img'
      src='/images/shared/habit-cards/shadow/mental-models.png'
      alt='Mental Models'
    />

    <p>
      A systems thinker is aware of how beliefs and attitudes influence perspectives and actions.
      As Peter Senge states, mental models are deeply ingrained assumptions, generalizations, or even pictures or images that influence how we understand the world and how we take action.
      The Habit illustration shown here demonstrates how two individuals, experiencing the very same event, can have two different mental models.
    </p>

    <h4>
      Questions you can ask to develop an awareness of the effect of mental models in systems are:
    </h4>

    <ol>
      <li>
        How are the current metal models advancing our desired results?
      </li>

      <li>
        How are the current mental models hindering our efforts in this area?
      </li>

      <li>
        How am I helping others see the influence that mental models have on our decision making?
      </li>
    </ol>
  </div>
