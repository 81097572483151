import * as React from 'react'

export const Lesson03 = () =>
  <div className='content'>
    <p>
      <a href='/courses/03-big-picture'>A systems thinker seeks to understand the big picture</a>. Practicing this Habit of a Systems Thinker
      is a great way to avoid finding yourself in a tragedy of the commons scenario. Seeing the big
      picture will help you and others recognize when they are using a common resource and how personal
      gain could be coming at the expense of others or how using the resource in an unsustainable way
      will eventually result in depletion of the resource.
    </p>

    <p>
      Education of those who use a common resource is essential. Acknowledging the limitations of the commons
      can increase understanding of how individual actions impact the resource as a whole. Recognizing the <a href='/courses/10-time-delays'>time delays</a> involved
      with resource depletion helps people understand the long-term implications of their
      resource use. Understanding time delays is important for reconciling short-term individual rewards
      with long-term cumulative consequences. Since the time frame of a commons depletion is typically
      much longer than the time frame for individual gains, it is important that interventions are designed
      so that current actions will contribute to long-term solutions by avoiding short-term problems.
    </p>

    <p>
      In addition, consider providing a feedback mechanism directly to individuals or groups using a
      particular resource. For example, some water fountains designed for use with refillable bottles
      include a counter that shows how many plastic bottles have not been used because consumers have
      taken the time to refill or reuse a plastic bottle. Using refillable water bottles reduces the
      need for the manufacture of plastics and slows the growth of landfills. The feedback helps
      individuals see the impact they can have on preserving a limited resource.
    </p>

    <p>
      Another way to rewrite the tragedy of the commons story is to consider ways to manage or regulate
      the commons. Regulations can come in the form of rules or policies or they can be voluntary actions
      on the part of individuals who are using the commons. Some cities have specific hours of the day
      when individuals are asked to refrain from outdoor watering in order to preserve ground water
      reserves. Sometimes, when people are aware of a limited resource, they choose to regulate themselves.
    </p>
  </div>
