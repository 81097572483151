import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Trends are effective ways to track progress in the workplace. Data-driven trends can show quarterly profits and losses, student achievement data, employee attendance, ratings of customer satisfaction, movement toward a goal (e.g. golf scores, pounds lost or steps walked each day). In addition to hard, numerical data, trends graphs can help make meaning of people’s perceptions. Specific points on the graph at any point in time are not nearly as important as the shape of the line. Some BOTGs represent generic patterns. These generic patterns of change are actually “grown-up” versions of the same graphs that the young children creatively named.</p>
    <div className='graphs'>
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/linear-growth.png'
        alt='Linear Growth'
      />
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/linear-decline.png'
        alt='Linear Decline'
      />
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/exponential-graph.png'
        alt='Exponential Growth'
      />
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/s-graph.png'
        alt='S-Shaped Growth'
      />
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/oscillation-graph.png'
        alt='Oscillation Growth'
      />
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/steady-graph.png'
        alt='Steady State Growth'
      />
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/goal-seeking.png'
        alt='Goal Seeking'
      />
      <img
        className='graph'
        src='/images/courses/habits/06-patterns/decreasing-growth.png'
        alt='Decreasing Growth'
      />
    </div>
    <p>Choose one of the above generic BOTGs (e.g. linear growth or decline, oscillation, s-shaped growth, goal-seeking). Think of a scenario or story from your work or family setting that has a trend that matches the graph you chose. Talk through your story while pointing at various parts of the graph, and see what kind of conversation develops.</p>
    <h4>Describe the story of your BOTG.</h4>
    <Field
      name='your-botg'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h3>Using the generic BOTGs, respond to the following reflection questions:</h3>
    <h4>Which graphs are easiest to apply to your workplace or family setting?</h4>
    <Field
      name='family-setting'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Which graphs are most challenging to apply to your workplace or family setting? Why?</h4>
    <Field
      name='apply-to'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
