import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='article-container'>
      <h3>
        Abstract:
      </h3>

      <p>
        Visit the link below, Pages 4-6, to read the article, “No Better Time: Systems Thinking for All” written by Waters Center President, Tracy Benson and published in the Winter 2021 edition of Kansas Child Magazine.
      </p>

      <p>
        Kansas Child magazine is intended to provide a forum for the discussion of child care and early education issues and ideas.
      </p>

      <p>
        <strong>Author:</strong> Tracy Benson
      </p>

      <a href='https://www.yumpu.com/en/document/read/65177437/2021-winter-kansas-child' target='_blank' rel='noopener noreferrer'>
        <button className='orange'>
          View Article in Kansas Child Magazine
        </button>
      </a>
    </div>
  </div>
