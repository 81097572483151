import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/05-mental-models/course-img.png'
      alt='Mental Models'
    />
    <p>
      When considering adopting a new pet, it is easy to imagine two people having very different reactions. Based on experience, one might see it as an opportunity for fun and play, while another sees a pet as an added financial responsibility. Mental models are the assumptions and beliefs people develop from their experience over time. Mental models influence the ways we interpret the world we experience. Because every individual has their own personally developed mental models based on their culture and life experiences, they oftentimes see the world in quite different ways.
    </p>
    <div className='quote'>
      <p className='quote-text'>“Remember, always, that everything you know, and everything
        everyone knows, is only a model. Get your model out there where it can be
        viewed. Invite others to challenge your assumptions and add their own … Expose
        your mental models to the light of day.”
      </p><br />
      <p className='quote-author'> – Donella H. Meadows</p>
    </div>

    <img
      className='system-icon left'
      src='/images/courses/shared/icon-workplace.svg'
      alt='Workplace Example'
    />

    <h3>The Staff Meeting</h3>

    <p>The office manager, Judy, facilitated a staff meeting where she had to review
      new procedures for requesting and documenting vacation time. After the meeting,
      three different conversations took place:
    </p>

    <h4>CONVERSATION #1: JUDY AND MARCUS</h4>
    <p>Judy shared with Marcus, “I was a little nervous at first, but after I
      introduced the new process for submitting vacation leave, everyone seemed to
      really buy in. No one even asked a question. It went much better than I
      expected.” Marcus responded, “I agree that people were quiet, but what made you
      think they were OK with what you said? Even though people were quiet, I sensed
      some tension and resistance.”
    </p>
    <h4>CONVERSATION #2: ROBERTO AND ELSA</h4>
    <p>At the same time, in an office down the hall, Roberto said, “I can’t believe
      we have to go through so much paperwork just to take a day off! Judy’s
      expectations are unreasonable.” Elsa then said, “If you felt that way, why
      didn’t you say something? I don’t really care about new procedures. I will just
      call in sick when I want to take a vacation day — so much easier!”
    </p>
    <h4>CONVERSATION #3: CHRIS AND MONICA</h4>
    <p>In another office, Chris debriefed the meeting with Monica. “I’m surprised
      that Judy and the other managers didn’t ask for our input before dictating the
      new procedures.” Monica responded, “I saw it a little differently. I don’t have
      the time to give input about mundane things like paperwork related to vacation
      leave. I’m glad they made a decision and just told us what to do!”
    </p>
    <p>When people look at, listen to and experience the very same situation, they
      can leave with diverse impressions about what occurred. Whether people are
      reading the same book or attending the same event, individuals derive their
      opinions and impressions based on their preferences and what they choose to pay
      attention to and value.
    </p>
    <p>People pay attention to different things and notice aspects of their
      experience based on what is important to them. For example, a past experience,
      family value or a personal priority can influence what people hold as most
      significant. Important priorities and values contribute to the development of
      mental models.
    </p>
    <img
      className='lesson-img'
      src='/images/courses/habits/05-mental-models/lesson-one-img2.png'
      alt='Book Cover'
    />
    <p>Mental models are sometimes referred to as paradigms. In Donella Meadows’
      primer, "Thinking in Systems", she discusses key places to intervene in a system
      to get more of what you want. These interventions are called leverage points.
      She proposes that one of the highest, most impactful places to intervene in a
      system is with the paradigms or mental models people hold of the system. She
      writes, “You can say paradigms are harder to change than anything else about a
      system … there’s nothing physical or expensive or even slow in the process of
      paradigm change. In a single individual it can happen in a millisecond. All it
      takes is a click in the mind, a falling of scales from the eyes, a new way of
      seeing. Whole societies are another matter — they resist challenge to their
      paradigms harder than they resist anything else.” (pp. 163-64)
    </p>
    <p>Mental models, in the form of paradigms, can be difficult to change. It is
      unwise to think you can single-handedly change or shift another person’s mental
      model. However, individuals can develop and nurture the environmental conditions
      where people can reflect and consider their own mental models. This safe culture
      of reflection can foster desirable shifts and paradigm adjustments. It is a
      personal choice to be open to the “click in the mind” that opens up new
      possibilities. Systems thinkers recognize the power and influence of mental
      models, and this important Habit of thinking reminds us that mental models are
      people’s current reality and greatly influence their view of the future. In
      addition, Meadows goes deeper into her analysis of places to intervene in
      systems, and encourages us all to free ourselves of the strong influence of our
      paradigms or mental models.
    </p>
    <div className='quote'>
      <p className='quote-text'>“That is to keep oneself unattached in the arena of paradigms, to
        stay flexible, to realize that no paradigm is ‘true,’ that everyone, including
        the one that sweetly shapes your own worldview, is a tremendously limited
        understanding of an immense and amazing universe that is far beyond human
        comprehension.”
      </p><br />
      <p className='quote-author'> – Donella H. Meadows</p>
    </div>
    <p>This flexibility of thinking and interpreting reminds us to enter each
      situation with a beginner’s mind in order to take in a wide pool of information.
      There is value in staying mindful of how our mental models are developed and how
      they influence our decision-making and actions.
    </p>
  </div>
