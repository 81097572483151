import * as React from 'react'

export const Lesson05 = () =>
  <div className='content'>
    <p>Let’s use two different cases to learn how feedback loops can help increase understanding and build systems thinking capacity to navigate challenging situations.</p>
    <h3>A Teacher’s Dilemma</h3>
    <img
      className='system-icon right'
      src='/images/courses/shared/icon-school.svg'
      alt='School Example'
    />
    <p>Here is a story of a teacher who wanted to justify an intuition and develop a theory to examine some dynamics in her classroom. Teacher Sara was concerned about some of her students’ low achievement levels, as indicated by their standardized test results. She was feeling pressure to adopt teaching strategies that focused on test prep and other “teach to the test” approaches that would likely cause some immediate improvement in test results.</p>
    <p>Knowing that increases in results were critical to her reputation and evaluation as a teacher, she felt she needed to comply with this test prep expectation. However, Sara was frustrated because her students hated “teach to the test” instruction and often became bored and disengaged. She began to think about other important factors that she should be paying attention to in order to impact measures of student achievement.</p>
    <img
      id='cause-effect'
      src='/images/courses/habits/09-circular-nature/cause-effect.svg'
      alt='Cause and Effect'
    />
    <p>Sara recognized that when students accomplished small successes, as in being able to explain a concept to a peer or finally figuring out a way to solve a math problem, their beliefs in their own capabilities were positively impacted. She needed to look for many more ways where students could uncover evidence of their own learning that made them feel successful. Waiting for achievement tests or end of the unit tests were not enough. This shift in self-belief motivated her students to put forth more effort in their learning. In other words, any evidence of success caused students to increase their self-belief, which caused an increase in their level of engagement in learning. As students became more engaged, their level of achievement was positively impacted. Sara also recognized the impact when students experienced a wide range of measures of student achievement that provided evidence of personal success.</p>
    <img
      id='r1-loop'
      src='/images/courses/habits/09-circular-nature/r1-loop.svg'
      alt='R1 Loop'
    />
    <p>She drew the R1 loop to the right to show her theory. Causal loop B1 shows the initial problem with the test prep solution. When Sara’s students’ test scores are low, the need for test prep goes up, which helps address the low-test scores. This loop illustrates how test prep may initially balance out the problem of low student achievement, as measured by test scores. Causal loop R1 helped Sara develop her theory of what drives and motivates student achievement and success.</p>
    <p>What became evident for Sara is that test prep may positively impact student achievement in the short-run (orange solid arrow), but it actually has a diminishing impact on student engagement over time (orange dotted arrow). She saw this diminishing effect as problematic for boosting ongoing achievement in the classroom because of decreasing levels of engagement (see causal loop R2). Her diagram shows how test prep (B1) might actually be working against her effort to boost achievement (R1). R2 shows that test prep over time may actually be causing a diminishing spiral impact on student achievement.</p>
    <h3>Employee Buy-in</h3>
    <img
      className='system-icon'
      src='/images/courses/shared/icon-workplace.svg'
      alt='Workplace Example'
    />
    <p>Consider the rollout of a new initiative for your organization. The following example includes questions and sample responses that lead to the development of a causal loop.</p>
    <p>This example serves as a lead-in to Practice Exercise #4: Feedback Loop Practice.</p>
    <div className='q-a'>
      <p>Q: What aspect of your system would you like to see increase or decrease?</p>
      <p>A: Employee buy-in to a new initiative is critical to my organization.</p>
    </div>
    <div className='q-a'>
      <p>Q: What key factor causes an increase in employee buy-in?</p>
      <p>A: It seems important for the employee to believe that the initiative has value, so a key factor may be perceived value of the initiative.</p>
    </div>
    <div className='q-a'>
      <p>Q: How has perceived value grown over time?</p>
      <p>A: Having a quality experience resulting in evidence that the new initiative has value would increase the perception of value. So, engaging employees in ways that surface this evidence would be an important factor in this system. The more employees are engaged in ways that demonstrate proof of value, the more their buy-in will undoubtedly increase. This is a reinforcing loop.</p>
    </div>
    <img
      id='evidence-loop'
      src='/images/courses/habits/09-circular-nature/evidence-loop.svg'
      alt='Evidence Loop'
    />
    <p>Systems thinkers not only believe in cause and effect, but they also actively seek to understand circular causal relationships to increase understanding of complex systems. With practice, causal loop drawings help explain essential components and their relationships.</p>
    <div className='quote'>
      <p className='quote-text'>“Shallow men believe in luck. Strong men believe in cause and effect.”</p>
      <p className='quote-author'> — Ralph Waldo Emerson, essayist</p>
    </div>
  </div>
