import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='article-container'>
      <h3>
        Abstract:
      </h3>

      <p>
        This article provides a summary of the Health System Forum, presented by the Waters Center for Systems Thinking on Feb. 11, 2021. Specifically, the community that was built between participants looking to create a more productive, equitable health system for all.
      </p>

      <p>
        <strong>Author:</strong> Alexis Kieft
      </p>
    </div>
  </div>
