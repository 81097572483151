import React from 'react'
import { ToggleImage } from 'components/shared/toggle-image'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Systems archetypes help explain causal relationships and apply in many contexts. You can substitute any non-renewable resource for common pastureland to tell a similar story about what happens when a limited resource becomes depleted. Overfishing, for example, occurs when more fish are caught than can be replaced through natural reproduction. This is a problem in many parts of the world today. Billions of people rely on fish as their primary source of protein and their economic livelihood. Increased fishing coupled with unsustainable practices is pushing many fish populations to the point of collapse. More than 85% of the world’s fisheries are at their limit, despite increased regulation. This is a complex challenge affecting large portions of the world’s economy, and its complexity is due in part to the fact that the consequences of fishing practices are not immediately apparent, but rather occur over decades. Once again, time delays make it more difficult to understand the effect of overfishing on the overall situation.</p>
    <ol>
      <li>Download this template of the <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/tragedy-of-the-commons-template-so.pdf' target='_blank' rel='noopener noreferrer'>Tragedy of the Commons archetype</a>. Each box of the archetype on the template is sized to fit a small sticky note, so you can label each variable in the loops to help you tell the story.</li>
      <li>Read over the fishing scenario again, and apply the archetype template to the scenario by placing and labeling a sticky note for each box in the loops.</li>
    </ol>
    <p>Once you finish, click on the button below to see one example of this archetype applied to overfishing. Please note that this example is not the only right answer. Compare your template with the example, and if possible, with others engaged in this same exercise.</p>
    <ToggleImage
      src='/images/courses/habits/10-time-delays/Tragedy-of-the-Commons-Example.png'
      alt='Tragedy of the Commons Example'
      showText='Show Example'
      hideText='Hide Example'
    />
  </div>
