import * as React from 'react'
import { resourceList } from 'components/resources'
import { getSessionAuth } from 'components/shared/session'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'
import Helmet from 'react-helmet'

export const LessonCatalog = () => {
  const { user } = getSessionAuth()

  const getAuth = user => ({
    divClass: !user ? 'is-disabled' : '',
    buttonClass: !user ? 'block' : 'none'
  })

  return (
    <div>
      <Header />
      <div className='content'>
        <Helmet>
          <title>Lesson Plans – Systems Thinking Resources</title>
          <meta name='description' content='Free, downloadable lesson plans for all ages.' />
          <meta property='og:title' content='Lesson Plans – Systems Thinking Resources' />
          <meta property='og:description' content='Free, downloadable lesson plans for all ages.' />
          <meta property='og:image' content='https://thinkingtoolsstudio.org/images/resources/shared/secondary-icon-blue.svg' />
          <meta property='og:url' content='https://thinkingtoolsstudio.org/resources/lessons' />
          <meta name='twitter:image:alt' content='Systems Thinking Lesson Plans' />
        </Helmet>

        <h1 className='resource-home-title mobilecenter'>
          Lesson Plans
        </h1>

        <h2 className='mobilecenter'>
          Systems Thinking Resources
        </h2>

        <p>
          Lesson plans offered have been produced and successfully used by educators with students in PreK-Secondary settings.
          When using the lesson plans, you may want to implement them as is, or modify them to better meet the needs of your students, the
          content your students will be learning, and the outcomes you desire.
        </p>

        <h4>
          Lesson plans fall into the following categories: PreK-Elementary and Secondary.
        </h4>

        <p>
          Each lesson plan in the list below features an icon that indicates its category. See below for more information on each of these designations and note that some lesson plans are applicable for both.
        </p>

        <div className='resource-icons-catalog'>
          <div className='icon-group'>
            <img className='resource-icon' src='/images/resources/shared/primary-icon-blue.svg' />

            <p>
              This content is best used by educators with students in PreK-Elementary environments. Some items are specific to subject (i.e. reading); however, we encourage you to think about how you might be able to use these resources in any subject area or area of interest.
            </p>
          </div>

          <div className='icon-group'>
            <img className='resource-icon' src='/images/resources/shared/secondary-icon-blue.svg' />

            <p>
              This content is best used by educators with young adult students in a secondary environment. Some items are specific to subject (i.e. English literature); however, we encourage you to think about how you might be able to use these resources in any subject area or area of interest. Many secondary items are also applicable to adults in higher education or professional development settings.
            </p>
          </div>
        </div>

        <div className='resource-cards'>
          {resourceList
            .filter(r => r.groups.includes('lesson'))
            .sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
            .map(r =>
              <div key={r.path} className='r-content'>
                <div className='r-info'>
                  <h2>
                    <a className={getAuth(user).divClass} href={`/resources/${r.path}/${r.sections[0].path}`}>{r.name}</a>
                  </h2>

                  <p className='r-summary'>
                    {r.content}
                  </p>

                  <p className='r-concepts'>
                    <b>ST Habits & Tools:</b> {r.concepts}
                  </p>

                  <p className='r-audience'>
                    <b>Audience:</b> {r.audience}
                  </p>

                  <a href='https://waterscenterst.org/login'>
                    <button style={{ display: getAuth(user).buttonClass }} className='auth-button'>
                      Log in or Sign Up to View This Content
                    </button>
                  </a>
                </div>

                <div className='r-icons'>
                  {r.icons.map(i =>
                    <img
                      key={i.id}
                      className='resource-icon'
                      src={i.source}
                      alt={i.name}
                    />)}
                </div>
              </div>)}
        </div>
      </div>
      <Footer />
    </div>
  )
}
