import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { editAction, loadAction } from './edit.actions'
import { AuthComponent } from 'components/auth'
import PropTypes from 'prop-types'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

// Validation items
const required = value => value ? undefined : 'Required'
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
const minValue = min => value =>
  value && value.length < min ? `Must be at least ${min} characters` : undefined
const minValue5 = minValue(5)
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength80 = maxLength(80)
const maxLength40 = maxLength(40)
const maxLength20 = maxLength(20)

// Select lists
const countryList = require('../../lib/lists/countries')

// Field for input

class EditProfileView extends Component {
  componentDidMount () {
    this.props.fetchUserData()
  }

  renderField ({ input, label, type, meta: { touched, error, warning } }) {
    return (
      <div>
        <label>{label}</label>
        <div>
          <input {...input} placeholder={label} type={type} />
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    )
  }

  // Field for select menu
  renderSelectField ({ input, label, type, meta: { touched, error }, children }) {
    return (
      <div>
        <label>{label}</label>
        <div>
          <select {...input}>
            {children}
          </select>
          {touched && error && <span>{error}</span>}
        </div>
      </div>
    )
  }

  render () {
    const { handleSubmit, error, submitting } = this.props
    return (
      <div>
        <Header />
        <main id='settings-page'>
          <section className='photo-header edit-profile'>
            <h1>Edit Learner Profile</h1>
            <img src='images/site/edit-icon.svg' />

          </section>
          <section className='white'>
            <p className='centered'>
              Fill out the form below to edit your user profile.
            </p>
          </section>
          <section className='grey'>
            <p className='centered'>An * indicates required information.</p>
            <form onSubmit={handleSubmit}>
              <fieldset disabled={submitting}>
                <div className='form-field'>
                  <label htmlFor='firstname'>First Name*</label>
                  <Field
                    name='firstname'
                    component={this.renderField}
                    type='text'
                    validate={[required, maxLength40]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='lastname'>Last Name*</label>
                  <Field
                    name='lastname'
                    component={this.renderField}
                    type='text'
                    validate={[required, maxLength80]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='city'>City*</label>
                  <Field
                    name='city'
                    component={this.renderField}
                    type='text'
                    validate={[required, maxLength40]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='state'>State/Province*</label>
                  <Field
                    name='state'
                    component={this.renderField}
                    type='text'
                    validate={[required, maxLength80]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='zip'>Zip</label>
                  <Field
                    name='zip'
                    component={this.renderField}
                    type='text'
                    validate={[maxLength20]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='country'>Country*</label>
                  <Field name='country' component={this.renderSelectField} validate={[required, maxLength80]}>
                    <option value='' disabled>select</option>
                    {countryList.map(option => <option key={option.id} value={option.value}>{option.value}</option>)}
                  </Field>
                </div>

                <div className='form-field'>
                  <label htmlFor='organization'>School/Organization*</label>
                  <Field
                    name='organization'
                    component={this.renderField}
                    type='text'
                    validate={[required, maxLength80]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='position'>Title/Position</label>
                  <Field
                    name='position'
                    component={this.renderField}
                    type='text'
                    validate={[maxLength80]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='username'>Username*</label>
                  <Field
                    name='username'
                    component={this.renderField}
                    type='text'
                    validate={[required, minValue5, maxLength20]}
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor='email'>Email*</label>
                  <Field
                    name='email'
                    component={this.renderField}
                    type='email'
                    validate={[required, email, maxLength80]}
                  />
                </div>
                {error && <strong>{error}</strong>}
                <button className='blue' type='submit'>Submit</button>
              </fieldset>
            </form>
          </section>
          <section className='white'>
            <p className='centered'>
              By using this service, you agree to our <a href='/terms'>Terms and Conditions</a> and <a href='/our-privacy-policy'>Privacy Policy</a>.
            </p>
          </section>
        </main>
        <Footer />
      </div>
    )
  }
}
EditProfileView.propTypes = {
  fetchUserData: PropTypes.func,
  handleSubmit: PropTypes.any,
  error: PropTypes.any,
  submitting: PropTypes.any
}

let EditProfile = reduxForm({
  form: 'EditProfile',
  enableReinitialize: true
})(EditProfileView)

EditProfile = connect(
  state => ({
    editProfile: state.editProfile,
    initialValues: state.editProfile.formValues
  }),
  (dispatch) => ({
    async onSubmit (d) {
      const result = await editAction(d)
      dispatch(result)
      window.alert('Saved Successfully!')
    },
    async fetchUserData () {
      const userData = await loadAction()
      dispatch(userData)
    }
  })
)(AuthComponent(EditProfile))

exports.EditProfile = EditProfile
