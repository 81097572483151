import * as React from 'react'

export const Section01 = () =>
  <div className='content'>

    <h3>
      Initial Question to Ponder:
    </h3>

    <p>
      <em>Why would it be important to consider perspectives from other people when taking action or completing a task?</em>
    </p>

    <img src='images/resources/mirror-mirror-star/star-template.svg' className='lesson-img med-img' />

    <h3>
      Materials:
    </h3>

    <ul>
      <li>
        Half sheet handouts with printed double star on both sides (one for each person). You can download the handout <a href='https://ttsfilestore.blob.core.windows.net/ttsfiles/mirror-mirror-star-handout.pdf' target='_blank' rel='noopener noreferrer'>here</a>.
      </li>

      <li>
        One small mirror for every 2 people.  Size should be approximately 3 in. X 4 in.
      </li>
    </ul>

    <h3>
      Activity Description:
    </h3>

    <p>
      Working in partners, each take turns with the two challenges listed below.
    </p>

    <p>
      <strong>Challenge 1:</strong> With a pencil or a pen, focus on the border that forms the double star.  Draw a line in the center of the two border lines. Do your best to keep your line in the center of the outlined path. The result will be a completed hand-drawn star shape that is in the center of the star border.
    </p>

    <p>
      <em>Note: This first task should be relatively easy.  You can even ask partners to time one another to increase the level of challenge.</em>
    </p>

    <p>
      <strong>Challenge 2:</strong> Repeat the same task on the other side of the paper as described in Challenge 1, but you must not look at the paper on the table, and are only allowed to look at the image of the star reflected in the mirror.  Your partner will hold the mirror so you can see your star as you attempt the task.
    </p>

    <p>
      <em>Note: This second challenge will be significantly more difficult for each person. Some might get so frustrated that they fail to complete the task. If noticing frustration, assure participants that any attempt at the task is important to learning from this activity.</em>
    </p>

    <h3>
      Initial Debrief:
    </h3>

    <p>
      “What did you experience as you worked through each of the Challenges?  As a mirror holder?  As a line drawer?”
    </p>

    <p>
      “How can you explain the difficulty some had with Challenge 2?” What role does your brain and your ability to cognitively process the reflection have on your performance?”
    </p>

    <p>
      “What connections can you make between the challenges and the actions you take as a result of your own perspectives?”
    </p>

    <p>
      <em>Note: Here you want to encourage people to surface how difficult changing perspectives is when you are taking action.  It is typically much easier to act when seeing things from your own point of view.</em>
    </p>

    <p>
      Revisit the Question to Ponder: <em>“Why would it be important to consider perspectives from other people when taking action or completing a task?”</em>
    </p>

    <h3>
      Habits of a Systems Thinker Debrief:
    </h3>

    <p>
      <em>“Which Habits of a Systems Thinker did we practice during this exercise? Justify or explain your choice(s).”</em>
    </p>

    <h4>
      Targeted Habit & Possible Responses:
    </h4>

    <div className='habits-row'>
      <img src='images/shared/habit-cards/shadow/perspectives.png' className='small-img' />

      <p>
        “By looking at the reflection of the star in the mirror, I had to change my perspective.  I was tempted to look away from the mirror and complete the task by looking directly at my paper.  It was really hard!”<br /><br />

        “This exercise made me realize how hard it is to move away from your own point of view and see things from other perspectives, especially when it appears that other perspectives are backwards.”
      </p>
    </div>

    <h4>
      Other Habits & Possible Responses:
    </h4>

    <div className='habits-row'>
      <img src='images/shared/habit-cards/shadow/successive-approx.png' className='small-img' />

      <p>
        “There were a number of times in Challenge 2 when I had to regroup and get my orientation back.  Sometimes I had to stop, take a break and try to improve as I went along. This is a lesson learned for me as I am trying to understand completely new challenges or new ways I am not used to.”
      </p>
    </div>

    <div className='habits-row'>
      <img src='images/shared/habit-cards/shadow/leverage.png' className='small-img' />

      <p>
        “I realized I had to think backwards or opposite when I was doing Challenge 2.  As soon as I saw the reflection, and the need to think backwards as a structure, I was better able to ‘tell’ my hand what to do. This understanding was leverage for me as I worked on Challenge 2.”
      </p>
    </div>

    <div className='habits-row'>
      <img src='images/shared/habit-cards/shadow/mental-models.png' className='small-img' />

      <p>
        “After Challenge 1, my mental model was like, ’Oh, this is easy!’ My mindset that this would easy ended up causing me more frustration when I was trying Challenge 2.  It made me want to give up rather than persist.  I can see when people who expect to sail through a task or learning challenge, bump up against adversity or challenge might give up rather than persist.  It could be all about mental models.  ‘If I’m not good at something right away, then I don't want to be involved.’”
      </p>
    </div>

  </div>
