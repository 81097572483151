import { SubmissionError } from 'redux-form'
import { apiCallAsync } from 'components/shared/api'

export const ActionTypes = {
  LoadEventsSuccess: 'EVENTS.LOAD.SUCCESS',
  RegisterSuccess: 'EVENTS.REGISTER.SUCCESS'
}

export const loadEventsAction = async () => {
  try {
    const r = await apiCallAsync('/events', null, 'GET')
    return { type: ActionTypes.LoadEventsSuccess, payload: { eventData: r } }
  } catch (ex) {
    let err = 'Unknown error'
    try {
      err = JSON.parse(ex.message).message
    } catch (ex2) { }
    throw new SubmissionError({
      _error: err
    })
  }
}

export const registerAction =
    (data) => async (dispatch, getState) => {
      try {
        const r = await apiCallAsync('/events/register', data, 'POST')
        return { type: ActionTypes.RegisterSuccess, payload: r }
      } catch (ex) {
        let err = 'Unknown error'
        try {
          err = JSON.parse(ex.message).message
        } catch (ex2) {}
        throw new SubmissionError({
          _error: err
        })
      }
    }
