import * as React from 'react'
import { Field } from 'redux-form'

export const Lesson02s01 = () =>
  <div className='content'>
    <p>Systems thinking offers you a powerful new perspective, a specialized
      language, and a set of tools that you can use to address the most perplexing
      problems in your everyday life and work. Systems thinking is a way of
      understanding your day-to-day world that emphasizes the relationships among a
      system's parts, rather than the parts themselves.
    </p>
    <img
      id='systems-thinking-is'
      src='/images/courses/habits/01-introduction/systems-thinking-is.svg'
      alt='Systems Thinking Is'
    />
    <h3>Why Is Systems Thinking Important?</h3>
    <p>Systems thinking can help you design thoughtful, enduring solutions to
      challenges. In its simplest sense, systems thinking gives you a more accurate,
      and deeper understanding of experiences and conditions. This in-depth
      understanding provides added insights to help you work with a system's natural
      forces in order to achieve the results you desire. It also encourages you to
      think about problems and solutions with an eye toward the long view.
    </p>
    <h4>Questions to get you started as a systems thinker:</h4>
    <ol>
      <li>How can I move beyond my understanding of a problem or challenge and broaden
        my view with the help of others in my system?
      </li>
      <li>How could their perspectives help me see challenges in ways I have never
        imagined?
      </li>
      <li>How might a particular solution I’m considering play out over the long run?</li>
      <li>What unintended consequences might surface because of my preferred action?</li>
      <li>How do my preferred actions limit me from seeing other options that could
        actually produce more desirable results?
      </li>
    </ol>
    <p>These are the types of questions systems thinkers consistently pose. Systems
      thinkers develop habits of thinking and use visual tools to help deepen their
      understanding of systems of interest. Developing a deep understanding of complex
      systems is the practice of a systems thinker.
    </p>
    <h3>Exploring the Characteristics of Systems</h3>
    <p>You will notice the word system is commonly used in many different contexts.
      Examples of familiar systems include:
    </p>
    <ul>
      <li>Solar system</li>
      <li>Human body systems, including digestive, respiratory, nervous, endocrine and
        immune systems
      </li>
      <li>Security systems</li>
      <li>Computer operating systems</li>
      <li>School systems</li>
      <li>Transportation systems</li>
      <li>And many others</li>
    </ul>
    <div className='reflection'>
      <h4>After reviewing this list, what other systems can you add to this list?</h4>
      <Field
        name='reviewing'
        component='textarea'
        className='reflection-input-large'
        placeholder='Enter your response here.'
      />
    </div>
  </div>
