import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise04 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>Identify a possible policy change or innovation that could impact a system of interest. How might this Habit affect your implementation of a policy change or innovation to your system?</h4>
    <Field
      name='possible-policy'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>If you make a change to the system, how long before you expect to see the desired results? If you stop doing something, how long before you can expect to see the negative consequences decline or abate?</h4>
    <Field
      name='system-change'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What will be the role of time delays in the effects you expect to see?</h4>
    <Field
      name='time-role'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What can you do to mitigate the effect of these time delays? Perhaps you need to explicitly make people aware of the potential time delays so they can plan for them and/or implement temporary measures that will allow for the time delays.</h4>
    <Field
      name='time-effect'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Will the change you propose show immediate results or will you need to wait to see improvement? If you need to wait, how long is the anticipated wait?</h4>
    <Field
      name='immediate-results'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
