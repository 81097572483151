import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>Identify a situation when there was a delay between cause and effect in your life. Describe this scenario. How does the recognition of this time delay help you see the causal connection?</h4>
    <Field
      name='cause-effect'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
