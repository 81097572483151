import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <img
      className='lesson-img med-img'
      src='/images/courses/habits/08-structure/course-img.png'
      alt='System Structure'
    />
    <p>
      In the illustration shown, the wind lifts the kite higher in the air as the man lets out the string. The wind spins the woman's pinwheel round and round. The wind plays an important role, yet it is not only the wind, but also the structure of each plaything that generates its behavior. The pinwheel’s structure can be described as a square piece of material folded on the diagonal and pinned to a stick. The kite is also made from a lightweight piece of material, but a frame supports its structure. The kite is attached to a long string and has a tail. The structure of the toys determines which one spins and which one flies. A systems thinker understands that a system’s structure generates its behavior.
    </p>
    <div className='quote'>
      <p className='quote-text'>“There is no doubt whatsoever about the influence of architecture and structure upon human character and action. We make our buildings and afterwards they make us. They regulate the course of our lives.”</p>
      <p className='quote-author'> — Winston Churchill, Prime Minister </p>
    </div>
    <p>Structures can be very tangible, as with the kite and the pinwheel or the various configurations of space in a building. As suggested by Churchill in the above quotation, buildings are a good place to start identifying system structures. We design buildings in specific ways for specific purposes. For example, a commercial kitchen must have certain features to make it functional for preparing food. Residential kitchens have many of those same features. They are designed for homes to accommodate not only food preparation, but also to serve as a place for family and friends to congregate while the meal is being prepared. Physical structure is a good place to start as systems thinkers assess space, furniture placement, lighting and acoustics.</p>
    <h3>Desks Versus Tables</h3>
    <img
      className='system-icon right clear'
      src='/images/courses/shared/icon-school.svg'
      alt='School Example'
    />
    <p>A traditional classroom may be arranged with desks in rows so all students face the front of the room. This arrangement is ideal for listening to a lecture, taking notes, and watching an instructor write on a chalkboard, but it may not be the most suitable arrangement if the teacher desires a more collaborative atmosphere in the classroom. Instead, he/she may exchange desks for tables and replace the chalkboard at the front of the room with portable white boards to encourage students to work together to solve equations and share their thinking with other students.</p>
    <p>Wedding planners pay careful attention to physical structures as they design a plan that will meet the expectations of the soon-to-be married couple and their families and guests. In addition to space, they pay attention to the structures that maximize interactions and engagement with other guests. Let’s use this next example to apply the importance of structure on wedding planning.</p>
    <img
      className='system-icon right clear'
      src='/images/courses/shared/icon-community.svg'
      alt='Community Example'
    />
    <p>Excited about their upcoming wedding reception, Mark and Madeline had a serious conversation about whether or not to use place cards. Mark preferred a more laissez-faire approach where guests were free to mingle for drinks and then make their way to a table of their choice when it was time for the meal. Madeline preferred a more formal approach with place cards — a tangible structure that would direct the movement of their guests and influence the conversations that would take place during the meal.</p>
    <img
      className='system-icon right clear'
      src='/images/courses/shared/icon-family.svg'
      alt='Family Example'
    />
    <p>She also wanted family members to mingle and interact with invited friends.  After some convincing, Mark agreed with Madeline that the use of place cards would be a structure that would help reduce potentially awkward situations and help ensure that all their guests had an enjoyable evening.</p>
    <p>Systems thinkers who recognize that a system’s structure generates its behavior are intentional about matching a system’s design with its purpose. They envision the desired system behavior and then they create the structures that will produce the desired outcomes. If you desire to change the way a system behaves, you do so by changing the structure of the system. Structures influence the behavior of the system. Those structures can be physical structures, as in architecture; laws and policies, as in government; or social structures like traditions and routines.</p>
  </div>
