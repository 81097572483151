import * as React from 'react'

export const Lesson01 = () =>
  <div className='content'>
    <h3>
      Introduction
    </h3>

    <p>
      Before we begin, let’s do a quick review of causal links. You can revisit the <a href='/courses/05-causal-links'>causal links course</a> if needed.
    </p>

    <h3>
      Causal Link Guidelines
    </h3>

    <div className='key'>
      <h4>
        KEY:
      </h4>

      <p>
        An “S” or “+” indicates that the variables change in the same direction (both go up or both go down) or a change in a variable adds to the following variable.
      </p>

      <p>
        An “O” or “-” indicates that the variables change in opposite directions or a change in a variable subtracts from the following variable.
      </p>
    </div>

    <p>
      When drawing cause → effect linkages between variables, use arrows to show the direction of causality. The arrowheads are labeled to show the relationship between the variables.
    </p>

    <p>
      <b>HINT:</b> To create your labels, always start with the assumption that the first variable goes up.
    </p>

    <p>
      Let’s use a dental example of variables that include sugar intake, the development of new cavities and teeth brushing.
    </p>

    <p>
      So, if sugar intake goes up, then what happens to cavities (all else being equal)? They also go up, so the link is labeled with an “S” or “+” sign.
    </p>

    <h3>
      Examples
    </h3>

    <img
      className='causal-img med-img'
      src='/images/courses/tools/06-connection-circles/sugar-intake.svg'
      alt='Sugar Intake'
    />

    <p>
      <b>Tell the story:</b> As sugar intake increases, the potential for new cavities increases.
    </p>

    <img
      className='causal-img med-img'
      src='/images/courses/tools/06-connection-circles/brushing-teeth.svg'
      alt='Brushing Teeth'
    />

    <p>
      <b>Tell the story:</b> When people continue to brush their teeth, the potential for new cavities decreases.
    </p>
  </div>
