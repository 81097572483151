import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson01 = () =>
  <div className='content'>

    <p>
      You might be in a “fixes that backfire” scenario when…
    </p>

    <div className='quote'>
      <p className='quote-text'>
        You think you've solved a problem in the short run, yet the solution actually makes the problem worse in the long run.
      </p>
    </div>

    <img
      className='lesson-img large-img block'
      src='/images/courses/archetypes/01-fixes-that-backfire/fixes-that-backfire.svg'
      alt='Fixes That Backfire'
    />

    <h3>
      The Story of this Archetype
    </h3>

    <div className='arch-components'>
      <div className='arch-p-container'>
        <p>
          <em>As you read each part of the story, press the “Next Part of Story” button below the graphic to see the story of the archetype unfold.</em>
        </p>

        <p>
          <strong>Part 1:</strong> The story begins with a problem symptom.  You can think of a problem symptom in a variety of ways. It can be a need, a goal to be met, an opportunity for change or a difficult, complex, messy problem. Typically, it would be something that is causing enough distress or discomfort that there is a perceived need to take action or to respond in some way.
        </p>

        <p>
          <strong>Part 2:</strong> When faced with a problem symptom, people want to implement a solution, referred to in this archetype as a “fix.”  The archetype suggests this may be a quick fix, a solution that alleviates, not necessarily the root problem, but at least one or more of the problem symptoms.
        </p>

        <p>
          <strong>Part 3:</strong> At this point in the story, you have minimized “the pain.”  The problem has been addressed in the short term. The problem symptom improves.
        </p>

        <p>
          <strong>Part 4:</strong> The quick fix solution creates a balancing feedback loop by lessening the problem symptom. This causal relationship is noted by the B in the center of the loop. You can learn more about balancing feedback in the <a href='/courses/08-cld-balancing'>Causal Loop Diagrams Part 2: Balancing Feedback course</a>.
        </p>

        <p>
          <strong>Part 5:</strong> However, after some time has passed, unintended consequences begin to emerge as a result of the fix. These unintended consequences feed back to the original problem symptom causing it to reoccur and become worse.
        </p>

        <p>
          <strong>Part 6:</strong> The unintended consequence creates a <a href='/courses/07-cld-reinforcing'>reinforcing feedback loop</a> by increasing the problem symptom.Often times when the problem symptom gets worse the fix is applied more often or to a greater degree, which feeds back to the unintended consequence, increasing the problem symptom.This causal relationship is noted by the R in the center of the loop.
        </p>

        <p>
          The way to show there is a delay between two connected elements in a causal loop diagram is the placement of two small parallel lines in the middle of the connecting line. In the Fixes that Backfire archetype this time delay occurs between the quick fix and the unintended consequence.
        </p>
      </div>

      <div className='arch-build-container'>
        <ToggleImages
          images={[{ id: 1, image: '/images/courses/archetypes/01-fixes-that-backfire/ftb-step1.svg' }, { id: 2, image: '/images/courses/archetypes/01-fixes-that-backfire/ftb-step2.svg' }, { id: 3, image: '/images/courses/archetypes/01-fixes-that-backfire/ftb-step3.svg' }, { id: 4, image: '/images/courses/archetypes/01-fixes-that-backfire/ftb-step4.svg' }, { id: 5, image: '/images/courses/archetypes/01-fixes-that-backfire/ftb-step5.svg' }, { id: 6, image: '/images/courses/archetypes/01-fixes-that-backfire/ftb-step6.svg' }]}
        />
      </div>
    </div>

    <div className=' exerpt'>
      <p>
        <strong>Reflection:</strong>  Consider a time when you needed to respond quickly to a problem. <em>How did the need to move quickly impact the solution?<br />What problems were created?</em>
      </p>
    </div>

    <p>
      Continue to Lesson 2 for examples of stories that fit the Fixes that Backfire archetype.
    </p>

  </div>
