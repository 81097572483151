import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <div className='template-block'>
      <img
        className='template-double'
        src='/images/resources/success-to-the-successful/success-to-the-successful-summary-so.svg'
        alt='Success to the Successful Summary'
      />

      <img
        className='template-double'
        src='/images/resources/success-to-the-successful/success-to-the-successful-template-so.svg'
        alt='Success to the Successful Template'
      />
    </div>
  </div>
