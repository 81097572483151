import { SubmissionError } from 'redux-form'
import { apiCallAsync } from 'components/shared/api'

export const ActionTypes = {
  EditSuccess: 'PROFILE.EDIT.SUCCESS',
  LoadSuccess: 'PROFILE.LOAD.SUCCESS'
}

export const editAction = async data => {
  try {
    const r = await apiCallAsync('/auth/update-user', data, 'PUT')
    return { type: ActionTypes.EditSuccess, payload: r }
  } catch (ex) {
    let err = 'Unknown error'
    try {
      err = JSON.parse(ex.message).message
    } catch (ex2) { }
    throw new SubmissionError({
      _error: err
    })
  }
}

export const loadAction = async () => {
  try {
    const r = await apiCallAsync('/auth/current-user', null, 'GET')
    console.log(r)
    return { type: ActionTypes.LoadSuccess, payload: { formValues: r } }
  } catch (ex) {
    let err = 'Unknown error'
    try {
      err = JSON.parse(ex.message).message
    } catch (ex2) { }
    throw new SubmissionError({
      _error: err
    })
  }
}
