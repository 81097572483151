import * as React from 'react'
import { ToggleImages } from 'components/shared/toggle-images'

export const Lesson01 = () =>
  <div className='content'>
    <p>
      You might be in a "tragedy of the commons” scenario when….
    </p>

    <div className='quote'>
      <p className='quote-text'>
        Competing parties all access the same common resource and choose actions that result in the resource being used in an unsustainable manner.
      </p>
    </div>

    <img
      className='lesson-img large-img block'
      src='/images/courses/archetypes/07-tragedy-of-the-commons/tragedy-commons.svg'
      alt='Tragedy of the Commons'
    />

    <p>
      The components of this archetype template include:
    </p>

    <ul>
      <li>
        Two parties, organizations, companies, groups or individuals represented by the letters A and B.
      </li>

      <li>
        Two reinforcing loops that show the results of their specific efforts.
      </li>

      <li>
        Two balancing loops that show the impact of those specific efforts on the whole.
      </li>
    </ul>

    <p>
      This archetype is most easily introduced in the context of the story from which it gets its name.
      During the Middle Ages, villages in the United Kingdom were often built around a commons. These
      historic parcels of land provided a resource that could be used by everyone in the village for a
      specific function, like grazing sheep. Villagers, also referred to as commoners, would bring their
      sheep to the commons to graze. Initially, a commons tended to be a mutually beneficial situation for
      everyone involved. In the case of the early British commons, the sheep had plenty to eat, stayed
      healthy and produced wool for the villagers. Over time, however, there was the potential for the
      commons to be overgrazed so there was no longer enough grass for all of the sheep. When the commons
      worked well, individuals or entities regulated the number of sheep and the amount of time the sheep
      spent in the commons to ensure it was sustainable and continued to benefit the entire village. But
      sometimes, overgrazing depleted the resource, adversely affecting the entire village and hence the
      name, “tragedy of the commons.”
    </p>

    <h3>
      Story of this Archetype
    </h3>

    <p>
      As you read each part of the story, press the “Next Part of Story” button below the graphic to see the story of the archetype unfold.
    </p>

    <div className='arch-components'>
      <div className='arch-p-container'>

        <p>
          <strong>Part 1:</strong> This story begins with two reinforcing loops. <a href='/courses/07-cld-reinforcing'>Reinforcing Feedback course</a>. Two parties, organizations,
          companies, groups or individuals, represented here by the letters <strong>A</strong> and <strong>B</strong>,
          engage in some common activity that produces mutual benefit. The first loop shows entity <strong>A</strong> engaging in an activity, like
          grazing sheep, that results in gain or benefit to him. The more activity, like grazing sheep, the
          greater the gains, as in more wool. The second loop shows entity <strong>B</strong> doing the same thing. These
          loops are occurring simultaneously.
        </p>

        <p>
          <strong>Part 2:</strong> Together, the activity of these two entities creates a total, or combined,
          amount of activity. In the story of the British commons, this would be the total amount of grass eaten
          by the sheep.
        </p>

        <p>
          <strong>Part 3:</strong> Over time, as total activity increases, the gain per individual goes down.
          This introduces a balancing dynamic in the story. <a href='/courses/08-cld-balancing'>Balancing Feedback course</a>.
          This decrease in the gain per individual activity decreases the net gain for both person <strong>A</strong> and <strong>B</strong>. As a
          result, they increase their activity in order to achieve the same result, increasing the total
          activity, which further decreases the gain per individual’s activity. In the case of the commons,
          as everyone continues to graze their sheep in the commons, the grass is depleted, adversely affecting
          the wellbeing of the sheep and eventually wool production.
        </p>

        <p>
          <strong>Part 4:</strong> There is one final element to this archetypical story: the resource limit.
          The resource limit directly impacts the amount each individual entity is able to gain. In the case
          of grazing the commons, there is only so much grass available for grazing in the field. As the total
          activity, which is the combination of the grazing of both <strong>Farmer A’s</strong> and <strong>Farmer B’s</strong> sheep goes up,
          the resource limit is also impacting the gain per individual. This resource limit can be a limitation
          of physical space, in this case the size of the field, or current conditions, perhaps drought has
          caused normally fresh, green grass to be dry or sparse.  The resource limit intensifies the decreasing
          gains and can result in increased activity on the part of both entities to try to resume the net gains
          originally experienced by entity <strong>A</strong> and <strong>B</strong>.
        </p>
      </div>

      <div className='arch-build-container'>
        <ToggleImages
          images={[{ id: 1, image: '/images/courses/archetypes/07-tragedy-of-the-commons/totc-part1.svg' }, { id: 2, image: '/images/courses/archetypes/07-tragedy-of-the-commons/totc-part2.svg' }, { id: 3, image: '/images/courses/archetypes/07-tragedy-of-the-commons/totc-part3.svg' }, { id: 4, image: '/images/courses/archetypes/07-tragedy-of-the-commons/totc-part4.svg' }]}
        />
      </div>
    </div>

    <p>
      British commons still exist today because in certain cases, individuals learned from the “tragedy
      of the commons” and found a way to limit or regulate individual activity so that they are able to
      share the resource in ways that allow it to be sustainable over time.
    </p>

    <p>
      The Tragedy of the Commons archetype can have more than two reinforcing loops. There may be multiple
      entities who share a common resource, all initially netting gain from the resource, but over time they
      find that net gain decreasing. This can be shown by adding reinforcing loops that contribute to the total
      activity and impact the gain per individual activity. As with the example of the commons, there would most
      likely be more than two farmers, thus a reinforcing loop for each additional farmer.
    </p>

    <p>
      Daniel Kim, a systems thinking consultant, describes the tragedy of the commons as “all for one and none
      for all” or “individual gain, collective pain.”  With those ideas in mind, continue to Lesson 2 for more
      stories that can be told by the Tragedy of the Commons archetype.
    </p>
  </div>
