import * as React from 'react'

export const Lesson02 = () =>
  <div className='content'>
    <h3>
      Academic Performance
    </h3>

    <p>
      Tay had been an excellent high school student, socially and intellectually active, who earned an academic scholarship
      to attend college. Determined to continue his outstanding academic record, Tay set the goal at the beginning of his
      freshman year of college to earn all A’s. As Tay started receiving grades on tests and papers in his first semester
      classes, he was falling short of his goal of consistently producing “A” work. Tay decided to drop one of the harder
      classes he was taking and to attend sessions at the on-campus Tutoring Center.  The next week, he had a “B” on a paper.
      When his dad asked during their Sunday call that week how Tay was doing, Tay announced that he only needed to make the
      Dean’s List, not have straight A’s, to maintain his scholarship, so that was what he was aiming for.  He was shifting
      to a more attainable goal.
    </p>

    <h3>
      Personal Fitness
    </h3>

    <p>
      You decided after your annual physical that to improve your blood pressure you’d institute a fitness goal of walking
      10,000 steps each day. The first day you monitored your steps, you only registered 3,400. In order to increase your
      steps, you knew you’d have to make lifestyle changes. You started parking farther away from your office building,
      the grocery store and church on Sunday. During every phone conversation, you paced around the office or the house.
      When you came home from work, instead of starting dinner preparations right away, you took a 20-minute walk through
      the neighborhood. After two weeks, the step count was only up to 7,700 steps/day. Maybe the goal of 10,00 steps/day
      wasn’t realistic with your daily schedule. Maybe 8,000/day would be more realistic.
    </p>

    <h3>
      A Coach’s Drifting Goal
    </h3>

    <p>
      Your American football team entered the new season with high expectations based on its previous year’s performance
      and its veteran roster. You and your team’s fans were expecting to be the league champions! All your playoff games
      would be played in your home stadium. But a third of the way through the season, after suffering three particularly
      embarrassing losses and heading into a game against a division rival, your team’s division standing was third and
      you and team management decided it was time to take some serious corrective action. At this point in the season,
      that would mean roster adjustments. You benched your long-time starting quarterback in favor of your highly-touted
      but inexperienced rookie, and you traded for a wide-receiver who would have to learn your team’s plays instantaneously
      in order to contribute to the team’s success. Although the rookie quarterback showed promise, he inevitably made his
      share of rookie mistakes … being rattled into throwing interceptions or overthrowing his receivers. And the new receiver
      struggled to run the correct plays, which didn’t help. They were both improving, but soon you and management altered
      your expectations. This wouldn’t be the year the team won the league championship, with its accompanying homefield
      advantage throughout the playoffs. But if you made some more roster adjustments, perhaps re-inserting the experienced
      quarterback, the team could still at least make the playoffs. That would be the new goal.
    </p>
  </div>
