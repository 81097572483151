import * as React from 'react'
import { ToggleImage } from 'components/shared/toggle-image'
import { Field } from 'redux-form'

export const Exercise02 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>“Seeing is believing” is a common cause and effect phrase. Using a piece of
      scratch paper, draw a simple loop that illustrates the meaning of this phrase.
      Hint: Seeing is believing, and believing is based on what one sees.
    </p>
    <p>What is the story of your loop using a context that makes sense to you? For
      example, “When I actually see expected gains in our quarterly financial report,
      I will believe that this new approach to marketing is the way to go. As my
      belief in the power of this new approach grows, my hope for our continued
      financial gains will also grow.”
    </p>
    <h4>Tell the story of your loop here:</h4>
    <Field
      name='loop-story'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <p>Click on the button below to compare the drawing to yours.</p>
    <ToggleImage
      src='/images/courses/habits/09-circular-nature/seeing-believing.jpg'
      alt='Seeing is Believing'
      showText='Show Example'
      hideText='Hide Example'
    />
  </div>
