import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise05 = () =>
  <div className='content'>
    <h3>Practice the Tool</h3>
    <p>As a final practice for this course, you will build a stock-flow map about a complex system of interest to you. Begin with a pressing question that you want to explore by creating a map from scratch.</p>
    <p>As you are diving into the systems thinking practice of creating stock-flow maps, don’t hesitate to use an existing map and modify the stocks, flows, and converters to meet your needs. There are many basic structures that repeat across a variety of situations.</p>
    <p>You may choose to build on the example of employee engagement and trust described in Exercise 4, or use the “Advocate and Resister” story from Lesson 5 to create a map about how to bring about a major change.</p>
    <p>This template may also help you as you work to begin your own stock-flow map. Keep in mind that each map will be unique and may require different converters and connectors.</p>
    <img
      className='stock-flow-map'
      src='/images/courses/tools/03-stock-flow/stock-flow-map-template.svg'
      alt='Stock Flow Template'
    />
    <h3>Directions:</h3>
    <ol>
      <li>Draw a stock-flow map.</li>
      <li>Ask yourself the critical questions laid out in this course.</li>
      <li>Talk through the map with a friend or colleague.</li>
      <li>Revise your map if desired.</li>
    </ol>
    <Field
      name='stockFlowTemplate'
      component={FileUpload} image
      instructions='Upload a photograph of your map below.'
    />
    <h3>Connections to Other Courses:</h3>
    <p>In the Resources section of the Studio, you will find a stock-flow resource entitled <a href='/resources/water-activity/water-apparatus-assembly'>The Water Activity</a>. This activity requires some materials, but offers a kinesthetic experience to really build your understanding of stock-flow maps.</p>
    <p>How Full is Your Bucket?, a popular text by Tom Rath, is an example of stock-flow or bathtub thinking.</p>
  </div>
