import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { forgotAction } from 'components/forgot-password/password.actions'
import { push } from 'connected-react-router'
import { NoAuthComponent } from 'components/auth'
import PropTypes from 'prop-types'
import { Header } from 'components/shared/header'
import { Footer } from 'components/shared/footer'

// Validation items
const required = value => value ? undefined : 'Required'
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address' : undefined

// Field for input
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <input {...input} placeholder={label} type={type} />
    {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
)
renderField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object
}

function ForgotPasswordView ({ handleSubmit, error, submitting }) {
  return (
    <div>
      <Header />
      <main className='forgot-password-page'>
        <h1 className='centered'>Forgot Password</h1>
        <section className='white'>
          <p className='centered'>Please enter your email address in the form below. Once you hit “submit” you will receive an email in approximately in 1-10 minutes. When you get the email, click on the first link to reset your password. There will be a second link in the email that you can ignore. If you have questions about resetting your password, contact <a href='mailto:tech@waterscenterst.org'>tech@waterscenterst.org</a>.</p>
        </section>
        <section className='grey'>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={submitting}>
              <div className='form-field'>
                <label htmlFor='email'>Email*</label>
                <Field
                  name='email' component={renderField}
                  type='email' validate={[required, email]}
                />
              </div>
              {error && <strong>{error}</strong>}
              <button className='blue' type='submit'>Submit</button>
            </fieldset>
          </form>
        </section>
        <section className='white' />
      </main>
      <Footer />
    </div>
  )
}
ForgotPasswordView.propTypes = {
  handleSubmit: PropTypes.any,
  error: PropTypes.any,
  submitting: PropTypes.any
}

const ForgotPasswordForm = reduxForm({
  form: 'ForgotPassword',
  onSubmit: forgotAction
})(ForgotPasswordView)

export const ForgotPasswordPage = connect(
  (state, props) => props,
  (dispatch) => ({
    onSubmit: async (d) => {
      const result = await forgotAction(d)
      dispatch(result)
      window.alert('Check your email for additional instructions')
      dispatch(push('/login'))
    }
  })
)(NoAuthComponent(ForgotPasswordForm))
