import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { LessonLayout } from './LessonLayout'
import { IntroPage } from 'components/courses/course-parts/intro-page'
import { CourseFeedback } from 'components/courses/course-parts/course-feedback'
import { AuthComponent } from 'components/auth'
import PropTypes from 'prop-types'

const AuthCourseLayout = ({ course, match }) => (
  <div>
    <Switch>
      <Route
        path={`${match.url}/course-feedback`}
        render={props => <CourseFeedback {...props} course={course} />}
      />
      {course.lessons.map(l => (
        // create route for every lesson in the course
        // - the path for each lesson is /courses/{course-path}/{lesson-path}
        // - within each lesson, there can be sections, but any url parts after the {lesson-path}
        // will be handled by the LessonLayout
        // - the path /courses/{course-path} (i.e. course root) will take you to the intro page
        <Route
          key={l.path}
          path={`${match.url}/${l.path}`}
          render={props => (
            <LessonLayout {...props} lesson={l} course={course} />
          )}
        />
      ))}
      <Route
        path={match.url}
        render={props => <IntroPage {...props} course={course} />}
        exact
      />
      {/* for invalid paths, redirect to the intro of the course which is just {course-path} */}
      <Redirect to={match.url} />
    </Switch>
  </div>
)
export const CourseLayout = AuthComponent(AuthCourseLayout)

AuthCourseLayout.propTypes = {
  course: PropTypes.object,
  match: PropTypes.any
}
