import { SubmissionError } from 'redux-form'
import { apiCallAsync } from 'components/shared/api'

export const ActionTypes = {
  SaveFeedbackSuccess: 'SAVEFEEDBACK.SUCCESS'
}

export const saveFeedback =
  (data) => async (dispatch, getState) => {
    try {
      // console.log(data)
      const r = await apiCallAsync('/feedback', data, 'POST')
      return { type: ActionTypes.FeedbackSuccess, payload: r }
    } catch (ex) {
      // console.log(ex)
      let err = 'Unknown error'
      try {
        err = JSON.parse(ex.message).message
      } catch (ex2) {}
      throw new SubmissionError({
        _error: err
      })
    }
  }
