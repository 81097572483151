import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Take one day and keep an ongoing list of when you practice this Habit: Checks results and changes actions when needed: successive approximation.</p>
    <h4>Copy the instances into the text box.</h4>
    <Field
      name='day-keep'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <Field
      name='habitResult'
      component={FileUpload} image
      instructions='Or, upload your list below.'
    />
    <h4>Reflect on your list and note your reactions. Did anything surprise you? Did you see the value of setbacks and how they contribute to progress?</h4>
    <Field
      name='your-list'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
