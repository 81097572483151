import * as React from 'react'
import { Field } from 'redux-form'

export const Lesson02s01 = () =>
  <div className='content'>
    <p>Stock-flow maps are a tool that helps systems thinkers pay attention to accumulations and their rates of change.  Systems thinking is derived from the field of system dynamics, where practitioners and systems dynamicists use advanced stock-flow maps made into mathematical computer models to represent a system.</p>
    <p>A stock-flow map helps clarify and deepen understanding of a system. The map provides a visual that helps explain the causal factors contributing to a system’s behavior. When building the map, you use simple icons and arrows to show how system parts are connected. The visual map can help you begin to identify areas of potential leverage. <a href='/courses/14-leverage'>Click here</a> for a link to the Habits course on leverage.</p>
    <h3>Basic Definitions</h3>
    <div className='definitions'>
      <div className='definition'>
        <img
          className='example-img'
          src='/images/courses/tools/03-stock-flow/stock-icon.svg'
          alt='Stock Icon'
        />
        <div className='definition-info'>
          <p>Represents an accumulation, concrete or abstract, that can increase or decrease over time. It represents the “nouns” in a system.  It can only change based on the rate(s) of flow.</p>
        </div>
      </div>
      <div className='definition'>
        <img
          className='example-img'
          src='/images/courses/tools/03-stock-flow/flow-icon.svg'
          alt='Flow Icon'
        />
        <div className='definition-info'>
          <p>Represents the actions or processes that directly add to or take away from the stock. The flow is always a rate and is defined as units of the stock per units of time.  Flows represent the “verbs” in the system.</p>
        </div>
      </div>
      <div className='definition'>
        <img
          className='example-img'
          src='/images/courses/tools/03-stock-flow/converter-icon.svg'
          alt='Converter Icon'
        />
        <div className='definition-info'>
          <p>Holds information about the system that affects the rate of the flows or the value of another converter.</p>
        </div>
      </div>
      <div className='definition'>
        <img
          className='example-img'
          src='/images/courses/tools/03-stock-flow/connector-icon.svg'
          alt='Connector Icon'
        />
        <div className='definition-info'>
          <p>Indicates that there is a relationship between the elements of the system.</p>
        </div>
      </div>
    </div>
    <p>In order to apply these four elements to a simple stock-flow map, let’s refer to the picture shown, taken from the Habits card, “pays attention to accumulations and their rates of change.”</p>
    <img
      className='lesson-img small-img'
      src='/images/courses/habits/13-accumulations/course-img.png'
      alt='Accumulations'
    />
    <h3>Consider these questions:</h3>
    <h4>What is the key accumulation in this system?</h4>
    <h4>What is causing the water in the birdbath to increase?</h4>
    <h4>What is causing it to decrease?</h4>
    <p>One key accumulation in this picture is water in the birdbath. The boy is increasing the accumulation with water from the hose. Evaporation from the sun, water splashing out, and birds drinking are all causing water in the birdbath to decrease. This is a fairly simple system. There could be additional factors not shown in the picture, like rain water increasing the accumulation, but given the picture, it is fairly easy to describe in words what is happening. Now take a look at that same system illustrated in a stock-flow map.</p>
    <img
      className='birdbath-stock-flow'
      src='/images/courses/tools/03-stock-flow/birdbath-flow.svg'
      alt='Birdbath Stock Flow Diagram'
    />
    <h3>Reflection:</h3>
    <h4>Tell the story of the map by starting with, “When the water in the birdbath is low,…”</h4>
    <Field
      name='story'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
