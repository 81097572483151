import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise01 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <h4>Let’s identify some of the structures found in traffic, as part of a transportation system. What are some physical structures in that system?</h4>
    <ul>
      <li>Physical arrangement of roads and highways</li>
      <li>Traffic signals</li>
      <li>Others?</li>
    </ul>
    <Field
      name='structures-found'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>How do those structures influence the behavior of the system? How do vehicles move through the system?</h4>
    <ul>
      <li>Traffic lights indicate cars will have to stop when the signal is red, leading to delays for some drivers. If these delays are unacceptable, the local jurisdiction may build overpasses to produce a continuous traffic flow at that intersection.</li>
      <li>Many highway systems have installed electronic marquees with the capacity to display changing messages. The message may alert drivers to a specific hazard, or it may carry a general warning message (for example, reminding drivers of the danger of operating a vehicle in an impaired state). The variability of the message is intended to increase the likelihood that drivers pay attention to the warnings.</li>
      <li>What are some other examples of traffic structures that influence system behavior?</li>
    </ul>
    <Field
      name='structures-influence'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>Now choose a system that is relevant to you and your work. What structures can you identify? What behaviors are produced by those structures?</h4>
    <Field
      name='choose-system'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <p>Keep in mind that not all structures are physical, nor are all structures visible. Read on to explore less tangible structures.</p>
  </div>
