import * as React from 'react'
import { Field } from 'redux-form'

export const Exercise04 = () =>
  <div className='content'>
    <h3>Practice the Habit</h3>
    <p>Reflect on and respond to these questions as you consider important trends that impact you, your work and life.</p>
    <h4>What trends are important for you to pay attention to as you strive to achieve goals or desired outcomes?</h4>
    <Field
      name='trends-are'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>What types of trends do you tend to notice?</h4>
    <Field
      name='trends-do'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>How might these tendencies create blind spots that limit your ability to recognize other important patterns and trends?</h4>
    <Field
      name='create-blind'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
    <h4>How does your perspective influence the ways you see change? How can you become open to other perspectives that may help you see patterns in different ways?</h4>
    <Field
      name='see-change'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />
  </div>
