import * as React from 'react'
import { FileUpload } from 'components/shared/file-upload'
import { Field } from 'redux-form'

export const Exercise03 = () =>
  <div className='content'>
    <img
      className='lesson-img'
      src='/images/courses/tools/01-behavior-over-time-graphs/exercise-three-img.png'
      alt='Hope and Fear BOTG'
    />

    <h3>
      Practice the Habit
    </h3>

    <p>
      Consider an issue that you would like to think about more deeply. It could be related to your work, your family, your personal life or your community. Try to identify a situation where looking at the dynamics of the circumstances over time will give you new insight into the issue. For an additional challenge, think about a situation that has both actual and perceptual data. If you need an idea, here are some examples to get you started thinking about what to graph for practice:
    </p>

    <ul>
      <li>
        Consider the morale of your work team and how it may have changed over the past couple of years.
      </li>

      <li>
        Graph the amount of money you have spent or saved over the past few months.
      </li>

      <li>
        Consider how the quality of your relationship with your supervisor or co-workers has changed over time.
      </li>

      <li>
        Graph the amount of stress you have experienced since you began a new job.
      </li>

      <li>
        Graph your interest in social media since you opened up an account.
      </li>
    </ul>

    <p>
      Once you have identified your data source, think about a time frame that would give you the most information about the situation. You might be looking at a pattern of behavior over the course of a single day or you might want to consider data going five years back and projecting five years into the future. Answers to this question will determine how you label your X-axis.
    </p>

    <div className='quote'>
      <h3 id='quote-h3'>
        The Power of the Dotted Line
      </h3>

      <p className='quote-text'>
        BOTGs often show what is happening up to the present time, but BOTGs can also offer a picture of what you hope is going to happen or what you fear will happen. Use a dotted line to represent the present, and then extend your graph out to project what might happen in the next few days, weeks or months.
      </p>
    </div>

    <p>
      Now you are ready to determine how you will describe your variable on the Y-axis. What is changing over time? Will you measure it with a numeric scale? If it is a level of something abstract (happiness, confidence, morale), consider labeling your Y-axis simply High, Medium, Low. This makes it easy to compare multiple variables if general amounts work for all of them.
    </p>

    <p>
      Once you have established your X- and Y-axes, you are ready to draw your graph. Draw as many graphs as necessary to represent as many aspects of your story as possible.
    </p>

    <p>
      When your graph or set of graphs is complete, find a trusted partner or colleague and tell your story. As you explain the graph, focus on what is causing the slope of the line to change. Tell about significant events that may be connected to the timeline you have created along your X-axis.
    </p>

    <Field
      name='botgGraphs'
      component={FileUpload} image
      instructions='Upload a photo of your graph below.'
    />

    <h4>
      What important elements are changing in your system?
    </h4>

    <Field
      name='elements'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      What patterns or trends have emerged over time?
    </h4>

    <Field
      name='patterns'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h4>
      What new insights did you gain by using BOTGs to examine your system?
    </h4>

    <Field
      name='gain'
      component='textarea'
      className='reflection-input-large'
      placeholder='Enter your response here.'
    />

    <h3>
      Connection to Habits of a Systems Thinker
    </h3>

    <img
      className='small-img right'
      src='/images/shared/habit-cards/shadow/change-over-time.png'
      alt='Patterns Image'
    />

    <p>
      Behavior-over-time graphs are closely connected to these Habits of a Systems Thinker. Visit these courses in the Habits section if you haven’t already.
    </p>

    <p>
      <a href='/courses/06-patterns/' target='blank'>Observes how elements within systems change over time, generating patterns and trends.</a> This Habit connects directly with the tool behavior-over-time graphs. The illustration on this Habit card is another way to think about BOTGs as a tool. As the years (measure of time) progress, the girl changes in a number of ways. This graph is labeled for her size, specifically height.  But there are many other variables that can be graphed (some are easily measured while others are more perceptual).  Consider her level of maturity, her confidence, her ability to read. This Habit card helps to illustrate the many different patterns and trends that can be represented by a BOTG.
    </p>

    <img
      className='small-img right clear'
      src='/images/shared/habit-cards/shadow/surfaces-tests.png'
      alt='Assumptions Image'
    />

    <p>
      <a href='/courses/07-assumptions/' target='blank'>Surfaces and tests assumptions.</a> BOTGs help surface and test assumptions.  For example, consider a small group of colleagues working to increase their effectiveness as a team. If they want to assess their level of collaboration, each member of the team could draw a BOTG that shows their perception of their level of collaboration over the last few weeks. As team members share their graphs with one another, they begin to understand how effective their collaboration has been and areas where they might want to focus in order to grow.
    </p>
  </div>
