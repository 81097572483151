import * as React from 'react'

export const Section01 = () =>
  <div className='content'>
    <h3>
      Abstract:
    </h3>

    <p>
      To realize the vision of a high-quality education system that serves all children, regardless of zip code, race, religion, gender or language, it is critical that
      leaders understand and manage change in complex systems. For this to happen, leaders need to have the mindsets and skill sets to create equitable systems that adapt
      and respond to a changing educational landscape.
    </p>

    <p>
      <strong>Author:</strong> Tracy Benson
    </p>
  </div>
